/* eslint-disable jsx-a11y/iframe-has-title */
import "./DetailedReport.css";
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

import ReactExport from "react-export-excel";

import {
  CaretDownOutlined,
  CaretRightOutlined,
  FileExcelFilled,
  ClockCircleFilled,
  DashboardFilled,
  SortAscendingOutlined,
  CloseCircleTwoTone,
  CheckCircleTwoTone,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Table, Button, Input, Tooltip } from "antd";
import { isEmpty } from "lodash";
import { userSelector } from "../../redux/Selectors";

import Personalization from "~/pages/DetailedReport/components/Personalization";
import ModalCreateTask from "~pages/CreateTask/ModalCreateTask";
import Filters from "~/pages/DetailedReport/components/Filters";
import parsedDateToLocale from "~/helpers/parsedDateToLocale";
import Breadcrumb from "~/components/Breadcrumb/Breadcrumb";
import DrawerTask from "~/components/DrawerTask/DrawerTask";
import convertNumToTime from "~/helpers/convertNumToTime";
import ChartsModal from "./components/ChartsModal";
import stringToSlug from "~/helpers/stringToSlug";
import Modal from "antd/lib/modal/Modal";
import Calendar from "./components/CalendarReport/CalendarReport";

import { SET_FORM_FIELDS } from "~/redux/actions/DatailedReportFiltersActions";
import { ToastContext } from "~/providers/ToastProvider";
import { TaskRoutes, StatusesRoutes } from "~/http/routes";
import { getTask } from "~/util/util";
import { render } from "react-dom";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const { Search } = Input;

function commonTaskFields(tasks) {
  const checkList = [];
  const commonFields = [];

  function isCommon(field) {
    return (
      checkList.find(
        (e) =>
          e.label.toLowerCase() === field.label.toLowerCase() &&
          e.type_id === field.type_id,
      ) &&
      !commonFields.find(
        (e) =>
          e.label.toLowerCase() === field.label.toLowerCase() &&
          e.type_id === field.type_id,
      )
    );
  }

  for (const task of tasks) {
    for (const field of task.fields) {
      if (isCommon(field)) commonFields.push(field);
      else checkList.push(field);
    }
  }

  return commonFields;
}

function DetailedReport() {
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.detailedReportFilters);
  const { companyId } = useSelector((state) => state.userStore);
  const { groupBy, formFields } = useSelector(
    (state) => state.detailedReportFilters,
  );

  function compareByAlph(a, b) {
    if (a === null && b === null) {
      return 0; // Ambos são nulos, considerá-los iguais
    }
    if (a === null) {
      return 1; // Se 'a' é nulo, colocá-lo depois de 'b'
    }
    if (b === null) {
      return -1; // Se 'b' é nulo, colocá-lo depois de 'a'
    }

    // Comparação alfabética para valores não nulos
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0; // 'a' e 'b' são iguais
  }

  const initialColumns = [
    {
      title: "ID",
      dataIndex: "id",
      align: "center",
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: "Data início",
      dataIndex: "start_date_formatted",
      align: "center",
      render: renderColumn,
      showTitle: true,
      ellipsis: true,
      sorter: (a, b) => a.start_date - b.start_date,
    },
    {
      title: "Subprojeto",
      dataIndex: "subproject_name",
      width: "10%",
      align: "center",
      render: renderColumn,
      showTitle: true,
      ellipsis: true,
      sorter: (a, b) => a.subproject_name.localeCompare(b.subproject_name),
    },
    {
      title: "Título",
      dataIndex: "name",
      align: "center",
      render: renderColumn,
      showTitle: true,
      ellipsis: true,
      width: "10%",
      sorter: (a, b) => compareByAlph(a.name, b.name),
    },
    {
      title: "Status",
      dataIndex: "status_name",
      align: "center",
      render: renderColumn,
      showTitle: true,
      ellipsis: true,
      filters: [
        {
          text: "A FAZER",
          value: "A FAZER",
        },
        {
          text: "CONCLUÍDO",
          value: "CONCLUÍDO",
        },
        {
          text: "AGUARDANDO APROVAÇÃO",
          value: "AGUARDANDO APROVAÇÃO",
        },
        {
          text: "FAZENDO",
          value: "FAZENDO",
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.status_name.indexOf(value) === 0,
    },
    {
      title: "Responsável",
      dataIndex: "responsible_name",
      align: "center",
      width: "10%",
      render: renderColumn,
      showTitle: true,
      ellipsis: true,
      sorter: (a, b) => compareByAlph(a.responsible_name, b.responsible_name),
    },

    {
      title: "Setor",
      dataIndex: "sector_name",
      align: "center",
      render: renderColumn,
      showTitle: true,
      ellipsis: true,
      sorter: (a, b) => compareByAlph(a.sector_name, b.sector_name),
    },
    {
      title: "Serviço",
      dataIndex: "service_name",
      align: "center",
      render: renderColumn,
      showTitle: true,
      ellipsis: true,
      /*       sorter: (a, b) => compareByAlph(a.service_name, b.service_name),
       */
    },
    {
      title: "Inventário",
      dataIndex: "inventory_name",
      align: "center",
      render: renderColumn,
      showTitle: true,
      ellipsis: true,
      /*       sorter: (a, b) => compareByAlph(a.inventory_name, b.inventory_name),
       */
    },

    {
      title: "Tempo de execução",
      dataIndex: "worked_time_formatted",
      align: "center",
      render: renderColumn,
      showTitle: true,
      /*       sorter: (a, b) => a.worked_time - b.worked_time,
       */
    },
    {
      title: "Descrição",
      dataIndex: "description",
      align: "center",
      render: renderColumn,
      showTitle: true,
      ellipsis: true,
      /*       sorter: (a, b) => compareByAlph(a.description, b.description),
       */
    },
    {
      title: "Comentários",
      dataIndex: "comments",
      align: "center",
      render: renderColumn,
      showTitle: true,
      ellipsis: true,
      /*       sorter: (a, b) => compareByAlph(a.comments, b.comments),
       */
    },
    {
      title: "Situação",
      dataIndex: "deleted_at",
      align: "center",
      width: "8%",
      render: renderDeletedOrActive,
      showTitle: true,
      ellipsis: false,
      filters: [
        {
          text: "ATIVAS",
          value: null,
        },
        {
          text: "EXCLUÍDAS",
          value: "",
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) =>
        record.deleted_at
          ? record.deleted_at.includes(value)
          : record.deleted_at === value,
      //sorter: (a, b) => a.deleted_at - b.deleted_at,
    },
  ];

  function renderDeletedOrActive(_, data) {
    const { deleted_at } = data;
    if (deleted_at === null) {
      return (
        <span>
          <Tooltip title="Tarefa ativa!">
            <CheckCircleTwoTone
              style={{ fontSize: "1.2rem" }}
              twoToneColor="#64CA31"
              className="align-middle"
            />
          </Tooltip>
        </span>
      );
    } else {
      return (
        <span>
          <Tooltip title="Tarefa excluída!">
            <CloseCircleTwoTone
              style={{ fontSize: "1.2rem" }}
              twoToneColor="#EB5757"
              className="align-middle"
            />
          </Tooltip>
        </span>
      );
    }
  }

  const [tasks, setTasks] = useState([]);
  const [params, setParams] = useState([]);
  const [columns, setColumns] = useState(initialColumns);
  const [tasksGrouped, setTasksGrouped] = useState([]);
  const [commonFields, setCommonFields] = useState([]);
  const [selectedTask, setSelectedTask] = useState({});
  const [selectedImage, setSelectedImage] = useState("");
  const [statuses, setStatuses] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isDrawerLoading, setIsDrawerLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isEditTaskModalVisible, setIsEditTaskModalVisible] = useState(false);
  const [dashboardsModalVisible, setDashboardsModalVisible] = useState(false);
  const [TimeLineModalVisible, setTimeLineModalVisible] = useState(false);
  const [TimeLineModalIframeUrl, setTimeLineModalIframeUrl] = useState("");
  const [taskEditingData, setTaskEditingData] = useState(null);
  const { setToast } = useContext(ToastContext);

  const userStore = useSelector(userSelector);

  useEffect(() => {
    async function getStatus() {
      const res = await StatusesRoutes.getStatus(
        userStore.companyId,
        userStore.subprojectId,
      );
      setStatuses(res.data.data);
    }
    getStatus();
  }, []);

  useEffect(() => {
    dispatch({ type: SET_FORM_FIELDS, data: [] });
    const commonFields = commonTaskFields(tasks);
    setCommonFields(commonFields);
    setColumns(initialColumns);
  }, [tasks]);

  useEffect(() => {
    if (groupBy) group(groupBy);
    else setTasksGrouped([]);
  }, [groupBy, tasks]);

  const columnsGrouped = [
    {
      title: (
        <span>
          Agrupado por: <strong>{groupedBy()}</strong>
        </span>
      ),
      dataIndex: "name",
    },
  ];

  function groupedBy() {
    switch (groupBy) {
      case "status_id":
        return "Status";
      case "responsible_id":
        return "Responsável";
      case "project_id":
        return "Projeto";
      case "subproject_id":
        return "Subprojeto";
      case "sector_id":
        return "Setor";
      case "service_id":
        return "Serviço";
      case "inventory_id":
        return "Inventário";
    }
  }

  function group(field) {
    setIsLoading(true);

    const group = tasks.reduce((r, a) => {
      r[a[`${field}`]] = [...(r[a[`${field}`]] || []), a];
      return r;
    }, {});

    const newGroup = [];

    for (const g in group) {
      const name = group[g][0][groupBy.replace("id", "name")];
      const length = `: ${group[g].length}`;

      newGroup.push({
        key: g,
        name: name
          ? name + length
          : `Sem ${groupedBy().toLowerCase()}` + length,
        data: group[g],
      });
    }

    setTasksGrouped(newGroup);
    setIsLoading(false);
  }

  function renderColumn(text) {
    if (text) return text;
    return "-";
  }

  function renderFieldColumn(text) {
    if (text?.value) return text?.value;

    return "-";
  }

  function renderMidiasColumn(text) {
    if (!isEmpty(text?.value)) {
      return <div>{text.value.length} mídias</div>;
    }

    return "-";
  }

  async function handleGenerateReportClick() {
    setIsLoading(true);
    try {
      async function getTasks() {
        const res = await TaskRoutes.getReport(companyId, params);
        const tasks = await res.data.data;
        setTasks(tasks);
        setIsCalendarOpen(false);
        setIsCalendarOpen(true);
      }

      getTasks();
    } catch (err) {
      setToast({
        type: "error",
        title: "Erro!",
        message: "Um erro ocorreu ao gerar o relatório!",
        show: true,
        autohide: true,
      });
    }
    setIsLoading(false);
  }
  useEffect(() => {
    const subProjects = [];
    const statusFilter = [];

    for (const project of filters.projects) {
      for (const sp of project.subProjects) {
        if (subProjects.length) subProjects.push(sp);
        else subProjects.push(sp);
      }
    }

    for (const status of filters.statuses) {
      for (const st of status.statuses) {
        if (statuses.length) statusFilter.push(st);
        else statusFilter.push(st);
      }
    }

    setParams({
      initial_creation_date: filters.initialCreationDate || "",
      final_creation_date: filters.finalCreationDate || "",
      initial_task_begin_date: filters.initialTaskBeginDate || "",
      final_task_begin_date: filters.finalTaskBeginDate || "",
      initial_delivery_date: filters.initialDeliveryDate || "",
      final_delivery_date: filters.finalDeliveryDate || "",
      initial_task_end_date: filters.initialTaskEndDate || "",
      final_task_end_date: filters.finalTaskEndDate || "",
      subprojects: subProjects,
      sectors: filters.sectors,
      services: filters.services,
      inventories: filters.inventories,
      responsibles: filters.users,
      statuses: statusFilter,
      filedTasks: filters.filedTasks,
      deletedTasks: filters.deletedTasks,
    });
  }, [filters]);

  function handleCheckFormField() {
    setIsLoading(true);
    const aux = initialColumns;

    for (const field of formFields) {
      if (Number(field.type_id) === 8) {
        aux.push({
          title: field.label,
          dataIndex: stringToSlug(field.label),
          align: "center",
          render: renderMidiasColumn,
        });
      } else {
        aux.push({
          title: field.label,
          dataIndex: stringToSlug(field.label),
          align: "center",
          render: renderFieldColumn,
        });
      }
    }

    setColumns(aux);
    setIsLoading(false);
  }

  function handleOnRow({ id, subproject_id, deleted_at }) {
    return {
      onClick: (e) => handleRowClick(id, subproject_id, deleted_at),
    };
  }

  async function handleRowClick(id, subproject_id, deleted_at) {
    if (!deleted_at) {
      setIsDrawerLoading(true);
      setIsDrawerOpen(true);

      try {
        const res = await TaskRoutes.getTask(subproject_id, id);
        const task = res.data.data;
        const statusRes = await StatusesRoutes.getStatus(
          userStore.companyId,
          task.subproject_id,
        );
        task.statuses = statusRes.data.data;

        setSelectedTask(task);
      } catch (err) {
        setIsDrawerOpen(false);

        setToast({
          type: "error",
          title: "Erro!",
          message: "Um erro ocorreu ao exibir a tarefa selecionada!",
          show: true,
          autohide: true,
        });
      }

      setIsDrawerLoading(false);
    } else {
      setToast({
        type: "error",
        title: "Aviso!",
        message: "Impossível editar uma tarefa excluída!",
        show: true,
        autohide: true,
      });
    }
  }

  async function handleTaskValueChange(name, item, taskId, update) {
    const task = selectedTask;

    if (task) {
      task[name] = item;

      if (update) {
        try {
          const taskToUpdate = getTask(task);
          taskToUpdate.status_id = taskToUpdate.status.id;

          await TaskRoutes.updateTask(
            taskToUpdate,
            taskId,
            taskToUpdate.subproject_id,
          );

          setSelectedTask((e) => ({ ...e, ...task }));
          handleGenerateReportClick();
        } catch (err) {
          setToast({
            type: "error",
            title: "Erro!",
            message: "Um erro ocorreu alterar o status da tarefa!",
            show: true,
            autohide: true,
          });
        }
      }

      return true;
    }
  }
  async function handleTaskEdit(id) {
    const res = await TaskRoutes.getTask(userStore.subprojectId, id);
    const taskData = res.data.data;
    setTaskEditingData(taskData);
    setIsEditTaskModalVisible(true);
  }

  function handleCloseModalCreateTask() {
    setIsEditTaskModalVisible(false);
  }

  function handleCloseDrawerTask() {
    setIsDrawerOpen(false);
  }

  function handleCloseModalImage() {
    setSelectedImage("");
  }

  function handleGenerateDashboardsClick() {
    setDashboardsModalVisible(true);
  }

  async function handleGenerateTimeLineClick() {
    const res = await TaskRoutes.getTimeLineURL(companyId, params);
    setTimeLineModalIframeUrl(res);
    setTimeLineModalVisible(true);
  }

  function handleCloseTimeLineModal() {
    setTimeLineModalVisible(false);
  }

  function handleChartsModalCancel() {
    setDashboardsModalVisible(false);
  }

  async function handleSearchTaskIdAllCompany(id) {
    setIsLoading(true);
    try {
      async function getTasks() {
        const params = [];
        params.task_id = id;
        const res = await TaskRoutes.getReport(companyId, params);
        const task = await res.data.data;
        if (task.length <= 0) {
          setToast({
            type: "warning",
            title: "Ops!",
            message: "Task não encontrada nessa Empresa",
            show: true,
            autohide: true,
          });
        }
        setTasks(task);
        setIsCalendarOpen(false);
        //setIsCalendarOpen(true);
      }

      if (id) {
        getTasks();
      } else {
        setToast({
          type: "warning",
          title: "Ops!",
          message: "Digite um Id válido ",
          show: true,
          autohide: true,
        });
      }
    } catch (err) {
      setToast({
        type: "error",
        title: "Erro!",
        message: "Um erro ocorreu ao buscar a Task! ",
        show: true,
        autohide: true,
      });
    }
    setIsLoading(false);
  }

  function expandedRowRender(e) {
    const { data } = e;

    return (
      <Table
        dataSource={data}
        columns={columns}
        rowClassName="detailed-report-row"
        pagination={{
          showSizeChanger: true,
          position: ["bottomCenter"],
        }}
        locale={{ emptyText: "Sem dados para exibir" }}
        onRow={handleOnRow}
      />
    );
  }

  function renderExcelExport() {
    const filename = `relatorio-detalhado-${moment().format(
      "DD-MM-YYYY_HH-mm-ss",
    )}${!isEmpty(tasksGrouped) ? `(agrupado-por-${groupedBy()})` : ""}`;

    function formatColValue(col, dataIndex) {
      if (Array.isArray(col[dataIndex])) return col[dataIndex].join("\n");

      if (typeof col[dataIndex] === "object" && col[dataIndex] !== null) {
        if (Array.isArray(col[dataIndex].value))
          return col[dataIndex].value.join("\n");

        return col[dataIndex].value;
      }

      return col[dataIndex];
    }

    return (
      <ExcelFile
        filename={filename}
        element={
          <Button
            className="export-to-excel-button"
            type="default"
            size="large"
            icon={<FileExcelFilled style={{ fontSize: "18px" }} />}
            disabled={isEmpty(tasks)}
          >
            Exportar
          </Button>
        }
      >
        {isEmpty(tasksGrouped) ? (
          <ExcelSheet data={tasks} name="Relatório detalhado">
            {columns.map(({ title, dataIndex }) => (
              <ExcelColumn
                label={title}
                value={(col) => formatColValue(col, dataIndex)}
              />
            ))}
          </ExcelSheet>
        ) : (
          tasksGrouped.map(({ name, data }) => (
            <ExcelSheet data={data} name={name.replace(": ", "(") + ")"}>
              {columns.map(({ title, dataIndex }) => (
                <ExcelColumn
                  label={title}
                  value={(col) => formatColValue(col, dataIndex)}
                />
              ))}
            </ExcelSheet>
          ))
        )}
      </ExcelFile>
    );
  }

  return (
    <div>
      <Breadcrumb title="Relatório detalhado" routeToBack="/home" />

      <div className="d-flex justify-content-between">
        <Search
          style={{ width: "20%", marginTop: "auto" }}
          placeholder="Buscar Task em toda Empresa"
          onSearch={handleSearchTaskIdAllCompany}
          //allowClear
          enterButton
        />
        <Button
          className="btn-default"
          size="large"
          style={{ width: "250px", height: "55px" }}
          onClick={handleGenerateReportClick}
        >
          Gerar relatório
        </Button>
      </div>

      <div className="mt-4">
        <Filters />
      </div>

      <div className="mt-4">
        <Personalization
          commonFields={commonFields}
          isLoading={isLoading}
          onCheckFormField={handleCheckFormField}
        />
      </div>

      <div className="d-flex justify-content-end mt-4">
        {renderExcelExport()}

        <Button
          className="ml-3 "
          type="danger"
          size="large"
          disabled={isEmpty(tasks)}
          onClick={handleGenerateTimeLineClick}
          icon={
            <ClockCircleFilled style={{ fontSize: "18px", verticalAlign: 0 }} />
          }
        >
          Relatório personalizado
        </Button>
        <Button
          className="ml-3 "
          type="primary"
          size="large"
          disabled={isEmpty(tasks)}
          onClick={handleGenerateDashboardsClick}
          icon={
            <DashboardFilled style={{ fontSize: "18px", verticalAlign: 0 }} />
          }
        >
          Gerar dashboards
        </Button>
      </div>

      <Table
        className="mt-4"
        dataSource={isEmpty(tasksGrouped) ? tasks : tasksGrouped}
        columns={isEmpty(tasksGrouped) ? columns : columnsGrouped}
        rowClassName="detailed-report-row"
        loading={isLoading}
        expandable={
          !isEmpty(tasksGrouped) && {
            expandRowByClick: true,
            expandIcon: ({ expanded }) => {
              if (expanded) return <CaretDownOutlined />;
              return <CaretRightOutlined />;
            },
            expandedRowRender,
          }
        }
        locale={{ emptyText: "Sem dados para exibir" }}
        pagination={{
          showSizeChanger: true,
          position: ["bottomCenter"],
        }}
        scroll={{ x: 1500 }}
        onRow={isEmpty(tasksGrouped) ? handleOnRow : () => {}}
      />
      {isCalendarOpen ? (
        <>
          <hr />
          <h4 className="font-weight-bold mb-0">Calendário</h4>

          <Calendar tasks={tasks}></Calendar>
        </>
      ) : null}

      {isDrawerOpen ? (
        <DrawerTask
          show={isDrawerOpen}
          onClose={handleCloseDrawerTask}
          data={selectedTask}
          changeTaskValue={handleTaskValueChange}
          handleEditTask={handleTaskEdit}
          loading={isDrawerLoading}
        />
      ) : null}

      <ChartsModal
        isVisible={dashboardsModalVisible}
        onCancel={handleChartsModalCancel}
        tasks={tasks}
      />

      <Modal
        width="60%"
        className="modal-projects"
        centered
        visible={isEditTaskModalVisible}
        onCancel={handleCloseModalCreateTask}
        title="Editar tarefa"
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
      >
        <ModalCreateTask
          taskEditingData={taskEditingData}
          handleClose={handleCloseModalCreateTask}
          refreshList={() => {
            setTaskEditingData(null);
            setSelectedTask({});
            handleGenerateReportClick();
          }}
        />
      </Modal>

      <Modal
        centered
        visible={selectedImage}
        onCancel={handleCloseModalImage}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
      >
        <div className="d-flex justify-content-center">
          <img className="modal-field-img" src={selectedImage} />
        </div>
      </Modal>

      <Modal
        centered
        width={1000}
        height={800}
        visible={TimeLineModalVisible}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        onCancel={handleCloseTimeLineModal}
      >
        <div>
          <iframe
            src={TimeLineModalIframeUrl}
            frameborder="0"
            width={1000}
            height={800}
          ></iframe>
        </div>
      </Modal>
    </div>
  );
}

export default DetailedReport;
