import React from 'react';
import { Column } from "@ant-design/charts";

import { FadeLoader } from "react-spinners";
import { isEmpty } from 'lodash';

function TasksStatusColumnChart(props) {
	const {
		data,
	} = props;

	if (!isEmpty(data))
		return (
			<Column data={data}
				yField="value"
				xField="status"
				seriesField="status"
				label={{
					position: "middle",
					style: { fill: "#fff" },
				}}
				colorField="type"
				color={["#2D9CDB", "#F2994A", "#CFD8DC", "#8CD26B"]} />
		);


	return (
		<div className="d-flex align-items-center justify-content-center"
			style={{ height: "400px" }} >
			<FadeLoader color="#000000" loading={true} size={100} />
		</div>
	);
}

export default TasksStatusColumnChart;
