import React from "react";
import { Button as ButtonAntd } from 'antd';
import "./Button.css";
import { PlusOutlined } from '@ant-design/icons';
import { BeatLoader } from "react-spinners";

function Button({ children, className, withIcon, loading, htmlType, icon = <PlusOutlined />, ...others }) {

	const buttonStyle = {
		background: "#ffe300",
		borderColor: "#ffe300",
		color: "#000000",
		fontWeight: 500,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: '5px'
	};

	return (
		<ButtonAntd
			type="primary"
			className={`btn-default ${className}`}
			style={buttonStyle}
			icon={withIcon && icon}
			{...others}
		>
			{loading ? (
				<BeatLoader
					color={buttonStyle.color}
					loading={loading}
					size={10}
				/>
			) : (
				children
			)}
		</ButtonAntd>
	);
}

export default Button;
