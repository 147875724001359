import { Dropdown } from "antd";
import { notificationIcon } from "../../assets/svg/Icons";

import { useEffect, useState } from "react";
import { LogsRoutes } from "~/http/routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { convertToBrazilTime } from "~/util/date";
import { useSelector } from "react-redux";
import { userSelector } from "~/redux/Selectors";

function NotificationDropdown() {
  const [logList, setLogList] = useState([]);
  const [reload, setReload] = useState(false);
  const userStore = useSelector(userSelector);

  const { push } = useHistory();

  useEffect(() => {
    const getList = async () => {
      const res = await LogsRoutes.getLogsByUserLogged(userStore.companyId);
      setLogList(res?.data?.data);
    };
    getList();
  }, [reload, userStore?.companyId]);

  let itemsList = [];

  logList.forEach((log, index) => {
    const {
      action,
      subproject_id,
      task_id,
      user,
      created_at,
      subproject_name,
    } = log;
    switch (action) {
      case "subproject_id":
        itemsList.push({
          key: index,
          label: (
            <p
              style={{
                maxWidth: "30rem",
                marginBottom: "1px",
                borderBlockEnd: "solid 1px #D6DEE1",
              }}
            >
              <span className="material-icons mr-2 my-1">workspaces</span>{" "}
              <span style={{ fontWeight: "bold" }}>Tarefa</span> transferida
              para o Subprojeto{" "}
              <span style={{ fontWeight: "bold" }}>{subproject_name}</span> por{" "}
              <span style={{ fontWeight: "bold", color: " #FFE300" }}>
                {user}
              </span>{" "}
              em{" "}
              <span style={{ color: "#c3c3c3" }}>
                {convertToBrazilTime(created_at)}
              </span>
              .
            </p>
          ),
        });
        break;
      case "name":
        itemsList.push({
          key: index,
          label: (
            <p
              style={{
                maxWidth: "30rem",
                marginBottom: "1px",
                borderBlockEnd: "solid 1px #D6DEE1",
              }}
            >
              <span className="material-icons mr-2 my-1">title</span>
              <span style={{ fontWeight: "bold" }}>Título</span> da Tarefa{" "}
              <span style={{ fontWeight: "bold" }}>{task_id}</span> alterado por{" "}
              <span style={{ fontWeight: "bold", color: "#FFE300" }}>
                {user}
              </span>{" "}
              em{" "}
              <span style={{ color: "#c3c3c3" }}>
                {convertToBrazilTime(created_at)}
              </span>
              .
            </p>
          ),
        });
        break;
      case "responsible_id":
        itemsList.push({
          key: index,
          label: (
            <p
              style={{
                maxWidth: "30rem",
                marginBottom: "1px",
                borderBlockEnd: "solid 1px #D6DEE1",
              }}
            >
              <span className="material-icons mr-2 my-1">account_circle</span>{" "}
              <span style={{ fontWeight: "bold" }}>Responsável</span> da Tarefa{" "}
              <span style={{ fontWeight: "bold" }}>{task_id}</span> alterado por{" "}
              <span style={{ fontWeight: "bold", color: "#FFE300" }}>
                {user}
              </span>{" "}
              em{" "}
              <span style={{ color: "#c3c3c3" }}>
                {convertToBrazilTime(created_at)}
              </span>
              .
            </p>
          ),
        });
        break;
      case "status_id":
        itemsList.push({
          key: index,
          label: (
            <p
              style={{
                maxWidth: "30rem",
                marginBottom: "1px",
                borderBlockEnd: "solid 1px #D6DEE1",
              }}
            >
              <span className="material-icons mr-2 my-1">view_kanban</span>{" "}
              <span style={{ fontWeight: "bold" }}>Status</span> da Tarefa{" "}
              <span style={{ fontWeight: "bold" }}>{task_id}</span> alterada por{" "}
              <span style={{ fontWeight: "bold", color: " #FFE300" }}>
                {user}
              </span>{" "}
              em{" "}
              <span style={{ color: "#c3c3c3" }}>
                {convertToBrazilTime(created_at)}
              </span>
              .
            </p>
          ),
        });
        break;
      case "priority_id":
        itemsList.push({
          key: index,
          label: (
            <p
              style={{
                maxWidth: "30rem",
                marginBottom: "1px",
                borderBlockEnd: "solid 1px #D6DEE1",
              }}
            >
              <span className="material-icons mr-2 my-1">priority_high</span>{" "}
              <span style={{ fontWeight: "bold" }}>Prioridade</span> da Tarefa{" "}
              <span style={{ fontWeight: "bold" }}>{task_id}</span> alterada por{" "}
              <span style={{ fontWeight: "bold", color: " #FFE300" }}>
                {user}
              </span>{" "}
              em{" "}
              <span style={{ color: "#c3c3c3" }}>
                {convertToBrazilTime(created_at)}
              </span>
              .
            </p>
          ),
        });
        break;
      /* case "check_in":
        itemsList.push({
          key: index,
          label: (
            <p style={{ maxWidth: "26rem" }}>
              {`Tarefa ${task_id} foi realizado o check-in por ${user} em ${convertToBrazilTime(created_at)}.`}
            </p>
          ),
        });
        break;
      case "check_out":
        itemsList.push({
          key: index,
          label: (
            <p style={{ maxWidth: "26rem" }}>
              {` Tarefa ${task_id} foi realizado o check-out por ${user} em ${convertToBrazilTime(created_at)}.`}
            </p>
          ),
        });
        break; */
      case "description":
        itemsList.push({
          key: index,
          label: (
            <p
              style={{
                maxWidth: "30rem",
                marginBottom: "1px",
                borderBlockEnd: "solid 1px #D6DEE1",
              }}
            >
              <span className="material-icons mr-2 my-1">description</span>{" "}
              <span style={{ fontWeight: "bold" }}>Descrição</span> da tarefa{" "}
              <span style={{ fontWeight: "bold" }}>{task_id}</span> editado por{" "}
              <span style={{ fontWeight: "bold", color: " #FFE300" }}>
                {user}
              </span>{" "}
              em{" "}
              <span style={{ color: "#c3c3c3" }}>
                {convertToBrazilTime(created_at)}
              </span>
              .
            </p>
          ),
        });
        break;
      case "due_date":
        itemsList.push({
          key: index,
          label: (
            <p
              style={{
                maxWidth: "30rem",
                marginBottom: "1px",
                borderBlockEnd: "solid 1px #D6DEE1",
              }}
            >
              <span className="material-icons mr-2 my-1">event</span>{" "}
              <span style={{ fontWeight: "bold" }}>Data de entrega</span> da
              tarefa <span style={{ fontWeight: "bold" }}>{task_id}</span>{" "}
              alterado por{" "}
              <span style={{ fontWeight: "bold", color: " #FFE300" }}>
                {user}
              </span>{" "}
              em{" "}
              <span style={{ color: "#c3c3c3" }}>
                {convertToBrazilTime(created_at)}
              </span>
              .
            </p>
          ),
        });
        break;
      case "start_date":
        itemsList.push({
          key: index,
          label: (
            <p
              style={{
                maxWidth: "30rem",
                marginBottom: "1px",
                borderBlockEnd: "solid 1px #D6DEE1",
              }}
            >
              <span className="material-icons mr-2 my-1">today</span>{" "}
              <span style={{ fontWeight: "bold" }}>Data de início</span> da
              tarefa <span style={{ fontWeight: "bold" }}>{task_id}</span>{" "}
              alterado por{" "}
              <span style={{ fontWeight: "bold", color: " #FFE300" }}>
                {user}
              </span>{" "}
              em{" "}
              <span style={{ color: "#c3c3c3" }}>
                {convertToBrazilTime(created_at)}
              </span>
              .
            </p>
          ),
        });
        break;
      case "inventory_id":
        itemsList.push({
          key: index,
          label: (
            <p
              style={{
                maxWidth: "30rem",
                marginBottom: "1px",
                borderBlockEnd: "solid 1px #D6DEE1",
              }}
            >
              <span className="material-icons mr-2 my-1">inventory_2</span>{" "}
              <span style={{ fontWeight: "bold" }}>Inventário</span> da tarefa{" "}
              <span style={{ fontWeight: "bold" }}>{task_id}</span> alterado por{" "}
              <span style={{ fontWeight: "bold", color: " #FFE300" }}>
                {user}
              </span>{" "}
              em{" "}
              <span style={{ color: "#c3c3c3" }}>
                {convertToBrazilTime(created_at)}
              </span>
              .
            </p>
          ),
        });
        break;
      case "observer_added":
        itemsList.push({
          key: index,
          label: (
            <p
              style={{
                maxWidth: "30rem",
                marginBottom: "1px",
                borderBlockEnd: "solid 1px #D6DEE1",
              }}
            >
              <span className="material-icons mr-2 my-1">visibility</span>{" "}
              <span style={{ fontWeight: "bold" }}>Observador</span> adicionado
              à tarefa <span style={{ fontWeight: "bold" }}>{task_id}</span> por{" "}
              <span style={{ fontWeight: "bold", color: " #FFE300" }}>
                {user}
              </span>{" "}
              em{" "}
              <span style={{ color: "#c3c3c3" }}>
                {convertToBrazilTime(created_at)}
              </span>
              .
            </p>
          ),
        });
        break;
      case "observer_removed":
        itemsList.push({
          key: index,
          label: (
            <p
              style={{
                maxWidth: "30rem",
                marginBottom: "1px",
                borderBlockEnd: "solid 1px #D6DEE1",
              }}
            >
              <span className="material-icons mr-2 my-1">visibility_off</span>{" "}
              <span style={{ fontWeight: "bold" }}>Observador</span> removido da
              tarefa <span style={{ fontWeight: "bold" }}>{task_id}</span> por{" "}
              <span style={{ fontWeight: "bold", color: " #FFE300" }}>
                {user}
              </span>{" "}
              em{" "}
              <span style={{ color: "#c3c3c3" }}>
                {convertToBrazilTime(created_at)}
              </span>
              .
            </p>
          ),
        });
        break;
      case "sector_id":
        itemsList.push({
          key: index,
          label: (
            <p
              style={{
                maxWidth: "30rem",
                marginBottom: "1px",
                borderBlockEnd: "solid 1px #D6DEE1",
              }}
            >
              <span className="material-icons mr-2 my-1">info</span>{" "}
              <span style={{ fontWeight: "bold" }}>Setor</span> da tarefa{" "}
              <span style={{ fontWeight: "bold" }}>{task_id}</span> alterado por{" "}
              <span style={{ fontWeight: "bold", color: " #FFE300" }}>
                {user}
              </span>{" "}
              em{" "}
              <span style={{ color: "#c3c3c3" }}>
                {convertToBrazilTime(created_at)}
              </span>
              .
            </p>
          ),
        });
        break;
      case "service_id":
        itemsList.push({
          key: index,
          label: (
            <p
              style={{
                maxWidth: "30rem",
                marginBottom: "1px",
                borderBlockEnd: "solid 1px #D6DEE1",
              }}
            >
              <span className="material-icons mr-2 my-1">info</span>{" "}
              <span style={{ fontWeight: "bold" }}>Serviço</span> da tarefa{" "}
              <span style={{ fontWeight: "bold" }}>{task_id}</span> alterado por{" "}
              <span style={{ fontWeight: "bold", color: " #FFE300" }}>
                {user}
              </span>{" "}
              em{" "}
              <span style={{ color: "#c3c3c3" }}>
                {convertToBrazilTime(created_at)}
              </span>
              .
            </p>
          ),
        });
        break;

      case "comment":
        itemsList.push({
          key: index,
          label: (
            <p
              style={{
                maxWidth: "30rem",
                marginBottom: "1px",
                borderBlockEnd: "solid 1px #D6DEE1",
              }}
            >
              <span className="material-icons mr-2 my-1">comment</span>{" "}
              <span style={{ fontWeight: "bold" }}>Comentário</span> adicionado
              à tarefa <span style={{ fontWeight: "bold" }}>{task_id}</span> por{" "}
              <span style={{ fontWeight: "bold", color: " #FFE300" }}>
                {user}
              </span>{" "}
              em{" "}
              <span style={{ color: "#c3c3c3" }}>
                {convertToBrazilTime(created_at)}
              </span>
              .
            </p>
          ),
        });
        break;
      case "created_task":
        itemsList.push({
          key: index,
          label: (
            <p
              style={{
                maxWidth: "30rem",
                marginBottom: "1px",
                borderBlockEnd: "solid 1px #D6DEE1",
              }}
            >
              <span className="material-icons mr-2 my-1">add_circle</span>{" "}
              <span style={{ fontWeight: "bold" }}>Tarefa</span> adicionada ao
              Subprojeto{" "}
              <span style={{ fontWeight: "bold" }}>{subproject_name}</span> por{" "}
              <span style={{ fontWeight: "bold", color: " #FFE300" }}>
                {user}
              </span>{" "}
              em{" "}
              <span style={{ color: "#c3c3c3" }}>
                {convertToBrazilTime(created_at)}
              </span>
              .
            </p>
          ),
        });
        break;

      case "created_attachment_task":
        itemsList.push({
          key: index,
          label: (
            <p
              style={{
                maxWidth: "30rem",
                marginBottom: "1px",
                borderBlockEnd: "solid 1px #D6DEE1",
              }}
            >
              <span className="material-icons mr-2 my-1">attach_file</span>{" "}
              <span style={{ fontWeight: "bold" }}>Anexo</span> adicionado à
              Tarefa <span style={{ fontWeight: "bold" }}>{task_id}</span> por{" "}
              <span style={{ fontWeight: "bold", color: " #FFE300" }}>
                {user}
              </span>{" "}
              em{" "}
              <span style={{ color: "#c3c3c3" }}>
                {convertToBrazilTime(created_at)}
              </span>
              .
            </p>
          ),
        });
        break;
      case "deleted_attachment_task":
        itemsList.push({
          key: index,
          label: (
            <p
              style={{
                maxWidth: "30rem",
                marginBottom: "1px",
                borderBlockEnd: "solid 1px #D6DEE1",
              }}
            >
              <span className="material-icons mr-2 my-1">attachment</span>{" "}
              <span style={{ fontWeight: "bold" }}>Anexo</span> excluído da
              Tarefa <span style={{ fontWeight: "bold" }}>{task_id}</span> por{" "}
              <span style={{ fontWeight: "bold", color: " #FFE300" }}>
                {user}
              </span>{" "}
              em{" "}
              <span style={{ color: "#c3c3c3" }}>
                {convertToBrazilTime(created_at)}
              </span>
              .
            </p>
          ),
        });
        break;
      case "deleted_task":
        itemsList.push({
          key: index,
          label: (
            <p
              style={{
                maxWidth: "30rem",
                marginBottom: "1px",
                borderBlockEnd: "solid 1px #D6DEE1",
              }}
            >
              <span className="material-icons mr-2 my-1">delete</span>{" "}
              <span style={{ fontWeight: "bold" }}>Tarefa</span> excluída do
              Subprojeto{" "}
              <span style={{ fontWeight: "bold" }}>{subproject_name}</span> por{" "}
              <span style={{ fontWeight: "bold", color: " #FFE300" }}>
                {user}
              </span>{" "}
              em{" "}
              <span style={{ color: "#c3c3c3" }}>
                {convertToBrazilTime(created_at)}
              </span>
              .
            </p>
          ),
        });
        break;
      case "created_task_field":
        itemsList.push({
          key: index,
          label: (
            <p
              style={{
                maxWidth: "30rem",
                marginBottom: "1px",
                borderBlockEnd: "solid 1px #D6DEE1",
              }}
            >
              <span className="material-icons mr-2 my-1">checklist_rtl</span>{" "}
              <span style={{ fontWeight: "bold" }}>Formulário</span> adicionado
              à Tarefa <span style={{ fontWeight: "bold" }}>{task_id}</span> por{" "}
              <span style={{ fontWeight: "bold", color: " #FFE300" }}>
                {user}
              </span>{" "}
              em{" "}
              <span style={{ color: "#c3c3c3" }}>
                {convertToBrazilTime(created_at)}
              </span>
              .
            </p>
          ),
        });
        break;
      case "updated_task_field":
        itemsList.push({
          key: index,
          label: (
            <p
              style={{
                maxWidth: "30rem",
                marginBottom: "1px",
                borderBlockEnd: "solid 1px #D6DEE1",
              }}
            >
              <span className="material-icons mr-2 my-1">edit_note</span>{" "}
              <span style={{ fontWeight: "bold" }}>Anexo</span> alterado na
              Tarefa <span style={{ fontWeight: "bold" }}>{task_id}</span> por{" "}
              <span style={{ fontWeight: "bold", color: " #FFE300" }}>
                {user}
              </span>{" "}
              em{" "}
              <span style={{ color: "#c3c3c3" }}>
                {convertToBrazilTime(created_at)}
              </span>
              .
            </p>
          ),
        });
        break;
      /*   case "notification-request":
        itemsList.push({
          key: index,
          label: (
            <p style={{ maxWidth: "26rem" }}>
              {` Tarefa ${task_id} foi renotificada por ${user} em ${convertToBrazilTime(created_at)}.`}
            </p>
          ),
        });
        break;
      case "recolheu-assinatura":
        itemsList.push({
          key: index,
          label: (
            <p style={{ maxWidth: "26rem" }}>
              {` Tarefa ${task_id} foi inserida assinatura digital por ${user} em ${convertToBrazilTime(created_at)}.`}
            </p>
          ),
        });
        break; */

      default:
        break;
    }
  });

  const iconCount = itemsList.length;

  let items = [];

  items =
    itemsList.length > 9
      ? (items = itemsList.slice(0, 10))
      : (items = itemsList);

  if (itemsList.length < 1) {
    items.push({
      key: 0,
      label: (
        <p
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: "1.1rem",
            marginTop: "10px",
            maxWidth: "30rem",
            color: "#c2c2c2",
          }}
        >
          <span className="material-icons mr-2">done_all</span> Sem novas
          notificações para exibir :D
        </p>
      ),
    });
  }

  items.push({
    key: 10,
    label: (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          onClick={handleAllNotificationsClick}
          className="primary"
          style={{
            borderRadius: "10px",
            backgroundColor: "#2D9CDB",
            color: "white",
            cursor: "pointer",
            width: "80%",
          }}
        >
          Ver todas notificações
        </button>
      </div>
    ),
  });

  async function handleUserSawNotifications(open) {
    try {
      if (!open) {
        await LogsRoutes.postLastUserView({ companyId: userStore.companyId });
        setReload(() => true);

        setTimeout(() => setReload(() => false), 300);
      }
    } catch (err) {
      console.error({ err });
    }
  }
  function handleAllNotificationsClick() {
    push("/home/notifications");
  }

  return (
    <Dropdown
      trigger="click"
      onOpenChange={(open) => handleUserSawNotifications(open)}
      menu={{
        items,
        style: { borderRadius: "10px" },
      }}
      placement="bottom"
      arrow
    >
      <button
        style={{ backgroundColor: "transparent", position: "relative" }}
        className="mr-5 d-flex flex-column align-items-center  "
      >
        <span className="notification-icon">{notificationIcon}</span>
        <span className="notification-count">{iconCount}</span>
      </button>
    </Dropdown>
  );
}

export default NotificationDropdown;
