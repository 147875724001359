import React from "react";
import "./Select.css";

function Select(props) {
  const {
    className = "",
    selectClassName = "",
    errors,
    touched,
    id,
    value,
    handleChange,
    placeholder,
    inputBackground,
    labelBackground,
    inputPlaceholder,
    options,
    withNull,
    disabled,
    style,
    multiple,
  } = props;
  return (
    <>
      <div
        className={`input-div ${className} rounded flex${
          errors && touched ? " border-danger" : ""
        }`}
      >
        <select
          className={`w-100 border-0 ${selectClassName} ${
            disabled && "select-disabled"
          }`}
          id={id}
          value={value}
          onChange={handleChange}
          style={{
            backgroundColor: inputBackground ? inputBackground : "white",
            ...style,
          }}
          placeholder={inputPlaceholder}
          disabled={disabled}
          multiple={multiple}
        >
          {withNull ? <option value="">{withNull}</option> : ""}
          {options
            ? options.map((elem) => (
                <option key={elem.value} value={elem.value}>
                  {elem.name}
                </option>
              ))
            : ""}
        </select>
        <span
          className="span-input"
          style={{
            backgroundColor: labelBackground ? labelBackground : "white",
          }}
        >
          {placeholder}
        </span>
      </div>
      {errors && touched ? (
        <p className="text-danger p-error m-0">{errors}</p>
      ) : null}
    </>
  );
}

export default Select;
