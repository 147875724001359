import axios from "axios";

const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_URL_API,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

AxiosInstance.interceptors.request.use((config) => {
  const newConfig = {
    ...config,
  };

  const reduxStore = JSON.parse(localStorage.getItem("reduxStore"));
  const token = reduxStore?.authStore?.auth;

  if (token) {
    newConfig.headers["Authorization"] = `Bearer ${token}`;
  }

  return Promise.resolve(newConfig);
});

const { get, post, put, patch, delete: remove } = AxiosInstance;
export { get, post, put, patch, remove };

export default AxiosInstance;
