import React, { useContext, useState } from "react";
import { Row } from "react-bootstrap";
import { Modal } from "antd";

import UnderlinedButton from "~/components/UnderlinedButton";
import UploadMidias from "~/components/UploadMidias";
import Button from "~/components/Button/Button";
import { ToastContext } from "~/providers/ToastProvider";
import { AtaRoutes } from "~/http/routes";

function AddAttachmentToSubjectModal(props) {
	const {
		isVisible,
		ataId,
		subjectId,
		onCancel,
		onSuccess,
	} = props;

	const { setToast } = useContext(ToastContext);

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [file, setFile] = useState(null);

	function resetState() {
		setIsSubmitting(false);
		setFile(null);
	}

	async function handleUpload(file) {
		setFile(file);
	}

	async function handleSubmit() {
		setIsSubmitting(true);

		const formData = new FormData();
		formData.append("attachment", file);

		try {
			const { data: attachment } = await AtaRoutes.addAttachmentToSubject(ataId, subjectId, formData);

			if (onSuccess)
				onSuccess(attachment);

			setToast({
				type: 'success',
				title: 'Sucesso',
				message: 'Anexo adicionado com sucesso!',
				show: true,
				autohide: true,
			});
		} catch (err) {
			setToast({
				type: 'error',
				title: 'Erro',
				message: 'Não foi possível adicionar o anexo',
				show: true,
				autohide: true,
			});
		}

		resetState();
	}

	function handleCancel() {
		if (onCancel)
			onCancel();

		resetState();
	}

	return (
		<Modal title="Adicionar anexo"
			visible={isVisible}
			footer={null}
			width="500px"
			onCancel={handleCancel}
			destroyOnClose>
			{file &&
				<span>{file.name}</span>
			}

			<UploadMidias accept="image/*,audio/*,video/*,.pdf,.doc,.docx"
				fileList={[]}
				onRemove={null}
				beforeUpload={handleUpload}
				onPreview={null} />

			<Row className="mt-4 d-flex align-items-center flex-column">
				<Button className="button__save default-button"
					size="large"
					onClick={handleSubmit}
					loading={isSubmitting}
					disabled={isSubmitting || !file}>
					{!isSubmitting ? 'Salvar e adicionar' : 'Salvando...'}
				</Button>

				<UnderlinedButton className="mt-2"
					onClick={handleCancel}>
					Cancelar
				</UnderlinedButton>
			</Row>
		</Modal>
	);
}

export default AddAttachmentToSubjectModal;
