import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import Select from "~/components/Select/Select";
import { ATA_SET_GROUP_BY } from '~/redux/actions/AtaReportFilters';

function Personalization() {
	const dispatch = useDispatch();
	const { groupBy } = useSelector(state => state.ataReportFilters);

	const groupByOptions = [
		{ name: 'Status', value: 'status' },
	];

	function handleGroupByChange(e) {
		dispatch({ type: ATA_SET_GROUP_BY, data: e.target.value });
	}

	return (
		<div>
			<h4 className="font-weight-bold mb-3">Personalização</h4>

			<div className="card">
				<div className="card-body">
					<div className="row">
						<div className="col-sm-12 col-md-6 col-lg-3 col-xl-2">
							<Select placeholder="Agrupar por:"
								value={groupBy}
								handleChange={handleGroupByChange}
								inputBackground="transparent"
								inputPlaceholder=""
								options={groupByOptions}
								withNull="Não agrupar" />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Personalization;
