import React from "react";
import { Col, Row, Image } from "react-bootstrap";
import "./Message.css";

import imagePlaceHolder from "../../assets/avatar-placeholder.png";
import { longDateFormat, longDateAndHourFormat } from "../../util/date";
import { userSelector } from "../../redux/Selectors";
import { useSelector } from "react-redux";
import { wrapUrlInAnchorTag } from "~/helpers/stringUrlToLink";

function Message(props) {
  const { userPhoto, type, content } = props;

  let userPhotoValidated = userPhoto === null ? "" : userPhoto;

  const userStore = useSelector(userSelector);

  const userStorePhoto = userStore.userPhoto.includes("null")
    ? imagePlaceHolder
    : userStore.userPhoto;

  return (
    <Col {...props.style}>
      <Row className="d-flex align-items-center">
        <Image
          className="mr-2"
          width="50"
          height="50"
          src={
            userPhotoValidated !== ""
              ? `data:image/png;base64,${userPhotoValidated}`
              : userStorePhoto
          }
          roundedCircle
        ></Image>

        {type === "message" ? (
          <Col>
            {content.comment.length > 0
              ? content.comment.map((comment) => (
                  <>
                    <p
                      className="message-text"
                      dangerouslySetInnerHTML={{
                        __html: wrapUrlInAnchorTag(comment.text),
                      }}
                    />
                  </>
                ))
              : ""}
            <br />
            <span className="message-date-footer">
              {longDateAndHourFormat(content.date)}
            </span>
            <span className="message-date-footer font-weight-bold">
              {" "}
              - {content.user ? content.user.username : userStore.userName}
            </span>
          </Col>
        ) : (
          ""
        )}

        {type === "audio" ? (
          <Col>
            <span className="message-text">Audio</span>
            <br />
            <span className="message-date-footer">15 de abril de 2021</span>
          </Col>
        ) : (
          ""
        )}

        {type === "image" ? (
          <Col>
            <span className="message-text">Image</span>
            <br />
            <span className="message-date-footer">15 de abril de 2021</span>
          </Col>
        ) : (
          ""
        )}
      </Row>
      <hr className="rounded separator" />
    </Col>
  );
}

export default Message;
