import "./SwitchCheckbox.css";

function SwitchCheckbox(props) {
  const { label, checked, id, onChange } = props;

  return (
    <div className="switch__container">
      <p style={{ marginBottom: "10px" }}>{label}</p>
      <input
        id={id}
        checked={checked}
        onChange={onChange}
        className="switch switch--shadow"
        type="checkbox"
      />
      <label htmlFor={id} className="switch__label"></label>
    </div>
  );
}

export default SwitchCheckbox;
