import { combineReducers, createStore } from "redux";

import DetailedReportFiltersReducer from "./reducers/DetailedReportFiltersReducer";
import AuthReducer from "./reducers/AuthReducer";
import UserReducer from "./reducers/UserReducer";
import AtaReducer from "./reducers/AtaReducer";
import AtaReportFiltersReducer from "./reducers/AtaReportReducer";

const storeConfig = () => {
	return createStore(
		combineReducers({
			authStore: AuthReducer,
			userStore: UserReducer,
			detailedReportFilters: DetailedReportFiltersReducer,
			ataReportFilters: AtaReportFiltersReducer,
			ata: AtaReducer,
		}),
	);
}

const store = storeConfig();

export default store;
