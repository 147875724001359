import React, { useContext, useState } from "react";
import { Form, Formik } from "formik";
import { Row } from "react-bootstrap";
import { Modal } from "antd";

import UnderlinedButton from "~/components/UnderlinedButton";
import FormikInput from "~/components/Input/FormikInput";
import isEmailValid from "~/helpers/isEmailValid";
import Button from "~/components/Button/Button";
import { ToastContext } from "~/providers/ToastProvider";
import { AtaRoutes, UserRoutes } from "~/http/routes";
import { userSelector } from "~/redux/Selectors";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

function UserAlreadyRegistered(props) {
	const {
		isVisible,
		user,
		subprojectId,
		ataId,
		onCancel,
		onSuccess,
	} = props;

	const [loading, setLoading] = useState(false);

	async function handleConfirm() {
		setLoading(true);
		let register;

		try {
			const { data } = await AtaRoutes.vinculateToSubprojectAndAddAsParticipant(ataId, user.id, subprojectId);
			register = data;
		} catch (err) {
			console.log('err:', err);
		}

		setLoading(false);

		if (onSuccess)
			onSuccess(register);
	}

	return (
		<Modal visible={isVisible}
			title="Usuário já cadastrado na empresa"
			footer={null}
			width="500px"
			onCancel={onCancel}
			destroyOnClose>

			{user &&
				<p>
					Um usuário com este e-mail já encontra-se cadastrado na empresa,
					porém, não encontra-se vinculado a este subprojeto.

					<p><b>Usuário:</b></p>
					<p>Nome: {user.name}</p>
					<p>E-mail: {user.email}</p>
					<p>Telefone: {user.phone}</p>

					<hr />

					<p>
						Deseja vincular este usuário ao subprojeto e adicioná-lo como participante?
					</p>
				</p>
			}

			<Row className="mt-4 d-flex align-items-center flex-column">
				<Button className="button__save default-button"
					size="large"
					onClick={handleConfirm}
					loading={loading}
					disabled={loading}>
					{!loading ? 'Vincular e adicionar' : 'Salvando...'}
				</Button>

				<UnderlinedButton className="mt-2"
					onClick={onCancel}
					disabled={loading}>
					Cancelar
				</UnderlinedButton>
			</Row>
		</Modal>
	);
}

function UserAlreadyRegisteredButNotInCompany(props) {
	const {
		isVisible,
		onClose,
		user,
	} = props;

	return (
		<Modal visible={isVisible}
			title="Usuário já cadastrado no sistema"
			width="500px"
			onCancel={onClose}
			onOk={onClose}
			destroyOnClose>

			{user &&
				<p>
					Um usuário com este e-mail já encontra-se cadastrado na sistema.

					<p><b>Usuário:</b></p>
					<p>Nome: {user.name}</p>
					<p>E-mail: {user.email}</p>
					<p>Telefone: {user.phone}</p>

					<hr />

					<p>
						Por favor, convide-o para sua empresa para poder adicioná-lo como participante.
					</p>
				</p>
			}
		</Modal>
	);
}

const initialValues = {
	name: '',
	email: '',
	username: '',
	password: '',
	role: '',
	phone: '',
	document_number: '',
};

function RegisterParticipantModal(props) {
	const {
		isVisible,
		ataId,
		subprojectId,
		onCancel,
		onSuccess,
	} = props;

	const { setToast } = useContext(ToastContext);
	const { companyId } = useSelector(userSelector);

	const [user, setUser] = useState(initialValues);
	const [loading, setLoading] = useState(false);
	const [userAlreadyRegisteredOnCompamy, setUserAlreadyRegisteredOnCompany] = useState(null);
	const [userAlreadyRegisteredOnSystem, setUserAlreadyRegisteredOnSystem] = useState(null);

	function renderInputLabel(label, required = true) {
		return (
			<>
				{label}
				{required &&
					<b style={{
						color: '#f00',
						paddingLeft: 3,
					}}>
						*
					</b>
				}
			</>
		);
	}

	function isAbleToSave(values) {
		const {
			name,
			email,
			username,
			password,
			role,
			phone,
		} = values;

		return name
			&& email
			&& username
			&& password
			&& role
			&& phone
			&& phone.replace(/_/g, '').length === 15;
	}

	async function handleSubmit(values, { resetForm }) {
		try {
			const { data } = await AtaRoutes.createUserAndAddAsParticipant(ataId, values);
			resetForm();

			if (onSuccess)
				onSuccess(data);
		} catch (err) {
			let message = "Um erro inesperado ocorreu ao cadastrar o usuário";

			if (err?.response)
				message = err.response.data || err.response.data.message;

			return setToast({
				type: 'error',
				title: 'Erro',
				message,
				show: true,
				autohide: true,
			});
		}
	}

	function handleFormValidation({ email }) {
		const errors = {};

		if (!isEmailValid(email))
			errors.email = "E-mail inválido";

		return errors;
	}

	function handleCancel() {
		setUser(initialValues);

		if (onCancel)
			onCancel();
	}

	async function handleEmailInputBlur(email) {
		if (!email)
			return;

		setLoading(true);
		let user;

		try {
			const { data: { data } } = await UserRoutes.findUserByEmail(email);
			user = data;
		} catch (err) {
			console.log('err:', err);
		}

		if (!isEmpty(user)) {
			const company = user.companies.find(company => company.id === companyId);

			if (company) {
				setUserAlreadyRegisteredOnCompany(user);
			} else {
				setUserAlreadyRegisteredOnSystem(user);
			}
		}

		setLoading(false);
	}

	return (
		<Modal visible={isVisible}
			title="Cadastrar participante"
			footer={null}
			width="500px"
			onCancel={handleCancel}
			destroyOnClose>
			<Formik enableReinitialize
				initialValues={user}
				onSubmit={handleSubmit}
				validate={handleFormValidation}>
				{({ isSubmitting, errors, values, submitForm, setFieldValue }) => {
					return (
						<Form>
							<FormikInput className="mt-4"
								name="name"
								placeholder={renderInputLabel("Nome")}
								labelBackground="#fff"
								title={values.name}
								error={errors.name}
								maxLength={255}
								disabled={loading} />

							<FormikInput className="mt-4"
								name="document_number"
								placeholder={renderInputLabel("N° de documento", false)}
								labelBackground="#fff"
								title={values.document_number}
								error={errors.document_number}
								maxLength={255}
								disabled={loading} />

							<FormikInput className="mt-4"
								name="email"
								placeholder={renderInputLabel("E-mail")}
								onBlur={handleEmailInputBlur.bind(null, values.email)}
								labelBackground="#fff"
								title={values.email}
								error={errors.email}
								maxLength={255}
								disabled={loading} />

							<FormikInput className="mt-4"
								name="username"
								placeholder={renderInputLabel("Username")}
								labelBackground="#fff"
								title={values.username}
								error={errors.email}
								maxLength={255}
								disabled={loading} />

							<FormikInput className="mt-4"
								name="password"
								type="password"
								placeholder={renderInputLabel("Senha")}
								labelBackground="#fff"
								error={errors.password}
								maxLength={255}
								disabled={loading} />

							<FormikInput className="mt-4"
								name="role"
								placeholder={renderInputLabel("Cargo/empresa")}
								labelBackground="#fff"
								error={errors.role}
								maxLength={255}
								disabled={loading} />

							<FormikInput className="mt-4"
								name="phone"
								placeholder={renderInputLabel("WhatsApp")}
								labelBackground="#fff"
								error={errors.phone}
								maxLength={255}
								mask="(99) 99999-9999"
								disabled={loading} />

							<small>
								Campos marcados com
								<b style={{
									color: '#f00',
									paddingLeft: 3,
								}}>
									*
								</b> são obrigatórios.
							</small>

							<Row className="mt-4 d-flex align-items-center flex-column">
								<Button className="button__save default-button"
									size="large"
									onClick={submitForm}
									loading={isSubmitting}
									disabled={!isAbleToSave(values) || loading}>
									{!isSubmitting ? 'Salvar e adicionar' : 'Salvando...'}
								</Button>

								<UnderlinedButton className="mt-2"
									onClick={handleCancel}
									disabled={loading}>
									Cancelar
								</UnderlinedButton>
							</Row>

							<UserAlreadyRegistered
								isVisible={userAlreadyRegisteredOnCompamy}
								user={userAlreadyRegisteredOnCompamy}
								subprojectId={subprojectId}
								ataId={ataId}
								onSuccess={register => {
									setUserAlreadyRegisteredOnCompany(null);
									if (onSuccess)
										onSuccess(register);
								}}
								onCancel={() => {
									setFieldValue('email', '');
									setUserAlreadyRegisteredOnCompany(null);
								}}
							/>

							<UserAlreadyRegisteredButNotInCompany
								isVisible={userAlreadyRegisteredOnSystem}
								user={userAlreadyRegisteredOnSystem}
								onClose={() => {
									setFieldValue('email', '');
									setUserAlreadyRegisteredOnSystem(null);
								}}
							/>
						</Form>
					);
				}}
			</Formik>
		</Modal >
	);
}

export default RegisterParticipantModal;
