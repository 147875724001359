'use strict';

import store from "~/redux/Store";

function checkPermission(permission, logical = 'or') {
	const { permissions } = store.getState().userStore;

	if (!permissions)
		return false;

	const slugs = permissions.map(permission => permission.slug);

	if (!Array.isArray(permission))
		return slugs.includes(permission);
	else {
		let hasPermission = false;

		for (const slug of permission) {
			if (logical === 'or' && slugs.includes(slug)) {
				hasPermission = true;
				break;
			} else if (logical === 'and' && !slugs.includes(slug)) {
				hasPermission = false;
				break;
			} else {
				hasPermission = true;
			}
		}

		return hasPermission;
	}
}

export default checkPermission;
