import React from "react";
import FormikAntdSelect from "~/pages/CreateTask/FormikAntdSelect";

function AutomaticNotificationSelect(props) {
  const { options, ...rest } = props;

  function opt() {
    return options.map(({ daysBefore, notifyOnDue }) => {
      let name = `Apenas ${
        daysBefore === 7
          ? "1 semana"
          : `${daysBefore} dia${daysBefore > 1 ? "s" : ""}`
      } antes da data`;

      if (notifyOnDue)
        name = `${
          daysBefore === 7
            ? "1 semana"
            : `${daysBefore} dia${daysBefore > 1 ? "s" : ""}`
        } antes e na data`;

      if (notifyOnDue & (daysBefore === 0)) name = `Apenas na data`;

      return { name, value: JSON.stringify({ daysBefore, notifyOnDue }) };
    });
  }

  return <FormikAntdSelect {...rest} options={opt()} />;
}

export default AutomaticNotificationSelect;
