import React from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import {
	ATA_SET_INITIAL_CREATION_DATE,
	ATA_SET_FINAL_CREATION_DATE,
	ATA_SET_INITIAL_FINISHED_DATE,
	ATA_SET_FINAL_FINISHED_DATE,
} from '~/redux/actions/AtaReportFilters';

function Period() {
	const filters = useSelector(state => state.ataReportFilters);
	const dispatch = useDispatch();

	const dateFormat = 'DD/MM/YYYY';

	function onDatePickerChange(value, input) {
		value = value ? moment(value).format('YYYY-MM-DD') : null;

		switch (input) {
			case 'initial-creation-date':
				dispatch({ type: ATA_SET_INITIAL_CREATION_DATE, data: value });
				break;
			case 'final-creation-date':
				dispatch({ type: ATA_SET_FINAL_CREATION_DATE, data: value });
				break;
			case 'initial-finished-date':
				dispatch({ type: ATA_SET_INITIAL_FINISHED_DATE, data: value });
				break;
			case 'final-finished-date':
				dispatch({ type: ATA_SET_FINAL_FINISHED_DATE, data: value });
				break;
		}
	}

	return (
		<div>
			<div className="row mb-3">
				<div className="row col-sm-12 col-lg-6">
					<h6 className="col-sm-12">Data de criação:</h6>
					<div className="col-sm-12 col-md-6 mt-1">
						<span className="font-italic mr-2">de</span>
						<DatePicker format={dateFormat}
							value={filters.initialCreationDate ? moment(filters.initialCreationDate) : null}
							placeholder="Selecione uma data"
							onChange={e => onDatePickerChange(e, 'initial-creation-date')} />
					</div>

					<div className="col-sm-12 col-md-6 mt-1">
						<span className="font-italic mr-1">até</span>
						<DatePicker format={dateFormat}
							value={filters.finalCreationDate ? moment(filters.finalCreationDate) : null}
							placeholder="Selecione uma data"
							onChange={e => onDatePickerChange(e, 'final-creation-date')} />
					</div>
				</div>

				<div className="row col-sm-12 col-lg-6">
					<h6 className="col-sm-12">Data de finalização:</h6>
					<div className="col-sm-12 col-md-6">
						<span className="font-italic mr-2 mt-1">de</span>
						<DatePicker format={dateFormat}
							value={filters.initialFinishedDate ? moment(filters.initialFinishedDate) : null}
							placeholder="Selecione uma data"
							onChange={e => onDatePickerChange(e, 'initial-finished-date')} />
					</div>

					<div className="col-sm-12 col-md-6">
						<span className="font-italic mr-1 mt-1">até</span>
						<DatePicker format={dateFormat}
							value={filters.finalFinishedDate ? moment(filters.finalFinishedDate) : null}
							placeholder="Selecione uma data"
							onChange={e => onDatePickerChange(e, 'final-finished-date')} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default Period;
