import { useField } from "formik";
import React from "react";
import SwitchCheckbox from "./SwitchCheckbox";

const FormikSwitchCheckbox = (props) => {
	const [field] = useField(props);

	return <SwitchCheckbox {...props} {...field} />;
};

export default FormikSwitchCheckbox;
