import React from "react";
import { DatePicker } from "formik-antd";

function InputDatepicker({
  errors,
  touched,
  placeholder,
  label,
  inputBackground = "transparent",
  labelBackground = "#fafafa",
  name,
  style,
  onChange,
  ...rest
}) {
  return (
    <>
      <div
        className={`input-div rounded flex p-2${
          errors && touched ? " border-danger" : ""
        }`}
      >
        <span
          className="span-input"
          style={{
            backgroundColor: labelBackground ? labelBackground : "white",
          }}
        >
          {label}
        </span>
        <DatePicker
          {...rest}
          format={"DD/MM/YYYY - HH:mm"}
          suffixIcon={() => <></>}
          name={name}
          style={{
            backgroundColor: inputBackground ? inputBackground : "white",
            ...style,
            outline: "none",
            boxShadow: "none",
            height: "24px",
          }}
          placeholder={placeholder}
          className="w-100 border-0 p-0"
          onChange={onChange}
          showTime={true}
        />
      </div>
      {errors && touched ? (
        <p className="text-danger p-error m-0">{errors}</p>
      ) : null}
    </>
  );
}

export default InputDatepicker;
