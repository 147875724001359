const CLEAR_FILTERS = "CLEAR_FILTERS";
const SET_INITIAL_CREATION_DATE = "SET_INITIAL_CREATION_DATE";
const SET_FINAL_CREATION_DATE = "SET_FINAL_CREATION_DATE";
const SET_INITIAL_TASK_BEGIN_DATE = "SET_INITIAL_TASK_BEGIN_DATE";
const SET_FINAL_TASK_BEGIN_DATE = "SET_FINAL_TASK_BEGIN_DATE";
const SET_INITIAL_DELIVERY_DATE = "SET_INITIAL_DELIVERY_DATE";
const SET_FINAL_DELIVERY_DATE = "SET_FINAL_DELIVERY_DATE";
const SET_INITIAL_TASK_END_DATE = "SET_INITIAL_TASK_END_DATE";
const SET_FINAL_TASK_END_DATE = "SET_FINAL_TASK_END_DATE";
const SET_FILTERS_PROJECTS = "SET_FILTERS_PROJECTS";
const SET_FILTERS_SECTORS = "SET_FILTERS_SECTORS";
const SET_FILTERS_SERVICES = "SET_FILTERS_SERVICES";
const SET_FILTERS_INVENTORIES = "SET_FILTERS_INVENTORIES";
const SET_FILTERS_USERS = "SET_FILTERS_USERS";
const SET_FILTERS_STATUS = "SET_FILTERS_STATUS";
const SET_GROUP_BY = "SET_GROUP_BY";
const SET_FORM_FIELDS = "SET_FORM_FIELDS";
const SET_FILED_TASKS = "SET_FILED_TASKS";
const SET_DELETED_TASKS = "SET_DELETED_TASKS";

export {
  CLEAR_FILTERS,
  SET_INITIAL_CREATION_DATE,
  SET_FINAL_CREATION_DATE,
  SET_INITIAL_TASK_BEGIN_DATE,
  SET_FINAL_TASK_BEGIN_DATE,
  SET_INITIAL_DELIVERY_DATE,
  SET_FINAL_DELIVERY_DATE,
  SET_INITIAL_TASK_END_DATE,
  SET_FINAL_TASK_END_DATE,
  SET_FILTERS_PROJECTS,
  SET_FILTERS_SECTORS,
  SET_FILTERS_SERVICES,
  SET_FILTERS_INVENTORIES,
  SET_FILTERS_USERS,
  SET_FILTERS_STATUS,
  SET_GROUP_BY,
  SET_FORM_FIELDS,
  SET_FILED_TASKS,
  SET_DELETED_TASKS,
};
