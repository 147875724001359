import { defineState } from 'redux-localstore';

import { SET_SUBJECTS } from '../actions/AtaActions';

const initialState = defineState({
	subjects: [],
})("ata");

function AtaReducer(state = initialState, action) {
	switch (action.type) {
		case SET_SUBJECTS:
			return { ...state, subjects: action.data };
		default:
			return state;
	}
}

export default AtaReducer;
