import React, { useContext, useEffect, useState } from "react";
import { Modal, Image, Button, Typography, Tooltip } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";

import Input from "~/components/Input/Input";
import { taskFieldAttachmentRoutes } from "~/http/routes";
import { ToastContext } from "~/providers/ToastProvider";

const { Text } = Typography;

function FieldAttachmentModal(props) {
  const {
    id,
    filename,
    url,
    createdAt,
    subprojectId,
    taskId,
    taskFieldId,
    visible,
    onCancel,
    afterSubmit,
  } = props;

  const [description, setDescription] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { setToast } = useContext(ToastContext);

  useEffect(() => {
    setDescription(props.description || "");
  }, [props.description]);

  function handleDescriptionChange(e) {
    setDescription(e.target.value);
  }

  async function handleSubmit() {
    setIsSubmitting(true);
    try {
      let attachment;

      if (!id) {
        const body = {
          filename,
          file: url,
          description,
        };

        const res = await taskFieldAttachmentRoutes.post(
          subprojectId,
          taskId,
          taskFieldId,
          body,
        );
        attachment = res.data.data;
      } else {
        const body = {
          description,
        };

        const res = await taskFieldAttachmentRoutes.update(
          subprojectId,
          taskId,
          taskFieldId,
          id,
          body,
        );
        attachment = res.data.data;
      }

      setToast({
        type: "success",
        title: "Sucesso!",
        message: "Mídia salva com sucesso!",
        show: true,
        autohide: true,
      });

      afterSubmit(attachment);
    } catch (err) {
      setToast({
        type: "error",
        title: "Erro!",
        message: "Um erro ocorreu ao salvar a mídia!",
        show: true,
        autohide: true,
      });
    }

    setDescription("");
    setIsSubmitting(false);
  }

  function handleCancel() {
    setDescription("");

    if (onCancel) onCancel();
  }

  return (
    <Modal
      title={id ? "Editar Arquivo" : "Cadastrar Arquivo"}
      visible={visible}
      footer={null}
      onCancel={handleCancel}
    >
      <div className="mb-4">
        {url.includes(".pdf") ||
        url.includes("application/pdf") ||
        url.includes(".sheet") ||
        url.includes(".plain") ||
        url.includes(".ms-excel") ||
        url.includes(".msexcel") ||
        url.includes(".msword") ? (
          <iframe
            title={filename}
            width="100%"
            src={
              url.includes(".pdf") ||
              url.includes("application/pdf") ||
              url.includes(".sheet") ||
              url.includes(".ms-excel") ||
              url.includes(".msexcel") ||
              url.includes(".plain") ||
              url.includes(".msword")
                ? `https://docs.google.com/viewer?url=${url}&embedded=true`
                : url
            }
            frameBorder="0"
          />
        ) : (
          <Image src={url} width="100%" />
        )}
      </div>

      <Input
        placeholder="Descrição"
        value={description}
        inputType="textArea"
        labelBackground="#fff"
        handleChange={handleDescriptionChange}
      />

      {createdAt ? (
        <small>
          <b>Criado em: </b>
          {createdAt}
        </small>
      ) : (
        <></>
      )}

      <div className="row mt-4">
        <div className="col-sm-10">
          <Button
            className="btn-default"
            size="medium"
            loading={isSubmitting}
            disabled={
              (id && (description === props.description || !description)) ||
              isSubmitting
            }
            onClick={handleSubmit}
          >
            {!isSubmitting ? "Salvar" : " Salvando..."}
          </Button>
        </div>

        <div className="col-sm-2">
          <Tooltip title='Ao clicar em "Salvar" a nova mídia ou alteração em mídia existente será salva diretamente no banco de dados. Tenha atenção e cuidado.'>
            <QuestionCircleFilled style={{ fontSize: "25px" }} />
          </Tooltip>
        </div>
      </div>

      <div className="d-flex justify-content-center mt-3">
        <Text underline style={{ cursor: "pointer" }} onClick={handleCancel}>
          Cancelar
        </Text>
      </div>
    </Modal>
  );
}

export default FieldAttachmentModal;
