import "./Calendar.css";
import moment from "moment";
import "antd/dist/antd.css";

import React, { useEffect, useState, useContext } from "react";
import AxiosInstance from "../../http/Axios";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

import { userSelector } from "../../redux/Selectors";
import { TaskRoutes } from "../../http/routes";
import { ToastContext } from "~/providers/ToastProvider";
import {
  DashboardOutlined,
  SearchOutlined,
  CalendarOutlined,
  ProjectOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";

import { configIcon } from "../../assets/svg/Icons";
import Modal from "antd/lib/modal/Modal";
import ModalCreateTask from "~/pages/CreateTask/ModalCreateTask";

import Breadcrumb from "~/components/Breadcrumb/Breadcrumb";
import CheckPermission from "~/components/CheckPermission";
import DrawerTask from "../../components/DrawerTask/DrawerTask";
import Select from "antd/lib/select";

import ToastComponent from "../../components/ToastComponent/ToastComponent";

const backRoute = "/home/projects/kanban";

function Calendar() {
  const [tasks, setTasks] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [isTaskDrawerLoading, setIsTaskDrawerLoading] = useState(false);
  const [modalData, setModalData] = useState({});
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState([]);
  const [modalIsOpen, setModalState] = useState(false);
  const [reload, setReload] = useState(false);
  const [taskEditingData, setTaskEditingData] = useState(null);

  const [error, setError] = useState({ hasError: false, message: "" });

  const { setToast } = useContext(ToastContext);

  const userStore = useSelector(userSelector);
  const { push } = useHistory();
  const { Option } = Select;

  const handleModal = () => {
    setModalState((m) => !m);
  };

  useEffect(() => {
    AxiosInstance.get(userStore.companyId + "/service").then((res) => {
      const response = res.data.data.map((elem) => (
        <Option key={elem.name} value={elem.name}>
          {elem.name}
        </Option>
      ));

      setServices(response);
    });
  }, []);

  useEffect(() => {
    const getTasks = async () => {
      const tasksResponse = await TaskRoutes.list(userStore.subprojectId);
      const tasks = tasksResponse.data.data;

      const eventsTasks = tasks.map((e) => {
        let color = e.service_color ? e.service_color : "#C4C4C4";

        if (selectedService == "") {
          return {
            id: e.id,
            title:
              e.name + " | | " + e.service_name + " | | " + e.responsible_name,
            start: moment(e.start_date, "DD-MM-YYYY").format("YYYY-MM-DD"),
            end: moment(e.due_date, "DD-MM-YYYY").format("YYYY-MM-DD"),
            extendedProps: {
              id: e.id,
            },
            priority_color: color,
          };
        } else if (selectedService.includes(e.service_name)) {
          return {
            id: e.id,
            title:
              e.name + " | | " + e.service_name + " | | " + e.responsible_name,
            start: moment(e.start_date, "DD-MM-YYYY").format("YYYY-MM-DD"),
            end: moment(e.due_date, "DD-MM-YYYY").format("YYYY-MM-DD"),
            extendedProps: {
              id: e.id,
            },
            priority_color: color,
          };
        } else {
          return {};
        }
      });

      setTasks(eventsTasks);
    };

    getTasks();
  }, [selectedService, reload]);

  const closeCreateModal = () => {
    setReload(() => true);

    setTimeout(() => setReload(() => false), 300);
    setTaskEditingData(null);
    handleModal();
  };

  const reloadCalendar = async () => {
    try {
      setReload(() => true);
    } catch (error) {
      setError({ message: error.message, hasError: true });
    }
  };

  const handleClose = () => {
    setError({ message: "", hasError: false });
  };

  const renderEventContent = (eventInfo) => {
    const bg_color = eventInfo.event._def.extendedProps.priority_color;
    return (
      <>
        <div
          style={{
            backgroundColor: `${bg_color}90`,
            height: "auto",
            width: "100%",
            borderRadius: "5px",
            padding: "5px",
            borderLeft: `7px solid ${bg_color} `,
            borderLeftColor: ".99",
            opacity: ".85",
          }}
        >
          <p
            style={{
              width: "min",
              color: "#111",
              fontWeight: 500,
              borderRadius: "10px",
              paddingLeft: "10px",
              paddingBottom: "2px",
            }}
          >
            {" "}
            {eventInfo.event.title}{" "}
          </p>
        </div>
      </>
    );
  };

  const handleTaskClick = async (id) => {
    try {
      setIsTaskDrawerLoading(true);
      setModalShow(true);

      const res = await TaskRoutes.getTask(userStore.subprojectId, id);
      const task = res.data.data;

      setModalData({ ...task });
      setIsTaskDrawerLoading(false);
    } catch (err) {
      setToast({
        type: "error",
        title: "Erro!",
        message: "Um erro ocorreu abrir a tarefa!",
        show: true,
        autohide: true,
      });

      setIsTaskDrawerLoading(false);
      setModalShow(false);
    }
  };

  return (
    <>
      <ToastComponent
        delay="5000"
        title="Ops! Houve um erro"
        description={error.message}
        type="error"
        showToast={error.hasError}
        handleClose={handleClose}
      />
      <div className="d-flex justify-content-between mb-3 ">
        <div className="d-flex ">
          <Breadcrumb routeToBack={backRoute} className={"align-middle"} />
          <button
            className="button-kanban"
            onClick={() => push(`/home/projects/kanban`)}
          >
            <span>
              <ProjectOutlined className="align-middle mr-0" />
            </span>{" "}
            Kanban
          </button>
          <button
            className="button-kanban"
            onClick={() => push(`/home/projects/listview`)}
          >
            <span>
              <UnorderedListOutlined className="align-middle mr-0" />
            </span>{" "}
            Lista
          </button>
        </div>

        <div className="d-flex justify-content-between ">
          <Select
            style={{ width: "300px", margin: "auto" }}
            placeholder="Filtre por Serviços"
            onChange={(e) => {
              setSelectedService(e);
            }}
            mode="multiple"
            maxTagCount={1}
            value={selectedService}
            allowClear={true}
          >
            {services}
          </Select>

          <CheckPermission slug="criar-tarefa">
            <button className="btn-create " onClick={handleModal}>
              + Nova Tarefa
            </button>
          </CheckPermission>
        </div>
      </div>

      <FullCalendar
        locale="pt-br"
        headerToolbar={{
          left: "prev,next",
          center: "title",
          right: "",
        }}
        height="auto"
        dayHeaderClassNames=".fc .fc-col-header-cell-cushion"
        dayCellClassNames={".fc .fc-daygrid-day-number"}
        dayHeaderFormat={{ weekday: "long" }}
        firstDay={1}
        plugins={[dayGridPlugin]}
        events={tasks}
        eventColor={"#fff"}
        eventContent={renderEventContent}
        eventClick={(e) => handleTaskClick(e.event._def.publicId)}
      />
      {modalShow && (
        <DrawerTask
          show={modalShow}
          loading={isTaskDrawerLoading}
          onClose={() => setModalShow(false)}
          data={modalData}
          //changeTaskValue={changeTaskValue}
          //handleEditTask={editTask}
          //afterSaveForm={getDaily}
          //afterAddField={getDaily}
        />
      )}

      <Modal
        width="60%"
        className="modal-projects"
        centered
        visible={modalIsOpen}
        onCancel={closeCreateModal}
        title="Cadastro de tarefa"
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
      >
        <ModalCreateTask
          handleClose={closeCreateModal}
          refreshList={reloadCalendar}
          taskEditingData={taskEditingData}
        />
      </Modal>
    </>
  );
}

export default Calendar;
