import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { Checkbox, Divider } from 'antd';
import { isEmpty } from 'lodash';

import Select from "~/components/Select/Select";
import { SET_FORM_FIELDS, SET_GROUP_BY } from '~/redux/actions/DatailedReportFiltersActions';

function Personalization(props) {
	const {
		commonFields,
		isLoading,
		onCheckFormField,
	} = props;

	const dispatch = useDispatch();
	const { groupBy, formFields } = useSelector(state => state.detailedReportFilters);

	const groupByOptions = [
		{ name: 'Status', value: 'status_id' },
		{ name: 'Responsável', value: 'responsible_id' },
		{ name: 'Projeto', value: 'project_id' },
		{ name: 'Subprojeto', value: 'subproject_id' },
		{ name: 'Setor', value: 'sector_id' },
		{ name: 'Serviço', value: 'service_id' },
		{ name: 'Inventário', value: 'inventory_id' },
	];

	function setFormFields(e) {
		dispatch({ type: SET_FORM_FIELDS, data: e });
	}

	function handleGroupByChange(e) {
		dispatch({ type: SET_GROUP_BY, data: e.target.value });
	}

	function handleCheck(field) {
		const aux = formFields;
		const index = aux.findIndex(e => e.id == field.id);

		if (index > -1)
			aux.splice(index, 1);
		else
			aux.push(field);

		setFormFields(aux);

		if (onCheckFormField)
			onCheckFormField();
	}

	function handleCheckAll(e) {
		let fields = [];
		if (e.target.checked)
			fields = commonFields.map(e => e);

		setFormFields(fields);

		if (onCheckFormField)
			onCheckFormField();
	}

	function renderCommonFields() {
		if (isLoading)
			return (
				<div className="d-flex justify-content-center">
					<Spinner animation="border"
						role="status" />
				</div>
			);

		else if (isEmpty(commonFields))
			return null;

		return (
			<div className="mt-3">
				<span>Adicionar campos comuns do formulário na tabela:</span>

				<div className="row mt-3 overflow-auto" style={{ maxHeight: '200px' }}>
					<div className="col-sm-12">
						<Checkbox checked={formFields.length === commonFields.length}
							indeterminate={formFields.length !== commonFields.length}
							onChange={handleCheckAll}>
							Marcar todos
						</Checkbox>
					</div>

					<Divider />

					{commonFields.map((e, i) => (
						<div key={i}
							className="col-sm-12 col-md-6 col-lg-4 col-xl-3 row">
							<Checkbox checked={formFields.find(f => f.id == e.id)}
								className="col-sm-12 mr-3 text-truncate"
								onChange={handleCheck.bind(null, e)}>
								{e.label}
							</Checkbox>


							<Divider />
						</div>
					))}
				</div>
			</div>
		);
	}

	return (
		<div>
			<h4 className="font-weight-bold mb-3">Personalização</h4>

			<div className="card">
				<div className="card-body">
					<div className="row">
						<div className="col-sm-12 col-md-6 col-lg-3 col-xl-2">
							<Select placeholder="Agrupar por:"
								value={groupBy}
								handleChange={handleGroupByChange}
								inputBackground="transparent"
								inputPlaceholder=""
								options={groupByOptions}
								withNull="Não agrupar" />
						</div>
					</div>

					{renderCommonFields()}
				</div>
			</div>
		</div>
	);
}

export default Personalization;
