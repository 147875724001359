import React from "react";

const SectionTitle = ({ className = "", children }) => {
  return (
    <div className={`d-flex ${className}`}>
      <p className="font-weight-bold d-inline">{children}</p>
    </div>
  );
};

export default SectionTitle;
