import React, { useEffect, useState } from 'react';

import Button from '~/components/Button/Button'
// import CircleColor from '~/components/CircleColor/CircleColor';
import { Input } from 'antd';
import { Table } from "antd";
import { ArrowLeftOutlined, SearchOutlined, EditOutlined } from '@ant-design/icons';
import "./SectorList.css";
import { Typography } from 'antd';
import { Link } from "react-router-dom";
import { SectorRoutes } from "~/http/routes";
import { useSelector } from 'react-redux';

import { userSelector } from "~/redux/Selectors";
import { useCallback } from 'react';
import CheckPermission from '~/components/CheckPermission';
import checkPermission from '~/helpers/checkPermission';

const { Title } = Typography;

// const { Option } = Select;

function SectorList({ history }) {

	const [inventory, setInventory] = useState([]);
	const [searchFilterValue, setSearchFilterValue] = useState('');

	const { companyId } = useSelector(userSelector);

	useEffect(() => {
		SectorRoutes.getSector(companyId).then(({ data }) => {
			setInventory(data.data);
		});
	}, [companyId]);

	const handleInputChange = (event) => {
		setSearchFilterValue(event.target.value);
	};

	const filterList = useCallback(() => {
		return inventory.filter(value => (
			value.name && value.name.toLowerCase().includes(searchFilterValue.toLowerCase())
		))
	}, [searchFilterValue, inventory])

	function getColumns() {
		const columns = [
			{ key: "name", title: "Nome", dataIndex: "name", width: "90%" },
		];

		if (checkPermission('editar-setores')) {
			columns.push({
				key: "edit",
				title: "Editar",
				align: "center",
				render: (inventory) => {
					return (
						<EditOutlined
							className="inventory__icons"
							onClick={() => history.push(`/home/sectors/${inventory.id}`)}
						/>
					);
				},
			});
		}

		return columns;
	}

	return (
		<div className="inventory">
			<div className="inventory__title">
				<ArrowLeftOutlined className="inventory__title--icon" onClick={() => history.goBack()} />
				<Title level={4}>Listagem de setores</Title>
			</div>
			<div className={checkPermission('cadastrar-setores') && "inventory__filters"}
				style={{ marginTop: '20px' }}>
				<Input
					value={searchFilterValue}
					onChange={handleInputChange}
					className="inventory__filters--search"
					size="large"
					placeholder="Pesquisa por nome"
					prefix={<SearchOutlined />}
				/>
				{/* <Select size="large" className="inventory__filters--select" defaultValue="0">
          <Option value="0">
            <CircleColor color="green">
              Manutenção em dia
            </CircleColor >
          </Option>
          <Option value="1">
            <CircleColor color="red">
              Manutenção atrasada
            </CircleColor>
          </Option>
          <Option value="2">
            <CircleColor color="yellow">
              Manutenção perto de atrasar
            </CircleColor>
          </Option>
          <Option value="3">
            <CircleColor color="blue">
              Não necessita de manutenção
            </CircleColor>
          </Option>
        </Select> */}
				<CheckPermission slug="cadastrar-setores">
					<Link to="/home/sectors/create">
						<Button size="large" withIcon >
							Novo Cadastro
						</Button>
					</Link>
				</CheckPermission>
			</div>
			<div>
				<Table
					columns={getColumns()}
					dataSource={filterList()}
				/>
			</div>
		</div>
	)
}

export default SectorList;
