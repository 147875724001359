import React, { createContext, useState } from "react";
import ToastComponent from "~/components/ToastComponent/ToastComponent";

const initialState = {
  show: false,
  message: "",
  title: "",
  type: "",
  autohide: true,
};

export const ToastContext = createContext({
  toast: initialState,
  setToast: () => { },
});

const ToastProvider = ({ children }) => {
  const [value, setValue] = useState(initialState);

  const handleClose = () => {
    setValue(initialState);
  };

  return (
    <ToastContext.Provider
      value={{
        toast: value,
        setToast: (toast = {}) => setValue({ ...toast, show: true }),
      }}
    >
      {children}
      <ToastComponent
        title={value.title}
        description={value.message}
        type={value.type}
        showToast={value.show}
        autohide={value.autohide}
        handleClose={handleClose}
      />
    </ToastContext.Provider>
  );
};

export default ToastProvider;
