import React from "react";

export function wrapUrlInAnchorTag(text) {
  const urlPattern = /(https?:\/\/[^\s]+)/g;
  const hasUrl = urlPattern.test(text);

  if (!hasUrl) {
    return text;
  }

  return text.replace(
    urlPattern,
    '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>',
  );
}

export function wrapUrlInAnchorTagWithIcon(text) {
  const urlPattern = /(https?:\/\/[^\s]+)/g;
  const parts = text.split(urlPattern);

  return parts.map((part, index) => {
    if (urlPattern.test(part)) {
      return (
        <a
          style={{ position: "relative" }}
          key={index}
          href={part}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span
            style={{ position: "absolute", color: "#40A9FF" }}
            className="material-icons ml-2"
          >
            link
          </span>
        </a>
      );
    } else {
      return part;
    }
  });
}
