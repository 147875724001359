import { Formik, Form } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import FormikInput from "~/components/Input/FormikInput";
// import SectionTitle from "~/components/SectionTitle/SectionTitle";
import Breadcrumb from "~/components/Breadcrumb/Breadcrumb";
import Text from "antd/lib/typography/Text";
import { BeatLoader } from "react-spinners";
import {
  InventoryRoutes,
  SectorRoutes,
  ServiceRoutes,
  UserRoutes,
} from "~/http/routes";
import { useSelector } from "react-redux";
import { userSelector } from "~/redux/Selectors";
// import Checkbox from "antd/lib/checkbox/Checkbox";
import { ToastContext } from "~/providers/ToastProvider";
import CollapsibleSubItems from "~/components/CollapsibleSubItems/CollapsibleSubItems";
import CollapsibleSubProject from "~/components/CollapsibleSubItems/CollapsibleSubProject";

const initialService = {
  id: "",
  name: "",
  active: true,
  subprojects: [],
};

const validation = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = "Campo obrigatório";
  }

  return errors;
};

const subprojectsMock = [
  {
    id: 1,
    name: "Subproject 01",
    selected: false,
  },
  {
    id: 2,
    name: "Subproject 02",
    selected: false,
  },
];

const ServiceForm = () => {
  const { id } = useParams();
  const history = useHistory();
  const { companyId } = useSelector(userSelector);
  const { setToast } = useContext(ToastContext);
  const [service, setService] = useState(initialService);
  const [color] = useState("#C4C4C4");
  //const [subprojects, setSubprojects] = useState(subprojectsMock);

  useEffect(() => {
    if (id !== "create") {
      ServiceRoutes.getServiceById(companyId, id).then(({ data }) => {
        setService(data.data);
      });
    }
  }, [companyId, id]);

  const onSubmit = (values) => {
    const save = values.id ? ServiceRoutes.update : ServiceRoutes.save;

    return save(companyId, values)
      .then(() => {
        history.push("/home/services");

        setToast({
          title: "Sucesso!",
          type: "success",
          message: "Serviço salvo com sucesso!",
        });
      })
      .catch((err) => {
        setToast({
          type: "error",
          message: err.response?.data?.message || err?.message,
          title: "Ops, aconteceu um erro ao salvar o serviço!",
        });
      });
  };

  return (
    <>
      <Breadcrumb title="Cadastro de serviços" routeToBack="/home/services" />
      <Formik
        enableReinitialize
        initialValues={service}
        onSubmit={onSubmit}
        validate={validation}
      >
        {({ isSubmitting, values, setFieldValue }) => {
          return (
            <Form>
              <Row>
                <Col md={12} sm={12} xs={12}>
                  <FormikInput
                    name="name"
                    placeholder="Nome"
                    className="mt-4"
                  />
                </Col>
                {/* <Col md={3} sm={4} xs={4}>
                  <FormikSwitchCheckbox
                    id="active"
                    label="Ativo"
                    name="active"
                  />
                </Col> */}
              </Row>
              <Row className="mt-4 d-flex  ">
                <Col md={11}>
                  <FormikInput
                    name="description"
                    placeholder="Descrição"
                    inputType="textArea"
                    rows={4}
                  />
                </Col>

                <Col md={1}>
                  <FormikInput
                    className="mt-4 "
                    name="color"
                    placeholder="Cor"
                    inputType="color"
                    value={color}
                    data-toggle="tooltip"
                    title="Essa cor será exibida na visualização em calendário das tarefas"
                  />

                  {/* <div className="d-flex flex-column mt-4 ">
                    <label className="m-auto">Cor do Serviço:</label>
                    <input
                      data-toggle="tooltip"
                      title="Essa cor será exibida na visualização em calendário das tarefas"
                      className="m-auto"
                      name="color"
                      type="color"
                      onChange={(e) => setColor(e.target.value)}
                      value={color}
                    />
                  </div> */}
                </Col>
              </Row>

              {/* <SectionTitle className="mt-4">Subprojetos</SectionTitle>
              <Row>
                {subprojects.map((sp, index) => (
                  <Checkbox
                    className="mb-4 ml-3"
                    onChange={(event) => {
                      const newSubproject = {
                        ...sp,
                        selected: event.target.checked,
                      };
                      setSubprojects((prev) => [
                        ...prev.slice(0, index),
                        newSubproject,
                        ...prev.slice(index + 1),
                      ]);
                    }}
                    checked={sp.selected}
                  >
                    {sp.name}
                  </Checkbox>
                ))}
              </Row> */}

              <CollapsibleSubItems
                title="Inventários"
                onSearch={() => InventoryRoutes.getInventory(companyId)}
                selections={values.inventory_id}
                handleSelections={(items) =>
                  setFieldValue("inventory_id", items)
                }
              />
              <CollapsibleSubItems
                title="Setores"
                onSearch={() => SectorRoutes.getSector(companyId)}
                selections={values.sector_id}
                handleSelections={(items) => setFieldValue("sector_id", items)}
              />
              <CollapsibleSubItems
                title="Usuários"
                onSearch={UserRoutes.getUsers}
                selections={values.user_id}
                handleSelections={(items) => setFieldValue("user_id", items)}
              />
              <CollapsibleSubProject
                selections={values.sub_project_id}
                handleSelections={(items) =>
                  setFieldValue("sub_project_id", items)
                }
              />
              <Row className="mt-5 d-flex align-items-center justify-content-center flex-column">
                <button
                  type="submit"
                  className="btn-default mt-5"
                  disabled={isSubmitting}
                  style={{ width: "30%" }}
                >
                  {isSubmitting ? (
                    <BeatLoader
                      color="#000000"
                      loading={isSubmitting}
                      size={10}
                    />
                  ) : (
                    "Salvar"
                  )}
                </button>

                <Text
                  underline
                  className="mt-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push("/home/projects")}
                >
                  Cancelar
                </Text>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ServiceForm;
