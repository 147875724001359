import "./index.css";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "../../redux/Selectors";
import { Menu, Dropdown, Tag, Avatar, Modal } from "antd";
import { Image } from "react-bootstrap";
import moment from "moment";

import {
  CheckSquareOutlined,
  CommentOutlined,
  EyeOutlined,
  MoreOutlined,
  PaperClipOutlined,
  DeleteTwoTone,
  EditTwoTone,
  ExclamationCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import Chip from "../Chip/Chip";
import { ProjectRoutes, TaskRoutes } from "../../http/routes";

import { ToastContext } from "~/providers/ToastProvider";
import CheckPermission from "../CheckPermission";
import checkPermission from "~/helpers/checkPermission";
import SubprojectDropdown from "../SubprojectDropdown/SubprojectDropdown";

const iconsStyle = {
  color: "#585858",
  fontSize: "17px",
  marginRight: "3px",
};

function DropdownMenu(props) {
  const { dateClosed, statusName, onClick } = props;

  return (
    <Menu onClick={onClick}>
      {checkPermission("notificar-tarefas") && (
        <Menu.ItemGroup title="Habilitar notificação">
          <Menu.Item key="push_notification">
            <p
              style={{
                color: "#585858",
                fontWeight: 500,
                margin: 0,
              }}
            >
              Push
            </p>
          </Menu.Item>
          <Menu.Item key="email">
            <p
              style={{
                color: "#585858",
                fontWeight: 500,
                margin: 0,
              }}
            >
              Email
            </p>
          </Menu.Item>
          <Menu.Item key="whats">
            <p
              style={{
                color: "#585858",
                fontWeight: 500,
                margin: 0,
              }}
            >
              Whatsapp
            </p>
          </Menu.Item>
        </Menu.ItemGroup>
      )}
      {dateClosed !== null && statusName === "CONCLUÍDO" ? (
        <Menu.ItemGroup title="Outros">
          <Menu.Item key="toFile">
            <p
              style={{
                color: "#FFB40F",
                fontWeight: 500,
                margin: 0,
              }}
            >
              Arquivar
            </p>
          </Menu.Item>
        </Menu.ItemGroup>
      ) : (
        <></>
      )}
      {checkPermission("alterar-task-subproject") && (
        <Menu.ItemGroup title="">
          <Menu.Item key="change_subproject">
            <span
              style={{
                color: "#2D9CDB",
                fontWeight: 500,
              }}
              className="d-flex align-items-center justify-content-between "
            >
              Alterar subprojeto{" "}
              <EditTwoTone className="ml-2" twoToneColor="#2D9CDB" />
            </span>
          </Menu.Item>
        </Menu.ItemGroup>
      )}
      {checkPermission("deletar-tarefa") && (
        <Menu.ItemGroup title="">
          <Menu.Item key="delete_task">
            <span
              style={{
                color: "#EB5757",
                fontWeight: 500,
              }}
              className="d-flex align-items-center justify-content-between "
            >
              Excluir tarefa <DeleteTwoTone twoToneColor="#EB5757" />
            </span>
          </Menu.Item>
        </Menu.ItemGroup>
      )}
    </Menu>
  );
}

function Card(props) {
  const {
    id,
    name,
    description,
    statusName,
    sectorName,
    serviceName,
    priorityName,
    priorityColor,
    responsiblePhoto,
    dateClosed,
    dueDate,
    startDate,
    commentsQuantity,
    attachmentsQuantity,
    observersQuantity,
    fieldsQuantity,
    filledFields,
    daysLate,
    provided,
    snapshot,
    handleCardClick,
    onToFile,
    onFinishChangeSubproject,
    handleDeleteTask,
    customFields,
  } = props;

  const dispatch = useDispatch();
  const userStore = useSelector(userSelector);
  const { setToast } = useContext(ToastContext);
  const { companyId } = useSelector((state) => state.userStore);
  const [isChangeSubProjectModalVisible, setIsChangeSubProjectModalVisible] =
    useState(false);

  const [subProjectSelected, setSubProjectSelected] = useState(
    userStore.subprojectId,
  );

  const defaultNotificationObj = {
    task_id: id,
    push_notification: false,
    email: false,
    whats: false,
    telegran: false,
    message: " ",
  };

  const toFile = async () => {
    try {
      if (window.confirm("Tem certeza que deseja arquivar esta tarefa?")) {
        await TaskRoutes.toFile(id);

        setToast({
          type: "success",
          title: "Sucesso!",
          message: "Tarefa arquivada com sucesso!",
          show: true,
          autohide: true,
        });

        if (onToFile) onToFile(id);
      }
    } catch (err) {
      setToast({
        type: "error",
        title: "Erro!",
        message: "Um erro inesperado ocorreu ao arquivar a tarefa!",
        show: true,
        autohide: true,
      });
    }

    const projectsRes = await ProjectRoutes.getProjects(companyId);

    dispatch({ type: "SET_PROJECT_INFOS", data: projectsRes.data.data });
  };

  const { confirm } = Modal;

  const showConfirmDeleteTaskModal = () => {
    confirm({
      icon: <ExclamationCircleOutlined style={{ fontSize: "3em" }} />,
      content: `Tem certeza que deseja excluir a tarefa?`,
      okText: "Excluir",
      okButtonProps: {
        style: {
          alignContent: "center",
          display: "inline-flex",
          alignItems: "center",
          alignSelf: "center",
        },
        danger: true,
        icon: <DeleteOutlined></DeleteOutlined>,
      },
      cancelText: "Cancelar",
      closable: true,
      onOk() {
        handleDeleteTask(id);
      },
    });
  };

  const handleDropDownMenuClick = async ({ key }) => {
    switch (key) {
      case "toFile":
        return await toFile();
      case "change_subproject":
        return setIsChangeSubProjectModalVisible(true);
      case "delete_task":
        return showConfirmDeleteTaskModal();

      default:
        const payload = { ...defaultNotificationObj };
        payload[key] = true;

        return await TaskRoutes.postNotification(payload);
    }
  };

  function renderDescription() {
    if (description) return <p className="description">{description}</p>;

    return <p className="description" style={{ height: "21px" }}></p>;
  }

  function renderSector() {
    if (!sectorName)
      return <p className="description" style={{ height: "27px" }}></p>;

    return (
      <Tag
        className="mb-2"
        color="gold"
        style={{ borderRadius: "25px", height: "20px" }}
      >
        <p className="description">{sectorName}</p>
      </Tag>
    );
  }

  function renderObserverEyeIcon() {
    if (!observersQuantity) return <></>;

    return (
      <span className="mr-3 cursor-pointer" onClick={handleCardClick}>
        <EyeOutlined style={iconsStyle} />
      </span>
    );
  }

  function renderFieldsCounter() {
    if (!fieldsQuantity) return <></>;

    return (
      <span
        className="d-flex align-items-center mr-3"
        style={{ color: "#585858" }}
      >
        <CheckSquareOutlined style={iconsStyle} />
        {String(filledFields)}/{fieldsQuantity}
      </span>
    );
  }

  function renderCommentsCounter() {
    if (!commentsQuantity) return <></>;

    return (
      <span
        className="d-flex align-items-center mr-3"
        style={{ color: "#585858" }}
      >
        <CommentOutlined style={iconsStyle} />
        {commentsQuantity}
      </span>
    );
  }

  function renderAttachmentsCounter() {
    if (!attachmentsQuantity) return <></>;

    return (
      <span
        className="d-flex align-items-center mr-3 cursor-pointer"
        style={{ color: "#585858" }}
        onClick={handleCardClick}
      >
        <PaperClipOutlined style={iconsStyle} />
        {attachmentsQuantity}
      </span>
    );
  }

  function renderResponsiblePhoto() {
    if (!responsiblePhoto)
      return (
        <Avatar
          size={20}
          icon={
            <span
              className="material-icons"
              style={{
                color: "#585858",
                fontSize: 18,
              }}
            >
              person
            </span>
          }
        />
      );

    return (
      <Image
        width="20"
        height="20"
        src={"data:image/png;base64, " + responsiblePhoto}
        roundedCircle
      />
    );
  }

  function getFormFields() {
    const filteredResults = customFields.form_field_results?.filter(
      (result) => {
        return result.task_id === id;
      },
    );

    return filteredResults[0]?.name == null
      ? filteredResults[0]?.value
      : filteredResults[0]?.name;
  }

  function getBloquedFields() {
    const filteredResults = customFields.blocked_field_results?.filter(
      (result) => {
        return result.task_id === id;
      },
    );

    return filteredResults[0]?.name == null
      ? filteredResults[0]?.value
      : filteredResults[0]?.name;
  }

  function renderQuantitiesIcons() {
    if (
      !observersQuantity &&
      !fieldsQuantity &&
      !commentsQuantity &&
      !attachmentsQuantity
    )
      return <span className="mr-3" style={{ height: "20px" }} />;

    return (
      <>
        {renderObserverEyeIcon()}
        {renderFieldsCounter()}
        {renderCommentsCounter()}
        {renderAttachmentsCounter()}
      </>
    );
  }

  function renderDueDateOrIsLate() {
    const nowInMiliseconds = new Date().getTime();
    let msg;
    let colorText = "#ff3838";

    if (!dueDate || dateClosed) return <></>;

    const dueDateObj = moment(dueDate, "DD/MM/YYYY HH:mm:ss");
    const dueDateTimeZone = dueDateObj.subtract(3, "hours");

    const dueDateInMiliseconds = moment(dueDateTimeZone, "DD/MM/YYYY HH:mm:ss")
      .toDate()
      .getTime();

    if (daysLate > 0) {
      msg = `atrasado há ${daysLate} dias`;
    } else if (dueDateInMiliseconds < nowInMiliseconds) {
      msg = `atrasado`;
    } else {
      if (dueDateInMiliseconds - nowInMiliseconds < 86400000) {
        msg = `entrega em ${
          (dueDateInMiliseconds - nowInMiliseconds) / 3600000 < 1
            ? Math.floor(
                ((dueDateInMiliseconds - nowInMiliseconds) % 3600000) / 60000,
              ) + " min"
            : Math.ceil((dueDateInMiliseconds - nowInMiliseconds) / 3600000) +
              " h"
        } `;
        colorText = "#F2994A";
      } else {
        msg = `entrega em ${dueDateTimeZone.format("DD/MM/YY - HH:mm")}`;
        colorText = "#2D9CDB";
      }
    }

    return (
      <span
        className="d-flex align-items-center"
        style={{
          color: `${colorText}`,
          textTransform: "uppercase",
        }}
      >
        <span className="material-icons mr-1" style={{ fontSize: 18 }}>
          alarm
        </span>
        {msg}
      </span>
    );
  }

  function renderChangeSubprojectModal() {
    if (isChangeSubProjectModalVisible) {
      return (
        <SubprojectDropdown
          id="subproject_id"
          projects={userStore.projectInfos}
          handleSubProjectSelected={handleSubProjectSelected}
          value={subProjectSelected}
          placeholder="Subprojeto"
          taskId={id}
          isVisible={isChangeSubProjectModalVisible}
          onClose={handleCloseChangeSubprojectModal}
          onSubmit={handleChangeSubprojectId}
        />
      );
    }
  }

  function handleCloseChangeSubprojectModal() {
    setIsChangeSubProjectModalVisible(false);
    setSubProjectSelected(null);
  }

  function handleSubProjectSelected(e) {
    setSubProjectSelected(e);
  }

  async function handleChangeSubprojectId() {
    try {
      setIsChangeSubProjectModalVisible(false);
      setSubProjectSelected(null);
      onFinishChangeSubproject();
      TaskRoutes.patchSubProjecId(id, subProjectSelected);

      setToast({
        type: "success",
        title: "Ok!",
        message: "Subprojeto da task alterado com sucesso!",
        show: true,
        autohide: true,
      });
    } catch (err) {
      setToast({
        type: "error",
        title: "Erro!",
        message: "Um erro ocorreu ao alterar o subprojeto da task!",
        show: true,
        autohide: true,
      });
    }
  }

  return (
    <>
      <div
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        className="kanban-card shadow-sm"
        ref={provided.innerRef}
        style={{
          backgroundColor: snapshot.isDragging ? "#f2eeed" : "#FFF",
          ...provided.draggableProps.style,
          borderLeft: `17px solid ${priorityColor}`,
        }}
      >
        <div>
          <div className="d-flex">
            {customFields.sector_name && sectorName ? (
              renderSector()
            ) : (
              <Chip
                color={priorityColor}
                className="m-0"
                statusName={priorityName}
              />
            )}

            {!checkPermission("notificar-tarefas") &&
            dateClosed === null &&
            statusName !== "CONCLUÍDO" ? (
              <></>
            ) : (
              <div className="d-flex justify-content-end align-items-center w-100">
                <div style={{ cursor: "pointer" }}>
                  <Dropdown
                    overlay={
                      <DropdownMenu
                        dateClosed={dateClosed}
                        statusName={statusName}
                        onClick={handleDropDownMenuClick}
                      />
                    }
                    trigger={["click"]}
                    className="w-100 h-100"
                  >
                    <MoreOutlined
                      rotate={90}
                      style={{
                        color: "#585858",
                        fontSize: "25px",
                      }}
                    />
                  </Dropdown>
                </div>
              </div>
            )}
          </div>
          <div onClick={handleCardClick}>
            <div className="d-flex align-items-center w-100">
              <span className="font-weight-bold card-name cursor-pointer">
                {/* <strong className="cursor-pointer">{`#${id || "N/A"} - `} </strong> */}
                {customFields.created_date ? (
                  <div
                    style={{
                      justifyContent: "space-between",
                      width: "307px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span
                      className="font-weight-normal"
                      style={{
                        fontSize: "13px",
                        color: "#585858",
                        paddingTop: "10px",
                      }}
                    >
                      {startDate ? startDate.split(" ")[0] : <></>}
                    </span>
                    <br></br>
                    {customFields.task_id ? (
                      <span
                        style={{
                          fontSize: "14px",
                          color: "#585858",
                          marginRight: "0",
                        }}
                      >{`${id}`}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}

                <div
                  style={{
                    justifyContent: "space-between",
                    width: "307px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span className="card-name" style={{ fontSize: "16px" }}>
                    {name}
                  </span>
                  {customFields.blocked_field && getBloquedFields() ? (
                    <Tag
                      color="blue"
                      style={{
                        borderRadius: "25px",
                        height: "20px",
                        margin: "0",
                        fontWeight: "normal",
                      }}
                    >
                      <p>{getBloquedFields()}</p>
                    </Tag>
                  ) : (
                    <></>
                  )}
                </div>

                {Object.keys(customFields).length === 0 ? (
                  <span
                    style={{ fontSize: "14px", color: "#585858" }}
                  >{`${id}`}</span>
                ) : (
                  <span style={{ fontSize: "15px", color: "#585858" }}>
                    {serviceName ? serviceName : <br></br>}
                  </span>
                )}
              </span>
            </div>
            {customFields.form_field && (
              <>
                <br></br>
                <span
                  className="font-weight-bold"
                  style={{ fontSize: "14px", color: "#585858" }}
                >
                  {getFormFields() ? getFormFields() : <br></br>}
                </span>
              </>
            )}

            {Object.keys(customFields).length === 0 && renderDescription()}
            {Object.keys(customFields).length === 0 && renderSector()}
          </div>
        </div>
        <div>
          <div className="d-flex align-items-center">
            {Object.keys(customFields).length === 0 && renderQuantitiesIcons()}
          </div>
          <div className="d-flex justify-content-between mt-2">
            {renderResponsiblePhoto()}
            {renderDueDateOrIsLate()}
          </div>
        </div>
      </div>
      {renderChangeSubprojectModal()}
    </>
  );
}

export default Card;
