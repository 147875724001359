import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Divider } from "antd";
import { isEqual } from "lodash";
import { StatusesRoutes } from "~/http/routes";

import { SET_FILTERS_STATUS } from "~/redux/actions/DatailedReportFiltersActions";

function Status() {
  const dispatch = useDispatch();
  const { projectInfos } = useSelector((state) => state.userStore);
  const { statuses } = useSelector((state) => state.detailedReportFilters);
  const { companyId } = useSelector((state) => state.userStore);
  const { subprojectId } = useSelector((state) => state.userStore);

  const [projectsMap, setProjectsMap] = useState([]);
  const [statusesMap, setStatusesMap] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [SubProjectsAuxCheckAll, setSubProjectsAuxCheckAll] = useState([0]);

  useEffect(() => {
    const setStatus = async () => {
      const statuses = await StatusesRoutes.getStatuses(
        companyId,
        subprojectId,
      );

      setStatusesMap(statuses.data.data);
    };

    setStatus();
  }, []);

  useEffect(() => {
    const aux = [];
    const auxStatus = [];
    const statusHash = statusesMap.filter(
      (status) =>
        status.subproject_id != null && status.subproject_id.startsWith("#"),
    );

    for (const project of projectInfos) {
      project.subProjects.map((subproject) => {
        let subprojectStatuses = statusesMap.filter(
          (status) => status.subproject_id == subproject.id,
        );

        aux.push({
          id: subproject.id,
          name: subproject.name,
          statuses:
            subprojectStatuses.length > 0 ? subprojectStatuses : statusHash,
        });

        auxStatus.push({
          id: subproject.id,
          statuses: [],
        });
      });
    }

    setProjectsMap(aux);

    setStatusList(auxStatus);
  }, [statusesMap]);

  function setSubProjects(e) {
    dispatch({ type: SET_FILTERS_STATUS, data: e });
  }

  function handleCheck(subProject, status) {
    const aux = statusList;
    const index = aux.findIndex((e) => e.id == subProject.id);

    if (aux[index]?.statuses.length === status.length) aux.splice(index, 1);
    else {
      if (index > -1) aux[index].statuses = status;
      else
        aux.push({
          id: subProject.id,
          statuses: status,
        });
    }

    setSubProjects(aux);
  }

  function handleCheckAll(e) {
    const aux = [];
    projectsMap.map((sp) => {
      aux.push({
        id: sp.id,
        statuses: sp.statuses.map((s) => {
          return s.id;
        }),
      });
    });
    if (e.target.checked) {
      setSubProjects(aux);
      setSubProjectsAuxCheckAll(aux);
    } else setSubProjects([]);
  }

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <Checkbox
            checked={isEqual(statuses, SubProjectsAuxCheckAll)}
            indeterminate={!isEqual(statuses, SubProjectsAuxCheckAll)}
            onChange={handleCheckAll}
          >
            Marcar todos
          </Checkbox>
        </div>

        <Divider />
      </div>

      <div>
        {projectsMap.map((e, i) => {
          const options = e.statuses.map((sp) => {
            return { label: sp.name, value: sp.id };
          });

          const values = statuses.find((p) => p.id == e.id)?.statuses || [];

          const isAllChecked = options.length == values.length;

          return (
            <div key={i}>
              <Checkbox
                checked={isAllChecked}
                indeterminate={!isAllChecked}
                onChange={handleCheck.bind(
                  null,
                  e,
                  e.statuses.map((sp) => sp.id),
                )}
              >
                {e.name}
              </Checkbox>

              <Divider />

              <div className="ml-5">
                <Checkbox.Group
                  options={options}
                  value={values}
                  onChange={(sp) => handleCheck(e, sp)}
                />
              </div>

              <Divider />
            </div>
          );
        })}
      </div>
    </>
  );
}

export default Status;
