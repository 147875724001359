import React, { useCallback, useEffect, useState } from "react";
import { Table } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";

import { useSelector } from "react-redux";
import { userSelector } from "~/redux/Selectors";
import { ProjectRoutes } from "~/http/routes";
// import CircleColor from "~/components/CircleColor/CircleColor";
import { Input } from "antd";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined, SearchOutlined } from "@ant-design/icons";
import Button from "~/components/Button/Button";
import { Typography } from "antd";
import CheckPermission from "~/components/CheckPermission";
import checkPermission from "~/helpers/checkPermission";

// const { Option } = Select;

const { Title } = Typography;

const ProjectList = () => {
	const history = useHistory();
	const [projects, setProjects] = useState([]);
	const { companyId } = useSelector(userSelector);
	const [searchFilterValue, setSearchFilterValue] = useState("");

	useEffect(() => {
		ProjectRoutes.getProjects(companyId).then(({ data }) => {
			setProjects(data.data);
		});
	}, [companyId]);

	const handleInputChange = (event) => {
		setSearchFilterValue(event.target.value);
	};

	const filterList = useCallback(() => {
		return projects.filter((value) =>
			value.name.toLowerCase().includes(searchFilterValue.toLowerCase()),
		);
	}, [searchFilterValue, projects]);

	function getColumns() {
		const columns = [
			{ key: "name", title: "Nome", dataIndex: "name", width: "90%" },
		]

		if (checkPermission('editar-projetos')) {
			columns.push({
				key: "edit",
				title: "Editar",
				align: "center",
				render: (project) => {
					return (
						<EditOutlined
							className="inventory__icons"
							onClick={() => history.push(`/home/projects/${project.id}`)}
						/>
					);
				},
			});
		}

		return columns;
	}

	return (
		<div>
			<div className="inventory__title">
				<ArrowLeftOutlined
					className="inventory__title--icon"
					onClick={() => history.goBack()}
				/>
				<Title level={4}>Listagem de projetos</Title>
			</div>

			<div className={checkPermission('cadastrar-projetos') && "inventory__filters"}
				style={{ marginTop: '20px' }}>
				<Input
					value={searchFilterValue}
					onChange={handleInputChange}
					className="inventory__filters--search"
					size="large"
					placeholder="Pesquisa por nome"
					prefix={<SearchOutlined />}
				/>

				<CheckPermission slug="cadastrar-projetos">
					<Link to="/home/projects/create">
						<Button size="large" withIcon>
							Novo Cadastro
						</Button>
					</Link>
				</CheckPermission>
			</div>
			<Table
				columns={getColumns()}
				dataSource={filterList()}
			/>
		</div>
	);
};

export default ProjectList;
