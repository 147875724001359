'use strict';

import expirationInDays from "./expirationInDays";

function isUpToDate(date) {
	if (!date)
		return false;

	return expirationInDays(date) > 0;
}

export default isUpToDate;
