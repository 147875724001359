import { useState } from "react";
import "./Project.css";
import Kanban from "../../components/Kanban/Kanban.js";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { Link, useHistory } from "react-router-dom";
import {
  DashboardOutlined,
  SearchOutlined,
  CalendarOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { Switch as SwitchOption } from "antd";
import CheckPermission from "~/components/CheckPermission";
import checkPermission from "~/helpers/checkPermission";
import Input from "~/components/Input/Input";
import { userSelector } from "~/redux/Selectors";
import { useSelector } from "react-redux";

function Project() {
  const { subprojectId } = useSelector(userSelector);
  const userStore = useSelector(userSelector);

  const { push } = useHistory();

  const [modalIsOpen, setModalState] = useState(false);
  const [searchedTask, setSearchedTask] = useState("");
  const [searchedUser, setSearchedUser] = useState(
    checkPermission("visualizar-todas-tarefas") ? "" : userStore.userId,
  );
  const [checked, setChecked] = useState(true);
  const [switchLabelColor, setSwitchLabelColor] = useState("grey");

  const handleModal = () => {
    setModalState((m) => !m);
  };

  function handleSearchedTaskChange(e) {
    setSearchedTask(e.target.value);
    setChecked(false);
    setSwitchLabelColor("grey");
  }

  function switchResponsibleFilter(e) {
    setSearchedTask("");
    let filter = !e ? `${userStore.userId}` : "";
    let colorLabel = e ? "#1890ff" : "grey";
    setSwitchLabelColor(colorLabel);
    setSearchedUser(filter);

    setChecked(e);
  }

  return (
    <div>
      <div className="mt-3 mb-4 project__header">
        <div className="d-flex">
          <Breadcrumb title="" routeToBack="/home" />

          <button
            className="button-kanban"
            style={{ marginLeft: "10px", width: "175px" }}
            onClick={() => push(`/home/projects/listview`)}
          >
            <span>
              <UnorderedListOutlined className="align-middle mr-0" />
            </span>{" "}
            Lista
          </button>
          <button
            className="button-kanban"
            style={{ marginLeft: "10px" }}
            onClick={() => push(`/home/projects/calendar`)}
          >
            <span>
              <CalendarOutlined className="align-middle mr-0" />
            </span>{" "}
            Agenda
          </button>
        </div>

        <div className="d-flex">
          <Link to="/home/dashboard" style={{ textDecoration: "none" }}>
            <button className="btn-dashboard">
              <DashboardOutlined />
              <span className="btn-title">Dashboard</span>
            </button>
          </Link>
          <CheckPermission slug="visualizar-atas">
            <button
              className="btn-dashboard"
              onClick={() => push(`/home/atas/${subprojectId}`)}
            >
              <span className="btn-title" style={{ marginLeft: 0 }}>
                Atas
              </span>
            </button>
          </CheckPermission>
          <CheckPermission slug="criar-tarefa">
            <button className="btn-create" onClick={handleModal}>
              + Nova Tarefa
            </button>
          </CheckPermission>
        </div>
      </div>
      <div className="d-flex  ">
        <div className="col-sm-3 pl-0">
          <Input
            value={searchedTask}
            onChange={handleSearchedTaskChange}
            className="inventory__filters--search"
            size="large"
            placeholder="Buscar tarefa"
            inputPlaceholder="Pesquisar por id, nome, descrição, setor ou serviço"
            prefix={<SearchOutlined />}
          />
        </div>

        <CheckPermission slug="visualizar-todas-tarefas">
          <div className="pl-0 d-flex align-items-center  ">
            <SwitchOption
              onClick={(e) => switchResponsibleFilter(e)}
              checked={checked}
            />
            <p
              className="ml-1"
              style={{
                color: switchLabelColor,
                margin: "auto",
              }}
            >
              <strong>Todas as tarefas</strong>
            </p>
          </div>
        </CheckPermission>
      </div>
      {/*<div>*/}
      {/*  <Formik*/}
      {/*    initialValues={{*/}
      {/*      description: "",*/}
      {/*      users: "",*/}
      {/*      status: "",*/}
      {/*      initDate: "",*/}
      {/*      finalDate: "",*/}
      {/*    }}*/}
      {/*    onSubmit={handleFormSubmit}*/}
      {/*  >*/}
      {/*    {({*/}
      {/*      values,*/}
      {/*      handleSubmit,*/}
      {/*      handleChange,*/}
      {/*      isSubmitting,*/}
      {/*      errors,*/}
      {/*      touched,*/}
      {/*    }) => (*/}
      {/*      <form className="w-100" onSubmit={handleSubmit}>*/}
      {/*        <div className="header-form-kanban">*/}
      {/*          <div >*/}
      {/*            <SearchBar*/}
      {/*              placeholder="Pesquise por descrição"*/}
      {/*              value={values.description}*/}
      {/*              handleChange={handleChange}*/}
      {/*              id="description"*/}
      {/*              inputBackground="transparent"*/}
      {/*            />*/}
      {/*          </div>*/}
      {/*          <div >*/}
      {/*            <Select*/}
      {/*              placeholder="Usuários"*/}
      {/*              id="users"*/}
      {/*              errors={errors.users}*/}
      {/*              touched={touched.users}*/}
      {/*              value={values.users}*/}
      {/*              handleChange={handleChange}*/}
      {/*              inputBackground="transparent"*/}
      {/*              labelBackground="#fafafa"*/}
      {/*            />*/}
      {/*          </div>*/}
      {/*          <div >*/}
      {/*            <Select*/}
      {/*              placeholder="Status"*/}
      {/*              id="status"*/}
      {/*              errors={errors.status}*/}
      {/*              touched={touched.status}*/}
      {/*              value={values.status}*/}
      {/*              handleChange={handleChange}*/}
      {/*              inputBackground="transparent"*/}
      {/*              labelBackground="#fafafa"*/}
      {/*            />*/}
      {/*          </div>*/}
      {/*          <div >*/}
      {/*            <Input*/}
      {/*              placeholder="Início"*/}
      {/*              type="text"*/}
      {/*              id="initDate"*/}
      {/*              errors={errors.initDate}*/}
      {/*              touched={touched.initDate}*/}
      {/*              value={values.initDate}*/}
      {/*              handleChange={handleChange}*/}
      {/*              inputBackground="transparent"*/}
      {/*              labelBackground="#fafafa"*/}
      {/*              inputPlaceholder="12/10/2021"*/}
      {/*            />*/}
      {/*          </div>*/}
      {/*          <div >*/}
      {/*            <Input*/}
      {/*              placeholder="Fim"*/}
      {/*              type="text"*/}
      {/*              id="finalDate"*/}
      {/*              errors={errors.finalDate}*/}
      {/*              touched={touched.finalDate}*/}
      {/*              value={values.finalDate}*/}
      {/*              handleChange={handleChange}*/}
      {/*              inputBackground="transparent"*/}
      {/*              labelBackground="#fafafa"*/}
      {/*              inputPlaceholder="12/10/2021"*/}
      {/*            />*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </form>*/}
      {/*    )}*/}
      {/*  </Formik>*/}
      {/*</div>*/}
      <div className="div-kanban mt-2">
        <Kanban
          modalIsOpen={modalIsOpen}
          handleModal={handleModal}
          searchedTask={searchedTask}
          searchedUser={searchedUser}
        />
      </div>
    </div>
  );
}

export default Project;
