import React, { useState } from "react";
import { Formik } from "formik";
import { BeatLoader } from "react-spinners";
import homeBackground from "../../assets/home-background.png";
import Input from "../../components/Input/Input";
import "./Login.css";
import { LoginRoutes, UserRoutes } from "../../http/routes";
import { useHistory } from "react-router-dom";
import ToastComponent from "../../components/ToastComponent/ToastComponent";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "../../redux/Selectors";
import ForgetPasswordModal from "./ForgetPasswordModal";

function Login() {
  const history = useHistory();
  const dispatch = useDispatch();
  const authStore = useSelector(userSelector);
  const [toast, setToast] = useState({
    hasError: false,
    message: "",
    type: "",
  });
  const [forgetPassword, setForgetPassword] = useState(false);
  const [isLoadingToken, setIsLoadingToken] = useState(false);
  const [hasToken, setHasToken] = useState(false);

  if (authStore.auth) {
    window.localStorage.clear();
    dispatch({ type: "SET_AUTH_FALSE" });
  }

  const handleFormSubmit = (values) => {
    return LoginRoutes.postSession(values)
      .then(async (res) => {
        dispatch({ type: "SET_AUTH_TOKEN", data: res.data.token });
        history.push("/home/dashboard");
      })
      .catch((err) => {
        setToast({ message: err.message, hasError: true, type: "error" });
      });
  };

  const handleClose = () => {
    setToast({ message: "", hasError: false, type: "error" });
  };

  const formValidation = (values) => {
    const errors = {};
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (!values.email) {
      errors.email = "Campo obrigatório!";
    } else if (!emailRegex.test(values.email)) {
      errors.email = "E-mail inválido!";
    }

    if (!values.password) {
      errors.password = "Campo obrigatório!";
    }

    return errors;
  };

  async function handleGetToken(email) {
    setIsLoadingToken(true);
    try {
      const data = { email: email };
      const res = await UserRoutes.postForgotPasswordToken(data);
      setToast({
        message: res.data.message,
        hasError: true,
        type: "success",
      });
      setHasToken(true);
    } catch (err) {
      setToast({
        message: err.response.data.message,
        hasError: true,
        type: "error",
      });
      setIsLoadingToken(false);
    }
  }

  async function handleSendNewPassword(token, password, email) {
    try {
      if (typeof password !== "string" || password.trim().length <= 0) {
        setToast({
          message: "Senha Inválida!",
          hasError: true,
          type: "error",
        });
      } else {
        const data = { code: token, password: password, email: email };

        const res = await UserRoutes.postNewPassword(data);

        setForgetPassword(false);

        setToast({
          message: res.data.message,
          hasError: true,
          type: "success",
        });
      }
    } catch (err) {
      setToast({
        message: err.response.data.message,
        hasError: true,
        type: "error",
      });
    }
  }

  return (
    <div className="login-background">
      <div className="container pt-4">
        <div className="row bg-white rounded shadow">
          <div className="col-4 px-0">
            <div className="d-flex align-items-center justify-content-center flex-column p-4 h-100">
              <h4 className="font-weight-bold w-100">Seja bem-vindo(a)</h4>
              <Formik
                initialValues={{ email: "", password: "" }}
                onSubmit={handleFormSubmit}
                validate={(values) => formValidation(values)}
              >
                {({
                  values,
                  handleSubmit,
                  handleChange,
                  isSubmitting,
                  errors,
                  touched,
                }) => (
                  <form className="w-100" onSubmit={handleSubmit}>
                    <div className="mt-5">
                      <Input
                        placeholder="E-mail"
                        type="email"
                        id="email"
                        errors={errors.email}
                        touched={touched.email}
                        value={values.email}
                        handleChange={handleChange}
                      />
                    </div>
                    <div className="pt-4">
                      <Input
                        placeholder="Senha"
                        type="password"
                        id="password"
                        errors={errors.password}
                        touched={touched.password}
                        value={values.password}
                        handleChange={handleChange}
                      />
                    </div>
                    <button
                      className="btn-default mt-4 w-100 shadow mb-5 "
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <BeatLoader
                          color="#000000"
                          loading={isSubmitting}
                          size={10}
                        />
                      ) : (
                        "Entrar"
                      )}
                    </button>
                    <button
                      className="btn btn-outline-secondary mt-4 btn-sm w-100 "
                      type="button"
                      onClick={() => setForgetPassword(true)}
                    >
                      Esqueci minha senha
                    </button>
                  </form>
                )}
              </Formik>
            </div>
          </div>
          <div className="col-8">
            <img
              src={homeBackground}
              alt="Login background"
              className="login-background w-full"
            />
          </div>
        </div>
        <ForgetPasswordModal
          token={hasToken}
          isLoading={isLoadingToken}
          getToken={handleGetToken}
          showModal={forgetPassword}
          onCancel={() => setForgetPassword(false)}
          sendNewPassword={handleSendNewPassword}
        />
      </div>
      <ToastComponent
        delay="5000"
        title={toast.type == "error" ? "Ops. Houve um erro!" : "Sucesso"}
        description={toast.message}
        type={toast.type}
        showToast={toast.hasError}
        handleClose={handleClose}
        autohide={true}
      />
    </div>
  );
}

export default Login;
