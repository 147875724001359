import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Login from "./pages/Login/Login";
import Home from "./pages/Home/Home";
import "rsuite/lib/styles/index.less";
import GuardedRoute from "./helpers/GuardedRoutes";
import "antd/dist/antd.css";

function App() {
  return (
    <Router>
      <Switch>
        <GuardedRoute path="/home" component={Home} auth={false} />
        {/*<Route path="/home" component={Home} />*/}
        <Route path="/login" component={Login} />
        <Route path="/">
          <Redirect to="/login" />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
