import React from "react";
import Text from "antd/lib/typography/Text";

function UnderlinedButton(props) {
	const {
		children,
		style,
		...rest
	} = props;

	return (
		<Text {...rest}
			underline
			style={{ cursor: "pointer", ...style }}>
			{children}
		</Text>
	);
}

export default UnderlinedButton;
