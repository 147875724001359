import React, { useContext, useEffect, useState } from "react";
import { Button, Checkbox, Modal, Tooltip, Typography } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";

import Select from "~/components/Select/Select";
import Input from "~/components/Input/Input";
import FieldListOptions from "~/pages/FormTemplates/Components/FieldListOptions";

import { formRoutes, TaskFieldRoutes, ListOptionsRoutes } from "~/http/routes";
import { ToastContext } from "~/providers/ToastProvider";

const { Text } = Typography;

function FieldModal(props) {
  const { taskId, visible, onCancel, afterSave } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [types, setTypes] = useState([]);
  const { setToast } = useContext(ToastContext);
  const [isModalListOptionsOpen, setIsModalListOptionsOpen] = useState(false);
  const [listOptions, setListOptions] = useState();

  const [type, setType] = useState(null);
  const [label, setLabel] = useState("");
  const [required, setRequired] = useState(0);

  useEffect(async () => {
    try {
      const res = await formRoutes.types();

      setTypes(res.data.data.map(mapTypes));
    } catch (err) {
      setToast({
        type: "error",
        title: "Erro!",
        message: "Um erro ocorreu ao buscar os tipos de campos!",
        show: true,
        autohide: true,
      });
    }
  }, []);

  function mapTypes({ id }) {
    let name = "";

    switch (id) {
      case 1:
        name = "Monetário";
        break;

      case 2:
        name = "Numérico";
        break;

      case 3:
        name = "Texto";
        break;

      case 4:
        name = "CPF";
        break;

      case 5:
        name = "CNPJ";
        break;

      case 6:
        name = "Data";
        break;

      case 7:
        name = "Verdadeiro/Falso";
        break;

      case 8:
        name = "Arquivos";
        break;

      case 9:
        name = "Assinatura";
        break;

      case 10:
        name = "Foto Atual";
        break;

      case 11:
        name = "Geolocalização";
        break;

      case 12:
        name = "Lista de Opções";
        break;

      case 13:
        name = "Telefone";
        break;

      case 14:
        name = "Email";
        break;

      case 15:
        name = "Campo Bloqueado";
        break;

      default:
        setToast({
          type: "error",
          title: "Erro!",
          message: "Um erro ocorreu ao listar os tipos de campos",
          show: true,
          autohide: true,
        });
        break;
    }

    return {
      value: id,
      name,
    };
  }

  function resetForm() {
    setType(null);
    setLabel("");
    setRequired(0);
  }

  function handleLabelChange(e) {
    setLabel(e.target.value);
  }

  function handleTypeChange(e) {
    setType(e.target.value);
  }

  function handleRequiredChange() {
    setRequired(required ? 0 : 1);
  }

  async function handleSubmit() {
    setIsSubmitting(true);

    try {
      const body = {
        label,
        required,
        type_id: type,
      };

      const res = await TaskFieldRoutes.store(taskId, body);

      if (listOptions) {
        listOptions.task_field_id = res.data.data.id;
        await ListOptionsRoutes.store(res.data.data.id, listOptions);
      }

      setToast({
        type: "success",
        title: "Sucesso!",
        message: "Novo campo adicionado ao formulário com sucesso!",
        show: true,
        autohide: true,
      });

      if (afterSave) afterSave(res.data.data);
    } catch (err) {
      setToast({
        type: "error",
        title: "Erro!",
        message: "Um erro ocorreu ao salvar o novo campo!",
        show: true,
        autohide: true,
      });
    }

    resetForm();
    setIsSubmitting(false);
  }

  function handleCancel() {
    resetForm();

    if (onCancel) onCancel();
  }

  function allFilled() {
    return type && label;
  }

  async function handleSubmitListOptions(options, formId) {
    if (options) {
      const data = {
        options: options,
      };

      setListOptions(data);

      setIsModalListOptionsOpen(false);
    }
  }

  function handleCloseOptions() {
    setIsModalListOptionsOpen(false);
  }

  return (
    <Modal
      title="Cadastrar novo campo"
      visible={visible}
      footer={null}
      onCancel={handleCancel}
      destroyOnClose
    >
      <div className="row">
        <div className="col-sm-12 mb-4">
          <Input
            placeholder="Título"
            value={label}
            labelBackground="#fff"
            handleChange={handleLabelChange}
          />
        </div>

        <div className="col-sm-12 col-md-6 mb-4">
          <Select
            placeholder="Tipo"
            value={type}
            handleChange={handleTypeChange}
            inputBackground="transparent"
            inputPlaceholder=""
            options={types}
            withNull
          />
        </div>
        {type == 12 ? (
          <span
            className="material-icons text-primary mr-2 mt-2"
            onClick={() => setIsModalListOptionsOpen(true)}
            style={{ cursor: "pointer" }}
          >
            list
          </span>
        ) : (
          ""
        )}
        <FieldListOptions
          isModalOpen={isModalListOptionsOpen}
          onSubmit={handleSubmitListOptions}
          onCancel={handleCloseOptions}
          formId={taskId}
        />

        <div className="col-sm-12 col-md-6 mb-4">
          <Checkbox checked={required} onChange={handleRequiredChange}>
            Obrigatório
          </Checkbox>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-sm-10">
          <Button
            className="btn-default"
            size="medium"
            loading={isSubmitting}
            disabled={!allFilled() || isSubmitting}
            onClick={handleSubmit}
          >
            {!isSubmitting ? "Salvar" : " Salvando..."}
          </Button>
        </div>

        <div className="col-sm-2">
          <Tooltip title='Ao clicar em "Salvar" o novo campo será cadastrado diretamente no banco de dados.'>
            <QuestionCircleFilled style={{ fontSize: "25px" }} />
          </Tooltip>
        </div>
      </div>

      <div className="d-flex justify-content-center mt-3">
        <Text underline style={{ cursor: "pointer" }} onClick={handleCancel}>
          Cancelar
        </Text>
      </div>
    </Modal>
  );
}

export default FieldModal;
