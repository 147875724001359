import React, { useEffect, useState } from 'react';
import { InventoryRoutes } from '~/http/routes';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userSelector } from '~/redux/Selectors';
import HistoryCard from './components/HistoryCard';

function InventoryHistory() {

  const { inventoryId } = useParams();

  const { companyId } = useSelector(userSelector);

  const [loading, setLoading] = useState(false);
  const [inventoryHistoryList, setInventoryHistoryList] = useState([]);


  useEffect(() => {
    setLoading(true);
    InventoryRoutes.getInventoryHistory(companyId, inventoryId).then(res => {
      setInventoryHistoryList(res.data.data);
      setLoading(false);
    });
  }, [companyId, inventoryId]);

  return (
    <div>
      <h5>Tarefas realizadas</h5>
      {
        inventoryHistoryList.map(cardData => (
          <HistoryCard data={cardData} />
        ))
      }
    </div>
  )
}

export default InventoryHistory;
