import React, { useContext, useEffect, useState } from 'react';

import CalcCard from '~/components/CalcCard/CalcCard';
import { ToastContext } from '~/providers/ToastProvider';

function MTTRCard(props) {
	const {
		tasks,
		loading,
	} = props;

	const [MTTR, setMTTR] = useState(0);
	const { setToast } = useContext(ToastContext);

	useEffect(() => {
		getMTTR();
	}, [tasks]);

	function filterTasks(task) {
		return task.inventory_id;
	}

	function getMTTR() {
		try {
			const filteredTasks = tasks.filter(filterTasks);
			let TWT = 0;
			let A = 0;
			let MTTR = 0;

			for (const task of filteredTasks) {
				if (task.worked_time)
					TWT += task.worked_time;
			}

			A = filteredTasks.length;

			MTTR = (TWT / 60) / A;

			setMTTR(MTTR);
		} catch (err) {
			setToast({
				type: 'error',
				title: 'Erro!',
				message: 'Um erro ocorreu ao calcular o MTTR!',
				show: true,
				autohide: true,
			});
		}
	}

	return (
		<CalcCard title="MTTR"
			quantity={!isNaN(MTTR.toFixed(2)) ? MTTR.toFixed(2) : '0.00'}
			measureUnit="minutos"
			description="TEMPO MÉDIO DE EXECUÇÃO DA TAREFA"
			inform=" MTTR é o tempo médio de execução da manutenção (Mean Time To Repair). Este índice é relacionado à maquinas e equipamentos do inventário."
			loading={loading} />
	);
}

export default MTTRCard;
