'use strict';

function isFutureDelivery(date) {
	if (!date)
		return false;

	const today = new Date().valueOf();
	return Math.ceil(((((date) / 1000) / 60) / 60) / 24) > today;
}

export default isFutureDelivery;
