import React, { useEffect, useState, useContext } from "react";
import { Card, Input, Button, Modal } from "antd";
import { ToastContext } from "~/providers/ToastProvider";

function FieldListOptions(props) {
  const { isModalOpen, onCancel, listOptions = [], formId, onSubmit } = props;

  const { setToast } = useContext(ToastContext);
  const [options, setOptions] = useState(listOptions);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (listOptions.length > 0) {
      setOptions(listOptions);
    }
  }, [listOptions]);

  function handleAddOptionButtonClick() {
    const auxList = options ? options : [];
    if (!inputValue) {
      error("Digite um valor válido");
    } else {
      auxList.push({
        name: inputValue,
      });
      setOptions(auxList);
      setInputValue("");
    }
  }

  function handleRemoveOptionButtonClick(index) {
    const auxList = options;

    auxList.splice(index, 1);

    setOptions(auxList);
    setToast({
      type: "success",
      title: "Sucesso!",
      message: "Opção excluída!",
      show: true,
      autohide: true,
    });
  }

  function handleOptionLabelChange(e) {
    const { value } = e.target;
    setInputValue(value);
  }

  function error(message) {
    setToast({
      type: "error",
      title: "Erro!",
      message,
      show: true,
      autohide: true,
    });
  }

  return (
    <div>
      <Modal
        open={isModalOpen}
        onCancel={onCancel}
        footer={[
          <div className="w-100 justify-content-center d-flex">
            <Button
              style={{
                borderRadius: "5px",
                width: "150px",
                fontSize: "1rem",
              }}
              className="btn-default"
              onClick={() => onSubmit(options, formId)}
            >
              Salvar
            </Button>
          </div>,
        ]}
      >
        <div className="row mt-4">
          <div className="d-flex  w-100">
            <div className="col-sm-8 col-md-8 col-lg-8">
              <Input
                onPressEnter={() => handleAddOptionButtonClick()}
                style={{ borderRadius: "5px" }}
                bordered={true}
                value={inputValue}
                name="name"
                onFocus={(e) => e.target.select()}
                onChange={(e) => handleOptionLabelChange(e)}
                placeholder="Digite as opções"
              />
            </div>
            <div className="col-sm-4 col-md-4 col-lg-4">
              <Button
                className="btn-default"
                style={{ borderRadius: "5px", width: "auto" }}
                onClick={() => handleAddOptionButtonClick()}
              >
                Cadastrar
              </Button>
            </div>
          </div>

          <div className="row mt-2 pl-4 w-100 ">
            {options?.map((option, index) => {
              const { id } = option;
              return (
                <Card
                  key={id}
                  style={{
                    height: "40px",
                    alignItems: "center",
                    borderRadius: "5px",
                  }}
                  className="shadow-sm w-100 mt-2"
                  bordered={false}
                  bodyStyle={{ padding: "10px" }}
                >
                  <div className="row w-100 align-items-center">
                    <div className="col-sm-12 d-flex  pl-0 ">
                      <div className="col-sm-12">{option.name}</div>
                      <span
                        className="material-icons-outlined text-danger"
                        onClick={() => handleRemoveOptionButtonClick(index)}
                        style={{ cursor: "pointer" }}
                      >
                        delete_outline
                      </span>
                    </div>
                  </div>
                </Card>
              );
            })}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default FieldListOptions;
