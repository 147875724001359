import React from "react";
import { Card, Input, Select, Checkbox } from "antd";

const { Option } = Select;

function NewFieldCard(props) {
  const {
    label,
    required,
    type,
    types,
    onLabelChange,
    onTypeChange,
    onRequiredChange,
    onDelete,
    showOptions,
  } = props;

  return (
    <div>
      <Card
        className="shadow-sm"
        bordered={false}
        bodyStyle={{ padding: "10px" }}
      >
        <div className="row">
          <div className="col-sm-12 col-lg-5 pl-2">
            <Input
              className="text-truncate"
              value={label}
              bordered={false}
              onFocus={(e) => e.target.select()}
              onChange={onLabelChange}
            />
          </div>
          <div className="col-sm-10 col-lg-5 pr-0">
            <Select
              value={type}
              bordered={false}
              onChange={onTypeChange}
              style={{ width: "100%" }}
            >
              {types.map(({ name, value }) => (
                <Option value={value}>{name}</Option>
              ))}
            </Select>
          </div>
          <div className="col-sm-2 d-flex justify-content-end align-items-center pl-0">
            {type == 12 ? (
              <span
                className="material-icons text-primary mr-2"
                onClick={showOptions}
                style={{ cursor: "pointer" }}
              >
                list
              </span>
            ) : (
              ""
            )}
            <span
              className="material-icons-outlined text-danger"
              onClick={onDelete}
              style={{ cursor: "pointer" }}
            >
              delete_outline
            </span>
          </div>
        </div>
      </Card>

      <div
        style={{
          backgroundColor: "#e6f9ff",
          borderRadius: "0px 0px 10px 10px",
          padding: "5px",
        }}
      >
        <Checkbox checked={required} onChange={onRequiredChange}>
          Obrigatório
        </Checkbox>
      </div>
    </div>
  );
}

export default NewFieldCard;
