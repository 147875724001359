import React from 'react';

function Video(props) {
	const {
		src,
		width = '100%',
		controls = true,
		...rest
	} = props;

	return (
		<video {...rest}
			width={width}
			controls={controls}>
			<source src={src} />
		</video>
	);
}

export default Video;
