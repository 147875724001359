import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';

import CalcCard from '~/components/CalcCard/CalcCard';
import { ToastContext } from '~/providers/ToastProvider';

function METCard(props) {
	const {
		tasks,
		loading,
	} = props;

	const [MET, setMET] = useState(0);
	const { setToast } = useContext(ToastContext);

	useEffect(() => {
		getMTTR();
	}, [tasks]);

	function filterTasks(task) {
		return task.start_date && task.date_closed && !task.reference_task_id;
	}

	function getMTTR() {
		try {
			const filteredTasks = tasks.filter(filterTasks);
			let TE = 0;
			let A = 0;
			let MET = 0;

			for (const task of filteredTasks) {
				const createdAt = moment(task.createdAt);
				const today = moment();

				if ((today.diff(createdAt, 'days') + 1) <= 30) {
					TE += task.doing_status_duration;
					A++;
				}
			}

			if (A === 0) {
				setMET(0);
			} else {
				TE *= 24;

				MET = TE / A;

				setMET(MET);
			}

		} catch (err) {
			setToast({
				type: 'error',
				title: 'Erro!',
				message: 'Um erro ocorreu ao calcular o Tempo Médio de Execução!',
				show: true,
				autohide: true,
			});
		}
	}

	return (
		<CalcCard quantity={MET.toFixed(2)}
			measureUnit="horas"
			description="TEMPO MÉDIO DE EXECUÇÃO"
			tag="Últimos 30 dias"
			loading={loading} />
	);
}

export default METCard;
