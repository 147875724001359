import React from "react";
import "./CardComment.css";

import Message from "../Message/Message";

function CardComment(props) {
  const { data } = props;
  return (
    <div>
      {data.comments.length > 0
        ? data.comments.map((comment) => (
            <Message
              key={comment.id}
              type="message"
              content={comment}
              userPhoto={comment.user ? comment.user.photo : null}
            />
          ))
        : ""}
      {/* <Message type="audio" />
      <Message type="message" /> */}
    </div>
  );
}

export default CardComment;
