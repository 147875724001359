import "./MyTask.css";
import moment from "moment";
import "antd/dist/antd.css";

import React, { useEffect, useState, useContext } from "react";
import AxiosInstance from "../../http/Axios";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Table, Tag, Switch as SwitchOption, Tooltip } from "antd";
import { isArray, isEmpty } from "lodash";
import { getTask } from "~/util/util";

import { userSelector } from "../../redux/Selectors";
import { TaskRoutes, StatusesRoutes } from "../../http/routes";
import { ToastContext } from "~/providers/ToastProvider";
import {
  CalendarOutlined,
  ProjectOutlined,
  CaretDownOutlined,
  CaretRightOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  SyncOutlined,
  ClockCircleTwoTone,
} from "@ant-design/icons";

import Modal from "antd/lib/modal/Modal";
import ModalCreateTask from "~/pages/CreateTask/ModalCreateTask";

import Breadcrumb from "~/components/Breadcrumb/Breadcrumb";
import CheckPermission from "~/components/CheckPermission";
import DrawerTask from "../../components/DrawerTask/DrawerTask";
import Select from "antd/lib/select";

import ToastComponent from "../../components/ToastComponent/ToastComponent";

const backRoute = "/home/projects/kanban";

function MyTask() {
  const [tasks, setTasks] = useState("");
  const [tasksObserver, setTasksObserver] = useState("");
  const [idList, setIdList] = useState([]);
  const [idListObserver, setIdListObserver] = useState([]);

  const [selectedId, setSelectedId] = useState([]);
  const [taskFiltered, setTaskFiltered] = useState([]);

  const [groupedTasks, setGroupedTasks] = useState("");
  const [selectedTask, setSelectedTask] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isTaskDrawerLoading, setIsTaskDrawerLoading] = useState(false);
  const [modalIsOpen, setModalState] = useState(false);
  const [reload, setReload] = useState(false);
  const [taskEditingData, setTaskEditingData] = useState(null);

  const [switchLabelColor, setSwitchLabelColor] = useState("grey");
  const [checkedObserverTasks, setCheckedObserverTasks] = useState(false);

  const [error, setError] = useState({ hasError: false, message: "" });

  const { setToast } = useContext(ToastContext);

  const userStore = useSelector(userSelector);
  const { companyId } = useSelector((state) => state.userStore);

  const { push } = useHistory();
  const { Option } = Select;

  const handleModal = () => {
    setModalState((m) => !m);
  };

  const closeCreateModal = () => {
    setReload(() => true);

    setTimeout(() => setReload(() => false), 300);
    setTaskEditingData(null);
    handleModal();
  };

  const handleClose = () => {
    setError({ message: "", hasError: false });
  };

  async function handleRowClick({ id, company_id, subproject_id }) {
    try {
      setIsTaskDrawerLoading(true);
      setModalShow(true);
      const res = await TaskRoutes.getTask(subproject_id, id);
      const task = res.data.data;
      setSelectedTask(task);

      const statuses = await StatusesRoutes.getStatus(
        company_id,
        subproject_id,
      );
      task.statuses = statuses.data.data;

      setIsTaskDrawerLoading(false);
    } catch (err) {
      setModalShow(false);
      setToast({
        type: "error",
        title: "Erro!",
        message: "Um erro ocorreu ao exibir a tarefa selecionada!",
        show: true,
        autohide: true,
      });
    }

    setIsTaskDrawerLoading(false);
  }

  function handleOnRow(data) {
    return {
      onClick: () => handleRowClick(data),
    };
  }

  const columnsGrouped = [
    {
      title: (
        <h3>
          <strong>{`Minhas tarefas:  ${
            checkedObserverTasks ? "sou observador" : "sou o responsável"
          }`}</strong>
        </h3>
      ),
      dataIndex: "name",
    },
  ];

  const initialColumns = [
    {
      //title: "Prioridade",
      dataIndex: "priority_id",
      key: "priority_id",
      align: "start",
      width: "5%",
      render: renderPriorityColor,
      showTitle: false,
      ellipsis: false,
      defaultSortOrder: "ascend",
      sorter: (a, b) => compareByAlph(a.priority_id, b.priority_id),
    },
    {
      title: "ID",
      dataIndex: "id",
      width: "7%",
      align: "center",
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: "Data início",
      dataIndex: "start_date_formatted",
      align: "center",
      render: renderColumn,
      showTitle: true,
      ellipsis: true,
      sorter: (a, b) => a.start_date - b.start_date,
    },
    {
      //title: "Descrição",
      dataIndex: ["due_date", "date_closed"],
      align: "center",
      width: "3%",
      render: renderOverdueClock,
      showTitle: false,
      ellipsis: false,
      /*       sorter: (a, b) => compareByAlph(a.description, b.description),
       */
    },
    /*  {
      title: "Subprojeto",
      dataIndex: "subproject_name",
      align: "center",
      render: renderColumn,
      showTitle: true,
      ellipsis: true,
      sorter: (a, b) => a.subproject_name.localeCompare(b.subproject_name),
    }, */
    {
      title: "Título",
      dataIndex: "name",
      align: "center",
      render: renderColumn,
      showTitle: true,
      ellipsis: true,
      width: "15%",
      sorter: (a, b) => compareByAlph(a.name, b.name),
    },
    {
      title: "Responsável",
      dataIndex: "responsible_name",
      align: "center",
      render: renderColumn,
      showTitle: true,
      ellipsis: true,
      sorter: (a, b) => compareByAlph(a.responsible_name, b.responsible_name),
    },
    {
      title: "Status",
      dataIndex: "status_name",
      align: "center",
      render: renderColumn,
      showTitle: true,
      sorter: (a, b) => compareByAlph(a.status_name, b.status_name),
    },

    {
      title: "Setor",
      dataIndex: "sector_name",
      align: "center",
      render: renderColumn,
      showTitle: true,
      ellipsis: true,
      /* sorter: (a, b) => compareByAlph(a.sector_name, b.sector_name), */
    },
    {
      title: "Serviço",
      dataIndex: "service_name",
      align: "center",
      render: renderColumn,
      showTitle: true,
      ellipsis: true,
      /*       sorter: (a, b) => compareByAlph(a.service_name, b.service_name),
       */
    },
    {
      title: "Inventário",
      dataIndex: "inventory_name",
      align: "center",
      render: renderColumn,
      showTitle: true,
      ellipsis: true,
      /*       sorter: (a, b) => compareByAlph(a.inventory_name, b.inventory_name),
       */
    },

    {
      title: "Descrição",
      dataIndex: "description",
      align: "center",
      width: "20%",
      render: renderColumn,
      showTitle: true,
      ellipsis: true,
      /*       sorter: (a, b) => compareByAlph(a.description, b.description),
       */
    },
    /*     {
      title: "Prioridade",
      dataIndex: "priority_id",
      key: "priority_id",
      align: "center",
      render: renderPriority,
      showTitle: true,
      ellipsis: false,
      defaultSortOrder: "ascend",
      sorter: (a, b) => compareByAlph(a.priority_id, b.priority_id),
    }, */
  ];

  function renderOverdueClock(_, data) {
    const { due_date, date_closed } = data;
    const today_date = new Date().getTime();
    if (date_closed == null && due_date != null && due_date < today_date)
      return (
        <span>
          <Tooltip title="Tarefa atrasada!">
            <ClockCircleTwoTone
              style={{ fontSize: "1.2rem" }}
              twoToneColor="#EB5757"
              className="align-middle"
            />
          </Tooltip>
        </span>
      );
  }

  function renderColumn(text) {
    if (text) return text;
    return "-";
  }

  function renderPriorityColor(priority_id) {
    let color;
    switch (priority_id) {
      case 0: {
        color = "#CFD8DC";
        break;
      }
      case 1: {
        color = "#2D9CDB";
        break;
      }
      case 2: {
        color = "#F2994A";
        break;
      }
      case 3: {
        color = "#EB5757";
        break;
      }
      default:
        color = "white";
    }

    return (
      <div
        style={{
          marginLeft: "90%",
          backgroundColor: color,
          width: "15%",
          color: color,
          borderRadius: "10px 0 0 10px",
          lineHeight: "30px",
        }}
        key={priority_id}
      >
        |
      </div>
    );
  }

  function compareByAlph(a, b) {
    if (a > b) {
      return -1;
    }
    if (a < b) {
      return 1;
    }
    return 0;
  }

  async function getReport() {
    setIsLoading(true);
    try {
      const params = {};
      async function getTasks() {
        const res = await TaskRoutes.getReport(companyId, params);
        const tasks = await res.data.data;

        const tasksUserResponsible = tasks.filter(
          (task) => task.responsible_id === userStore.userId,
        );

        const tasksUserObserver = tasks.filter((task) =>
          task.observers.find(
            (observer) => observer.user_id === userStore.userId,
          ),
        );

        const idsUserResponsible = await tasksUserResponsible.map((item) => (
          <Option key={item.id} value={item.id}>
            {item.id}
          </Option>
        ));

        const idsUserObserver = await tasksUserObserver.map((item) => (
          <Option key={item.id} value={item.id}>
            {item.id}
          </Option>
        ));

        setIdList([idsUserResponsible]);
        setIdListObserver([idsUserObserver]);
        setTasks(tasksUserResponsible);
        setTasksObserver(tasksUserObserver);
        if (checkedObserverTasks) {
          await group(tasks);
          return;
        }

        await group(tasksUserResponsible);
      }

      getTasks();
    } catch (err) {
      setToast({
        type: "error",
        title: "Erro!",
        message: "Um erro ocorreu ao gerar o relatório!",
        show: true,
        autohide: true,
      });
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getReport();
  }, [reload]);

  useEffect(() => {
    if (selectedId == undefined || selectedId.length > 0) {
      group(tasks);
    }
  }, [selectedId]);

  function expandedRowRender(e) {
    const { data } = e;

    return (
      <Table
        dataSource={data}
        rowClassName={() => "rowExpandedClass"}
        columns={initialColumns}
        pagination={{
          showSizeChanger: false,
          position: ["bottomCenter"],
        }}
        locale={{ emptyText: "Sem dados para exibir" }}
        onRow={handleOnRow}
      />
    );
  }

  async function group(tasks) {
    setIsLoading(true);

    let group = tasks.reduce((r, a) => {
      r[a[`${"subproject_id"}`]] = [...(r[a[`${"subproject_id"}`]] || []), a];
      return r;
    }, {});

    if (selectedId == undefined) {
    } else if (!isArray(selectedId)) {
      const taskFiltered = Object.values(group)
        .flat()
        .find((item) => item.id == selectedId);
      setGroupedTasks([]);
      setTaskFiltered([taskFiltered]);
      setIsLoading(false);
      return;
    }
    const newGroup = [];

    for (const g in group) {
      const name = group[g][0].subproject_name;
      //const sequence = group[g][0].status_sequence;
      const length = `: ${group[g].length}`;

      newGroup.push({
        key: g,
        name: name ? name + length : `Sem ${"Status".toLowerCase()}` + length,
        data: group[g],
        //sequence: sequence,
      });

      /*   newGroup.sort((a, b) => {
        if (a.sequence > b.sequence) return 1;
        if (a.sequence < b.sequence) return -1;
        return 0;
      }); */
    }

    newGroup.sort((a, b) => a.name.localeCompare(b.name));

    setGroupedTasks(newGroup);

    setIsLoading(false);
  }

  const reloadListView = async () => {
    try {
      setReload(() => true);
    } catch (error) {
      setError({ message: error.message, hasError: true });
    }
  };

  async function handleTaskValueChange(name, item, taskId, update) {
    const task = selectedTask;

    if (task) {
      task[name] = item;

      if (update) {
        try {
          const taskToUpdate = getTask(task);
          taskToUpdate.status_id = taskToUpdate.status.id;

          await TaskRoutes.updateTask(
            taskToUpdate,
            taskId,
            taskToUpdate.subproject_id,
          );

          setSelectedTask((e) => ({ ...e, ...task }));
          getReport();
        } catch (err) {
          setToast({
            type: "error",
            title: "Erro!",
            message: "Um erro ocorreu alterar o status da tarefa!",
            show: true,
            autohide: true,
          });
        }
      }

      return true;
    }
  }

  async function handleTaskEdit(id) {
    const res = await TaskRoutes.getTask(userStore.subprojectId, id);
    const taskData = res.data.data;
    setTaskEditingData(taskData);
    setModalState(true);
  }

  async function switchObserverFilter(e) {
    let colorLabel = "grey";
    setSwitchLabelColor(colorLabel);
    setCheckedObserverTasks(e);
    if (!isArray(selectedId)) {
      if (selectedId != undefined) {
      } else {
        if (e) {
          group(tasksObserver);
        } else {
          group(tasks);
        }
      }
    } else {
      if (e) {
        group(tasksObserver);
      } else {
        group(tasks);
      }
    }
  }

  return (
    <>
      <ToastComponent
        delay="5000"
        title="Ops! Houve um erro"
        description={error.message}
        type="error"
        showToast={error.hasError}
        handleClose={handleClose}
      />
      <div className="d-flex justify-content-between mb-3 ">
        <div className="d-flex ">
          <Breadcrumb routeToBack={backRoute} className={"align-middle"} />
          {/*  <button
            className="button-kanban"
            onClick={() => push(`/home/projects/kanban`)}
          >
            <span>
              <ProjectOutlined className="align-middle mr-0" />
            </span>{" "}
            Kanban
          </button>
          <button
            className="button-kanban"
            onClick={() => push(`/home/projects/calendar`)}
          >
            <span>
              <CalendarOutlined className="align-middle mr-0" />
            </span>{" "}
            Agenda
          </button> */}
        </div>
        {/* 
        <div className="d-flex justify-content-between ">
          <CheckPermission slug="criar-tarefa">
            <button className="btn-create " onClick={handleModal}>
              + Nova Tarefa
            </button>
          </CheckPermission>
        </div> */}
      </div>
      <div className="d-flex">
        <Select
          style={{ width: "300px", alignItems: "end" }}
          placeholder="Filtre por Id"
          onChange={(e) => {
            setSelectedId(e);
          }}
          showSearch
          maxTagCount={1}
          value={selectedId}
          allowClear={true}
        >
          {checkedObserverTasks ? [idListObserver] : [idList]}
        </Select>
        <div className="pl-0 ml-3 d-flex align-items-center  ">
          <SwitchOption
            onClick={(e) => switchObserverFilter(e)}
            checked={checkedObserverTasks}
          />
          <p
            className="ml-1"
            style={{
              color: switchLabelColor,
              margin: "auto",
            }}
          >
            <strong>
              {checkedObserverTasks ? "Observador" : "Responsável"}
            </strong>
          </p>
        </div>
      </div>

      <Table
        className="mt-4"
        dataSource={isEmpty(groupedTasks) ? taskFiltered : groupedTasks}
        columns={isEmpty(groupedTasks) ? initialColumns : columnsGrouped}
        rowClassName="rowClass"
        loading={isLoading}
        expandable={
          !isEmpty(groupedTasks) && {
            expandRowByClick: true,
            expandIcon: ({ expanded }) => {
              if (expanded) return <CaretDownOutlined />;
              return <CaretRightOutlined />;
            },
            expandedRowRender,
          }
        }
        locale={{ emptyText: "Sem dados para exibir" }}
        pagination={false}
        scroll={{ x: 1500 }}
        onRow={isEmpty(groupedTasks) ? handleOnRow : () => {}}
      />

      {modalShow && (
        <DrawerTask
          show={modalShow}
          loading={isTaskDrawerLoading}
          onClose={() => setModalShow(false)}
          data={selectedTask}
          changeTaskValue={handleTaskValueChange}
          handleEditTask={handleTaskEdit}
          //afterSaveForm={getReport}
          //afterAddField={getReport}
        />
      )}

      <Modal
        width="60%"
        className="modal-projects"
        centered
        visible={modalIsOpen}
        onCancel={closeCreateModal}
        title="Editar tarefa"
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
      >
        <ModalCreateTask
          handleClose={closeCreateModal}
          refreshList={reloadListView}
          taskEditingData={taskEditingData}
        />
      </Modal>
    </>
  );
}

export default MyTask;
