import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Typography, Skeleton } from "antd";
import { SubmitButton } from "formik-antd";
import { useSelector } from "react-redux";
import { Formik, Form, setIn } from "formik";
import { cloneDeep } from "lodash";

import FormikAntdSelect from "../CreateTask/FormikAntdSelect";
import UnderlinedButton from "~/components/UnderlinedButton";
import Breadcrumb from "~/components/Breadcrumb/Breadcrumb";
import FormikInput from "~/components/Input/FormikInput";
import NewFieldCard from "./Components/NewFieldCard";
import {
  formRoutes,
  InventoryRoutes,
  SectorRoutes,
  ListOptionsRoutes,
} from "~/http/routes";
import { ToastContext } from "~/providers/ToastProvider";
import FieldListOptions from "./Components/FieldListOptions";

const { Paragraph } = Typography;

function FormTemplateForm() {
  const { id } = useParams();
  const { push } = useHistory();
  const { companyId, projectInfos: projects } = useSelector(
    (state) => state.userStore,
  );
  const { setToast } = useContext(ToastContext);

  const formRef = useRef(null);
  const fieldsEnd = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isModalListOptionsOpen, setIsModalListOptionsOpen] = useState(false);
  const [listOptions, setListOptions] = useState();
  const [formId, setFormId] = useState();
  const [types, setTypes] = useState([]);
  const [subprojects, setSubprojects] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [inventories, setInventories] = useState([]);
  const [deletedFields, setDeletedFields] = useState([]);
  const [form, setForm] = useState({
    company_id: "",
    description: "",
    project_id: "",
    subproject_id: "",
    sector_id: "",
    inventory_id: "",
    fields: [],
  });

  useEffect(async () => {
    try {
      const res = await formRoutes.types();

      setTypes(res.data.data.map(mapTypes));
    } catch (err) {
      error("Um erro ocorreu ao buscar os tipos dos campos", true);
    }
  }, []);

  useEffect(async () => {
    setIsLoading(true);

    try {
      if (!hasId()) {
        setForm({
          company_id: companyId,
          description: "",
          project_id: "",
          subproject_id: "",
          sector_id: "",
          inventory_id: "",
          fields: [],
        });
      } else {
        const res = await formRoutes.find(id);

        const form = res.data.data;

        for (const field of form.fields) {
          if (field.required) field.required = true;
          else field.required = false;

          delete field.type;
        }

        if (res.data.data.project_id) {
          const aux = cloneDeep(projects);

          setSubprojects(
            aux.find((e) => e.id === res.data.data.project_id).subProjects ||
              [],
          );
        }

        setForm(res.data.data);
      }
    } catch (err) {
      error("Um erro ocorreu ao buscar os campos do formulário", true);
    }
  }, []);

  useEffect(async () => {
    try {
      const res = await SectorRoutes.getSector(companyId);

      setSectors(res.data.data);
    } catch (err) {
      error("Um erro ocorreu ao buscar os setores da empresa", true);
    }
  }, []);

  useEffect(async () => {
    try {
      const res = await InventoryRoutes.getInventory(companyId);

      setInventories(res.data.data);
    } catch (err) {
      error("Um erro ocorreu ao buscar os inventários da empresa", true);
    }

    setIsLoading(false);
  }, []);

  function hasId() {
    return id !== "create";
  }

  function mapTypes({ id }) {
    let name = "";

    switch (id) {
      case 1:
        name = "Monetário";
        break;

      case 2:
        name = "Numérico";
        break;

      case 3:
        name = "Texto";
        break;

      case 4:
        name = "CPF";
        break;

      case 5:
        name = "CNPJ";
        break;

      case 6:
        name = "Data";
        break;

      case 7:
        name = "Verdadeiro/Falso";
        break;

      case 8:
        name = "Arquivos";
        break;

      case 9:
        name = "Assinatura";
        break;

      case 10:
        name = "Foto Atual";
        break;

      case 11:
        name = "Geolocalização";
        break;

      case 12:
        name = "Lista de Opções";
        break;

      case 13:
        name = "Telefone";
        break;

      case 14:
        name = "Email";
        break;

      case 15:
        name = "Campo Bloqueado";
        break;

      default:
        //error("Um erro ocorreu ao buscar os tipos dos campos", true);
        break;
    }

    return {
      value: id,
      name,
    };
  }

  function error(message, goBack = false) {
    setToast({
      type: "error",
      title: "Erro!",
      message,
      show: true,
      autohide: true,
    });

    if (goBack) push("/home/forms");
  }

  function handleCancelButtonClick() {
    push("/home/forms");
  }

  function handleProjectChange(projectId, setFieldValue) {
    const aux = cloneDeep(projects);

    setSubprojects(aux.find((e) => e.id === projectId)?.subProjects || []);
    setFieldValue("subproject_id", "");
  }

  function handleNewFieldButtonClick(fields, setFieldValue) {
    fields.push({
      label: `Campo ${fields.length + 1}`,
      required: false,
      type_id: 3,
    });

    setFieldValue("fields", fields);

    if (fieldsEnd.current) {
      setTimeout(() => {
        fieldsEnd.current.scrollIntoView({ behavior: "smooth" });
      }, 25);
    }
  }

  function handleLabelChange(e, index, fields, setFieldValue) {
    const { value } = e.target;
    fields[index].label = value;

    setFieldValue("fields", fields);
  }

  function handleTypeChange(type, index, fields, setFieldValue) {
    fields[index].type_id = type;

    setFieldValue("fields", fields);
  }

  function handleRequiredChange(index, fields, setFieldValue) {
    fields[index].required = fields[index].required ? false : true;

    setFieldValue("fields", fields);
  }

  function handleDelete(index, fields, setFieldValue) {
    if (window.confirm("Tem certeza que deseja remover este campo?")) {
      if (fields[index].id) {
        const aux = cloneDeep(deletedFields);
        aux.push(fields[index].id);

        setDeletedFields(aux);
      }

      fields.splice(index, 1);

      setFieldValue("fields", fields);
    }
  }

  function handleValidate({ description }) {
    if (!description) {
      return {
        description: "Este campo é obrigatório!",
      };
    }
  }

  async function updateForm(form) {
    if (deletedFields.length > 0) {
      for (const fieldId of deletedFields)
        await formRoutes.deleteField(id, fieldId);
    }

    for (const field of form.fields) {
      if (field.id) {
        const aux = cloneDeep(field);
        delete aux.id;

        await formRoutes.updateField(id, field.id, aux);
      } else await formRoutes.storeField(id, field);
    }

    await formRoutes.update(id, form);
  }

  async function handleShowOptions(id) {
    try {
      setIsModalListOptionsOpen(true);
      setFormId(id);

      const res = await ListOptionsRoutes.find(id);

      setListOptions(res.data.data);
    } catch (err) {
      error("Um erro ocorreu ao buscar as opções da lista");
    }
  }

  function handleCloseOptions() {
    setIsModalListOptionsOpen(false);
  }

  async function handleSubmitListOptions(options, formId) {
    try {
      if (options) {
        const data = {
          form_field_id: formId,
          options: options,
        };
        await ListOptionsRoutes.store(formId, data);

        setToast({
          type: "success",
          title: "Sucesso!",
          message: `Salvo com sucesso`,
          show: true,
          autohide: true,
        });

        handleCloseOptions();
      }
    } catch (err) {
      error("Um erro ocorreu ao salvar a lista de opções");
    }
  }

  async function handleSubmit(values) {
    try {
      if (hasId()) await updateForm(values);
      else await formRoutes.store(values);

      setToast({
        type: "success",
        title: "Sucesso!",
        message: `Formulário ${
          hasId() ? "atualizado" : "cadastrado"
        } com sucesso!`,
        show: true,
        autohide: true,
      });

      push("/home/forms");
    } catch (err) {
      error(
        `Um erro ocorreu ao ${hasId() ? "salvar" : "cadastrar"} o formulário`,
      );
    }
  }

  return (
    <div>
      <Breadcrumb
        title={`${hasId() ? "Edição" : "Cadastro"} de formulário`}
        routeToBack="/home/forms"
      />

      <FieldListOptions
        isModalOpen={isModalListOptionsOpen}
        onSubmit={handleSubmitListOptions}
        onCancel={handleCloseOptions}
        listOptions={listOptions}
        formId={formId}
      />

      <Formik
        innerRef={formRef}
        initialValues={form}
        enableReinitialize
        onSubmit={handleSubmit}
        validate={handleValidate}
      >
        {({ values, isSubmitting, setFieldValue }) => (
          <Form className="mt-4">
            <Skeleton loading={isLoading}>
              <FormikInput name="description" placeholder="Título" />
            </Skeleton>

            <div className="row mt-4">
              <div className="col-sm-12 col-md-6 col-lg-3">
                <Skeleton loading={isLoading}>
                  <FormikAntdSelect
                    placeholder="Projeto"
                    name="project_id"
                    value={values.project_id}
                    inputBackground="transparent"
                    inputPlaceholder=""
                    options={projects.map((e) => {
                      return {
                        name: e.name,
                        value: e.id,
                      };
                    })}
                    onChange={(e) => handleProjectChange(e, setFieldValue)}
                    withNull
                  />
                </Skeleton>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-3">
                <Skeleton loading={isLoading}>
                  <FormikAntdSelect
                    placeholder="Subprojeto"
                    name="subproject_id"
                    value={values.subproject_id}
                    inputBackground="transparent"
                    inputPlaceholder=""
                    options={subprojects.map((e) => {
                      return {
                        name: e.name,
                        value: e.id,
                      };
                    })}
                    withNull
                  />
                </Skeleton>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-3">
                <Skeleton loading={isLoading}>
                  <FormikAntdSelect
                    placeholder="Setor"
                    name="sector_id"
                    value={values.sector_id}
                    inputBackground="transparent"
                    inputPlaceholder=""
                    options={sectors.map((e) => {
                      return {
                        name: e.name,
                        value: e.id,
                      };
                    })}
                    withNull
                  />
                </Skeleton>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-3">
                <Skeleton loading={isLoading}>
                  <FormikAntdSelect
                    placeholder="Inventário"
                    name="inventory_id"
                    value={values.inventory_id}
                    inputBackground="transparent"
                    inputPlaceholder=""
                    options={inventories.map((e) => {
                      return {
                        name: e.name,
                        value: e.id,
                      };
                    })}
                    withNull
                  />
                </Skeleton>
              </div>
            </div>

            <Paragraph className="mt-4" strong>
              Defina os campos que aparecerão na tarefa:
            </Paragraph>

            <Skeleton loading={isLoading}>
              {values.fields && values.fields.length > 0 ? (
                <div
                  className="row mt-4"
                  style={{
                    maxHeight: "400px",
                    overflowY: "auto",
                    padding: "5px",
                  }}
                >
                  {values.fields.map((field, i) => {
                    const { label, required, type_id, id } = field;

                    return (
                      <div className="col-sm-12 col-md-6 col-lg-4 mt-4" key={i}>
                        <NewFieldCard
                          label={label}
                          required={required}
                          type={type_id}
                          types={types}
                          onLabelChange={(e) =>
                            handleLabelChange(
                              e,
                              i,
                              values.fields,
                              setFieldValue,
                            )
                          }
                          onTypeChange={(type) =>
                            handleTypeChange(
                              type,
                              i,
                              values.fields,
                              setFieldValue,
                            )
                          }
                          onRequiredChange={handleRequiredChange.bind(
                            null,
                            i,
                            values.fields,
                            setFieldValue,
                          )}
                          onDelete={handleDelete.bind(
                            null,
                            i,
                            values.fields,
                            setFieldValue,
                          )}
                          showOptions={() => handleShowOptions(id)}
                        />
                      </div>
                    );
                  })}
                  <div ref={fieldsEnd} />
                </div>
              ) : (
                <></>
              )}
            </Skeleton>

            <div className="d-flex flex-column align-items-center mt-4">
              <UnderlinedButton
                className="text-center mb-4"
                onClick={handleNewFieldButtonClick.bind(
                  null,
                  values.fields,
                  setFieldValue,
                )}
              >
                + Adicionar novo
              </UnderlinedButton>

              <SubmitButton
                className="btn-default mt-4"
                disabled={isSubmitting}
                loading={isSubmitting}
                style={{ width: "250px" }}
              >
                {!isSubmitting ? "Salvar" : " Salvando..."}
              </SubmitButton>

              <UnderlinedButton
                className="text-center mt-2"
                onClick={handleCancelButtonClick}
              >
                Cancelar
              </UnderlinedButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default FormTemplateForm;
