import './Filters.css';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Participants from '~pages/AtaReport/components/FilterTabs/Participants';
import Projects from '~pages/AtaReport/components/FilterTabs/Projects';
import Period from '~pages/AtaReport/components/FilterTabs/Period';

import Icon from '~/components/Icon/Icon';
import Tabs from '~/components/Tabs/Tabs';
import Tab from '~/components/Tab/Tab';
import { ATA_CLEAR_FILTERS } from '~/redux/actions/AtaReportFilters';

function Filters() {
	const dispatch = useDispatch();
	const [activeTab, setActiveTab] = useState('period');

	function handleCleanAllFiltersClick() {
		dispatch({ type: ATA_CLEAR_FILTERS });
	}

	function handleTabClick(tab) {
		setActiveTab(tab);
	}

	function renderBody(tab) {
		switch (tab) {
			case 'period':
				return <Period />;
			case 'projects':
				return <Projects />;
			case 'participants':
				return <Participants />;
		}
	}

	return (
		<div>
			<div className="d-flex align-items-center mb-3">
				<h4 className="font-weight-bold mb-0">Filtros</h4>
				<Icon className="ml-3 clear-filters-icon"
					tooltip="Limpar todos os filtros"
					onClick={handleCleanAllFiltersClick}>
					cleaning_services
				</Icon>
			</div>

			<div className="card">
				<div className="card-header">
					<Tabs className="card-header-tabs">
						<Tab label="Período"
							active={activeTab === 'period'}
							onClick={handleTabClick.bind(null, 'period')} />
						<Tab label="Projetos"
							active={activeTab === 'projects'}
							onClick={handleTabClick.bind(null, 'projects')} />
						<Tab label="Participantes"
							active={activeTab === 'participants'}
							onClick={handleTabClick.bind(null, 'participants')} />
					</Tabs>
				</div>

				<div className="card-body overflow-auto" style={{ maxHeight: '300px' }}>
					{renderBody(activeTab)}
				</div>
			</div>

		</div>
	);
}

export default Filters;
