import React, { useContext, useEffect, useState } from 'react';

import CalcCard from '~/components/CalcCard/CalcCard';
import { ToastContext } from '~/providers/ToastProvider';

function MTTSCard(props) {
	const {
		tasks,
		loading,
	} = props;

	const [MTTS, setMTTS] = useState(0);
	const { setToast } = useContext(ToastContext);

	useEffect(() => {
		getMTTR();
	}, [tasks]);

	function filterTasks(task) {
		return task.start_date && task.date_closed && !task.reference_task_id;
	}

	function getMTTR() {
		try {
			const filteredTasks = tasks.filter(filterTasks);
			let TS = 0;
			let A = 0;
			let MTTS = 0;

			for (const task of filteredTasks) {
				const startDate = new Date(Number(task.start_date))
				const closedDate = new Date(Number(task.date_closed))

				const diff = Math.abs(closedDate - startDate)

				TS += Math.floor((diff / 1000) / 60)
			}

			A = filteredTasks.length;

			MTTS = TS / A;

			setMTTS(MTTS);
		} catch (err) {
			setToast({
				type: 'error',
				title: 'Erro!',
				message: 'Um erro ocorreu ao calcular o Tempo Médio de Solução da Tarefa!',
				show: true,
				autohide: true,
			});
		}
	}

	return (
		<CalcCard quantity={!isNaN(MTTS.toFixed(2)) ? MTTS.toFixed(2) : '0.00'}
			measureUnit="minutos"
			description="TEMPO MÉDIO DE SOLUÇÃO DA TAREFA"
			inform="Este índice representa a média dos tempos entre a criação e a conclusão da tarefa, exclui-se da conta as tarefas recorrentes."
			loading={loading} />
	);
}

export default MTTSCard;
