import React, { useContext, useState } from "react";
import { Row } from "react-bootstrap";
import { Modal } from "antd";

import UnderlinedButton from "~/components/UnderlinedButton";
import Button from "~/components/Button/Button";
import Input from '~/components/Input/Input';

import { ToastContext } from "~/providers/ToastProvider";
import { AtaRoutes } from "~/http/routes";

function RegisterSectorModal(props) {
	const {
		isVisible,
		companyId,
		subprojectId,
		ataId,
		onCancel,
		onSuccess,
	} = props;

	const { setToast } = useContext(ToastContext);

	const [name, setName] = useState('');
	const [isSubmitting, setIsSubmitting] = useState(false);

	function resetState() {
		setIsSubmitting(false);
		setName('');
	}

	function handleCancel() {
		resetState();

		if (onCancel)
			onCancel();
	}

	async function handleSubmit() {
		setIsSubmitting(true);
		let sector

		try {
			sector = (
				await AtaRoutes.subjectsCreateSector(ataId, {
					company_id: companyId,
					subproject_id: subprojectId,
					name,
				})
			).data;

			setToast({
				type: 'success',
				title: 'Sucesso',
				message: 'Setor cadastrado com sucesso!',
				show: true,
				autohide: true,
			});

			if (onSuccess)
				onSuccess(sector);

			resetState();
		} catch (err) {
			setIsSubmitting(false);

			setToast({
				type: 'error',
				title: 'Erro',
				message: 'Um erro inesperado ocorreu!',
				show: true,
				autohide: true,
			});
		}
	}

	return (
		<Modal title="Cadastro de setor"
			visible={isVisible}
			footer={null}
			width="400px"
			onCancel={handleCancel}
			destroyOnClose>
			<Input className="mt-4"
				placeholder="Nome"
				value={name}
				onChange={e => setName(e.target.value)}
				labelBackground="#fff"
				title={name}
				maxLength={255} />

			<Row className="mt-4 d-flex align-items-center flex-column">
				<Button className="button__save default-button"
					size="large"
					onClick={handleSubmit}
					loading={isSubmitting}
					disabled={!name}>
					{!isSubmitting ? 'Salvar' : 'Salvando...'}
				</Button>

				<UnderlinedButton className="mt-2"
					onClick={handleCancel}>
					Cancelar
				</UnderlinedButton>
			</Row>
		</Modal>
	);
}

export default RegisterSectorModal;
