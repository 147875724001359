import './AtaReport.css';
import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';

import { CaretDownOutlined, CaretRightOutlined, FileExcelFilled } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button } from 'antd';
import { isEmpty } from 'lodash';

import Personalization from '~/pages/AtaReport/components/Personalization';
import Filters from '~/pages/AtaReport/components/Filters';
import Breadcrumb from '~/components/Breadcrumb/Breadcrumb';

import { ToastContext } from '~/providers/ToastProvider';
import { AtaRoutes } from '~/http/routes';
import { useHistory } from 'react-router-dom';

function AtaReport() {
    const { push } = useHistory();
    const filters = useSelector(state => state.ataReportFilters);
    const { companyId } = useSelector(state => state.userStore);
    const { groupBy } = useSelector(state => state.ataReportFilters);

    const initialColumns = [
        {
            title: 'Nome',
            dataIndex: 'title',
            align: 'left',
            render: renderColumn,
        },
        {
            title: 'Empresa',
            dataIndex: 'third_party_company',
            align: 'left',
            render: renderColumn,
        },
        {
            title: 'Data',
            dataIndex: 'created_at',
            align: 'left',
            render: renderColumn,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            align: 'left',
            render: text => {
                switch (text) {
                    case 'OPEN':
                        return 'Aberta';

                    case 'PENDING':
                        return 'Pendente';

                    case 'FINISHED':
                        return 'Finalizada';

                    default:
                        return 'Status inválido';
                }
            },
        },
        {
            title: 'Subprojeto',
            dataIndex: 'subproject',
            align: 'left',
            render: renderColumn,
        },
    ];

    const [atas, setAtas] = useState([]);
    const [columns, setColumns] = useState(initialColumns);
    const [atasGrouped, setAtasGrouped] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { setToast } = useContext(ToastContext);

    useEffect(() => {
        setColumns(initialColumns);
    }, [atas]);

    useEffect(() => {
        if (groupBy)
            group(groupBy);
        else
            setAtasGrouped([]);
    }, [groupBy, atas]);

    const columnsGrouped = [
        {
            title: <span>Agrupado por: <strong>{groupedBy()}</strong></span>,
            dataIndex: 'name',
        },
    ];

    function groupedBy() {
        switch (groupBy) {
            case 'status':
                return 'Status';
        }
    }

    function group(field) {
        setIsLoading(true);

        const group = atas.reduce((r, a) => {
            r[a[`${field}`]] = [...r[a[`${field}`]] || [], a];
            return r;
        }, {});

        const newGroup = [];

        for (const g in group) {
            const name = group[g][0][groupBy.replace('id', 'name')];
            const length = `: ${group[g].length}`;

            newGroup.push({
                key: g,
                name: name ? name + length : `Sem ${groupedBy().toLowerCase()}` + length,
                data: group[g],
            });
        }

        setAtasGrouped(newGroup);
        setIsLoading(false);
    }

    function renderColumn(text) {
        if (text)
            return text;
        return '-';
    }

    function handleOnRow({ id, subproject_id }) {
        return {
            onClick: () => handleRowClick(id, subproject_id),
        };
    }

    function handleRowClick(id, subprojectId) {
        push(`/home/atas/${subprojectId}/${id}`);
    }

    async function handleGenerateReportClick() {
        setIsLoading(true);
        try {
            const subProjects = [];

            for (const project of filters.projects) {
                for (const sp of project.subProjects) {
                    if (subProjects.length)
                        subProjects.push(sp);
                    else
                        subProjects.push(sp);
                }
            }

            const params = {
                initial_creation_date: filters.initialCreationDate || '',
                final_creation_date: filters.finalCreationDate || '',
                initial_finished_date: filters.initialFinishedDate || '',
                final_finished_date: filters.finalFinishedDate || '',
                subprojects: subProjects,
                participants: filters.participants,
            };

            const res = await AtaRoutes.detailedReport(companyId, params);
            const atas = res.data;
            setAtas(atas);
        } catch (err) {
            console.log('err:', err);
            setToast({
                type: 'error',
                title: 'Erro!',
                message: 'Um erro ocorreu ao gerar o relatório!',
                show: true,
                autohide: true,
            });
        }
        setIsLoading(false);
    }

    function expandedRowRender(e) {
        const { data } = e;

        return <Table dataSource={data}
            columns={columns}
            rowClassName="detailed-report-row"
            pagination={{
                showSizeChanger: true,
                position: ['bottomCenter'],
            }}
            locale={{ emptyText: 'Sem dados para exibir' }}
            onRow={handleOnRow} />
    }

    return (
        <div>
            <Breadcrumb title="Relatório de Atas" routeToBack="/home" />

            <div className="d-flex justify-content-end">
                <Button className="btn-default"
                    size="large"
                    style={{ width: '250px', height: '55px' }}
                    onClick={handleGenerateReportClick}>
                    Gerar relatório
                </Button>
            </div>

            <div className="mt-4">
                <Filters />
            </div>

            <div className="mt-4">
                <Personalization />
            </div>

            <Table className="mt-4"
                dataSource={isEmpty(atasGrouped) ? atas : atasGrouped}
                columns={isEmpty(atasGrouped) ? columns : columnsGrouped}
                rowClassName="detailed-report-row"
                loading={isLoading}
                expandable={!isEmpty(atasGrouped) && {
                    expandRowByClick: true,
                    expandIcon: ({ expanded }) => {
                        if (expanded)
                            return <CaretDownOutlined />;
                        return <CaretRightOutlined />;
                    },
                    expandedRowRender,
                }}
                locale={{ emptyText: 'Sem dados para exibir' }}
                pagination={{
                    showSizeChanger: true,
                    position: ['bottomCenter'],
                }}
                scroll={{ x: 1500 }}
                onRow={isEmpty(atasGrouped) && handleOnRow} />
        </div >
    );
}

export default AtaReport;
