import React, { useEffect, useState } from "react";

import Button from "~/components/Button/Button";
import CancelButton from "~/components/CancelButton/CancelButton";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "../Inventory.css";
import "./CreateInventory.css";
import { Typography } from "antd";
import { Formik, Form } from "formik";
import { Col, Row } from "react-bootstrap";
import FormikInput from "~/components/Input/FormikInput";
import InputDatepicker from "~/components/InputDatepicker/InputDatepicker";
import { useSelector } from "react-redux";
import {
  InventoryRoutes,
  SectorRoutes,
  ServiceRoutes,
  UserRoutes,
} from "~/http/routes";
import CollapsibleSubItems from "~/components/CollapsibleSubItems/CollapsibleSubItems";

import { userSelector } from "~/redux/Selectors";
import { useParams } from "react-router-dom";
import CollapsibleSubProject from "~/components/CollapsibleSubItems/CollapsibleSubProject";

const { Title } = Typography;

const initialInventory = {
  id: "",
  name: "",
  assetNumber: "",
  detail: "",
  manufacturing_date: "",
  sector: "",
  serialNumber: "",
  model: "",
  parts: "",
  services: "",
};

function CreateInventory({ history }) {
  const { id } = useParams();
  const [inventory, setInventory] = useState(initialInventory);

  const { companyId } = useSelector(userSelector);

  useEffect(() => {
    if (id !== "create") {
      InventoryRoutes.getInventoryId(companyId, id).then(({ data }) => {
        setInventory(data.data);
      });
    }
  }, [companyId, id]);

  const createInventory = (values) => {
    const saveOrUpdate = values.id
      ? InventoryRoutes.update
      : InventoryRoutes.save;
    return saveOrUpdate(companyId, values).then(() =>
      history.push("/home/inventory"),
    );
  };

  return (
    <div className="inventory">
      <div className="inventory__title">
        <ArrowLeftOutlined
          className="inventory__title--icon"
          onClick={() => history.goBack()}
        />
        <Title level={4}>
          {id !== "create" ? "Edição de inventário" : "Cadastro de inventário"}
        </Title>
      </div>
      <div>
        <Formik
          enableReinitialize
          onSubmit={createInventory}
          initialValues={inventory}
        >
          {({ isSubmitting, submitForm, values, setFieldValue }) => {
            return (
              <Form>
                <Row className="mt-4">
                  <Col>
                    <FormikInput name="name" placeholder="Nome" />
                  </Col>
                  <Col md={6}>
                    <FormikInput name="model" placeholder="Modelo" />
                  </Col>
                  {/* <Col>
                    <FormikInput
                      name="assetNumber"
                      placeholder="Número patrimonial"
                    />
                  </Col> */}
                </Row>
                <Row className="mt-4">
                  {/* <Col md={4}>
                    <FormikInput
                      name="sector"
                      placeholder="Setor"
                      typeInput="select"
                      options={[]}
                    />
                  </Col> */}
                  <Col md={3}>
                    <InputDatepicker
                      name="manufacturing_date"
                      label="Data de fabricação"
                      placeholder=""
                    />
                  </Col>
                  <Col md={3}>
                    <InputDatepicker
                      name="acquisition_date"
                      label="Data de aquisição"
                      placeholder=""
                    />
                  </Col>
                  <Col md={3}>
                    <FormikInput
                      name="acquisition_value"
                      placeholder="Valor de aquisição"
                      type="number"
                      step="0.10"
                    />
                  </Col>
                  <Col md={3}>
                    <FormikInput
                      name="amount"
                      placeholder="Quantidade"
                      type="number"
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col md={3}>
                    <FormikInput
                      name="serial_number"
                      placeholder="Número de série"
                    />
                  </Col>
                  <Col md={3}>
                    <FormikInput
                      name="depreciation"
                      placeholder="Depreciação"
                      type="number"
                      step="0.10"
                    />
                  </Col>
                  <Col md={6}>
                    <FormikInput
                      name="equity_number"
                      placeholder="Número de patrimônio"
                    />
                  </Col>
                  {/*   <Col md={6}>
                    <FormikInput
                      name="service"
                      placeholder="Serviço"
                    />
                  </Col> */}
                  {/* <Col md={6}>
                    <FormikInput
                      name="parts"
                      placeholder="Peças"
                    />
                  </Col> */}
                </Row>
                <Row className="mt-4">
                  <Col md={12}>
                    <FormikInput
                      name="detail"
                      placeholder="Observações"
                      inputType="textArea"
                      rows={4}
                    />
                  </Col>
                </Row>
                <CollapsibleSubItems
                  title="Serviços"
                  onSearch={() => ServiceRoutes.getServices(companyId)}
                  selections={values.service_id}
                  handleSelections={(items) =>
                    setFieldValue("service_id", items)
                  }
                />
                <CollapsibleSubItems
                  title="Setores"
                  onSearch={() => SectorRoutes.getSector(companyId)}
                  selections={values.sector_id}
                  handleSelections={(items) =>
                    setFieldValue("sector_id", items)
                  }
                />
                <CollapsibleSubItems
                  title="Usuários"
                  onSearch={UserRoutes.getUsers}
                  selections={values.user_id}
                  handleSelections={(items) => setFieldValue("user_id", items)}
                />
                <CollapsibleSubProject
                  selections={values.sub_project_id}
                  handleSelections={(items) =>
                    setFieldValue("sub_project_id", items)
                  }
                />
                <Row className="mt-5 d-flex align-items-center flex-column">
                  <Button
                    className="button__save"
                    size="large"
                    onClick={submitForm}
                    loading={isSubmitting}
                  >
                    Salvar
                  </Button>
                  <CancelButton />
                </Row>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default CreateInventory;
