import React, { useState } from "react";
import "./CommentBox.css";

import { Input } from "antd";
import { TaskRoutes } from "../../http/routes";
// import { filesIcon } from "../../assets/svg/Icons";
import Button from "../Button/Button";

function CommentBox(props) {
  const { data, changeTaskValue } = props;
  const { comments, taskId, subProjectId } = data;
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  // const [image, setImage] = useState({ preview: "", raw: "" });

  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  const sendComment = async () => {
    const body = {
      text: message,
    };
    setLoading(true);

    try {
      const { data } = await TaskRoutes.postComment(taskId, body);

      // simula 1s de delay
      await new Promise((resolve) => setTimeout(resolve, 1000));

      comments.push({
        id: data.data.id.toString(),
        date: data.data.date.toString(),
        comment: [
          {
            text: message,
          },
        ],
      });
      changeTaskValue("comments", comments, taskId);
      setMessage("");
    } catch (err) {
      console.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  // const handleFileClick = async (e) => {
  // 	e.preventDefault();
  // 	const formData = new FormData();
  // 	formData.append("attachment", image.raw);
  // 	const customHeader = {
  // 		"Content-Type": "multipart/form-data",
  // 	};
  // 	try {
  // 		const response = await TaskRoutes.uploadAttachment(
  // 			subProjectId,
  // 			taskId,
  // 			formData,
  // 			customHeader,
  // 		);
  // 	} catch (e) {
  // 		console.error({ e });
  // 	}
  // };

  // const handleChange = (e) => {
  // 	if (e.target.files.length) {
  // 		setImage({
  // 			preview: URL.createObjectURL(e.target.files[0]),
  // 			raw: e.target.files[0],
  // 		});
  // 	}
  // };

  return (
    <>
      <div className="row">
        <div comment-container className="col-sm-12 col-md-9">
          <Input.TextArea
            value={message}
            onChange={handleInputChange}
            placeholder="Escreva um comentário."
            autoSize={{ minRows: 3, maxRows: 3 }}
          />
          {/* <input
						type="file"
						id="upload-button"
						style={{ display: "none" }}
						onChange={handleChange}
					/>
					{image.preview ? (
						<span
							className="textarea-icon"
							onClick={handleFileClick}
							style={{ color: "blue", fontSize: 10 }}
						>
							Upload ^
						</span>
					) : (
						""
					)} */}
          {/* <span className="textarea-icon" onClick={handleMicClick}>
						{micIcon}
					</span>
					<span className="textarea-icon" onClick={handleImgClick}>
						{imageIcon}
					</span> */}
          {/* <span className="textarea-user-icon">{imageIcon}</span> */}
        </div>

        <div className="col-sm-12 col-md-3 pl-0">
          <Button
            loading={loading}
            className="col-sm-12 h-100"
            disabled={!message || loading}
            onClick={sendComment}
          >
            Enviar
          </Button>
        </div>
      </div>

      {/* <label htmlFor="upload-button">
				<span className="textarea-icon">{filesIcon}</span>
			</label> */}
    </>
  );
}

export default CommentBox;
