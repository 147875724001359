import React from "react";
import Text from "antd/lib/typography/Text";
import { useHistory } from "react-router-dom";

function CancelButton() {

  const history = useHistory();

  return (
    <Text
      underline
      className="mt-2"
      style={{ cursor: "pointer" }}
      onClick={() => history.goBack()}
    >
      Cancelar
    </Text>
  );
}

export default CancelButton;
