import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function Icon(props) {
	const {
		type,
		className,
		children,
		tooltipPlacement,
		tooltip,
		...rest
	} = props;

	return (
		<>
			{tooltip ?
				<OverlayTrigger placement={tooltipPlacement || 'top'}
					overlay={<Tooltip>{tooltip}</Tooltip>}>
					<span {...rest}
						className={`material-icons-${type || 'round'} ${className || ''}`}>
						{children}
					</span>
				</OverlayTrigger>
				:
				<span {...rest}
					className={`material-icons-${type || 'round'} ${className || ''}`}>
					{children}
				</span>
			}
		</>
	);
}

export default Icon;
