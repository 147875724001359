import React, { useState } from "react";
import { Modal } from "antd";
import Input from "~/components/Input/Input";
import forgetPasswordImage from "../../assets/forget_password-background.png";
import { PulseLoader } from "react-spinners";

function ForgetPasswordModal(props) {
  const { showModal, onCancel, getToken, sendNewPassword, token, isLoading } =
    props;

  const [emailValue, setValueEmail] = useState("");
  const [tokenValue, setTokenValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");

  const hasToken = token ? true : false;

  return (
    <Modal
      open={showModal}
      onCancel={onCancel}
      centered
      closable={true}
      width="20%"
      mask={true}
      maskStyle={{ background: "black", opacity: "80%" }}
      className="rounded"
      bodyStyle={{ background: "#ffe300", padding: 0 }}
      footer=""
    >
      <div className="row w-100  m-0 ">
        <div className="p-4 pt-4">
          <h3>Esqueceu sua senha?</h3>
          <p className="" style={{ fontSize: "1.1rem" }}>
            Por favor, informe o e-mail associado à sua conta que enviaremos os
            detalhes para recuperar sua senha.
          </p>
          <img
            className="img-fluid w-75 mx-auto d-block"
            alt="imagem-forget-password"
            src={forgetPasswordImage}
          ></img>
        </div>
        <div
          className="w-100 p-3"
          style={{ borderRadius: "30px 30px 0 0", background: "#fafafa" }}
        >
          <Input
            className="w-100 mt-2"
            placeholder="E-mail"
            type="email"
            id="email"
            value={emailValue}
            onChange={(e) => setValueEmail(e.target.value)}
          />
          <button
            hidden={hasToken}
            onClick={() => getToken(emailValue)}
            className="btn-default mt-2 w-100 shadow-sm mb-5 "
          >
            {isLoading ? <PulseLoader></PulseLoader> : "Recuperar Senha"}
          </button>
          <div hidden={!hasToken}>
            <div className="d-flex mt-4">
              <span className="material-icons my-auto mr-2">vpn_key</span>
              <Input
                className="w-100 mt-2"
                placeholder="Código"
                type="text"
                id="token"
                value={tokenValue}
                onChange={(e) => setTokenValue(e.target.value)}
              />
            </div>
            <div className="d-flex mt-4">
              <span className="material-icons my-auto mr-2">lock</span>
              <Input
                className="w-100 mt-2"
                placeholder="Nova senha"
                type="text"
                id="password"
                value={passwordValue}
                onChange={(e) => setPasswordValue(e.target.value)}
              />
            </div>
            <button
              onClick={() =>
                sendNewPassword(tokenValue, passwordValue, emailValue)
              }
              className="btn-default mt-4 w-100 shadow-sm mb-5 "
            >
              Recuperar Senha
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ForgetPasswordModal;
