import { EyeOutlined, ScheduleOutlined } from '@ant-design/icons';
import moment from 'moment';
import React, { useState } from 'react';
import Chip from '~/components/Chip/Chip';
import DrawerTask from '~/components/DrawerTask/DrawerTask';
import './HistoryCard.css'
import { Modal } from 'antd';
import { Table } from "antd";

function HistoryCard({ data }) {

  const [modalShow, setModalShow] = useState(false);
  const [guaranteeModalOpen, setGuaranteeModalOpen] = useState(false);

  const handleClick = (item) => {
    setModalShow(true);
  };

  const handleGuarantee = () => {
    setGuaranteeModalOpen(g => !g);
  }

  console.log(data.fields.reduce((obj, cur) => ({ ...obj, [`value${cur.id}`]: cur.value }), {}));

  return (
    <div className="card-container">
      {modalShow && (
        <DrawerTask
          show={modalShow}
          onClose={() => setModalShow(false)}
          data={data}
        />
      )}
      <div className="card-left-side">
        <div className="card-line"></div>
        <div className="card-circle" style={{ backgroundColor: data.status.color }}></div>
      </div>
      <div className="paper-card">
        <div className="paper-card-info">
          <div className="paper-card-chips">
            <Chip color={data.status?.color} statusName={`${data.status?.name}`} />
            <Chip color={data.priority?.color} statusName={`Prioridade: ${data.priority?.name}`} />
          </div>
          <span className="card-row-info"><b>Tarefa: </b>{data.name}</span>
          <span className="card-row-info"><b>Criação:</b> {moment(data.date_created).format('DD/MM/YYYY')}</span>
          <span className="card-row-info"><b>Responsável:</b> {data.responsible.username}</span>
          <span className="card-row-info"><b>Descrição:</b> {data.description}</span>
        </div>
        <div className="paper-card-info">
          <span className="card-row-info"><b>Última atualização:</b> {moment(data.date_updated).format('DD/MM/YYYY - h:mm:ss a')}</span>
          <span className="card-row-info"><b>Telefone responsável: </b> {data.responsible.phone}</span>
          <span className="card-row-info"><b>Email responsável: </b> {data.responsible.email}</span>
        </div>
        <div className="d-flex">
          {
            data.fields.length > 0 &&
            <div onClick={handleGuarantee} className="paper-card-actions">
              <ScheduleOutlined className="actions-icon color-blue" twoToneColor="#2d9cdb" />
              <span className="paper-card-span color-blue">Garantias</span>
            </div>
          }
          <div onClick={handleClick} className="paper-card-actions">
            <EyeOutlined className="actions-icon" />
            <span className="paper-card-span">Ver tarefa</span>
          </div>
        </div>
      </div>
      <Modal
        title={`Garantias da atividade #${data.id}`}
        visible={guaranteeModalOpen}
        onCancel={handleGuarantee}
        footer={[]}
        closable
      >
        <Table
          columns={
            data.fields.map(element => ({ key: element.id, title: element.label, dataIndex: `value${element.id}` }))}
          dataSource={[data.fields.reduce((obj, cur) => ({ ...obj, [`value${cur.id}`]: cur.value }), {})]}
        />
      </Modal>
    </div>
  )
}

export default HistoryCard;
