import React from "react";

const Chip = ({ color, statusName, className, textColor = "white" }) => {
  return (
    <p
      className={className}
      style={{
        display: "flex",
        backgroundColor: color,
        borderRadius: "30px",
        minWidth: "110px",
        margin: "0",
        textAlign: "center",
        color: textColor,
        fontSize: "9px",
        height: "20px",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 500,
        marginRight: "5px",
        paddingLeft: 5,
        paddingRight: 5,
      }}
    >
      {statusName}
    </p>
  );
};

export default Chip;
