import { defineState } from "redux-localstore";

const initialState = defineState({
  projectInfos: [],
})("userStore");

function UserReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_ID":
      return { ...state, id: action.data };
    case "SET_COMPANY_ID":
      return { ...state, companyId: action.data };
    case "SET_USER_COMPANIES":
      return { ...state, companies: action.data };
    case "SET_SUBPROJECT_ID":
      return { ...state, subprojectId: action.data };
    case "SET_SUBPROJECT_NAME":
      return { ...state, subprojectName: action.data };
    case "SET_PROJECT_INFOS":
      return { ...state, projectInfos: action.data };
    case "SET_USER_PHOTO":
      return { ...state, userPhoto: action.data };
    case "SET_USER_NAME":
      return { ...state, userName: action.data };
    case "SET_USER_ID":
      return { ...state, userId: action.data };
    case "SET_USER_PHONE":
      return { ...state, userPhone: action.data };
    case "SET_USER_EMAIL":
      return { ...state, userEmail: action.data };
    case "SET_PERMISSIONS":
      return { ...state, permissions: action.data };
    default:
      return state;
  }
}

export default UserReducer;
