import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';

import TasksStatusColumnChart from '~/components/TasksStatusColumnChart/TasksStatusColumnChart';
import TasksPieChart from '~/components/TasksPieChart/TasksPieChart';
import MTBFCard from '~/pages/Dashboard/components/MTBFCard';
import MTTRCard from '~/pages/Dashboard/components/MTTRCard';
import MTTSCard from '~/pages/Dashboard/components/MTTSCard';
import METCard from '~/pages/Dashboard/components/METCard';
import isOverdue from '~/helpers/isOverdue';
import isUpToDate from '~/helpers/isUpToDate';
import isFutureDelivery from '~/helpers/isFutureDelivery';

function ChartsModal(props) {
	const {
		isVisible,
		onCancel,
		tasks,
	} = props;

	const [columnChartData, setColumnChartData] = useState([]);
	const [totalPieChartData, setTotalPieChartData] = useState([]);
	const [overduePieChartData, setOverduePieChartData] = useState([]);
	const [unexpiredPieChartData, setUnexpiredPieChartData] = useState([]);
	const [futureDeliveryChartData, setFutureDeliveryChartData] = useState([]);
	const [donePieChartData, setDonePieChartData] = useState([]);

	const [totalTasks, setTotalTasks] = useState(0);
	const [totalOverdueTasks, setTotalOverdueTasks] = useState(0);
	const [totalUnexpiredTasks, setTotalUnexpiredTasks] = useState(0);
	const [totalFutureDeliveryTasks, setTotalFutureDeliveryTasks] = useState(0);
	const [totalDoneTasks, setTotalDoneTasks] = useState(0);

	useEffect(() => {
		getTotalPieChartData();
		getOverduePieChartData();
		getUnexpiredPieChartData();
		getFutureDeliveryPieChartData();
		getDonePieChartData();
		getColumnChartData();
	}, [tasks]);

	function getPrioritiesDataToChart(tasks) {
		const priorities = [
			{ type: 'BAIXA', value: 0 },
			{ type: 'MODERADO', value: 0 },
			{ type: 'URGENTE', value: 0 },
			{ type: 'SEM PRIORIDADE', value: 0 },
		];
		const data = [];

		for (const task of tasks) {
			if (!task.priority_id)
				priorities[3].value++;
			else
				priorities[task.priority_id - 1].value++;
		}

		for (const priority of priorities) {
			if (priority.value > 0)
				data.push(priority);
		}

		return data;
	}

	function getTotalPieChartData() {
		const data = getPrioritiesDataToChart(tasks);
		const total = tasks.length;

		setTotalPieChartData(data);
		setTotalTasks(total);
	}

	function getOverduePieChartData() {
		const data = tasks.filter(task => isOverdue(task.due_date, task.date_closed));
		const total = data.length;

		setOverduePieChartData(getPrioritiesDataToChart(data));
		setTotalOverdueTasks(total);
	}

	function getUnexpiredPieChartData() {
		const data = tasks.filter(task => isUpToDate(task.due_date));
		const total = data.length;

		setUnexpiredPieChartData(getPrioritiesDataToChart(data));
		setTotalUnexpiredTasks(total);
	}

	function getFutureDeliveryPieChartData() {
		const data = tasks.filter(task => isFutureDelivery(task.due_date));
		const total = data.length;

		setFutureDeliveryChartData(getPrioritiesDataToChart(data));
		setTotalFutureDeliveryTasks(total);
	}

	function getDonePieChartData() {
		const data = tasks.filter(task => task.status_name === 'CONCLUÍDO');
		const total = data.length;

		setDonePieChartData(getPrioritiesDataToChart(data));
		setTotalDoneTasks(total);
	}

	function getColumnChartData() {
		const data = [
			{ status: 'A FAZER', value: 0 },
			{ status: 'FAZENDO', value: 0 },
			{ status: 'AGUARDANDO APROVAÇÃO', value: 0 },
			{ status: 'CONCLUÍDO', value: 0 },
		];

		for (const task of tasks) {
			const index = data.findIndex(e => e.status === task.status_name);
			if (index > -1)
				data[index].value++;
		}

		setColumnChartData(data);
	}

	return (
		<Modal visible={isVisible}
			onCancel={onCancel}
			footer={null}
			width="90%"
			bodyStyle={{ maxHeight: window.innerHeight - 150, overflowY: 'auto' }}>
			<div className="card shadow-sm no-border mt-4">
				<div className="card-body">
					<div className="row">
						<div className="col-sm-3 col-md-2 col-xl-1">
							<p className="priority_tag"
								style={{ backgroundColor: "#EB5757" }} >
								Urgente
							</p>
						</div>

						<div className="col-sm-3 col-md-2 col-xl-1">
							<p className="priority_tag"
								style={{ backgroundColor: "#F2994A" }} >
								Moderado
							</p>
						</div>

						<div className="col-sm-3 col-md-2 col-xl-1">
							<p className="priority_tag"
								style={{ backgroundColor: "#2D9CDB" }} >
								Baixa
							</p>
						</div>

						<div className="col-sm-3 col-md-2">
							<p className="priority_tag"
								style={{ backgroundColor: "#CFD8DC" }} >
								Sem prioridade
							</p>
						</div>
					</div>
				</div>

				<div className={`d-flex ${window.innerWidth > 1050 && 'justify-content-around'}`}
					style={{ height: "230px", overflowX: 'auto' }} >
					<TasksPieChart title="TOTAL DE TAREFAS"
						data={totalPieChartData}
						titleFormater={totalTasks} />

					<TasksPieChart title="ATRASADAS"
						data={overduePieChartData}
						titleFormater={totalOverdueTasks} />

					<TasksPieChart title="EM DIA"
						data={unexpiredPieChartData}
						titleFormater={totalUnexpiredTasks} />

					<TasksPieChart title="ENTREGA FUTURA"
						data={futureDeliveryChartData}
						titleFormater={totalFutureDeliveryTasks} />

					<TasksPieChart title="CONCLUÍDAS"
						data={donePieChartData}
						titleFormater={totalDoneTasks} />
				</div>
			</div>

			<div className="card shadow-sm no-border mt-4">
				<div className="card-body">
					<TasksStatusColumnChart data={columnChartData} />
				</div>
			</div>

			<div className="row">
				<div className="col-sm-12 col-md-6 col-lg-3 mt-4">
					<MTTRCard tasks={tasks} />
				</div>
				<div className="col-sm-12 col-md-6 col-lg-3 mt-4">
					<MTBFCard tasks={tasks} />
				</div>
				<div className="col-sm-12 col-md-6 col-lg-3 mt-4">
					<METCard tasks={tasks} />
				</div>
				<div className="col-sm-12 col-md-6 col-lg-3 mt-4">
					<MTTSCard tasks={tasks} />
				</div>
			</div>
		</Modal>
	);
}

export default ChartsModal;
