import "./ProjectForm.css";

import { Formik, Form } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import FormikInput from "~/components/Input/FormikInput";
import SectionTitle from "~/components/SectionTitle/SectionTitle";
import Breadcrumb from "~/components/Breadcrumb/Breadcrumb";
import { EditOutlined } from "@ant-design/icons";
import SubprojectForm from "./SubprojectForm";
import Text from "antd/lib/typography/Text";
import { BeatLoader } from "react-spinners";
import { ProjectRoutes, SubprojectRoutes } from "~/http/routes";
import { useSelector } from "react-redux";
import { userSelector } from "~/redux/Selectors";
import { ToastContext } from "~/providers/ToastProvider";
import CheckPermission from "~/components/CheckPermission";
import checkPermission from "~/helpers/checkPermission";

const initialProject = {
  id: "",
  name: "",
  address: "",
  speCode: "",
  active: true,
};

const validation = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = "Campo obrigatório";
  }

  return errors;
};

const ProjectForm = () => {
  const { id } = useParams();
  const history = useHistory();
  const { companyId } = useSelector(userSelector);
  const { setToast } = useContext(ToastContext);
  const [project, setProject] = useState(initialProject);
  const [subprojects, setSubprojects] = useState([]);
  const [selectedSubproject, setSelectedSubproject] = useState(undefined);

  useEffect(() => {
    if (id !== "create") {
      ProjectRoutes.getProjectById(companyId, id).then(({ data }) => {
        const values = {
          ...data.data,
          speCode: data.data.spe_code,
        };

        delete values.spe_code;

        setProject(values);
      });
    }
  }, [companyId, id]);

  useEffect(() => {
    if (project.id) {
      SubprojectRoutes.findAll(project.id).then(({ data }) => {
        setSubprojects(data.data);
      });
    }
  }, [project]);

  const onSubmit = (values, actions) => {
    values = {
      ...values,
      spe_code: values.speCode,
    };

    let save = () => Promise.resolve();

    if (
      values.id &&
      (values.name !== project.name ||
        values.speCode !== project.speCode ||
        values.address !== project.address)
    ) {
      if (values.name === project.name) delete values.name;

      if (values.speCode === project.speCode) delete values.speCode;

      if (values.address === project.address) delete values.address;

      save = ProjectRoutes.update;
    }

    if (!values.id) {
      save = ProjectRoutes.save;
    }

    return save(companyId, values)
      .then(({ data } = {}) => {
        const id = data?.data?.id || values.id;

        actions.setFieldValue("id", id);

        history.replace(id.toString());

        setToast({
          title: "Sucesso!",
          type: "success",
          message: "Projeto salvo com sucesso!",
        });
      })
      .catch((err) => {
        setToast({
          title: "Ops, aconteceu um erro ao salvar o projeto!",
          type: "error",
          message: err.response?.data?.message || err?.message,
        });
      })
      .finally(() => {
        if (id !== "create") {
          history.push("/home/projects");
        }
      });
  };

  const saveOrUpdateSubproject = (subproject) => {
    const index = subprojects.findIndex(
      (sp) => sp.id?.toString() === subproject.id?.toString(),
    );

    if (index >= 0) {
      setSubprojects((prev) => [
        ...prev.slice(0, index),
        subproject,
        ...prev.slice(index + 1),
      ]);
    } else {
      setSubprojects((prev) => [...prev, subproject]);
    }

    setSelectedSubproject(undefined);
  };

  return (
    <>
      <Breadcrumb title="Cadastro de projetos" routeToBack="/home/projects" />
      <Formik
        enableReinitialize
        initialValues={project}
        onSubmit={onSubmit}
        validate={validation}
      >
        {({ isSubmitting, values }) => {
          return (
            <Form>
              <Row>
                {/*Original: <Col md={11} sm={8} xs={7}> */}
                <Col md={12} sm={12} xs={12}>
                  <FormikInput
                    name="name"
                    placeholder="Nome"
                    className="mt-4"
                  />
                </Col>

                <Col sm={12} md={6}>
                  <FormikInput
                    name="address"
                    placeholder="Endereço"
                    className="mt-4"
                  />
                </Col>

                <Col sm={12} md={6}>
                  <FormikInput
                    name="speCode"
                    placeholder="Código SPE"
                    className="mt-4"
                  />
                </Col>
                {/* <Col md={1} sm={4} xs={5}>
                  <FormikSwitchCheckbox
                    id="active"
                    label="Ativo"
                    name="active"
                  />
                </Col> */}
              </Row>

              {values.id && (
                <>
                  <SectionTitle className="mt-4">Subprojetos:</SectionTitle>
                  <Row className="mt-4">
                    {subprojects.map((sp) => (
                      <Col
                        md={4}
                        sm={6}
                        xs={10}
                        className="mb-4"
                        onClick={() =>
                          checkPermission("editar-subprojetos")
                            ? setSelectedSubproject(sp)
                            : null
                        }
                      >
                        <div className="subproject-card">
                          {sp.name}
                          <CheckPermission slug="editar-subprojetos">
                            <EditOutlined />
                          </CheckPermission>
                        </div>
                      </Col>
                    ))}
                  </Row>

                  <CheckPermission slug="cadastrar-subprojetos">
                    <Row className="mt-3 d-flex justify-content-center">
                      <Text
                        underline
                        style={{ cursor: "pointer" }}
                        onClick={() => setSelectedSubproject({})}
                      >
                        + Adicionar novo
                      </Text>
                    </Row>
                  </CheckPermission>
                </>
              )}

              <Row className="mt-5 d-flex align-items-center justify-content-center flex-column">
                <button
                  type="submit"
                  className="btn-default mt-5"
                  disabled={isSubmitting}
                  style={{ width: "30%" }}
                >
                  {isSubmitting ? (
                    <BeatLoader
                      color="#000000"
                      loading={isSubmitting}
                      size={10}
                    />
                  ) : (
                    "Salvar"
                  )}
                </button>

                <Text
                  underline
                  className="mt-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push("/home/projects")}
                >
                  Cancelar
                </Text>
              </Row>
            </Form>
          );
        }}
      </Formik>

      <SubprojectForm
        projectId={id}
        onSave={saveOrUpdateSubproject}
        selectedSubproject={selectedSubproject}
        onClose={(newSubproject) => setSelectedSubproject(undefined)}
      />
    </>
  );
};

export default ProjectForm;
