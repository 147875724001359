import React from "react";
import { Row, Col, Toast } from "react-bootstrap";

/**
 * @param {{delay: number, title: string, description: string, type?: string, showToast: Boolean, handleClose: Function}} props
 */
function ToastComponent(props) {
  const { delay, title, description, type, showToast, handleClose, autohide } =
    props;

  let styles = {};
  switch (type) {
    case "warning":
      styles = { backgroundColor: "#ffc107", color: "white" };
      break;
    case "info":
      styles = { backgroundColor: "#17a2b8", color: "white" };
      break;
    case "success":
      styles = { backgroundColor: "#28a745", color: "white" };
      break;
    case "error":
      styles = { backgroundColor: "#dc3545", color: "white" };
      break;
    default:
      styles = {};
      break;
  }

  return (
    <Row>
      <Col>
        <Toast
          className="toast"
          onClose={handleClose}
          show={showToast}
          delay={delay}
          autohide={autohide}
          style={{
            position: "fixed",
            zIndex: 1080,
            bottom: 5,
            right: 5,
          }}
        >
          <Toast.Header style={styles}>
            <strong className="mr-auto">{title}</strong>
          </Toast.Header>
          <Toast.Body style={{ color: "black" }}>{description}</Toast.Body>
        </Toast>
      </Col>
    </Row>
  );
}

export default ToastComponent;
