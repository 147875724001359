import "./AtaForm.css";

import React, { useContext, useEffect, useRef, useState } from "react";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep, isEmpty, isEqual } from "lodash";
import { FilePdfOutlined, PaperClipOutlined } from "@ant-design/icons";
import { Form, Formik } from "formik";
import { Skeleton } from "antd";

import FormikAntdSelect from "~/pages/CreateTask/FormikAntdSelect";
import UnderlinedButton from "~/components/UnderlinedButton";
import Breadcrumb from "~/components/Breadcrumb/Breadcrumb";
import FormikInput from "~/components/Input/FormikInput";
import Select from "~/components/Select/Select";
import Button from "~/components/Button/Button";
import Input from "~/components/Input/Input";
import { ToastContext } from "~/providers/ToastProvider";
import { userSelector } from "~/redux/Selectors";

import AttachFileDigitizedAtaModal from "./AttachFileDigitizedAtaModal";
import RegisterParticipantModal from "~pages/Atas/RegisterParticipantModal";
import RegisterSubjectModal from "~pages/Atas/RegisterSubjectModal";
import AtaParticipantCard from "~/components/AtaParticipantCard";
import Subject from "~pages/Atas/Subject";

import checkPermission from "~/helpers/checkPermission";
import isValidUrl from "~/helpers/isValidUrl";
import {
  AtaRoutes,
  SectorRoutes,
  ServiceRoutes,
  UserRoutes,
  StatusesRoutes,
} from "~/http/routes";
import { SET_SUBJECTS } from "~/redux/actions/AtaActions";
import CheckPermission from "~/components/CheckPermission";
import { render } from "react-dom";

function AtaForm() {
  const { id, subprojectId } = useParams();
  const { push, replace } = useHistory();
  const { setToast } = useContext(ToastContext);
  const { companyId, projectInfos: projects } = useSelector(userSelector);
  const dispatch = useDispatch();

  const backRoute = `/home/atas/${subprojectId}`;
  const readOnly = !checkPermission(["criar-ata", "editar-ata"], "or");

  const initialState = {
    id: id !== "create" ? id : "",
    subprojectId,
    title: "",
    goals:
      "Essa reunião tem como objetivo definir as metas, apontar necessidade da obra com relação à programações, composição de equipe e organização por parte da Contratada.",
    solutions:
      "A Contratada deverá atender as necessidades exigidas nessa Ata.",
    consequences:
      "Caso a Contratada não atenda as necessidades apontadas nessa reunião, ela será notificada novamente, devendo ser criado um plano de ação para solução imediata. Se as inadimplências persistirem, a Construtora poderá realizar a troca de Empreiteira ou colocar uma outra para reforçar a equipe com o objetivo.",
    thirdPartyCompany: "",
  };

  const formRef = useRef(null);

  const { subjects } = useSelector((state) => state.ata);

  const [ata, setAta] = useState(initialState);
  const [participants, setParticipants] = useState([]);

  const [qualityCode, setQualityCode] = useState("");
  const [status, setStatus] = useState("");
  const [finishedAt, setFinishedAt] = useState("");
  const [digitizedAta, setDigitizedAta] = useState("");
  const [digitizedAtaUrl, setDigitizedAtaUrl] = useState("");
  const [timestamp, setTimestamp] = useState("");

  const [users, setUsers] = useState([]);
  const [thirdPartyCompanies, setThirdPartyCompanies] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [services, setServices] = useState([]);
  const [project, setProject] = useState({});
  const [subproject, setSubproject] = useState({});

  const [loading, setLoading] = useState(false);
  const [loadingParticipants, setLoadingParticipants] = useState(false);
  const [loadingSubjects, setLoadingSubjects] = useState(false);
  const [loadingSectors, setLoadingSectors] = useState(false);
  const [loadingStatuses, setLoadingStatuses] = useState(false);
  const [loadingServices, setLoadingServices] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const [saving, setSaving] = useState(false);
  const [isFinishing, setIsFinishing] = useState(false);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [digitizedAtaIsInvalid, setDigitizedAtaIsInvalid] = useState(false);
  const [isSubmittingDigitizedAta, setIsSubmittingDigitizedAta] =
    useState(false);
  const [isSendingAtaToParticipants, setIsSendingAtaToParticipants] =
    useState(false);
  const [autoSaveIntervalId, setAutoSaveIntervalId] = useState(null);

  const [savedMessage, setSavedMessage] = useState("");
  const [selectedSubject, setSelectedSubject] = useState(null);

  const [
    isRegisterParticipantModalVisible,
    setIsRegisterParticipantModalVisible,
  ] = useState(false);
  const [isRegisterSubjectModalVisible, setIsRegisterSubjectModalVisible] =
    useState(false);
  const [
    isAttachFileDigitizedAtaModalVisible,
    setIsAttachFileDigitizedAtaModalVisible,
  ] = useState(false);

  useEffect(() => {
    setLoading(true);

    if (projects.length) {
      const project = projects.find(
        (project) =>
          project.subProjects &&
          project.subProjects.find(
            (subproject) => subproject.id === Number(subprojectId),
          ),
      );
      if (project) {
        setProject(project);
        setSubproject(
          project.subProjects.find(
            (subproject) => subproject.id === Number(subprojectId),
          ),
        );
      }
    }

    setLoading(false);
  }, [projects]);

  useEffect(() => {
    dispatch({ type: SET_SUBJECTS, data: [] });
  }, []);

  useEffect(async () => {
    setLoading(true);
    setLoadingSectors(true);
    setLoadingStatuses(true);
    setLoadingServices(true);

    if (id !== "create") {
      setLoadingParticipants(true);
      setLoadingSubjects(true);
    }

    if (id !== "create") {
      try {
        const { data: ata } = await AtaRoutes.findById(id);

        setAta(mapAtaToCamelCase(ata));

        setTimestamp(moment(ata.created_at).format("DD/MM/YYYY HH:mm:ss"));
        setQualityCode(ata.quality_code);
        setStatus(ata.status);
        setFinishedAt(ata.finished_at);
        setDigitizedAta(
          ata.digitized_ata_name ? ata.digitized_ata_name : ata.digitized_ata,
        );
        setDigitizedAtaUrl(ata.digitized_ata_url);
      } catch (err) {
        setLoading(false);
        errorToastr(
          "Um erro inesperado aconteceu ao carregar as informações da ata.",
        );
        push(backRoute);
      }
    }
  }, []);

  useEffect(async () => {
    try {
      const {
        data: { data: users },
      } = await UserRoutes.getCompanyUsersShort(companyId, true, subprojectId);
      const thirdPartyCompanies = [];

      for (const user of users)
        if (
          user.companyRole &&
          !thirdPartyCompanies.find(
            (tpc) => tpc.toLowerCase() === user.companyRole.toLowerCase(),
          )
        )
          thirdPartyCompanies.push(user.companyRole);

      setUsers(users);
      setThirdPartyCompanies(thirdPartyCompanies);
    } catch (err) {
      setLoading(false);
      errorToastr("Um erro inesperado aconteceu durante a busca das empresas.");
      push(backRoute);
    }

    setLoading(false);
  }, []);

  useEffect(async () => {
    if (id !== "create" && users.length > 0) {
      try {
        const { data: participants } = await AtaRoutes.getParticipants(id);

        setParticipants(participants);
      } catch (err) {
        setLoadingParticipants(false);
        errorToastr(
          "Um erro inesperado aconteceu durante a busca dos participantes da ata.",
        );
        push(backRoute);
      }
    }

    setLoadingParticipants(false);
  }, [users]);

  useEffect(async () => {
    if (id !== "create") {
      try {
        const { data: subjects } = await AtaRoutes.getSubjects(id);

        dispatch({ type: SET_SUBJECTS, data: subjects });
      } catch (err) {
        setLoadingSubjects(false);
        errorToastr(
          "Um erro inesperado aconteceu durante a busca dos assuntos da ata.",
        );
        push(backRoute);
      }

      setLoadingSubjects(false);
    }
  }, []);

  useEffect(async () => {
    try {
      const {
        data: { data: sectors },
      } = await SectorRoutes.getSector(companyId, subproject.id);

      setSectors(sectors);
    } catch (err) {
      setLoadingSectors(false);
      errorToastr(
        "Um erro inesperado aconteceu durante a busca de informações da ata.",
      );
      push(backRoute);
    }

    setLoadingSectors(false);
  }, []);

  useEffect(async () => {
    try {
      const {
        data: { data: services },
      } = await ServiceRoutes.getServices(companyId, subproject.id);

      setServices(services);
    } catch (err) {
      setLoadingServices(false);
      errorToastr(
        "Um erro inesperado aconteceu durante a busca de informações da ata.",
      );
      push(backRoute);
    }

    setLoadingServices(false);
  }, []);

  useEffect(async () => {
    try {
      const {
        data: { data: statuses },
      } = await StatusesRoutes.getStatus(companyId, subprojectId);

      setStatuses(statuses);
    } catch (err) {
      setLoadingStatuses(false);
      errorToastr(
        "Um erro inesperado aconteceu durante a busca de informações da ata.",
      );
      push(backRoute);
    }

    setLoadingStatuses(false);
  }, []);

  useEffect(() => {
    if (
      formRef?.current?.values.id &&
      !autoSaveIntervalId &&
      status === "OPEN"
    ) {
      const intervalId = setInterval(autoSave, 60000);
      setAutoSaveIntervalId(intervalId);
    }
  });

  function warningToastr(message) {
    setToast({
      type: "warning",
      title: "Atenção",
      message,
      show: true,
      autohide: true,
    });
  }

  function errorToastr(message) {
    setToast({
      type: "error",
      title: "Erro",
      message,
      show: true,
      autohide: true,
    });
  }

  function successToastr(message) {
    setToast({
      type: "success",
      title: "Sucesso",
      message,
      show: true,
      autohide: true,
    });
  }

  function isAbleToSave(values) {
    return (
      !loading &&
      !saving &&
      !isFinishing &&
      values.title &&
      values.subprojectId &&
      (id !== "create" ? !isEmpty(dirty(mapAtaToUnderscoreCase(values))) : true)
    );
  }

  function isOpen() {
    return status === "OPEN" || !status;
  }

  function mapAtaToCamelCase(underscoreCaseAta) {
    return {
      id: underscoreCaseAta?.id || "",
      subprojectId: underscoreCaseAta?.subproject_id || "",
      title: underscoreCaseAta?.title || "",
      goals: underscoreCaseAta?.goals || "",
      solutions: underscoreCaseAta?.solutions || "",
      consequences: underscoreCaseAta?.consequences || "",
      thirdPartyCompany: underscoreCaseAta?.third_party_company || "",
    };
  }

  function mapAtaToUnderscoreCase(camelCaseAta) {
    return {
      id: camelCaseAta?.id || "",
      subproject_id: camelCaseAta?.subprojectId || "",
      title: camelCaseAta?.title || "",
      goals: camelCaseAta?.goals || "",
      solutions: camelCaseAta?.solutions || "",
      consequences: camelCaseAta?.consequences || "",
      third_party_company: camelCaseAta?.thirdPartyCompany || "",
    };
  }

  function dirty(ATA, baseAta = ata) {
    for (const [key, value] of Object.entries(mapAtaToUnderscoreCase(baseAta)))
      if (isEqual(ATA[key], value)) delete ATA[key];

    return ATA;
  }

  async function handleParticipantSelection(ataId, userId) {
    let participant;

    try {
      participant = (await AtaRoutes.addParticipant(ataId, userId)).data;
    } catch (err) {
      return errorToastr("Um erro ocorreu ao adicionar o participante");
    }

    const aux = cloneDeep(participants);
    aux.push(participant);
    setParticipants(aux);
    successToastr("Participante adicionado com sucesso");
  }

  async function deleteParticipant(ataId, userId) {
    let participant;

    try {
      participant = await AtaRoutes.deleteParticipant(ataId, userId);
    } catch (err) {
      return errorToastr("Um erro ocorreu ao remover o participante");
    }

    const aux = cloneDeep(participants);
    aux.pop(participant);
    setParticipants(aux);
    successToastr("Participante removido com sucesso");
  }

  function handleRegisterParticipantModalCancel() {
    setIsRegisterParticipantModalVisible(false);
  }

  function handleRegisterParticipantModalSuccess({ user, participant }) {
    const usersAux = cloneDeep(users);
    usersAux.push(user);
    setUsers(usersAux);

    const participantsAux = cloneDeep(participants);
    participantsAux.push(participant);
    setParticipants(participantsAux);

    if (
      !thirdPartyCompanies.find(
        (tpc) => tpc.toLowerCase() === user.companyRole.toLowerCase(),
      )
    ) {
      thirdPartyCompanies.push(user.companyRole);
      setThirdPartyCompanies(thirdPartyCompanies);
    }

    setIsRegisterParticipantModalVisible(false);

    successToastr("Participante adicionado com sucesso");
  }

  function handleRegisterSubjectModalCancel() {
    setSelectedSubject(null);
    setIsRegisterSubjectModalVisible(false);
  }

  function handleRegisterSubjectModalSuccess(subject) {
    const aux = cloneDeep(subjects);
    const subjectIndex = aux.findIndex((sbj) => sbj.id === subject.id);

    if (subjectIndex > -1)
      aux[subjectIndex] = {
        ...subject,
        body: JSON.stringify(subject.body),
      };
    else aux.push(subject);

    dispatch({ type: SET_SUBJECTS, data: aux });

    if (subjectIndex === -1) {
      setSelectedSubject(null);
      successToastr("Assunto adicionado com sucesso");
      setIsRegisterSubjectModalVisible(false);
    }
  }

  function handleRegisterSubjectModalDelete(subjectId) {
    const aux = cloneDeep(subjects);
    const subjectIndex = aux.findIndex((sbj) => sbj.id === subjectId);
    aux.splice(subjectIndex, 1);

    setIsRegisterSubjectModalVisible(false);
    setSelectedSubject(null);
    dispatch({ type: SET_SUBJECTS, data: aux });
  }

  function handleAttachFileDigitizedAtaModalSuccess(ata) {
    setDigitizedAta(ata.digitized_ata_name);
    setDigitizedAtaUrl(ata.digitized_ata_url);
    setStatus(ata.status);
    setIsAttachFileDigitizedAtaModalVisible(false);
  }

  function handleAttachFileDigitizedAtaModalCancel() {
    setIsAttachFileDigitizedAtaModalVisible(false);
  }

  function handleSubjectClick(subject) {
    setSelectedSubject(subject);

    setIsRegisterSubjectModalVisible(true);
  }

  async function autoSave() {
    if (formRef?.current?.values.id) {
      const {
        current: { values },
      } = formRef;
      const fields = dirty(mapAtaToUnderscoreCase(values), ata);

      if (!isEmpty(fields)) {
        fields.id = values.id;
        setSaving(true);

        try {
          await AtaRoutes.save(fields);

          setSavedMessage(
            <>
              Salvo <i>automaticamente</i> às {moment().format("HH:mm:ss")}
            </>,
          );
        } catch (err) {
          if (err.response)
            if (Array.isArray(err.response.data))
              errorToastr(err.response.data[0].message);
            else errorToastr(err.response.data.message);
          else
            errorToastr(
              "Um erro inesperado ocorreu portanto a ata não pôde ser salva!",
            );
        }

        setSaving(false);
      }
    }
  }

  async function handleSave(values, { setFieldValue }) {
    const fields = dirty(mapAtaToUnderscoreCase(values));

    if (values.id) fields.id = values.id;
    else {
      fields.company_id = companyId;
      fields.subproject_id = values.subprojectId;

      if (!fields?.goals) fields.goals = initialState.goals;

      if (!fields?.solutions) fields.solutions = initialState.solutions;

      if (!fields?.consequences)
        fields.consequences = initialState.consequences;
    }

    setSaving(true);

    try {
      const { data } = await AtaRoutes.save(fields);

      if (!values.id) {
        setFieldValue("id", data.id);
        setTimestamp(moment(data.created_at).format("DD/MM/YYYY HH:mm:ss"));
        setQualityCode(data.quality_code);
        setStatus(data.status);
        replace(`${data.id}`);
      }

      successToastr("Ata salva com sucesso!");

      setSavedMessage(
        <>
          Salvo <i>manualmente</i> às {moment().format("HH:mm:ss")}
        </>,
      );
    } catch (err) {
      if (err.response)
        if (Array.isArray(err.response.data))
          errorToastr(err.response.data[0].message);
        else errorToastr(err.response.data.message);
      else
        errorToastr(
          "Um erro inesperado ocorreu portanto a ata não pôde ser salva. Por favor, tente novamente!",
        );
    }

    setSaving(false);
  }

  async function handleFinish(id) {
    if (
      window.confirm(
        "Tem certeza que deseja finalizar esta ata? Atas finalizadas não podem mais ser editadas!",
      )
    ) {
      setIsFinishing(true);

      try {
        const { data: ata } = await AtaRoutes.finish(id);
        sendAtaToParticipants(id);

        setStatus(ata.status);
        setFinishedAt(ata.finished_at);
      } catch (err) {
        if (err.response)
          if (Array.isArray(err.response.data))
            errorToastr(err.response.data[0].message);
          else errorToastr(err.response.data.message);
        else
          errorToastr(
            "Um erro inesperado ocorreu portanto a ata não pôde ser finalizada. Por favor, tente novamente!",
          );
      }

      setIsFinishing(false);
    }
  }

  async function handleDuplicate(id) {
    if (window.confirm("Tem certeza que deseja duplicar esta ata?")) {
      setIsDuplicating(true);

      try {
        await AtaRoutes.duplicate(id).then((response) =>
          push(`/home/atas/${subprojectId}/${response.data.ata.id}`),
        );

        successToastr(
          "Ata duplicada com sucesso, em breve sera levado para a ATA duplicada!",
        );
        setTimeout(() => {
          window.location.reload(true);
        }, 3000);
      } catch (err) {
        if (err.response)
          if (Array.isArray(err.response.data))
            errorToastr(err.response.data[0].message);
          else errorToastr(err.response.data.message);
        else
          errorToastr(
            "Um erro inesperado ocorreu portanto a ata não pôde ser duplicada. Por favor, tente novamente!",
          );
      }

      setIsDuplicating(false);
    }
  }

  async function handleAttachDigitizedAta(id) {
    if (!isValidUrl(digitizedAta)) return setDigitizedAtaIsInvalid(true);

    setDigitizedAtaIsInvalid(false);
    setIsSubmittingDigitizedAta(true);

    try {
      const {
        data: { status, digitized_ata },
      } = await AtaRoutes.attachUrlDigitizedAta(id, digitizedAta);

      setStatus(status);
      setDigitizedAtaUrl(digitized_ata);
    } catch (err) {
      errorToastr(
        "Um erro inesperado aconteceu ao salvar a ata digitalizada. Por favor, tente novamente!",
      );
    }

    setIsSubmittingDigitizedAta(false);
  }

  async function sendAtaToParticipants(id) {
    setIsSendingAtaToParticipants(true);

    try {
      await AtaRoutes.sendToParticipants(id);
    } catch (err) {
      errorToastr(
        "Um erro inesperado aconteceu e a ata não pôde ser enviada para os participantes! Por favor, tente novamente.",
      );
    }

    setIsSendingAtaToParticipants(false);
  }

  async function handleExportReportToPDF(id) {
    setLoadingReport(true);
    let pdfBuffer;

    try {
      pdfBuffer = (await AtaRoutes.report(id)).data;
    } catch (err) {
      errorToastr(
        "Um erro inesperado ocorreu e a ata não pôde ser exportada! Por favor, tente novamente",
      );
    }

    if (pdfBuffer) {
      const file = new Blob([pdfBuffer], { type: "application/pdf" });

      const fileURL = URL.createObjectURL(file);

      window.open(fileURL);

      successToastr("Ata exportada com sucesso!");
    }

    setLoadingReport(false);
  }

  return (
    <div>
      <Row>
        <Col sm={12} md={6}>
          <Breadcrumb title="Criar Ata" routeToBack={backRoute} />
        </Col>

        <Col
          className="d-flex align-items-end"
          sm={12}
          md={6}
          style={{
            flexDirection: "column",
          }}
        >
          <small>{savedMessage}</small>

          {!isOpen() && (
            <Row className="pr-0">
              <Col className="pr-0" sm={12} md={7}>
                <Button
                  className="default-button export-to-pdf-button"
                  size="medium"
                  onClick={handleExportReportToPDF.bind(null, id)}
                  loading={loadingReport}
                  withIcon
                  icon={<FilePdfOutlined />}
                  disabled={loadingReport || isOpen()}
                >
                  Exportar para PDF
                </Button>
              </Col>

              <Col className="pr-0" sm={12} md={5}>
                <Button
                  className="default-button"
                  size="medium"
                  onClick={handleDuplicate.bind(null, id)}
                  loading={isDuplicating}
                  disabled={isDuplicating || isOpen()}
                >
                  Duplicar ata
                </Button>
              </Col>
            </Row>
          )}
        </Col>
      </Row>

      <div>
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={ata}
          onSubmit={handleSave}
        >
          {({ isSubmitting, errors, values, submitForm, setFieldValue }) => {
            return (
              <>
                <Form>
                  <Skeleton loading={loading}>
                    {!isOpen() && (
                      <Row className="justify-content-end">
                        <Col className="mt-4" sm={12} md={5}>
                          <Row>
                            <Col sm={12} md={status === "FINISHED" ? 8 : 12}>
                              {status === "FINISHED" ? (
                                <Input
                                  className="form-input"
                                  placeholder="Ata digitalizada"
                                  inputPlaceholder="Inserir url do arquivo ou anexar documento"
                                  value={digitizedAta}
                                  touched
                                  errors={
                                    digitizedAtaIsInvalid
                                      ? "URL inválida"
                                      : null
                                  }
                                  handleChange={(e) =>
                                    setDigitizedAta(e.target.value)
                                  }
                                  style={{
                                    cursor:
                                      status !== "FINISHED"
                                        ? "not-allowed"
                                        : "pointer",
                                  }}
                                  disabled={status !== "FINISHED" || readOnly}
                                />
                              ) : (
                                <div className="input-div rounded flex p-2">
                                  <span
                                    className="span-input"
                                    title={digitizedAtaUrl}
                                    style={{
                                      backgroundColor: "#fafafa",
                                    }}
                                  >
                                    Ata digitalizada
                                  </span>
                                  <div>
                                    <a
                                      href={digitizedAtaUrl}
                                      target="_blank"
                                      style={{
                                        textDecoration: "underline",
                                        color: "blue",
                                      }}
                                    >
                                      {digitizedAta}
                                    </a>
                                  </div>
                                </div>
                              )}
                            </Col>

                            {status === "FINISHED" && !readOnly && (
                              <Col sm={12} md={4}>
                                <Row>
                                  <Col sm={10} md={8}>
                                    <Button
                                      className="default-button"
                                      size="medium"
                                      onClick={handleAttachDigitizedAta.bind(
                                        null,
                                        values.id,
                                      )}
                                      loading={isSubmittingDigitizedAta}
                                      disabled={
                                        status !== "FINISHED" ||
                                        isSubmittingDigitizedAta ||
                                        !digitizedAta ||
                                        readOnly
                                      }
                                    >
                                      Salvar
                                    </Button>
                                  </Col>

                                  <Col sm={2} md={4}>
                                    <PaperClipOutlined
                                      style={{
                                        fontSize: 30,
                                        cursor:
                                          status === "FINISHED"
                                            ? "pointer"
                                            : "not-allowed",
                                        color:
                                          status === "FINISHED"
                                            ? "#000"
                                            : "#585858",
                                      }}
                                      onClick={() =>
                                        setIsAttachFileDigitizedAtaModalVisible(
                                          true,
                                        )
                                      }
                                      disabled={
                                        status !== "FINISHED" ||
                                        isSubmittingDigitizedAta ||
                                        readOnly
                                      }
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            )}
                          </Row>
                        </Col>
                      </Row>
                    )}

                    <Row>
                      <Col className="mt-4" sm={12} md={7}>
                        <FormikInput
                          name="title"
                          placeholder="Título da Ata"
                          title={values.title}
                          error={errors.title}
                          maxLength={255}
                          disabled={!isOpen() || readOnly}
                        />
                      </Col>

                      <Col className="mt-4" sm={12} md={5}>
                        <FormikAntdSelect
                          placeholder="Empresa"
                          value={values.thirdPartyCompany}
                          labelBackground="#fafafa"
                          onChange={(val) =>
                            setFieldValue("thirdPartyCompany", val)
                          }
                          inputBackground="transparent"
                          options={thirdPartyCompanies.map((tpc) => ({
                            name: tpc,
                            value: tpc,
                          }))}
                          withNull
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.value
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          disabled={!isOpen() || readOnly}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col className="mt-4" sm={12} md={7}>
                        <Input
                          className="form-input"
                          placeholder="Projeto"
                          value={project.name}
                          title={project.name}
                          disabled
                          style={{ cursor: "not-allowed" }}
                        />
                      </Col>

                      <Col className="mt-4" sm={12} md={5}>
                        <Input
                          className="form-input"
                          placeholder="Disciplina"
                          value={subproject.name}
                          title={subproject.name}
                          disabled
                          style={{ cursor: "not-allowed" }}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col className="mt-4" sm={12} md={7}>
                        <Input
                          className="form-input"
                          placeholder="Local"
                          value={project.address}
                          title={project.address}
                          disabled
                          style={{ cursor: "not-allowed" }}
                        />
                      </Col>

                      <Col className="mt-4" sm={6} md={3}>
                        <Input
                          className="form-input"
                          placeholder="Código SPE"
                          value={project.spe_code}
                          title={project.spe_code}
                          disabled
                          style={{ cursor: "not-allowed" }}
                        />
                      </Col>

                      <Col className="mt-4" sm={6} md={2}>
                        <Input
                          className="form-input"
                          placeholder="Data e Hora"
                          value={timestamp}
                          title={timestamp}
                          disabled
                          style={{ fontSize: 14 }}
                        />
                      </Col>
                    </Row>

                    {isOpen() && !readOnly && (
                      <>
                        <Row>
                          <Col className="mt-4" sm={11} md={4}>
                            <FormikAntdSelect
                              placeholder="Participantes"
                              value={null}
                              labelBackground="#fafafa"
                              inputBackground="transparent"
                              inputPlaceholder="Adicionar participante..."
                              options={users
                                .map((e) => {
                                  return {
                                    name: e.name,
                                    value: e.id,
                                  };
                                })
                                .filter(
                                  (e) =>
                                    !participants.find(
                                      (participant) =>
                                        participant.user_id === e.value,
                                    ),
                                )}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              onChange={(userId) =>
                                handleParticipantSelection(values.id, userId)
                              }
                              disabled={!values.id || !isOpen() || readOnly}
                            />
                          </Col>

                          <CheckPermission slug="criar-usuario-ata">
                            <Col className="mt-4 pl-0" sm={1}>
                              <Button
                                className="default-button ml-2"
                                style={{ width: "50px" }}
                                size="large"
                                onClick={() =>
                                  setIsRegisterParticipantModalVisible(true)
                                }
                                disabled={!values.id || !isOpen() || readOnly}
                              >
                                <b>+</b>
                              </Button>
                            </Col>
                          </CheckPermission>
                        </Row>
                        {!values.id && (
                          <small style={{ color: "#585858" }}>
                            Para adicionar participantes em uma nova ata é
                            necessário salvá-la primeiro.
                          </small>
                        )}
                      </>
                    )}
                  </Skeleton>

                  {!isOpen() ||
                    (readOnly && (
                      <p
                        className="mt-4 ml-4"
                        style={{
                          color: "#585858",
                        }}
                      >
                        Participantes
                      </p>
                    ))}

                  <Row
                    style={{
                      maxHeight: "325px",
                      overflowY: "auto",
                      overflowX: "hidden",
                      paddingBottom: "10px",
                    }}
                  >
                    {!loadingParticipants ? (
                      participants.map((participant) => (
                        <Col className="mt-4" sm={12} md={4} xl={3}>
                          <AtaParticipantCard
                            name={participant.user.name}
                            role={participant.user.companyRole}
                            picture={`data:image/png;base64,${participant.user.photo}`}
                            sawAta={participant.seen_at}
                            documentNumber={participant.user.document_number}
                            deleteParticipant={() =>
                              deleteParticipant(
                                participant.ata_id,
                                participant.user.id,
                              )
                            }
                          />
                        </Col>
                      ))
                    ) : (
                      <>
                        <Col className="mt-4" sm={12} md={4} xl={3}>
                          <AtaParticipantCard loading={loadingParticipants} />
                        </Col>
                        <Col className="mt-4" sm={12} md={4} xl={3}>
                          <AtaParticipantCard loading={loadingParticipants} />
                        </Col>
                        <Col className="mt-4" sm={12} md={4} xl={3}>
                          <AtaParticipantCard loading={loadingParticipants} />
                        </Col>
                        <Col className="mt-4" sm={12} md={4} xl={3}>
                          <AtaParticipantCard loading={loadingParticipants} />
                        </Col>
                        <Col className="mt-4" sm={12} md={4} xl={3}>
                          <AtaParticipantCard loading={loadingParticipants} />
                        </Col>
                      </>
                    )}
                  </Row>

                  <Skeleton loading={loading}>
                    <Row className="mt-4">
                      <Col sm={12}>
                        <FormikInput
                          name="goals"
                          placeholderClassName="textAreaPlaceholder"
                          placeholder="Objetivo"
                          error={errors.goals}
                          inputType="textArea"
                          style={{
                            height: "60px",
                            minHeight: "40px",
                            maxHeight: "200px",
                          }}
                          disabled={!isOpen() || readOnly}
                        />
                      </Col>
                    </Row>

                    <Row className="mt-4">
                      <Col sm={12}>
                        <FormikInput
                          name="solutions"
                          placeholderClassName="textAreaPlaceholder"
                          placeholder="Solução"
                          error={errors.solutions}
                          inputType="textArea"
                          style={{
                            height: "60px",
                            minHeight: "40px",
                            maxHeight: "200px",
                          }}
                          disabled={!isOpen() || readOnly}
                        />
                      </Col>
                    </Row>

                    <Row className="mt-4">
                      <Col sm={12}>
                        <FormikInput
                          name="consequences"
                          placeholderClassName="textAreaPlaceholder"
                          placeholder="Consequência"
                          error={errors.consequences}
                          inputType="textArea"
                          style={{
                            height: "60px",
                            minHeight: "40px",
                            maxHeight: "200px",
                          }}
                          disabled={!isOpen() || readOnly}
                        />
                      </Col>
                    </Row>

                    <p className="pt-4 text-center">
                      <em>
                        Os dados dos participantes serão utilizados para as
                        finalidades de confirmação e compromisso dos termos,
                        assuntos e tarefas anotadas na respectiva ata, além
                        disso o próprio sistema informará através do aplicativo
                        Whatsapp e E-mail as tarefas acordadas em Ata.
                      </em>
                    </p>

                    <p className="pt-2 text-center">
                      <b>
                        O consentimento e aceite dos termos constante nesta ata
                        será realizado através do acesso pelo link enviado por
                        e-mail ou WhatsApp.
                      </b>
                    </p>

                    <Skeleton loading={loadingSubjects}>
                      {subjects.map((subject) => (
                        <div className="mt-4">
                          <Subject
                            id={subject.id}
                            name={subject.name}
                            body={subject.body}
                            companyId={companyId}
                            ataId={values.id}
                            subprojectId={subproject.id}
                            onNameClick={handleSubjectClick.bind(null, subject)}
                            participants={users.filter(({ id }) =>
                              participants.find(
                                (participant) => participant.user_id === id,
                              ),
                            )}
                            sectors={sectors}
                            statuses={statuses}
                            services={services}
                            loadingUsers={loading}
                            loadingSectors={loadingSectors}
                            loadingStatuses={loadingStatuses}
                            loadingServices={loadingServices}
                            updateSectors={(sectors) => setSectors(sectors)}
                            updateStatuses={(statuses) => setStatuses(statuses)}
                            updateServices={(services) => setServices(services)}
                            ataIsOpen={isOpen()}
                          />
                        </div>
                      ))}
                    </Skeleton>

                    {isOpen() && !readOnly && (
                      <>
                        <Row className="mt-4">
                          <Col sm={12} md={3}>
                            <Button
                              className="dark-button"
                              onClick={() =>
                                setIsRegisterSubjectModalVisible(true)
                              }
                              disabled={!values.id || !isOpen() || readOnly}
                            >
                              Adicionar Assunto
                            </Button>
                          </Col>
                        </Row>
                        {!values.id && (
                          <small style={{ color: "#585858" }}>
                            Para adicionar assuntos em uma nova ata é necessário
                            salvá-la primeiro.
                          </small>
                        )}
                      </>
                    )}

                    {isOpen() && !readOnly && (
                      <Row className="mt-5 d-flex align-items-center flex-column">
                        <Row>
                          <Button
                            className="button__save default-button mr-2"
                            size="large"
                            onClick={submitForm}
                            loading={isSubmitting}
                            disabled={
                              !isAbleToSave(values) || !isOpen() || readOnly
                            }
                          >
                            {!saving ? "Salvar" : "Salvando..."}
                          </Button>

                          {values?.id && (
                            <Button
                              className="button__save default-button ml-2"
                              size="large"
                              onClick={handleFinish.bind(null, values.id)}
                              loading={isFinishing}
                              disabled={isFinishing || !isOpen() || readOnly}
                            >
                              {!saving ? "Finalizar" : "Finalizando..."}
                            </Button>
                          )}
                        </Row>

                        <UnderlinedButton
                          className="mt-2"
                          onClick={() => push(backRoute) || !isOpen()}
                        >
                          Cancelar
                        </UnderlinedButton>
                      </Row>
                    )}

                    {finishedAt && (
                      <>
                        <p
                          className="mt-4"
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <i>
                            Ata finalizada em{" "}
                            {moment(finishedAt).format("DD/MM/YYYY")} às{" "}
                            {moment(finishedAt).format("HH:mm:ss")}
                          </i>
                        </p>

                        <Row className="justify-content-end">
                          <Col sm={12} md={6} xl={4}>
                            <Button
                              className="default-button"
                              size="medium"
                              onClick={sendAtaToParticipants.bind(
                                null,
                                values.id,
                              )}
                              loading={isSendingAtaToParticipants}
                              disabled={
                                status === "OPEN" ||
                                isSendingAtaToParticipants ||
                                readOnly
                              }
                            >
                              Enviar ata para os participantes
                            </Button>
                          </Col>
                        </Row>
                      </>
                    )}
                  </Skeleton>
                </Form>

                <RegisterParticipantModal
                  isVisible={isRegisterParticipantModalVisible}
                  ataId={values.id}
                  subprojectId={subprojectId}
                  onCancel={handleRegisterParticipantModalCancel}
                  onSuccess={handleRegisterParticipantModalSuccess}
                />

                <RegisterSubjectModal
                  isVisible={isRegisterSubjectModalVisible}
                  ataId={values.id}
                  subject={selectedSubject}
                  onCancel={handleRegisterSubjectModalCancel}
                  onSuccess={handleRegisterSubjectModalSuccess}
                  onDelete={handleRegisterSubjectModalDelete}
                />

                <AttachFileDigitizedAtaModal
                  isVisible={isAttachFileDigitizedAtaModalVisible}
                  ataId={values.id}
                  onSuccess={handleAttachFileDigitizedAtaModalSuccess}
                  onCancel={handleAttachFileDigitizedAtaModalCancel}
                />
              </>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default AtaForm;
