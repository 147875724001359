import React, { useEffect, useState } from 'react';

import Button from '~/components/Button/Button'
// import CircleColor from '~/components/CircleColor/CircleColor';
import { Input } from 'antd';
import { Table } from "antd";
import { ArrowLeftOutlined, SearchOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import "./Inventory.css";
import { Typography } from 'antd';
import { Link } from "react-router-dom";
import { InventoryRoutes } from "~/http/routes";
import { useSelector } from 'react-redux';

import { userSelector } from "~/redux/Selectors";
import { useCallback } from 'react';
import CheckPermission from '~/components/CheckPermission';
import checkPermission from '~/helpers/checkPermission';

const { Title } = Typography;

// const { Option } = Select;

function InventoryList({ history }) {

	const [inventory, setInventory] = useState([]);
	const [searchFilterValue, setSearchFilterValue] = useState('');
	const [loading, setLoading] = useState(false);

	const { companyId } = useSelector(userSelector);

	useEffect(() => {
		setLoading(true);
		InventoryRoutes.getInventory(companyId).then(({ data }) => {
			setInventory(data.data);
			setLoading(false);
		});
	}, [companyId]);

	const handleInputChange = (event) => {
		setSearchFilterValue(event.target.value);
	};

	const filterList = useCallback(() => {
		return inventory.filter(value => {
			if (value.name)
				return value.name.toLowerCase().includes(searchFilterValue.toLowerCase());
		})
	}, [searchFilterValue, inventory])

	function getColumns() {
		const columns = [
			{ key: "name", title: "Nome", dataIndex: "name", width: "90%" },
			// { key: "status", title: "Status", dataIndex: "status", align: "center" },
			// { key: "warranty", title: "Garantia", dataIndex: "warranty", align: "center" },
			{
				key: "historic",
				title: "Histórico",
				align: "center",
				render: (inventory) => {
					return (
						<EyeOutlined
							className="inventory__icons"
							onClick={() => history.push(`/home/inventory-history/${inventory.id}`)}
						/>
					);
				},
			},
		];

		if (checkPermission('editar-inventarios')) {
			columns.push({
				key: "edit",
				title: "Editar",
				align: "center",
				render: (inventory) => {
					return (
						<EditOutlined
							className="inventory__icons"
							onClick={() => history.push(`/home/inventory/${inventory.id}`)}
						/>
					);
				},
			});
		}

		return columns;
	}

	return (
		<div className="inventory">
			<div className="inventory__title">
				<ArrowLeftOutlined className="inventory__title--icon" onClick={() => history.goBack()} />
				<Title level={4}>Listagem de inventário</Title>
			</div>
			<div className={checkPermission('cadastrar-inventarios') && "inventory__filters"}
				style={{ marginTop: '20px' }}>
				<Input
					value={searchFilterValue}
					onChange={handleInputChange}
					className="inventory__filters--search"
					size="large"
					placeholder="Pesquisa por nome"
					prefix={<SearchOutlined />}
				/>
				{/* <Select size="large" className="inventory__filters--select" defaultValue="0">
          <Option value="0">
            <CircleColor color="green">
              Manutenção em dia
            </CircleColor >
          </Option>
          <Option value="1">
            <CircleColor color="red">
              Manutenção atrasada
            </CircleColor>
          </Option>
          <Option value="2">
            <CircleColor color="yellow">
              Manutenção perto de atrasar
            </CircleColor>
          </Option>
          <Option value="3">
            <CircleColor color="blue">
              Não necessita de manutenção
            </CircleColor>
          </Option>
        </Select> */}
				<CheckPermission slug="cadastrar-inventarios">
					<Link to="/home/inventory/create">
						<Button size="large" withIcon >
							Novo Cadastro
						</Button>
					</Link>
				</CheckPermission>
			</div>
			{
				loading ||
				<div>
					<Table
						columns={getColumns()}
						dataSource={filterList()}
					/>
				</div>
			}
		</div>
	)
}

export default InventoryList;
