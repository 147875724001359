import './RegisterSubjectModal.css';
import React, { useContext, useEffect, useRef, useState } from "react";
import moment from 'moment';
import { CloseOutlined, DeleteFilled, LoadingOutlined, PaperClipOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { convertFromRaw, EditorState } from "draft-js";
import { Modal, Skeleton, Spin } from "antd";
import { Col, Row } from "react-bootstrap";
import { Form, Formik } from "formik";
import { cloneDeep } from "lodash";

import AddAttachmentToSubjectModal from './AddAttachmentToSubjectModal';
import UnderlinedButton from "~/components/UnderlinedButton";
import FormikInput from "~/components/Input/FormikInput";
import Button from "~/components/Button/Button";
import Editor from "~/components/Editor";

import { SET_SUBJECTS } from '~/redux/actions/AtaActions';
import { ToastContext } from "~/providers/ToastProvider";
import { AtaRoutes } from "~/http/routes";


function RegisterSubjectModal(props) {
	const {
		isVisible,
		ataId,
		subject,
		onCancel,
		onSuccess,
		onDelete,
	} = props;

	const { subjects } = useSelector(state => state.ata);
	const dispatch = useDispatch();

	const initialValues = {
		id: subject ? subject.id : '',
		name: subject ? subject.name : '',
		body: subject ? JSON.parse(subject.body) : {
			entityMap: {},
			blocks: [{
				key: "637gr",
				text: "",
				type: "unstyled",
				depth: 0,
				inlineStyleRanges: [],
				entityRanges: [],
				data: {}
			}]
		},
	};

	const editorInitialState = EditorState.createWithContent(convertFromRaw(initialValues.body));

	const { setToast } = useContext(ToastContext);
	const formRef = useRef(null);

	const [autoSaveIntervalId, setAutoSaveIntervalId] = useState(null);
	const [editorState, setEditorState] = useState(editorInitialState);
	const [attachments, setAttachments] = useState([]);
	const [attachmentDeleting, setAttachmentDeleting] = useState(null);

	const [isAutoSaving, setIsAutoSaving] = useState(false);
	const [isAttachModalVisible, setIsAttachModalVisible] = useState(false);
	const [isloadingAttachments, setIsLoadingAttachments] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [savedMessage, setSavedMessage] = useState('');

	useEffect(() => {
		setEditorState(EditorState.createWithContent(convertFromRaw(initialValues.body)));
	}, [subject]);

	useEffect(async () => {
		if (subject?.id) {
			setIsLoadingAttachments(true);

			try {
				const { data: attachments } = await AtaRoutes.getSubjectAttachments(ataId, subject.id);

				setAttachments(attachments);
			} catch (err) {
				setToast({
					type: 'error',
					title: 'Erro',
					message: 'Um erro inesperado aconteceu ao buscar os anexos',
					show: true,
					autohide: true,
				});
			}

			setIsLoadingAttachments(false);
		}
	}, [subject]);

	useEffect(() => {
		if (subject?.id && !autoSaveIntervalId) {
			const intervalId = setInterval(autoSave, 60000);
			setAutoSaveIntervalId(intervalId);
		}
	});

	useEffect(() => {
		if (!isVisible && autoSaveIntervalId) {
			clearInterval(autoSaveIntervalId);
			setAutoSaveIntervalId(null);
		}
	}, [isVisible])

	async function autoSave() {
		if (formRef?.current?.values.id) {
			const { current: { values } } = formRef;
			setIsAutoSaving(true);

			try {
				await AtaRoutes.updateSubject(ataId, values.id, values);

				setSavedMessage(<>Salvo <i>automaticamente</i> pela última vez às {moment().format('HH:mm:ss')}</>);

				const aux = cloneDeep(subjects);
				const subjectIndex = aux.findIndex(subject => subject.id === values.id);

				aux[subjectIndex] = {
					...values,
					body: JSON.stringify(values.body),
				};

				dispatch({ type: SET_SUBJECTS, data: aux });
			} catch (err) {
				setToast({
					type: 'error',
					title: 'Erro',
					message: 'Um erro inesperado aconteceu ao salvar automaticamente o assunto',
					show: true,
					autohide: true,
				});
			}

			setIsAutoSaving(false);
		}
	}

	function handleEditorStateChange(editorState) {
		setEditorState(editorState);
	}

	function handleEditorContentStateChange(contentState, setFieldValue) {
		setFieldValue('body', contentState);
	}

	function handleCancel() {
		setAttachments([]);
		setSavedMessage(null);
		setEditorState(EditorState.createWithContent(convertFromRaw({
			entityMap: {},
			blocks: [{
				key: "637gr",
				text: "",
				type: "unstyled",
				depth: 0,
				inlineStyleRanges: [],
				entityRanges: [],
				data: {}
			}]
		})));

		if (onCancel)
			onCancel();
	}

	function handleAttachModalSuccess(attachment) {
		const aux = cloneDeep(attachments);
		aux.push(attachment);

		setAttachments(aux);
		setIsAttachModalVisible(false);
	}

	function handleAttachModalCancel() {
		setIsAttachModalVisible(false);
	}

	async function handleSubmit(values, { resetForm }) {
		if (values.id) {
			try {
				await AtaRoutes.updateSubject(ataId, values.id, values);

				setSavedMessage(<>Salvo <i>manualmente</i> pela última vez às {moment().format('HH:mm:ss')}</>);

				if (onSuccess)
					onSuccess(values);
			} catch (err) {
				return setToast({
					type: 'error',
					title: 'Erro',
					message: 'Um erro inesperado aconteceu ao salvar o assunto',
					show: true,
					autohide: true,
				});
			}
		}

		else
			try {
				const { data } = await AtaRoutes.addSubject(ataId, values);

				resetForm();
				setEditorState(editorInitialState);

				if (onSuccess)
					onSuccess(data);
			} catch (err) {
				let message = "Um erro inesperado ocorreu ao cadastrar o assunto";

				if (err?.response)
					message = err.response.data;

				return setToast({
					type: 'error',
					title: 'Erro',
					message,
					show: true,
					autohide: true,
				});
			}
	}

	async function handleDelete(subjectId) {
		if (window.confirm('Tem certeza que deseja excluir este assunto?')) {
			setIsDeleting(true);

			try {
				await AtaRoutes.deleteSubject(ataId, subjectId);

				if (onDelete)
					onDelete(subjectId);

				setAttachments([]);
			} catch (err) {
				setToast({
					type: 'error',
					title: 'Erro',
					message: 'Um erro inesperado ocorreu ao excluir o assunto!',
					show: true,
					autohide: true,
				});
			}

			setIsDeleting(false);
		}
	}

	async function deleteAttachment(attachment) {
		setAttachmentDeleting(attachment);

		if (window.confirm('Tem certeza de que deseja exluir esse anexo?')) {
			try {
				await AtaRoutes.deleteSubjectAttachment(ataId, subject.id, attachment.id);

				const aux = cloneDeep(attachments);
				const index = aux.findIndex(attach => attach.id === attachment.id);
				aux.splice(index, 1);

				setAttachments(aux);
			} catch (err) {
				return setToast({
					type: 'error',
					title: 'Erro',
					message: 'Um erro inesperado ocorreu ao excluir o anexo!',
					show: true,
					autohide: true,
				});
			}
		}

		setAttachmentDeleting(null);
	}

	return (
		<Modal visible={isVisible}
			footer={null}
			width="700px"
			onCancel={handleCancel}
			destroyOnClose>
			<Formik innerRef={formRef}
				enableReinitialize
				initialValues={initialValues}
				onSubmit={handleSubmit}>
				{({ isSubmitting, values, setFieldValue, submitForm }) => {
					return (
						<Form>
							<Row>
								<Col sm={10} md={11}>
									<FormikInput className="mt-4"
										name="name"
										placeholder="Título do assunto"
										labelBackground="#fff"
										title={values.name}
										maxLength={255} />
								</Col>

								<Col className="d-flex justify-content-center align-items-end mb-1"
									sm={2}
									md={1}>
									<PaperClipOutlined style={{
										fontSize: '35px',
										cursor: values.id ? 'pointer' : 'not-allowed',
										color: values.id ? '#000' : '#585858',
									}}
										title={!values.id ? '	Para adicionar anexos em um novo assunto é necessário salvá-lo primeiro' : ''}
										onClick={() => setIsAttachModalVisible(true)}
										disabled={!values.id} />
								</Col>
							</Row>

							<Skeleton loading={isloadingAttachments}
								title={false}
								paragraph={4}>
								<div className="mt-2"
									style={{
										maxHeight: '70px',
										overflowY: 'auto',
									}}>
									{attachments.map(attachment => (
										<div className="d-inline-block">
											{attachmentDeleting && attachmentDeleting.id === attachment.id ?
												<>
													<Spin className="mr-1"
														indicator={<LoadingOutlined style={{ fontSize: '14px' }} />} />

													<span style={{
														textDecoration: 'underline',
														color: '#ccc',
													}}>
														{attachment.name}
													</span>
												</>
												:
												<a href={attachment.url}
													target="_blank"
													style={{
														textDecoration: 'underline',
													}}>
													{attachment.name}
												</a>
											}


											<CloseOutlined className="mr-1"
												title="Excluir anexo"
												style={{
													fontSize: 10,
													verticalAlign: 'text-top',
													cursor: 'pointer',
												}}
												onClick={deleteAttachment.bind(null, attachment)}
												disabled={isSubmitting || attachmentDeleting || isDeleting} />
										</div>
									))}
								</div>
							</Skeleton>

							<div className="mt-4">
								<Editor placeholder="Descrição"
									editorState={editorState}
									onEditorStateChange={handleEditorStateChange}
									onContentStateChange={contentState => handleEditorContentStateChange(contentState, setFieldValue)} />
							</div>

							<small>{savedMessage}</small>

							{!values.id ?
								<Row className="mt-4 d-flex align-items-center flex-column">
									<Button className="button__save default-button"
										size="large"
										onClick={submitForm}
										loading={isSubmitting || isDeleting || attachmentDeleting}>
										{!isSubmitting ? 'Salvar e adicionar' : 'Salvando...'}
									</Button>

									<UnderlinedButton className="mt-2"
										onClick={handleCancel}>
										Cancelar
									</UnderlinedButton>
								</Row>
								:
								<Row className="mt-4 d-flex justify-content-center">
									<Button className="button__save default-button delete-subject-button mr-4"
										size="large"
										onClick={handleDelete.bind(null, values.id)}
										loading={isDeleting}
										withIcon
										icon={<DeleteFilled />}
										disabled={isSubmitting || isAutoSaving || isDeleting || attachmentDeleting}>
										{!isDeleting ? 'Excluir assunto' : 'Excluindo...'}
									</Button>

									<Button className="button__save default-button"
										size="large"
										onClick={submitForm}
										loading={isSubmitting || isAutoSaving}
										disabled={isSubmitting || isAutoSaving || isDeleting || attachmentDeleting}>
										{!isSubmitting ? 'Salvar' : 'Salvando...'}
									</Button>
								</Row>
							}

							<AddAttachmentToSubjectModal isVisible={isAttachModalVisible}
								ataId={ataId}
								subjectId={values.id}
								onSuccess={handleAttachModalSuccess}
								onCancel={handleAttachModalCancel} />
						</Form>
					);
				}}
			</Formik>

		</Modal>
	);
}

export default RegisterSubjectModal;
