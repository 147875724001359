import React from "react";
import "./TooltipImage.css";
import { Image, OverlayTrigger, Tooltip } from "react-bootstrap";

function TooltipImage(props) {
  const { tip, src, placement, width, height } = props;

  return (
    <div>
      <OverlayTrigger
        placement={placement}
        delay={{ show: 50, hide: 50 }}
        overlay={<Tooltip>{tip}</Tooltip>}
      >
        <Image
          className="mr-2"
          width={width}
          height={height}
          src={src}
          roundedCircle
        />
      </OverlayTrigger>
    </div>
  );
}

export default TooltipImage;
