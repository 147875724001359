import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import React from "react";
import { Editor as DraftEditor } from "react-draft-wysiwyg";

function Editor(props) {
	const {
		placeholder,
		placeholderTitle,
		labelBackground,
		placeholderClassName,
		className = '',
		editorClassname = '',
		height = 500,
		...rest
	} = props;

	return (
		<div className={`input-div rounded flex p-2 ${className}`}
			style={{
				height,
			}}>
			<span className={`span-input ${placeholderClassName || ''}`}
				title={placeholderTitle}
				style={{
					backgroundColor: labelBackground ? labelBackground : "white",
				}}>
				{placeholder}
			</span>

			<div className="d-flex align-items-center mt-2">
				<DraftEditor {...rest}
					className={editorClassname}
					editorStyle={{
						height: height - 105,
						overflowX: 'auto',
						maxWidth: 634,
					}} />
			</div>
		</div>
	);
}

export default Editor;
