import "./FormTab.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import moment from "moment";
import { Form, Checkbox, SubmitButton } from "formik-antd";
import { isEqual, cloneDeep, isNull } from "lodash";
import { Button, Image, Skeleton } from "antd";
import { Formik } from "formik";

import InputDatepicker from "~/components/InputDatepicker/InputDatepicker";
import Select from "~/components/Select/Select";
import FieldAttachmentModal from "~/components/FieldAttachmentModal";
import FormikInput from "~/components/Input/FormikInput";
import UploadMidias from "~/components/UploadMidias";
import FieldModal from "~/components/FieldModal";
import {
  taskFieldAttachmentRoutes,
  TaskFieldRoutes,
  ListOptionsRoutes,
} from "~/http/routes";
import { ToastContext } from "~/providers/ToastProvider";
import checkPermission from "~/helpers/checkPermission";
import { CheckPicker } from "rsuite";
import CheckboxButton from "~/components/CheckboxButton/CheckboxButton";
import { wrapUrlInAnchorTagWithIcon } from "~/helpers/stringUrlToLink";

function ImageField(props) {
  const {
    id,
    label,
    attachments,
    subprojectId,
    taskId,
    afterSubmit,
    afterRemove,
  } = props;

  const [attachment, setAttachment] = useState({
    id: "",
    filename: "",
    url: "",
    description: "",
    created_at: "",
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { setToast } = useContext(ToastContext);

  function handleBeforeUpload(file) {
    try {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = () => {
        setAttachment({
          id: "",
          filename: file.name,
          url: reader.result,
          description: "",
          created_at: "",
        });

        setIsModalVisible(true);
      };
    } catch (err) {
      setToast({
        type: "error",
        title: "Erro!",
        message: "Um erro fazer upload da imagem!",
        show: true,
        autohide: true,
      });
    }
  }

  function handlePreview(file) {
    const { id, url, description, created_at } = file;

    setAttachment({
      id,
      url,
      description,
      created_at: moment(created_at).format("DD/MM/YYYY HH:mm:ss"),
    });
    setIsModalVisible(true);
  }

  function handleAfterSubmit(attachment) {
    setIsModalVisible(false);

    if (afterSubmit) afterSubmit(attachment);
  }

  async function handleRemove(file) {
    try {
      if (
        window.confirm(
          "Tem certeza que deseja excluir essa mídia?\n (Ao confirmar essa ação, a mídia será excluída diretamente do banco de dados)",
        )
      ) {
        await taskFieldAttachmentRoutes.delete(
          subprojectId,
          taskId,
          id,
          file.id,
        );

        setToast({
          type: "success",
          title: "Sucesso!",
          message: "Mídia excluída com sucesso!",
          show: true,
          autohide: true,
        });

        if (afterRemove) afterRemove(file);
      }
    } catch (err) {
      setToast({
        type: "error",
        title: "Erro!",
        message: "Um erro ao remover a mídia!",
        show: true,
        autohide: true,
      });
    }
  }

  return (
    <>
      <UploadMidias
        label={label}
        fileList={attachments}
        onRemove={handleRemove}
        beforeUpload={handleBeforeUpload}
        onPreview={handlePreview}
        accept="*"
      />

      <FieldAttachmentModal
        visible={isModalVisible}
        id={attachment.id}
        filename={attachment.filename}
        url={attachment.url}
        description={attachment.description}
        createdAt={attachment.created_at}
        subprojectId={subprojectId}
        taskId={taskId}
        taskFieldId={id}
        onCancel={() => setIsModalVisible(false)}
        afterSubmit={handleAfterSubmit}
      />
    </>
  );
}

const GoogleMapsLink = ({ coordinates }) => {
  const [lat, lng] = coordinates.split(",").map((coord) => coord.trim());

  const url = `https://www.google.com/maps?q=${lat},${lng}`;

  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
        fontSize: "18px",
      }}
    >
      <span
        class="material-icons"
        style={{
          fontSize: "30px",
        }}
      >
        location_on
      </span>
      Clique para ver a localização
    </a>
  );
};

function FormTab(props) {
  const { taskId, subprojectId, afterSave, afterAddField } = props;

  const formRef = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [fields, setFields] = useState([]);
  const [optionList, setOptionList] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [fieldModalIsVisible, setFieldModalIsVisible] = useState(false);
  const { setToast } = useContext(ToastContext);

  useEffect(async () => {
    setIsLoading(true);

    try {
      const res = await TaskFieldRoutes.list(taskId);

      setFields(res.data.data);

      setInitialValues(getInitialValues(res.data.data));
    } catch (err) {
      setToast({
        type: "error",
        title: "Erro!",
        message:
          "Um erro ocorreu ao carregar os campos do formulário da tarefa!",
        show: true,
        autohide: true,
      });
    }

    setIsLoading(false);
  }, []);

  useEffect(() => {
    setOptionList([]);
    async function getData() {
      try {
        fields.map(async (item) => {
          let options = await ListOptionsRoutes.findByTaskId(item.id);
          if (item.form_field_id) {
            options = await ListOptionsRoutes.find(item.form_field_id);
          }
          if (options) {
            setOptionList((prev) => [
              ...prev,
              options.data.data.map((elem) => ({
                value: elem.id,
                name: elem.name,
                form_id: elem.form_field_id,
                task_field_id: elem.task_field_id,
                type_id: 12,
              })),
            ]);
          }
        });
      } catch (err) {
        setToast({
          type: "error",
          title: "Erro!",
          message: "Um erro ocorreu ao buscar a lista de opções!",
          show: true,
          autohide: true,
        });
      }
    }
    getData();
  }, [fields]);

  function getFieldValue(field) {
    const { value, type_id, attachments } = field;

    if (type_id === 7)
      if (value === "1") return true;
      else return false;
    else if (type_id === 8) return attachments;
    else if (value === null) return "";
    else return value;
  }

  function getInitialValues(fields) {
    const initialValues = {};

    fields.forEach((field) => {
      initialValues[field.id] = getFieldValue(field);
    });

    return initialValues;
  }

  async function handleSubmit(values) {
    try {
      const fields = [];

      for (const [key, value] of Object.entries(values)) {
        if (!Array.isArray(value)) {
          let v = value;

          if (typeof value === "boolean") {
            if (value) v = 1;
            else v = 0;
          } else if (!value && value !== 0) v = null;

          fields.push({
            id: key,
            value: v,
          });
        }
      }

      const body = {
        fields,
      };

      await TaskFieldRoutes.updateMany(taskId, body);

      setInitialValues(values);

      setToast({
        type: "success",
        title: "Sucesso!",
        message: "Formulário salvo com sucesso!",
        show: true,
        autohide: true,
      });

      if (afterSave) afterSave();
    } catch (err) {
      setToast({
        type: "error",
        title: "Erro!",
        message: "Um erro ocorreu salvar o formulário!",
        show: true,
        autohide: true,
      });
    }
  }

  function handleValidate(values) {
    const errors = {};

    for (const [key, value] of Object.entries(values)) {
      const field = fields.find((e) => e.id === Number(key));

      /* if (field.type_id !== 7 && field.required && !value && value !== 0)
        errors[key] = "Este campo é obrigatório!";
      else */ if (
        field.type_id === 4 &&
        value &&
        value.replace("_", "").length < 14
      )
        errors[key] = "CPF inválido";
      else if (
        field.type_id === 5 &&
        value &&
        value.replace("_", "").length < 18
      )
        errors[key] = "CNPJ inválido";
      else if (field.type_id === 14) {
        if (value) {
          let emailList = value.split(",");
          emailList.forEach((email) => {
            if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(
                email.replace(" ", ""),
              )
            ) {
              errors[key] = "E-mail inválido";
            }
          });
        }
      }
    }

    return errors;
  }

  function isDirty(values) {
    const current = cloneDeep(values);
    const initial = cloneDeep(initialValues);

    for (const [key, value] of Object.entries(current)) {
      if (Array.isArray(value)) {
        delete current[key];
        delete initial[key];
      } else if (value === "___.___.___-__" || value === "__.___.___/____-__")
        current[key] = "";
    }

    return !isEqual(current, initial);
  }

  function handleNewFieldButtonClick() {
    setFieldModalIsVisible(true);
  }

  function handleFieldModalCancel() {
    setFieldModalIsVisible(false);
  }
  function handleAfterSaveNewField(field, values, setValues) {
    setFields((prev) => [...prev, field]);

    setValues({
      ...values,
      [field.id]: getFieldValue(field),
    });

    setInitialValues({
      ...initialValues,
      [field.id]: getFieldValue(field),
    });

    setFieldModalIsVisible(false);

    if (afterAddField) afterAddField();
  }

  function renderField(field, values, setFieldValue, handleChange) {
    const { id, label, type_id, attachments, form_field_id, required, value } =
      field;

    function handleAfterSubmitAttachment(attachment) {
      const field = values[attachment.task_field_id];
      const attachmentIndex = field.findIndex(
        (e) => e.id === Number(attachment.id),
      );

      if (attachmentIndex === -1) field.push(attachment);
      else field[attachmentIndex] = attachment;

      setFieldValue(attachment.task_field_id, field);
    }

    function handleAfterRemoveAttachment(attachment) {
      const field = values[attachment.task_field_id];
      const attachmentIndex = field.findIndex(
        (e) => e.id === Number(attachment.id),
      );

      field.splice(attachmentIndex, 1);

      setFieldValue(attachment.task_field_id, field);
    }

    function handleDateInputChange(date) {
      date = date ? moment(date).format("YYYY-MM-DD") : null;

      setFieldValue(date);
    }

    function getMask(values) {
      switch (type_id) {
        case 4:
          return "999.999.999-99";

        case 5:
          return "99.999.999/9999-99";

        case 13:
          if (values) {
            let mask =
              values.split("_").length === 2
                ? "(99) 9999-9999"
                : "(99)9 9999-9999";
            return mask;
          }
          return "(99)9 9999-9999";

        default:
          return "";
      }
    }

    switch (type_id) {
      case 6:
        return (
          <div className="mb-4">
            {required ? <span style={{ color: "red" }}>*</span> : ""}
            <InputDatepicker
              label={label}
              name={id}
              placeholder="Selecionar Data"
              labelBackground="white"
              inputReadOnly
              allowClear={false}
              onChange={handleDateInputChange}
            />
          </div>
        );

      case 7:
        return (
          <div className="mb-4">
            {required ? <span style={{ color: "red" }}>*</span> : ""}
            <Checkbox name={id}>{wrapUrlInAnchorTagWithIcon(label)}</Checkbox>
          </div>
        );

      case 8:
        return (
          <div className="mb-4">
            {required ? <span style={{ color: "red" }}>*</span> : ""}
            <ImageField
              id={id}
              label={label}
              attachments={attachments}
              taskId={taskId}
              subprojectId={subprojectId}
              afterSubmit={handleAfterSubmitAttachment}
              afterRemove={handleAfterRemoveAttachment}
            />
          </div>
        );

      case 9:
        return (
          <div className="mb-4">
            {required ? <span style={{ color: "red" }}>*</span> : ""}
            {attachments.length < 1 ? (
              <FormikInput
                inputPlaceholder="Para o preenchimento acesse o aplicativo Pilar.app"
                name={id}
                placeholder={label}
                inputType={
                  type_id === 1 ? "money" : type_id === 3 ? "textArea" : "input"
                }
                type={type_id === 2 ? "number" : "text"}
                readOnly
                placeholderClassName="input-placeholder d-inline-block text-truncate"
                labelBackground="#fff"
              ></FormikInput>
            ) : (
              <>
                <p>{label}</p>

                <Image
                  style={{
                    border: "1px solid",
                    borderRadius: "4px",
                    borderColor: "#CCCC",
                    padding: "10px",
                  }}
                  width="50%"
                  src={attachments[0].url}
                />
              </>
            )}
          </div>
        );

      case 10:
        return (
          <div className="mb-4">
            {required ? <span style={{ color: "red" }}>*</span> : ""}
            {attachments.length < 1 ? (
              <FormikInput
                inputPlaceholder="Para o preenchimento acesse o aplicativo Pilar.app"
                name={id}
                placeholder={label}
                inputType={
                  type_id === 1 ? "money" : type_id === 3 ? "textArea" : "input"
                }
                type={type_id === 2 ? "number" : "text"}
                readOnly
                placeholderClassName="input-placeholder d-inline-block text-truncate"
                labelBackground="#fff"
              ></FormikInput>
            ) : (
              <>
                <p>{label}</p>

                <Image
                  style={{
                    border: "1px solid",
                    borderRadius: "4px",
                    borderColor: "#CCCC",
                    padding: "10px",
                  }}
                  width="50%"
                  src={attachments[0].url}
                />
              </>
            )}
          </div>
        );

      case 11:
        return (
          <div className="mb-4">
            {required ? <span style={{ color: "red" }}>*</span> : ""}
            {isNull(value) ? (
              <FormikInput
                inputPlaceholder="Para obter a geolocalização acesse o aplicativo Pilar.app"
                name={id}
                placeholder={label}
                readOnly
                placeholderClassName="input-placeholder d-inline-block text-truncate"
                labelBackground="#fff"
              ></FormikInput>
            ) : (
              <>
                <p>{label}</p>

                <GoogleMapsLink coordinates={value} />
              </>
            )}
          </div>
        );

      case 12:
        const options = optionList.reduce((list, sub) => list.concat(sub), []);

        const filteredOptions = options.filter(
          (item) =>
            (item.form_id && item.form_id == form_field_id) ||
            (item.task_field_id && item.task_field_id == id),
        );

        return (
          <div className="mb-4">
            {required ? <span style={{ color: "red" }}>*</span> : ""}

            {
              <Select
                key={values[id]}
                placeholder={label}
                id={id}
                value={values[id]}
                name={id}
                handleChange={handleChange}
                inputBackground="transparent"
                labelBackground="#fff"
                inputPlaceholder=""
                options={filteredOptions}
                withNull={true}
              />
            }
          </div>
        );

      case 14:
        return (
          <div className="mb-4">
            {required ? <span style={{ color: "red" }}>*</span> : ""}
            <FormikInput
              placeholder={label}
              name={id}
              placeholderTitle={label}
              inputType={"input"}
              type={"text"}
              mask={getMask()}
              inputPlaceholder="Separe os emails por vírgula ( , )  "
              placeholderClassName="input-placeholder d-inline-block text-truncate"
              labelBackground="#fff"
            />
          </div>
        );

      case 15:
        return (
          <div className="mb-4">
            {required ? <span style={{ color: "red" }}>*</span> : ""}
            <FormikInput
              placeholder={label}
              name={id}
              placeholderTitle={label}
              inputType={"input"}
              type={"text"}
              readOnly
              inputPlaceholder="Campo Bloqueado"
              mask={getMask(values[id])}
              placeholderClassName="input-placeholder d-inline-block text-truncate"
              labelBackground="#fff"
            />
          </div>
        );

      default:
        return (
          <div className="mb-4">
            {required ? <span style={{ color: "red" }}>*</span> : ""}
            <FormikInput
              placeholder={label}
              name={id}
              placeholderTitle={label}
              inputType={
                type_id === 1 ? "money" : type_id === 3 ? "textArea" : "input"
              }
              type={type_id === 2 ? "number" : "text"}
              mask={getMask(values[id])}
              step="0.10"
              placeholderClassName="input-placeholder d-inline-block text-truncate"
              labelBackground="#fff"
            />
          </div>
        );
    }
  }

  return (
    <div style={{ paddingRight: "24px" }}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        innerRef={formRef}
        onSubmit={handleSubmit}
        validate={handleValidate}
      >
        {({ isSubmitting, values, setFieldValue, setValues, handleChange }) => {
          return (
            <Form>
              <Skeleton loading={isLoading} active>
                {fields.map((field) =>
                  renderField(field, values, setFieldValue, handleChange),
                )}
              </Skeleton>

              <Button
                className="w-100 mb-2"
                type="primary"
                onClick={handleNewFieldButtonClick}
                disabled={!checkPermission("criar-template-formulario")}
              >
                Novo campo
              </Button>

              <SubmitButton
                className="btn-default"
                disabled={!isDirty(values) || isSubmitting}
              >
                {!isSubmitting ? "Salvar" : " Salvando..."}
              </SubmitButton>

              <FieldModal
                visible={fieldModalIsVisible}
                taskId={taskId}
                onCancel={handleFieldModalCancel}
                afterSave={(field) =>
                  handleAfterSaveNewField(field, values, setValues)
                }
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default FormTab;
