import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import {
  AntDesignOutlined,
  EditOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import { convertFromRaw, EditorState } from "draft-js";
import { useHistory } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import { CloseButton, Col, Row } from "react-bootstrap";
import { Modal, Table } from "antd";
import { cloneDeep } from "lodash";

import AutomaticNotificationSelect from "~/components/AutomaticNotificationSelect";
import InputDatepicker from "~/components/InputDatepicker/InputDatepicker";
import ModalCreateTask from "../CreateTask/ModalCreateTask";
import RegisterServiceModal from "./RegisterServiceModal";
import RegisterSectorModal from "./RegisterSectorModal";
import Button from "~/components/Button/Button";
import { ToastContext } from "~/providers/ToastProvider";
import { AtaRoutes, TaskRoutes } from "~/http/routes";
import FormikAntdSelect from "../CreateTask/FormikAntdSelect";

const notificationOptions = [
  { daysBefore: 1, notifyOnDue: true },
  { daysBefore: 2, notifyOnDue: true },
  { daysBefore: 3, notifyOnDue: true },
  { daysBefore: 7, notifyOnDue: true },
  { daysBefore: 1, notifyOnDue: false },
  { daysBefore: 2, notifyOnDue: false },
  { daysBefore: 3, notifyOnDue: false },
  { daysBefore: 7, notifyOnDue: false },
];

function Subject(props) {
  const {
    id,
    name,
    body,
    companyId,
    ataId,
    subprojectId,
    participants,
    statuses,
    sectors,
    services,
    loadingUsers,
    loadingSectors,
    loadingServices,
    updateSectors,
    updateServices,
    onNameClick,
    onClick,
    ataIsOpen,
    ...rest
  } = props;

  const { push } = useHistory();
  const { setToast } = useContext(ToastContext);

  const [tasks, setTasks] = useState([]);
  const [loadingTasks, setLoadingTasks] = useState(false);

  const [registerSectorModalIsVisible, setRegisterSectorModalIsVisible] =
    useState(false);
  const [registerServiceModalIsVisible, setRegisterServiceModalIsVisible] =
    useState(false);
  const [editTaskModalIsVisible, setEditTaskModalIsVisible] = useState(false);
  const [taskEditingData, setTaskEditingData] = useState(null);

  useEffect(async () => {
    if (ataId) getTasks();
  }, []);

  useEffect(() => {
    if (ataIsOpen)
      tasksTableColumns.push({
        key: "edit",
        title: "",
        align: "center",
        width: "5%",
        render: ({ id }) => {
          return (
            <EditOutlined
              className="inventory__icons"
              onClick={handleTaskEditClick.bind(null, id)}
            />
          );
        },
      });
  }, [ataIsOpen]);

  const tasksTableColumns = [
    {
      key: "sector",
      title: (
        <>
          Setor
          {ataIsOpen && (
            <PlusCircleFilled
              className="inventory__icons ml-1"
              title="Adicionar novo setor"
              onClick={handleNewSectorClick}
            />
          )}
        </>
      ),
      dataIndex: "sector_id",
      width: "12%",
      render: (value, { id }) => (
        <FormikAntdSelect
          value={value}
          inputBackground="transparent"
          options={sectors.map(({ name, id }) => ({ name, value: id }))}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
          onChange={(val) => handleTaskInputChange(id, "sector_id", val)}
          disabled={!ataIsOpen}
        />
      ),
    },
    {
      key: "responsible",
      title: "Responsável",
      dataIndex: "responsible_id",
      width: "15%",
      render: (value, { id }) => (
        <FormikAntdSelect
          value={value}
          inputBackground="transparent"
          options={participants.map(({ name, id }) => ({ name, value: id }))}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
          onChange={(val) => handleTaskInputChange(id, "responsible_id", val)}
          disabled={!ataIsOpen}
        />
      ),
    },
    {
      key: "service",
      title: (
        <>
          Serviço
          {ataIsOpen && (
            <PlusCircleFilled
              className="inventory__icons ml-1"
              title="Adicionar novo serviço"
              onClick={handleNewServiceClick}
            />
          )}
        </>
      ),
      dataIndex: "service_id",
      width: "15%",
      render: (value, { id }) => (
        <FormikAntdSelect
          value={value}
          inputBackground="transparent"
          options={services.map(({ name, id }) => ({ name, value: id }))}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
          onChange={(val) => handleTaskInputChange(id, "service_id", val)}
          disabled={!ataIsOpen}
        />
      ),
    },
    {
      key: "startDate",
      title: "Data de início",
      dataIndex: "start_date",
      width: "12%",
      render: (value, { id }) => (
        <InputDatepicker
          value={value ? moment(new Date(Number(value))) : value}
          onChange={(date) => handleTaskInputChange(id, "start_date", date)}
          placeholder="Selecionar data"
          disabled={!ataIsOpen}
        />
      ),
    },
    {
      key: "notifyStart",
      title: "Notificar início em:",
      dataIndex: "notify_start",
      width: "12%",
      render: (value, { id }) => (
        <AutomaticNotificationSelect
          value={value ? JSON.stringify(value) : value}
          inputBackground="transparent"
          options={notificationOptions}
          showSearch
          optionFilterProp="value"
          filterOption={(input, option) =>
            option.value.toLowerCase().includes(input.toLowerCase())
          }
          onChange={(value) =>
            handleTaskInputChange(
              id,
              "notify_start",
              value ? JSON.parse(value) : value,
            )
          }
          withNull
          disabled={!ataIsOpen}
        />
      ),
    },
    {
      key: "dueDate",
      title: "Data de entrega",
      dataIndex: "due_date",
      width: "12%",
      render: (value, { id }) => (
        <InputDatepicker
          value={value ? moment(new Date(Number(value))) : value}
          onChange={(date) => handleTaskInputChange(id, "due_date", date)}
          placeholder="Selecionar data"
          disabled={!ataIsOpen}
        />
      ),
    },
    {
      key: "notifyDue",
      title: "Notificar entrega em:",
      dataIndex: "notify_due",
      width: "12%",
      render: (value, { id }) => (
        <AutomaticNotificationSelect
          value={value ? JSON.stringify(value) : value}
          inputBackground="transparent"
          options={notificationOptions}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.value.toLowerCase().includes(input.toLowerCase())
          }
          onChange={(value) =>
            handleTaskInputChange(
              id,
              "notify_due",
              value ? JSON.parse(value) : value,
            )
          }
          withNull
          disabled={!ataIsOpen}
        />
      ),
    },
    {
      key: "statuses",
      title: "Status",
      dataIndex: "status_id",
      width: "15%",
      render: (value, { id }) => (
        <FormikAntdSelect
          value={value}
          inputBackground="transparent"
          options={statuses.map(({ name, id }) => ({ name, value: id }))}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
          onChange={(val) => handleTaskInputChange(id, "status_id", val)}
          disabled={!ataIsOpen}
        />
      ),
    },
    {
      key: "delete",
      title: "Deletar",
      dataIndex: "delete",
      width: "13%",
      render: (value, { id }) => (
        <CloseButton
          inputBackground="transparent"
          onClick={() => handleDeleteTask(id)}
          disabled={!ataIsOpen}
        />
      ),
    },
  ];

  function errorToastr(message) {
    setToast({
      type: "error",
      title: "Erro",
      message,
      show: true,
      autohide: true,
    });
  }

  function successToastr(message) {
    setToast({
      type: "success",
      title: "Sucesso",
      message,
      show: true,
      autohide: true,
    });
  }

  async function getTasks() {
    setLoadingTasks(true);

    try {
      const { data: tasks } = await AtaRoutes.getSubjectTasks(ataId, id);

      setTasks(tasks);
    } catch (err) {
      push(`/home/atas/${subprojectId}`);
      errorToastr(
        "Um erro inesperado ocorreu ao buscar as tarefas dos assuntos!",
      );
    }

    setLoadingTasks(false);
  }

  async function addTask() {
    setLoadingTasks(true);

    try {
      const { data: task } = await AtaRoutes.addTaskToSubject(ataId, id, {
        subproject_id: subprojectId,
        sector_id: sectors[0].id,
        responsible_id: participants[0].id,
        service_id: services[0].id,
        start_date: Date.parse(new Date()),
        due_date: null,
        notify_before_start: null,
        notify_before_due: null,
        notify_at_start: null,
        notify_at_due: null,
      });

      const aux = cloneDeep(tasks);

      aux.push({
        id: task.id,
        sector_id: task.sector_id,
        responsible_id: task.responsible_id,
        service_id: task.service_id,
        start_date: task.start_date,
        due_date: task.due_date,
        notify_start: task.notify_before_start
          ? {
              daysBefore: task.notify_before_start,
              notifyOnDue: task.notify_at_start,
            }
          : null,
        notify_due: task.notify_before_due
          ? {
              daysBefore: task.notify_before_due,
              notifyOnDue: task.notify_at_due,
            }
          : null,
      });

      setTasks(aux);

      successToastr("Tarefa adicionada com sucesso!");
    } catch (err) {
      if (err.response)
        if (Array.isArray(err.response.data))
          errorToastr(err.response.data[0].message);
        else errorToastr(err.response.data.message);
      else
        errorToastr("Um erro inesperado ocorreu. Por favor, tente novamente!");
    }

    setLoadingTasks(false);
  }

  async function handleTaskInputChange(taskId, field, value) {
    const aux = cloneDeep(tasks);
    const task = aux.find((task) => task.id === taskId);
    const taskIndex = aux.indexOf(task);
    let columns = {};

    if (field === "start_date" || field === "due_date")
      value = Date.parse(value);

    task[field] = value;
    aux[taskIndex] = task;

    if (field === "notify_start") {
      columns.notify_before_start = value.daysBefore;
      columns.notify_at_start = value.notifyOnDue;
    } else if (field === "notify_due") {
      columns.notify_before_due = value.daysBefore;
      columns.notify_at_due = value.notifyOnDue;
    } else columns[field] = value;

    try {
      await AtaRoutes.updateSubjectTask(ataId, id, task.id, columns);
      successToastr("Tarefa alterada com sucesso!");
    } catch (err) {
      if (err.response)
        if (Array.isArray(err.response.data))
          return errorToastr(err.response.data[0].message);
        else return errorToastr(err.response.data.message);
      else
        return errorToastr(
          "Um erro inesperado ocorreu. Por favor, tente novamente!",
        );
    }

    setTasks(aux);
  }

  async function handleTaskEditClick(taskId) {
    try {
      const {
        data: { data: task },
      } = await TaskRoutes.getTask(subprojectId, taskId);

      setTaskEditingData(task);

      setEditTaskModalIsVisible(true);
    } catch (err) {
      errorToastr("Um erro inesperado ocorreu ao abrir a tarefa para edição!");
    }
  }

  function handleEditTaskModalClose() {
    setTaskEditingData(null);
    setEditTaskModalIsVisible(false);
  }

  function handleNewSectorClick() {
    setRegisterSectorModalIsVisible(true);
  }

  function handleNewServiceClick() {
    setRegisterServiceModalIsVisible(true);
  }

  async function handleDeleteTask(taskId) {
    setLoadingTasks(true);
    try {
      await TaskRoutes.deleteTask(ataId, id, taskId);
      successToastr("Tarefa excluída com sucesso!");
      getTasks();
    } catch (err) {
      errorToastr("Um erro inesperado ocorreu ao excluir a tarefa!");
    }
    setLoadingTasks(false);
  }

  function handleRegisterSectorModalSuccess(sector) {
    const aux = cloneDeep(sectors);
    aux.push(sector);

    if (updateSectors) updateSectors(aux);
    setRegisterSectorModalIsVisible(false);
  }

  function handleRegisterServiceModalSuccess(service) {
    const aux = cloneDeep(services);
    aux.push(service);

    if (updateServices) updateServices(aux);

    setRegisterServiceModalIsVisible(false);
  }

  function handleClick() {
    if (onClick) onClick();

    if (!name && onNameClick) onNameClick();
  }

  return (
    <div
      {...rest}
      className="input-div rounded flex p-2"
      style={{
        minHeight: "40px",
        cursor: ataIsOpen && !name ? "pointer" : "default",
      }}
      onClick={ataIsOpen ? handleClick : null}
      disabled={!ataIsOpen}
    >
      <span
        className="span-input"
        style={{
          backgroundColor: "#fafafa",
          fontSize: 14,
          fontWeight: "bold",
          cursor: ataIsOpen ? "pointer" : "default",
        }}
        onClick={ataIsOpen ? onNameClick : null}
        disabled={!ataIsOpen}
      >
        {name}
      </span>

      <div className="d-flex align-items-center">
        <Editor
          editorState={EditorState.createWithContent(
            convertFromRaw(JSON.parse(body)),
          )}
          toolbarHidden
          readOnly
        />
      </div>

      {tasks.length > 0 && (
        <Table
          dataSource={tasks}
          columns={tasksTableColumns}
          loading={loadingTasks}
          pagination={{
            pageSize: 5,
          }}
        />
      )}

      {ataIsOpen && (
        <>
          <Row className="mt-4 d-flex justify-content-end">
            <Col sm={12} md={2}>
              <Button
                className="dark-button"
                onClick={addTask}
                disabled={
                  sectors.length === 0 ||
                  participants.length === 0 ||
                  services.length === 0
                }
              >
                Adicionar tarefa
              </Button>
            </Col>
          </Row>
          <div className="d-flex justify-content-end">
            {sectors.length === 0 && (
              <small style={{ color: "#585858" }}>
                Para adicionar tarefas é necessário ter setores vinculados à
                disciplina.
              </small>
            )}
            {participants.length === 0 && (
              <small style={{ color: "#585858" }}>
                Para adicionar tarefas é necessário ter participantes na ata.
              </small>
            )}
            {services.length === 0 && (
              <small style={{ color: "#585858" }}>
                Para adicionar tarefas é necessário ter serviços vinculados à
                disciplina.
              </small>
            )}
          </div>
        </>
      )}

      <RegisterSectorModal
        isVisible={registerSectorModalIsVisible}
        companyId={companyId}
        subprojectId={subprojectId}
        ataId={ataId}
        onCancel={() => setRegisterSectorModalIsVisible(false)}
        onSuccess={handleRegisterSectorModalSuccess}
      />

      <RegisterServiceModal
        isVisible={registerServiceModalIsVisible}
        companyId={companyId}
        subprojectId={subprojectId}
        ataId={ataId}
        onCancel={() => setRegisterServiceModalIsVisible(false)}
        onSuccess={handleRegisterServiceModalSuccess}
      />

      <Modal
        title="Cadastro de tarefa"
        className="modal-projects"
        visible={editTaskModalIsVisible}
        width="60%"
        centered
        onCancel={handleEditTaskModalClose}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
      >
        <ModalCreateTask
          taskEditingData={taskEditingData}
          refreshList={getTasks}
          handleClose={handleEditTaskModalClose}
          titleDisabled
        />
      </Modal>
    </div>
  );
}

export default Subject;
