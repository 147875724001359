import React from 'react';
import { Card, Skeleton, Typography, Tag, Tooltip } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

function CalcCard(props) {
	const {
		title,
		quantity,
		measureUnit,
		description,
		tag,
		inform,
		loading,
	} = props;

	function renderTag() {
		if (tag)
			return (
				<Tag color="default"
					style={{ borderRadius: '25px' }}>{tag}</Tag>
			);

		return <></>;
	}

	function renderInformIcon() {
		if (inform)
			return (
				<Tooltip title={inform}>
					<QuestionCircleFilled style={{ fontSize: '25px' }} />
				</Tooltip>
			);

		return <></>;
	}

	function renderTitle() {
		if (title)
			return (
				<Title className="text-center"
					level={3}>
					{title}
				</Title>
			);

		return <div style={{ height: '44px' }} />;
	}

	function renderQuantity() {
		if (quantity)
			return (
				<Title className="text-center mt-0 mb-1">
					{quantity}
				</Title>
			);

		return <></>;
	}

	function renderMeasureUnit() {
		if (measureUnit)
			return (
				<Title className="text-center mt-0"
					level={3}>
					{measureUnit}
				</Title>
			);

		return <></>;
	}

	function renderDescription() {
		if (description)
			return (
				<Paragraph className="text-center">
					{description}
				</Paragraph>
			);

		return <></>;
	}

	return (
		<Card className="shadow"
			style={{ height: '100%' }}
			bodyStyle={{ borderRadius: '10px', height: '100%' }}
			bordered={false}>
			<Skeleton loading={loading}
				active>
				<div className="d-flex justify-content-end">
					{renderTag()}
					{renderInformIcon()}
				</div>

				{renderTitle()}
				{renderQuantity()}
				{renderMeasureUnit()}
				{renderDescription()}
			</Skeleton>
		</Card>
	);
}

export default CalcCard;
