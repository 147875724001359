import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox } from "antd";

import { SET_FILED_TASKS } from "~/redux/actions/DatailedReportFiltersActions";
import { SET_DELETED_TASKS } from "~/redux/actions/DatailedReportFiltersActions";

function Others() {
  const dispatch = useDispatch();
  const { filedTasks } = useSelector((state) => state.detailedReportFilters);
  const { deletedTasks } = useSelector((state) => state.detailedReportFilters);

  function handleCheckFiledTasks() {
    dispatch({ type: SET_FILED_TASKS, data: !filedTasks });
  }

  function handleCheckDeletedTasks() {
    dispatch({ type: SET_DELETED_TASKS, data: !deletedTasks });
  }

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <Checkbox checked={filedTasks} onChange={handleCheckFiledTasks}>
            Tarefas arquivadas
          </Checkbox>
          <Checkbox checked={deletedTasks} onChange={handleCheckDeletedTasks}>
            Tarefas deletadas
          </Checkbox>
        </div>
      </div>
    </>
  );
}

export default Others;
