import React from "react";
import { Upload } from "antd";
import { ExclamationCircleOutlined, UploadOutlined } from "@ant-design/icons";

function UploadMidias(props) {
  const { label, fileList, maxCount, onPreview, ...rest } = props;

  async function handlePreview(file) {
    if (onPreview) return onPreview(file);

    let src = file.url;

    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }

    const image = new Image();
    image.src = src;

    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  }

  return (
    <>
      <p>
        {label}

        {maxCount ? (
          <small className="ml-2">{`(Máximo ${maxCount} arquivos)`}</small>
        ) : (
          <></>
        )}
      </p>

      <Upload
        {...rest}
        accept={rest.accept ? rest.accept : "image/png, image/gif, image/jpeg"}
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        maxCount={maxCount}
      >
        <div className="upload-file">
          {maxCount && fileList.length === maxCount ? (
            <>
              <ExclamationCircleOutlined className="upload-icon" />
              <p>
                {`Limite de upload de arquivos atingido! (Máximo ${maxCount})`}
              </p>
            </>
          ) : (
            <>
              <UploadOutlined className="upload-icon" />
              <p>Clique ou arraste e solte para adicionar</p>
            </>
          )}
        </div>
      </Upload>
    </>
  );
}

export default UploadMidias;
