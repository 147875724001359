import "./TaskHistory.css";

import { List, Tooltip } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import ToastComponent from "~/components/ToastComponent/ToastComponent";
import { LogsRoutes } from "~/http/routes";
import { convertToBrazilTime } from "~/util/date";

function TaskHistory(props) {
  const { data, taskId } = props;

  const [logList, setLogList] = useState([]);
  const [reload, setReload] = useState(false);
  const [error, setError] = useState({ hasError: false, message: "" });

  const handleClose = () => {
    setError({ message: "", hasError: false });
  };

  useEffect(() => {
    const getList = async () => {
      const res = await LogsRoutes.getHistoricLogsByTaskId(taskId);
      setLogList(res.data.data);
    };
    getList();
  }, [taskId]);

  let itemsList = [];

  logList.forEach((log, index) => {
    const {
      action,
      subproject_id,
      subproject_name,
      old_subproject_name,
      name,
      old_name,
      responsible_name,
      old_responsible_name,
      description,
      status_name,
      old_status_name,
      priority_name,
      old_priority_name,
      start_date,
      old_start_date,
      due_date,
      old_due_date,
      inventory_name,
      old_inventory_name,
      sector_name,
      old_sector_name,
      observer_name,
      service_name,
      old_service_name,
      comment,
      task_id,
      user,
      created_at,
      fields,
    } = log;
    switch (action) {
      case "subproject_id":
        itemsList.push({
          key: index,
          data: log,
          label: (
            <div style={{ display: "flex" }}>
              <span
                className="material-icons icons mr-4"
                style={{
                  backgroundColor: "#47b2ef50",
                  color: "#2D9CDB",
                  borderRadius: "100%",
                  padding: "1%",
                  height: "41.54px",
                }}
              >
                workspaces
              </span>
              <div>
                <b>Subprojeto</b> da Tarefa <b>{task_id}</b> alterado de{" "}
                <b>{old_subproject_name}</b> para{" "}
                <b style={{ color: "#2D9CDB" }}>{subproject_name}</b> por{" "}
                <b style={{ fontWeight: "bold", color: "#FFE300" }}>{user}</b>
                <span
                  className="notification-date"
                  style={{ color: "#c3c3c3" }}
                >
                  {convertToBrazilTime(created_at)}
                </span>
                .
              </div>
            </div>
          ),
        });
        break;
      case "name":
        itemsList.push({
          key: index,
          data: log,
          label: (
            <div style={{ display: "flex" }}>
              <span
                className="material-icons icons mr-4"
                style={{
                  backgroundColor: "#47b2ef50",
                  color: "#2D9CDB",
                  borderRadius: "100%",
                  padding: "1%",
                  height: "41.54px",
                }}
              >
                title
              </span>
              <div>
                <b>Título</b> da Tarefa <b>{task_id}</b> alterado de{" "}
                <b>
                  "<Tooltip title={old_name}>{old_name.slice(0, 30)}</Tooltip>
                  {old_name.length > 30 ? "..." : ""}"
                </b>{" "}
                para{" "}
                <b style={{ color: "#2D9CDB" }}>
                  " <Tooltip title={name}>{name.slice(0, 30)}</Tooltip>
                  {name.length > 30 ? "..." : ""}"
                </b>{" "}
                por{" "}
                <b style={{ fontWeight: "bold", color: "#FFE300" }}>{user}</b>{" "}
                <span
                  className="notification-date"
                  style={{ color: "#c3c3c3" }}
                >
                  {convertToBrazilTime(created_at)}
                </span>
                .
              </div>
            </div>
          ),
        });
        break;
      case "responsible_id":
        itemsList.push({
          key: index,
          data: log,
          label: (
            <div style={{ display: "flex" }}>
              <span
                className="material-icons icons mr-4"
                style={{
                  backgroundColor: "#47b2ef50",
                  color: "#2D9CDB",
                  borderRadius: "100%",
                  padding: "1%",
                  height: "41.54px",
                }}
              >
                account_circle
              </span>
              <div>
                <b>Responsável</b> da Tarefa <b>{task_id}</b> alterado de{" "}
                <b>{old_responsible_name}</b> para{" "}
                <b style={{ color: "#2D9CDB" }}>{responsible_name}</b> por{" "}
                <b style={{ fontWeight: "bold", color: "#FFE300" }}>{user}</b>
                <span
                  className="notification-date"
                  style={{ color: "#c3c3c3" }}
                >
                  {convertToBrazilTime(created_at)}
                </span>
                .
              </div>
            </div>
          ),
        });
        break;
      case "status_id":
        itemsList.push({
          key: index,
          data: log,
          label: (
            <div style={{ display: "flex" }}>
              <span
                className="material-icons icons mr-4"
                style={{
                  backgroundColor: "#47b2ef50",
                  color: "#2D9CDB",
                  borderRadius: "100%",
                  padding: "1%",
                  height: "41.54px",
                }}
              >
                view_kanban
              </span>
              <div>
                <b>Status</b> da Tarefa <b>{task_id}</b> alterada de{" "}
                <b>{old_status_name}</b> para{" "}
                <b style={{ color: "#2D9CDB" }}>{status_name}</b> por{" "}
                <b style={{ fontWeight: "bold", color: " #FFE300" }}>{user}</b>
                <span
                  className="notification-date"
                  style={{ color: "#c3c3c3" }}
                >
                  {convertToBrazilTime(created_at)}
                </span>
                .
              </div>
            </div>
          ),
        });
        break;
      case "priority_id":
        itemsList.push({
          key: index,
          data: log,
          label: (
            <div style={{ display: "flex" }}>
              <span
                className="material-icons icons mr-4"
                style={{
                  backgroundColor: "#47b2ef50",
                  color: "#2D9CDB",
                  borderRadius: "100%",
                  padding: "1%",
                  height: "41.54px",
                }}
              >
                priority_high
              </span>
              <div>
                <b>Prioridade</b> da Tarefa <b>{task_id}</b> alterada de{" "}
                <b>{old_priority_name}</b> para{" "}
                <b style={{ color: "#2D9CDB" }}>{priority_name}</b> por{" "}
                <b style={{ fontWeight: "bold", color: " #FFE300" }}>{user}</b>
                <span
                  className="notification-date"
                  style={{ color: "#c3c3c3" }}
                >
                  {convertToBrazilTime(created_at)}
                </span>
                .
              </div>
            </div>
          ),
        });
        break;
      case "description":
        itemsList.push({
          key: index,
          data: log,
          label: (
            <div style={{ display: "flex" }}>
              <span
                className="material-icons icons mr-4"
                style={{
                  backgroundColor: "#47b2ef50",
                  color: "#2D9CDB",
                  borderRadius: "100%",
                  padding: "1%",
                  height: "41.54px",
                }}
              >
                description
              </span>
              <div>
                <b>Descrição</b> da tarefa <b>{task_id}</b> atualizada para{" "}
                <i style={{ color: "#2D9CDB", fontWeight: "bold" }}>
                  "
                  <Tooltip title={description}>
                    {description.slice(0, 70)}
                  </Tooltip>
                  {description.length > 70 ? "..." : ""}"
                </i>{" "}
                por{" "}
                <b style={{ fontWeight: "bold", color: " #FFE300" }}>{user}</b>
                <span
                  className="notification-date"
                  style={{ color: "#c3c3c3" }}
                >
                  {convertToBrazilTime(created_at)}
                </span>
                .
              </div>
            </div>
          ),
        });
        break;
      case "comment":
        itemsList.push({
          key: index,
          data: log,
          label: (
            <div style={{ display: "flex" }}>
              <span
                className="material-icons icons mr-4"
                style={{
                  backgroundColor: "#4aef4750",
                  color: "#18ae16",
                  borderRadius: "100%",
                  padding: "1%",
                  height: "41.54px",
                }}
              >
                comment
              </span>
              <div>
                <b>Comentário</b> adicionado na tarefa <b>{task_id}</b>
                {": "}
                <i style={{ color: "#2D9CDB", fontWeight: "bold" }}>
                  "{" "}
                  <Tooltip title={`${comment}`}>{comment.slice(0, 70)}</Tooltip>
                  {comment.length > 70 ? "..." : ""}"
                </i>{" "}
                por{" "}
                <b style={{ fontWeight: "bold", color: " #FFE300" }}>{user}</b>
                <span
                  className="notification-date"
                  style={{ color: "#c3c3c3" }}
                >
                  {convertToBrazilTime(created_at)}
                </span>
                .
              </div>
            </div>
          ),
        });
        break;
      case "due_date":
        itemsList.push({
          key: index,
          data: log,
          label: (
            <div style={{ display: "flex" }}>
              <span
                className="material-icons icons mr-4"
                style={{
                  backgroundColor: "#47b2ef50",
                  color: "#2D9CDB",
                  borderRadius: "100%",
                  padding: "1%",
                  height: "41.54px",
                }}
              >
                event
              </span>
              <div>
                <b>Data de entrega</b> da tarefa <b>{task_id}</b> alterado{" "}
                {old_due_date && (
                  <>
                    de{" "}
                    <b>
                      {moment(new Date(parseInt(old_due_date))).format(
                        "DD/MM/YY - HH:mm",
                      )}
                    </b>{" "}
                  </>
                )}
                para{" "}
                <b style={{ color: "#2D9CDB" }}>
                  {" "}
                  {moment(new Date(parseInt(due_date))).format(
                    "DD/MM/YY - HH:mm",
                  )}
                </b>{" "}
                por{" "}
                <b style={{ fontWeight: "bold", color: " #FFE300" }}>{user}</b>
                <span
                  className="notification-date"
                  style={{ color: "#c3c3c3" }}
                >
                  {convertToBrazilTime(created_at)}
                </span>
                .
              </div>
            </div>
          ),
        });
        break;
      case "start_date":
        itemsList.push({
          key: index,
          data: log,
          label: (
            <div style={{ display: "flex" }}>
              <span
                className="material-icons icons mr-4"
                style={{
                  backgroundColor: "#47b2ef50",
                  color: "#2D9CDB",
                  borderRadius: "100%",
                  padding: "1%",
                  height: "41.54px",
                }}
              >
                today
              </span>
              <div>
                <b>Data de início</b> da tarefa <b>{task_id}</b> alterado{" "}
                {old_start_date && (
                  <>
                    de{" "}
                    <b>
                      {moment(new Date(parseInt(old_start_date))).format(
                        "DD/MM/YY - HH:mm",
                      )}
                    </b>{" "}
                  </>
                )}
                para{" "}
                <b style={{ color: "#2D9CDB" }}>
                  {moment(new Date(parseInt(start_date))).format(
                    "DD/MM/YY - HH:mm",
                  )}
                </b>{" "}
                por{" "}
                <b style={{ fontWeight: "bold", color: " #FFE300" }}>{user}</b>
                <span
                  className="notification-date"
                  style={{ color: "#c3c3c3" }}
                >
                  {convertToBrazilTime(created_at)}
                </span>
                .
              </div>
            </div>
          ),
        });
        break;
      case "created_task":
        itemsList.push({
          key: index,
          data: log,
          label: (
            <div style={{ display: "flex" }}>
              <span
                className="material-icons icons mr-4"
                style={{
                  backgroundColor: "#4aef4750",
                  color: "#18ae16",
                  borderRadius: "100%",
                  padding: "1%",
                  height: "41.54px",
                }}
              >
                add_circle
              </span>
              <div>
                <b>Tarefa</b> <b style={{ color: "#2D9CDB" }}>{name}</b> com ID{" "}
                <b style={{ color: "#2D9CDB" }}>{task_id}</b> <b>adicionada</b>{" "}
                ao Subprojeto <b>{subproject_name}</b> por{" "}
                <b style={{ fontWeight: "bold", color: " #FFE300" }}>{user}</b>
                <span
                  className="notification-date"
                  style={{ color: "#c3c3c3" }}
                >
                  {convertToBrazilTime(created_at)}
                </span>
                .
              </div>
            </div>
          ),
        });
        break;
      case "deleted_task":
        itemsList.push({
          key: index,
          data: log,
          label: (
            <div style={{ display: "flex" }}>
              <span
                className="material-icons icons mr-4"
                style={{
                  backgroundColor: "#f44e4e89",
                  color: "#ab0e0e",
                  borderRadius: "100%",
                  padding: "1%",
                  height: "41.54px",
                }}
              >
                delete
              </span>
              <div>
                <b>Tarefa</b> com ID{" "}
                <b style={{ color: "#2D9CDB" }}>{task_id}</b> <b>removida</b> do
                Subprojeto <b>{subproject_name}</b> por{" "}
                <b style={{ fontWeight: "bold", color: " #FFE300" }}>{user}</b>
                <span
                  className="notification-date"
                  style={{ color: "#c3c3c3" }}
                >
                  {convertToBrazilTime(created_at)}
                </span>
                .
              </div>
            </div>
          ),
        });
        break;

      case "created_attachment_task":
        itemsList.push({
          key: index,
          data: log,
          label: (
            <div style={{ display: "flex" }}>
              <span
                className="material-icons icons mr-4"
                style={{
                  backgroundColor: "#4aef4750",
                  color: "#18ae16",
                  borderRadius: "100%",
                  padding: "1%",
                  height: "41.54px",
                }}
              >
                attach_file
              </span>
              <div>
                <b>Anexo</b> com nome{" "}
                <b style={{ color: "#2D9CDB" }}>{fields}</b> adicionado à tarefa{" "}
                <b>{task_id}</b>
                por{" "}
                <b style={{ fontWeight: "bold", color: " #FFE300" }}>{user}</b>
                <span
                  className="notification-date"
                  style={{ color: "#c3c3c3" }}
                >
                  {convertToBrazilTime(created_at)}
                </span>
                .
              </div>
            </div>
          ),
        });
        break;
      case "deleted_attachment_task":
        itemsList.push({
          key: index,
          data: log,
          label: (
            <div style={{ display: "flex" }}>
              <span
                className="material-icons icons mr-4"
                style={{
                  backgroundColor: "#f44e4e89",
                  color: "#ab0e0e",
                  borderRadius: "100%",
                  padding: "1%",
                  height: "41.54px",
                }}
              >
                attachment
              </span>
              <div>
                <b>Anexo</b> excluído da tarefa <b>{task_id}</b>
                por{" "}
                <b style={{ fontWeight: "bold", color: " #FFE300" }}>{user}</b>
                <span
                  className="notification-date"
                  style={{ color: "#c3c3c3" }}
                >
                  {convertToBrazilTime(created_at)}
                </span>
                .
              </div>
            </div>
          ),
        });
        break;
      case "created_task_field":
        itemsList.push({
          key: index,
          data: log,
          label: (
            <div style={{ display: "flex" }}>
              <span
                className="material-icons icons mr-4"
                style={{
                  backgroundColor: "#4aef4750",
                  color: "#18ae16",
                  borderRadius: "100%",
                  padding: "1%",
                  height: "41.54px",
                }}
              >
                checklist_rtl
              </span>{" "}
              <div>
                <b>Formulário</b> com etiqueta{" "}
                <b style={{ color: "#2D9CDB" }}>{fields}</b> adicionado à tarefa{" "}
                <b>{task_id}</b> por{" "}
                <b style={{ fontWeight: "bold", color: " #FFE300" }}>{user}</b>
                <span
                  className="notification-date"
                  style={{ color: "#c3c3c3" }}
                >
                  {convertToBrazilTime(created_at)}
                </span>
                .
              </div>
            </div>
          ),
        });
        break;
      case "updated_task_field":
        itemsList.push({
          key: index,
          data: log,
          label: (
            <div style={{ display: "flex" }}>
              <span
                className="material-icons icons mr-4"
                style={{
                  backgroundColor: "#47b2ef50",
                  color: "#2D9CDB",
                  borderRadius: "100%",
                  padding: "1%",
                  height: "41.54px",
                }}
              >
                edit_note
              </span>
              <div>
                <b>Formulário</b> alterado na tarefa <b>{task_id}</b> por{" "}
                <b style={{ fontWeight: "bold", color: " #FFE300" }}>{user}</b>
                <span
                  className="notification-date"
                  style={{ color: "#c3c3c3" }}
                >
                  {convertToBrazilTime(created_at)}
                </span>
                .
              </div>
            </div>
          ),
        });
        break;
      case "inventory_id":
        itemsList.push({
          key: index,
          data: log,
          label: (
            <div style={{ display: "flex" }}>
              <span
                className="material-icons icons mr-4"
                style={{
                  backgroundColor: "#47b2ef50",
                  color: "#2D9CDB",
                  borderRadius: "100%",
                  padding: "1%",
                  height: "41.54px",
                }}
              >
                inventory_2
              </span>
              <div>
                <b>Inventário</b> da Tarefa <b>{task_id}</b> alterada de{" "}
                <b>{old_inventory_name}</b> para{" "}
                <b style={{ color: "#2D9CDB" }}>{inventory_name}</b> por{" "}
                <b style={{ fontWeight: "bold", color: " #FFE300" }}>{user}</b>
                <span
                  className="notification-date"
                  style={{ color: "#c3c3c3" }}
                >
                  {convertToBrazilTime(created_at)}
                </span>
                .
              </div>
            </div>
          ),
        });
        break;
      case "sector_id":
        itemsList.push({
          key: index,
          data: log,
          label: (
            <div style={{ display: "flex" }}>
              <span
                className="material-icons icons mr-4"
                style={{
                  backgroundColor: "#47b2ef50",
                  color: "#2D9CDB",
                  borderRadius: "100%",
                  padding: "1%",
                  height: "41.54px",
                }}
              >
                info
              </span>
              <div>
                <b>Setor</b> da Tarefa <b>{task_id}</b> alterada de{" "}
                <b>{old_sector_name}</b> para{" "}
                <b style={{ color: "#2D9CDB" }}>{sector_name}</b> por{" "}
                <b style={{ fontWeight: "bold", color: " #FFE300" }}>{user}</b>
                <span
                  className="notification-date"
                  style={{ color: "#c3c3c3" }}
                >
                  {convertToBrazilTime(created_at)}
                </span>
                .
              </div>
            </div>
          ),
        });
        break;

      case "service_id":
        itemsList.push({
          key: index,
          data: log,
          label: (
            <div style={{ display: "flex" }}>
              <span
                className="material-icons icons mr-4"
                style={{
                  backgroundColor: "#47b2ef50",
                  color: "#2D9CDB",
                  borderRadius: "100%",
                  padding: "1%",
                  height: "41.54px",
                }}
              >
                info
              </span>
              <div>
                <b>Serviço</b> da Tarefa <b>{task_id}</b> alterada de{" "}
                <b>{old_service_name}</b> para{" "}
                <b style={{ color: "#2D9CDB" }}>{service_name}</b> por{" "}
                <b style={{ fontWeight: "bold", color: " #FFE300" }}>{user}</b>
                <span
                  className="notification-date"
                  style={{ color: "#c3c3c3" }}
                >
                  {convertToBrazilTime(created_at)}
                </span>
                .
              </div>
            </div>
          ),
        });
        break;
      case "observer_added":
        itemsList.push({
          key: index,
          data: log,
          label: (
            <div style={{ display: "flex" }}>
              <span
                className="material-icons icons mr-4"
                style={{
                  backgroundColor: "#4aef4750",
                  color: "#18ae16",
                  borderRadius: "100%",
                  padding: "1%",
                  height: "41.54px",
                }}
              >
                visibility
              </span>
              <div>
                <b>Observador</b> de nome{" "}
                <b style={{ color: "#2D9CDB" }}>{observer_name}</b> adicionado à
                Tarefa <b>{task_id}</b> por{" "}
                <b style={{ fontWeight: "bold", color: " #FFE300" }}>{user}</b>
                <span
                  className="notification-date"
                  style={{ color: "#c3c3c3" }}
                >
                  {convertToBrazilTime(created_at)}
                </span>
                .
              </div>
            </div>
          ),
        });
        break;
      case "observer_removed":
        itemsList.push({
          key: index,
          data: log,
          label: (
            <div style={{ display: "flex" }}>
              <span
                className="material-icons icons mr-4"
                style={{
                  backgroundColor: "#f44e4e89",
                  color: "#ab0e0e",
                  borderRadius: "100%",
                  padding: "1%",
                  height: "41.54px",
                }}
              >
                visibility_off
              </span>
              <div>
                <b>Observador</b> de nome{" "}
                <b style={{ color: "#2D9CDB" }}>{observer_name}</b> removido da
                Tarefa <b>{task_id}</b>
                por{" "}
                <b style={{ fontWeight: "bold", color: " #FFE300" }}>{user}</b>
                <span
                  className="notification-date"
                  style={{ color: "#c3c3c3" }}
                >
                  {convertToBrazilTime(created_at)}
                </span>
                .
              </div>
            </div>
          ),
        });
        break;

      default:
        break;
    }
  });

  let items = [];

  items = itemsList;

  if (itemsList.length < 1) {
    items.push({
      key: 0,
      label: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: "1.1rem",
            marginTop: "10px",

            color: "#c2c2c2",
          }}
        >
          <span className="material-icons icons mr-2">done_all</span> Sem
          notificações para exibir :D
        </div>
      ),
    });
  }

  const reloadListView = async () => {
    try {
      setReload(() => true);
    } catch (error) {
      setError({ message: error.message, hasError: true });
    }
  };

  return (
    <>
      <ToastComponent
        delay="5000"
        title="Ops! Houve um erro"
        description={error.message}
        type="error"
        showToast={error.hasError}
        handleClose={handleClose}
      />
      <div className="d-flex justify-content-between mb-3 "></div>
      <div className="">
        <List
          className=""
          itemLayout="vertical"
          size="large"
          dataSource={items}
          footer={<div></div>}
          renderItem={(item) => (
            <List.Item
              className="row-item"
              //onClick={() => handleRowClick(item.data)}
              key={item.key}
            >
              {item.label}
            </List.Item>
          )}
        />
      </div>
    </>
  );
}

export default TaskHistory;
