import React, { useEffect, useState } from "react";
import { Collapse, Table } from "antd";

const columns = [
  {
    key: "id",
    title: "Código",
    dataIndex: "id",
  },
  {
    key: "name",
    title: "Nome",
    dataIndex: "name",
  },
];

const CollapsibleSubItems = ({
  title,
  onSearch,
  selections = [],
  handleSelections,
}) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    onSearch().then(({ data }) => setOptions(data.data));
  }, []);

  const dataSource = options.map((option) => ({ ...option, key: option.id }));

  return (
    <Collapse style={{ marginTop: 15 }}>
      <Collapse.Panel header={title}>
        <Table
          columns={columns}
          dataSource={dataSource}
          rowSelection={{
            type: "checkbox",
            selectedRowKeys: selections,
            onChange: handleSelections,
          }}
        />
      </Collapse.Panel>
    </Collapse>
  );
};

export default CollapsibleSubItems;
