import React, { useState } from "react";
import { starIcon } from "../../assets/svg/Icons";
import "./RateTask.css";
import { Rate } from "antd";
import CheckPermission from "../CheckPermission";
import checkPermission from "~/helpers/checkPermission";

function RateTask(props) {
	const { changeTaskValue, taskId, stars } = props;

	const [starValue, setStarValue] = useState(0);
	const [showRating, setShowRating] = useState(0);

	const handleRateTask = () => {
		setShowRating(() => !showRating);
	};

	const handleChange = (value) => {
		setStarValue(() => value);
	};

	const handleRateConfirm = async () => {
		changeTaskValue();
		try {
			const res = await changeTaskValue("stars", starValue, taskId, true);
			// console.log({ res });
		} catch (err) {
			console.log({ err });
		}
	};

	const desc = ["Péssimo", "Ruim", "Normal", "Bom", "Excelente"];

	if (!stars && !checkPermission('avaliar-tarefa'))
		return <></>;

	return (
		<div className="rate-task d-flex">
			{showRating ? (
				<div className="star-content d-flex flex-column align-items-center justify-content-center">
					<Rate tooltips={desc} onChange={handleChange} value={starValue} />
					{starValue ? (
						<>
							<p className="ant-rate-text">{desc[starValue - 1]}</p>
							<button
								className="confirm-rate-button"
								onClick={handleRateConfirm}
							>
								Confirmar nota
							</button>
						</>
					) : (
						""
					)}
				</div>
			) : (
				""
			)}
			{stars ? (
				<div className="w-100 bg-transparent p-0 d-flex justify-content-center align-items-center">
					{[...Array(stars).keys()].map((i) => (
						<span className="pl-2 mr-3" key={i}>
							{starIcon}
						</span>
					))}
				</div>
			) : (
				<CheckPermission slug="avaliar-tarefa">
					<button
						className="w-100 bg-transparent p-0 d-flex justify-content-center align-items-center"
						onClick={handleRateTask}
					>
						<span className="pl-2 mr-3">{starIcon}</span>
						<span>Avaliar tarefa</span>
					</button>
				</CheckPermission>
			)}
		</div>
	);
}

export default RateTask;
