import { defineState } from 'redux-localstore';

import {
    ATA_CLEAR_FILTERS,
    ATA_SET_INITIAL_CREATION_DATE,
    ATA_SET_FINAL_CREATION_DATE,
    ATA_SET_INITIAL_FINISHED_DATE,
    ATA_SET_FINAL_FINISHED_DATE,
    ATA_SET_FILTERS_PROJECTS,
    ATA_SET_FILTERS_PARTICIPANTS,
    ATA_SET_GROUP_BY,
} from "../actions/AtaReportFilters";

function clearOnlyFilters() {
    return {
        initialCreationDate: null,
        finalCreationDate: null,
        initialFinishedDate: null,
        finalFinishedDate: null,
        projects: [],
        participants: [],
    };
}

const initialState = defineState({
    initialCreationDate: null,
    finalCreationDate: null,
    initialFinishedDate: null,
    finalFinishedDate: null,
    projects: [],
    participants: [],
    groupBy: '',
})("ataReportFilters");

function AtaReportFiltersReducer(state = initialState, action) {
    switch (action.type) {
        case ATA_CLEAR_FILTERS:
            return { ...state, ...clearOnlyFilters() };
        case ATA_SET_INITIAL_CREATION_DATE:
            return { ...state, initialCreationDate: action.data };
        case ATA_SET_FINAL_CREATION_DATE:
            return { ...state, finalCreationDate: action.data };
        case ATA_SET_INITIAL_FINISHED_DATE:
            return { ...state, initialFinishedDate: action.data };
        case ATA_SET_FINAL_FINISHED_DATE:
            return { ...state, finalFinishedDate: action.data };
        case ATA_SET_FILTERS_PROJECTS:
            return { ...state, projects: action.data };
        case ATA_SET_FILTERS_PARTICIPANTS:
            return { ...state, participants: action.data };
        case ATA_SET_GROUP_BY:
            return { ...state, groupBy: action.data };
        default:
            return state;
    }
}

export default AtaReportFiltersReducer;
