import React, { useCallback, useContext, useEffect, useState } from 'react';
import { EditOutlined, SearchOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Table, Input } from 'antd';

import Breadcrumb from '~/components/Breadcrumb/Breadcrumb';
import Button from '~/components/Button/Button';
import Icon from '~/components/Icon/Icon';
import { ToastContext } from '~/providers/ToastProvider';
import { userSelector } from '~/redux/Selectors';
import { UserRoutes } from '~/http/routes';

function UserList() {
	const { push } = useHistory();
	const { companyId } = useSelector(userSelector);
	const { setToast } = useContext(ToastContext);

	const [loading, setLoading] = useState(false);
	const [searchFilterValue, setSearchFilterValue] = useState('');
	const [users, setUsers] = useState([]);

	useEffect(async () => {
		setLoading(true);

		try {
			const { data } = await UserRoutes.getCompanyUsersShort(companyId);
			const users = data.data

			users.sort(function (a, b) {
				if (a.name < b.name) { return -1; }
				if (a.name > b.name) { return 1; }
				return 0;
			})

			setUsers(users);
		} catch (err) {
			setToast({
				type: 'error',
				title: 'Erro',
				message: 'Um erro ocorreu durante a listagem dos usuários.',
				show: true,
				autohide: true,
			});
		}

		setLoading(false);
	}, [companyId]);

	function handleInputChange(e) {
		setSearchFilterValue(e.target.value);
	};

	const filterList = useCallback(() => {
		return users.filter(value => (
			(value.name && value.name.toLowerCase().includes(searchFilterValue.toLowerCase())) ||
			(value.email && value.email.toLowerCase().includes(searchFilterValue.toLowerCase()))
		))
	}, [searchFilterValue, users])

	const columns = [
		{
			key: "name",
			title: "Nome",
			dataIndex: "name",
			width: "30%",
		},
		{
			key: "email",
			title: "E-mail",
			dataIndex: "email",
			width: "30%"
		},
		{
			key: "role",
			title: "Papel",
			dataIndex: "role",
			width: "15%"
		},
		{
			key: "stars",
			title: "Avaliação",
			dataIndex: "stars",
			width: "15%",
			render: stars => {
				return (
					<div className="d-flex align-items-center">
						<Icon style={{ color: stars > 0 ? '#ffe300' : '#585858' }}>
							star
						</Icon>
						{stars}
					</div>
				);
			},
		},
		{
			key: "edit",
			title: "Editar",
			align: "center",
			width: "10%",
			render: ({ id }) => {
				return (
					<EditOutlined className="inventory__icons"
						onClick={() => push(`/home/users/invite/${id}`)} />
				);
			},
		},
	]

	return (
		<div>
			<Breadcrumb title="Usuários"
				routeToBack="/home/dashboard" />

			<div className="inventory__filters">
				<Input value={searchFilterValue}
					onChange={handleInputChange}
					className="inventory__filters--search"
					size="large"
					placeholder="Pesquise por nome, e-mail"
					prefix={<SearchOutlined />} />

				<Link to="/home/users/invite/create">
					<Button size="large" withIcon >
						Novo Cadastro
					</Button>
				</Link>
			</div>
			{
				loading ||
				<div>
					<Table columns={columns}
						dataSource={filterList()} />
				</div>
			}
		</div>
	);
}

export default UserList;
