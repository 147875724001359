const ATA_CLEAR_FILTERS = 'ATA_CLEAR_FILTERS';
const ATA_SET_INITIAL_CREATION_DATE = 'ATA_SET_INITIAL_CREATION_DATE';
const ATA_SET_FINAL_CREATION_DATE = 'ATA_SET_FINAL_CREATION_DATE';
const ATA_SET_INITIAL_FINISHED_DATE = 'ATA_SET_INITIAL_FINISHED_DATE';
const ATA_SET_FINAL_FINISHED_DATE = 'ATA_SET_FINAL_FINISHED_DATE';
const ATA_SET_FILTERS_PROJECTS = 'ATA_SET_FILTERS_PROJECTS';
const ATA_SET_FILTERS_PARTICIPANTS = 'ATA_SET_FILTERS_PARTICIPANTS';
const ATA_SET_GROUP_BY = 'ATA_SET_GROUP_BY';

export {
    ATA_CLEAR_FILTERS,
    ATA_SET_INITIAL_CREATION_DATE,
    ATA_SET_FINAL_CREATION_DATE,
    ATA_SET_INITIAL_FINISHED_DATE,
    ATA_SET_FINAL_FINISHED_DATE,
    ATA_SET_FILTERS_PROJECTS,
    ATA_SET_FILTERS_PARTICIPANTS,
    ATA_SET_GROUP_BY,
};
