import React, { useEffect, useState } from "react";
import { EditOutlined, SearchOutlined } from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import { Input, Table } from "antd";

import Breadcrumb from "~/components/Breadcrumb/Breadcrumb";
import Button from "~/components/Button/Button";
import { formRoutes } from "~/http/routes";
import CheckPermission from "~/components/CheckPermission";
import checkPermission from "~/helpers/checkPermission";
import { useSelector } from "react-redux";
import { userSelector } from "~/redux/Selectors";

function FormTemplates() {
  const { push } = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [searched, setSearched] = useState("");
  const [forms, setForms] = useState([]);
  const [filteredForms, setFilteredForms] = useState([]);
  const userStore = useSelector(userSelector);

  useEffect(async () => {
    setIsLoading(true);

    try {
      const res = await formRoutes.listByCompanyId(userStore.companyId);

      setForms(res.data.data);
      setFilteredForms(res.data.data);
    } catch (err) {
      console.log("err:", err);
    }

    setIsLoading(false);
  }, []);

  const columns = [
    {
      title: "Título",
      dataIndex: "description",
      width: "90%",
    },
    {
      title: "Editar",
      align: "center",
      render: ({ id }) => {
        return (
          <EditOutlined
            className="inventory__icons"
            onClick={() => push(`/home/forms/${id}`)}
          />
        );
      },
    },
  ];

  function handleSearchInputChange(e) {
    const { value } = e.target;

    const regex = new RegExp(value, "i");
    setSearched(value);
    setFilteredForms(
      forms.filter(({ description }) => regex.test(description)),
    );
  }

  return (
    <div>
      <Breadcrumb title="Listagem de formulários" routeToBack="/home" />

      <div className="row mt-4">
        <div
          className={
            checkPermission("criar-template-formulario")
              ? "col-sm-12 col-md-8 col-lg-10"
              : "col-sm-12"
          }
        >
          <Input
            value={searched}
            size="large"
            placeholder="Pesquisa por nome"
            prefix={<SearchOutlined />}
            onChange={handleSearchInputChange}
          />
        </div>
        <CheckPermission slug="criar-template-formulario">
          <div className="col-sm-12 col-md-4 col-lg-2">
            <Link to="/home/forms/create">
              <Button className="w-100" size="large" withIcon>
                Novo Cadastro
              </Button>
            </Link>
          </div>
        </CheckPermission>
      </div>

      <Table
        dataSource={filteredForms}
        columns={columns}
        loading={isLoading}
        locale={{ emptyText: "Sem dados para exibir" }}
      />
    </div>
  );
}

export default FormTemplates;
