import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import React, { useContext, useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
import { Formik } from "formik";
import "./CreateTask.css";
import Select from "../../components/Select/Select";
import AxiosInstance from "../../http/Axios";
import { useSelector } from "react-redux";
import { userSelector } from "../../redux/Selectors";
import { Form } from "formik-antd";
import InputDatepicker from "../../components/InputDatepicker/InputDatepicker";
import { UploadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import FormikInput from "~/components/Input/FormikInput";
import { TaskRoutes } from "~/http/routes/";
import { ToastContext } from "~/providers/ToastProvider";
import FormikSwitchCheckbox from "~/components/SwitchCheckbox/FormikSwitchCheckbox";
import { Upload } from 'antd';

function CreateTask() {
  const userStore = useSelector(userSelector);
  const [services, setServices] = useState([]);
  const [inventory, setInventory] = useState([]);
  // const [fields, setFields] = useState([]);
  const [sector, setSector] = useState([]);
  const [users, setUsers] = useState([]);
  const [status, setStatus] = useState([]);
  const { setToast } = useContext(ToastContext);
  const [arrayFiles, setArrayFiles] = useState([]);
  const [priorities, setPriorities] = useState();

  const successfulTaskRegister = () => {
    setToast({
      message: "Tarefa cadastrada com sucesso!",
      show: true,
      title: "Sucesso!",
      type: "success",
    });
  }

  const submitFiles = (id, setSubmitting, resetForm) => {

    arrayFiles.forEach(async (elem, index) => {

      try {
        const bodyFormData = new FormData();
        bodyFormData.append("attachment", elem.originFileObj);

        await AxiosInstance.post(
          userStore.subprojectId + "/task/" + id + "/attachment",
          bodyFormData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          },
        );
      } catch (err) {
        setToast({
          message:
            "Sua tarefa foi cadastrada com sucesso, porém ocorreu um erro no cadastro dos seus anexos!",
          show: true,
          type: "warning",
          title: "Atenção!",
          autohide: false
        });

        let changedArrayOfFiles = [...arrayFiles]

        changedArrayOfFiles[index] = {
          ...elem,
          status: 'error'
        }

        setArrayFiles(changedArrayOfFiles)
        console.warn(err);
      }
    });
    successfulTaskRegister();
    setArrayFiles([]);
    setSubmitting(false);
  };

  const handleFormSubmit = (values, { setSubmitting, resetForm }) => {
    const dueDate = new Date(values.due_date.split("/").reverse().join("-"));
    const startDate = new Date(
      values.start_date.split("/").reverse().join("-"),
    );
    // const fieldsSelected = fields.filter(
    //   (elem) => parseInt(elem.value) === parseInt(values.fields),
    // );
    const objectValues = {
      ...values,
      due_date: dueDate.getTime() + 10800000,
      start_date: startDate.getTime() + 10800000,
      inventory_id: values.inventory_id ? [parseInt(values.inventory_id)] : [],
      sector_id: values.sector_id ? [parseInt(values.sector_id)] : [],
      service_id: values.service_id ? [parseInt(values.service_id)] : [],
      observers_id: values.observers_id ? [parseInt(values.observers_id)] : []
      // fields: fieldsSelected[0].fields
    };
    AxiosInstance.post(userStore.subprojectId + "/task", objectValues)
      .then((res) => {
        if (arrayFiles) {
          submitFiles(res.data.data.id, setSubmitting, resetForm);
        } else {
          successfulTaskRegister();
        }
        resetForm(res.data.data);
      })
      .catch((err) => {
        setToast({
          message: err.message,
          show: true,
          type: "error",
          title: "Erro!",
        });
        setSubmitting(false);
      });
  };

  useEffect(() => {
    AxiosInstance.get(userStore.companyId + "/service").then((res) => {
      setServices(
        res.data.data.map((elem) => ({ value: elem.id, name: elem.name })),
      );
    });
    AxiosInstance.get(userStore.companyId + "/inventory").then((res) => {
      setInventory(
        res.data.data.map((elem) => ({ value: elem.id, name: elem.name })),
      );
    });
    AxiosInstance.get("/user?disabled=false").then((res) => {
      setUsers(
        res.data.data.map((elem) => ({ value: elem.id, name: elem.name })),
      );
    });
    AxiosInstance.get(userStore.companyId + "/statuses").then((res) => {
      setStatus(
        res.data.data.map((elem) => ({ value: elem.id, name: elem.name })),
      );
    });
    AxiosInstance.get(userStore.companyId + "/sector").then((res) => {
      setSector(
        res.data.data.map((elem) => ({ value: elem.id, name: elem.name })),
      );
    });
    // AxiosInstance.get("/task/form/").then((res) => {
    //   setFields(
    //     res.data.data.map((elem) => ({
    //       value: elem.id,
    //       name: elem.description,
    //       fields: elem.fields,
    //     })),
    //   );
    // });

    TaskRoutes.getPriorities().then(res => {
      const priorities = res.data.data.map(priority => (
        {
          ...priority,
          value: priority.id,
        }
      ))
      setPriorities(priorities);
    });
  }, [userStore.companyId]);

  const formValidation = (values) => {
    const errors = {};

    if (!values.start_date) {
      errors.start_date = "Campo obrigatório!";
    }

    if (!values.name) {
      errors.name = "Campo obrigatório!";
    }

    if (!values.status_id) {
      errors.status_id = "Campo obrigatório!";
    }

    // if (!values.due_date) {
    //   errors.due_date = "Campo obrigatório!";
    // }

    return errors;
  };

  const onChange = ({ fileList: newFileList }) => {
    setArrayFiles(newFileList);
  };

  const onPreview = async file => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  return (
    <div>
      <div className="mt-2 mb-4">
        <Breadcrumb
          title="Cadastro de tarefa"
          routeToBack="/home/projects/kanban"
        />
      </div>
      <Formik
        initialValues={{
          name: "",
          signature_required: false,
          checkin_required: false,
          description: "",
          permission: "",
          priority_id: 0,
          inventory_id: [],
          sector_id: [],
          service_id: [],
          members_id: [],
          due_date: "",
          due_date_time: true,
          start_date: "",
          start_date_time: true,
          priority: "",
          responsible_id: null,
          check_required_custom_fields: true,
          notify_all: true,
          check_in: "",
          check_out: "",
          worked_time: 0,
          fields: [],
          status_id: null,
        }}
        onSubmit={handleFormSubmit}
        validate={formValidation}
      >
        {({ values, handleChange, isSubmitting, errors, touched }) => (
          <Form className="w-100">
            <div className="header-form-create-task">
              <div>
                <FormikInput placeholder="Título" name="name" />
              </div>
              <FormikSwitchCheckbox
                id="signature_required"
                name="signature_required"
                label="Assinatura"
              />
              <FormikSwitchCheckbox
                id="checkin_required"
                name="checkin_required"
                label="Check-In/Out"
              />
              {/*<SwitchCheckbox checked={values.checkin_required}*/}
              {/*                id="checkin_required"*/}
              {/*                label="Recorrência"*/}
              {/*                onChange={handleChange}*/}
              {/*/>*/}
            </div>
            <div className="mt-4">
              <FormikInput
                placeholder="Descrição"
                name="description"
                inputType="textArea"
              />
            </div>

            <div
              className="mt-4"
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: "12px",
              }}
            >
              <Select
                placeholder="Serviço"
                id="service_id"
                errors={errors.service_id}
                touched={touched.service_id}
                value={values.service_id}
                handleChange={handleChange}
                inputBackground="transparent"
                labelBackground="#fafafa"
                inputPlaceholder=""
                options={services}
                withNull={true}
              />
              <Select
                placeholder="Prioridade"
                id="priority_id"
                errors={errors.priority_id}
                touched={touched.priority_id}
                value={values.priority_id}
                handleChange={handleChange}
                inputBackground="transparent"
                labelBackground="#fafafa"
                inputPlaceholder=""
                options={priorities}
              />
              <Select
                placeholder="Inventário"
                id="inventory_id"
                errors={errors.inventory_id}
                touched={touched.inventory_id}
                value={values.inventory_id}
                handleChange={handleChange}
                inputBackground="transparent"
                labelBackground="#fafafa"
                inputPlaceholder=""
                options={inventory}
                withNull={true}
              />
            </div>

            <div
              className="mt-4"
              style={{
                display: "grid",
                gridTemplateColumns: "2fr 1fr 1fr 2fr",
                gap: "12px",
              }}
            >
              <Select
                placeholder="Localização"
                id="sector_id"
                errors={errors.sector_id}
                touched={touched.sector_id}
                value={values.sector_id}
                handleChange={handleChange}
                inputBackground="transparent"
                labelBackground="#fafafa"
                inputPlaceholder=""
                options={sector}
                withNull={true}
              />
              <div>
                <InputDatepicker
                  name="start_date"
                  errors={errors.start_date}
                  touched={touched.start_date}
                  label="Início"
                  placeholder="Selecionar Data"
                />
              </div>
              <InputDatepicker
                name="due_date"
                errors={errors.due_date}
                touched={touched.due_date}
                label="Entrega"
                placeholder="Selecionar Data"
              />
              <Select
                placeholder="Responsável"
                id="responsible_id"
                errors={errors.responsible_id}
                touched={touched.responsible_id}
                value={values.responsible_id}
                handleChange={handleChange}
                inputBackground="transparent"
                labelBackground="#fafafa"
                inputPlaceholder=""
                options={users}
              />
            </div>

            <div
              className="mt-4"
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: "12px",
              }}
            >
              <Select
                placeholder="Observadores"
                id="observers_id"
                errors={errors.observers_id}
                touched={touched.observers_id}
                value={values.observers_id}
                handleChange={handleChange}
                inputBackground="transparent"
                labelBackground="#fafafa"
                inputPlaceholder=""
                options={users}
                withNull={true}
              />
              <div>
                <Select
                  placeholder="Status"
                  id="status_id"
                  errors={errors.status_id}
                  touched={touched.status_id}
                  value={values.status_id}
                  handleChange={handleChange}
                  inputBackground="transparent"
                  labelBackground="#fafafa"
                  inputPlaceholder=""
                  options={status}
                  withNull={true}
                />
              </div>
              {/* <Select
                  placeholder="Checklist"
                  id="fields"
                  // errors={errors.fields}
                  // touched={touched.fields}
                  // value={values.fields}
                  // handleChange={handleChange}
                  inputBackground="transparent"
                  labelBackground="#fafafa"
                  inputPlaceholder=""
                  options={fields}
                  withNull={true}
                /> */}
            </div>
            <div className="mt-2">
              <p>Adicionar mídia
                <small>
                  {' '}(Máximo 2 arquivos)
                </small>
              </p>
              <div className="d-flex">
                <Upload
                  className="upload-file"
                  accept="image/png, image/gif, image/jpeg"
                  listType="picture-card"
                  fileList={arrayFiles}
                  onChange={onChange}
                  onPreview={onPreview}
                  beforeUpload={() => (false)}
                  disabled={arrayFiles.length >= 2}
                >
                  <div className="upload-file">
                    {arrayFiles.length < 2 ?
                      (
                        <>
                          <UploadOutlined className="upload-icon" />
                          <p>Clique ou arraste e solte para adicionar</p>
                        </>
                      )
                      : (
                        <>
                          <ExclamationCircleOutlined className="upload-icon" />
                          <p>
                            Limite de upload de arquivos atingido! (Máximo 2)
                          </p>
                        </>

                      )}
                  </div>
                </Upload>
              </div>
            </div>
            <div className="div-button-submit mt-5">
              <button
                className="btn-default"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <BeatLoader
                    color="#000000"
                    loading={isSubmitting}
                    size={10}
                  />
                ) : (
                  "Salvar"
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div >
  );
}

export default CreateTask;
