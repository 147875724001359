import { defineState } from "redux-localstore";

import {
  CLEAR_FILTERS,
  SET_FILTERS_INVENTORIES,
  SET_FILTERS_PROJECTS,
  SET_FILTERS_SECTORS,
  SET_FILTERS_SERVICES,
  SET_FILTERS_USERS,
  SET_FILTERS_STATUS,
  SET_FINAL_CREATION_DATE,
  SET_FINAL_DELIVERY_DATE,
  SET_FINAL_TASK_BEGIN_DATE,
  SET_FINAL_TASK_END_DATE,
  SET_FORM_FIELDS,
  SET_GROUP_BY,
  SET_INITIAL_CREATION_DATE,
  SET_INITIAL_DELIVERY_DATE,
  SET_INITIAL_TASK_BEGIN_DATE,
  SET_INITIAL_TASK_END_DATE,
  SET_FILED_TASKS,
  SET_DELETED_TASKS,
} from "../actions/DatailedReportFiltersActions";

function clearOnlyFilters() {
  return {
    initialCreationDate: null,
    finalCreationDate: null,
    initialTaskBeginDate: null,
    finalTaskBeginDate: null,
    initialDeliveryDate: null,
    finalDeliveryDate: null,
    initialTaskEndDate: null,
    finalTaskEndDate: null,
    filedTasks: false,
    deletedTasks: false,
    projects: [],
    sectors: [],
    services: [],
    inventories: [],
    users: [],
    statuses: [],
  };
}

const initialState = defineState({
  initialCreationDate: null,
  finalCreationDate: null,
  initialTaskBeginDate: null,
  finalTaskBeginDate: null,
  initialDeliveryDate: null,
  finalDeliveryDate: null,
  initialTaskEndDate: null,
  finalTaskEndDate: null,
  filedTasks: false,
  deletedTasks: false,
  projects: [],
  sectors: [],
  services: [],
  inventories: [],
  users: [],
  statuses: [],
  groupBy: "",
  formFields: [],
})("detailedReportFilters");

function DetailedReportFiltersReducer(state = initialState, action) {
  switch (action.type) {
    case CLEAR_FILTERS:
      return { ...state, ...clearOnlyFilters() };
    case SET_INITIAL_CREATION_DATE:
      return { ...state, initialCreationDate: action.data };
    case SET_FINAL_CREATION_DATE:
      return { ...state, finalCreationDate: action.data };
    case SET_INITIAL_TASK_BEGIN_DATE:
      return { ...state, initialTaskBeginDate: action.data };
    case SET_FINAL_TASK_BEGIN_DATE:
      return { ...state, finalTaskBeginDate: action.data };
    case SET_INITIAL_DELIVERY_DATE:
      return { ...state, initialDeliveryDate: action.data };
    case SET_FINAL_DELIVERY_DATE:
      return { ...state, finalDeliveryDate: action.data };
    case SET_INITIAL_TASK_END_DATE:
      return { ...state, initialTaskEndDate: action.data };
    case SET_FINAL_TASK_END_DATE:
      return { ...state, finalTaskEndDate: action.data };
    case SET_FILTERS_PROJECTS:
      return { ...state, projects: action.data };
    case SET_FILTERS_SECTORS:
      return { ...state, sectors: action.data };
    case SET_FILTERS_SERVICES:
      return { ...state, services: action.data };
    case SET_FILTERS_INVENTORIES:
      return { ...state, inventories: action.data };
    case SET_FILTERS_USERS:
      return { ...state, users: action.data };
    case SET_FILTERS_STATUS:
      return { ...state, statuses: action.data };
    case SET_GROUP_BY:
      return { ...state, groupBy: action.data };
    case SET_FORM_FIELDS:
      return { ...state, formFields: action.data };
    case SET_FILED_TASKS:
      return { ...state, filedTasks: action.data };
    case SET_DELETED_TASKS:
      return { ...state, deletedTasks: action.data };
    default:
      return state;
  }
}

export default DetailedReportFiltersReducer;
