import React from "react";
import "./Header.css";

// import SearchBar from "../SearchBar/SearchBar";
import ProjectDropdown from "../ProjectDropdown/ProjectDropdown";
import UserMenu from "../UserMenu/UserMenu";
import { useSelector } from "react-redux";
import { userSelector } from "../../redux/Selectors";

function Header() {
  const userStore = useSelector(userSelector);

  return (
    <nav className="header-container p-4">
      {/*  <div className="d-flex align-items-center">
        <SearchBar placeholder="Para onde você quer ir? (CTRL + B)" />
      </div> */}
      <div>
        <ProjectDropdown projects={userStore.projectInfos} />
      </div>
      <div>
        <UserMenu />
      </div>
    </nav>
  );
}

export default Header;
