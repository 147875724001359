'use strict';

import expirationInDays from "./expirationInDays";

function isOverdue(startDate, deliveryDate) {
	if (!startDate)
		return false;

	if (deliveryDate)
		return false;

	return expirationInDays(deliveryDate) < 0;
}

export default isOverdue;
