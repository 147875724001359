import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Divider } from 'antd';
import { isEqual } from 'lodash';

import { ATA_SET_FILTERS_PROJECTS } from '~/redux/actions/AtaReportFilters';

function Projects() {
	const dispatch = useDispatch();
	const { projectInfos } = useSelector(state => state.userStore);
	const { projects } = useSelector(state => state.ataReportFilters);

	const [projectsMap, setProjectsMap] = useState([]);

	useEffect(() => {
		const aux = [];

		for (const project of projectInfos) {
			aux.push({
				id: project.id,
				subProjects: project.subProjects.map(e => e.id),
			});
		}

		setProjectsMap(aux);
	}, []);

	function setProjects(e) {
		dispatch({ type: ATA_SET_FILTERS_PROJECTS, data: e });
	}

	function handleCheck(project, subProjects) {
		const aux = projects;
		const index = aux.findIndex(e => e.id == project.id);

		if (aux[index]?.subProjects.length === subProjects.length)
			aux.splice(index, 1);
		else {
			if (index > -1)
				aux[index].subProjects = subProjects;
			else
				aux.push({
					id: project.id,
					subProjects,
				});
		}

		setProjects(aux);
	}

	function handleCheckAll(e) {
		if (e.target.checked)
			setProjects(projectsMap);
		else
			setProjects([]);
	}

	return (
		<>
			<div className="row">
				<div className="col-sm-12">
					<Checkbox checked={isEqual(projects, projectsMap)}
						indeterminate={!isEqual(projects, projectsMap)}
						onChange={handleCheckAll}>
						Marcar todos
					</Checkbox>
				</div>

				<Divider />
			</div>

			<div>
				{projectInfos.map((e, i) => {
					const options = e.subProjects.map(sp => {
						return { label: sp.name, value: sp.id }
					});

					const values = projects.find(p => p.id == e.id)?.subProjects || [];

					const isAllChecked = options.length == values.length;

					return (
						<div key={i}>
							<Checkbox checked={isAllChecked}
								indeterminate={!isAllChecked}
								onChange={handleCheck.bind(null, e, e.subProjects.map(sp => sp.id))}>{e.name}</Checkbox>

							<Divider />

							<div className="ml-5">
								<Checkbox.Group options={options} value={values} onChange={sp => handleCheck(e, sp)} />
							</div>

							<Divider />
						</div>
					)
				})}
			</div>
		</>
	);
}

export default Projects;
