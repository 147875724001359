import { useHistory } from "react-router-dom";
import { Dropdown, Image } from "react-bootstrap";
import "./UserMenu.css";
import { useSelector } from "react-redux";
import { userSelector } from "../../redux/Selectors";
import { LoginRoutes } from "../../http/routes";
import { useDispatch } from "react-redux";
import imagePlaceHolder from "../../assets/avatar-placeholder.png";
import NotificationDropdown from "./NotificationDropdown";

function UserMenu(props) {
  const userStore = useSelector(userSelector);
  const dispatch = useDispatch();
  const { push } = useHistory();

  const handlePerfilButton = () => {
    push("/home/user/profile");
  };

  const logout = () => {
    window.localStorage.clear();
    dispatch({ type: "SET_SUBPROJECT_ID", data: null });
    dispatch({ type: "SET_COMPANY_ID", data: null });
    dispatch({ type: "SET_AUTH_FALSE" });
    window.location.reload();
  };

  const handleSairButton = () => {
    LoginRoutes.logout()
      .then((res) => {
        logout();
      })
      .catch((err) => {
        console.error({ err });
        logout();
      });
  };

  // const testeUsers = [403, 10, 11, 112, 432];

  // const permission = testeUsers.includes(userStore.id);

  const companies = userStore.companies ? userStore.companies : [];

  const userCompanies = companies.map((company) => ({
    id: company.id,
    name: company.name,
  }));

  const handleChangeCompanyButton = (id) => {
    window.localStorage.clear();
    dispatch({ type: "SET_COMPANY_ID", data: id });
    dispatch({ type: "SET_SUBPROJECT_ID", data: null });

    if (window.location.pathname === "/home/dashboard")
      window.location.reload();
    else push("/home/dashboard");
  };

  const userMenuItems = [
    {
      name: "Perfil",
      onClick: handlePerfilButton,
      icon: (
        <span
          style={{ fontSize: "20px" }}
          className="material-icons-outlined mr-2"
        >
          person
        </span>
      ),
    },
    {
      name: "Sair",
      onClick: handleSairButton,
      icon: (
        <span
          style={{ fontSize: "20px", paddingLeft: "2px" }}
          className="material-icons mr-2"
        >
          logout
        </span>
      ),
    },
  ];

  function handleUserLogoClick() {
    push("/home/user/profile");
  }

  return (
    <div className="d-flex align-items-center justify-content-end">
      <NotificationDropdown />
      <div className="d-flex flex-row">
        <Image
          className="mr-2"
          width="40"
          height="40"
          roundedCircle
          src={
            userStore.userPhoto && !userStore.userPhoto.includes("null")
              ? userStore.userPhoto
              : imagePlaceHolder
          }
          style={{ cursor: "pointer" }}
          onClick={handleUserLogoClick}
        />

        <Dropdown variant="Secondary">
          <Dropdown.Toggle
            variant="Secondary"
            id="dropdown-basic"
            style={{ boxShadow: "none" }}
          >
            {userStore.userName}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {userMenuItems.map((menuItem) => (
              <Dropdown.Item
                className="d-flex"
                key={menuItem.name}
                onClick={menuItem.onClick}
              >
                {menuItem.icon}
                {menuItem.name}
              </Dropdown.Item>
            ))}
            {userCompanies.length > 1 && (
              <>
                <Dropdown.Divider />
                <Dropdown.Header>Todas as Empresas</Dropdown.Header>
                {userCompanies.map((company) => {
                  const isCurrentCompany = company.id === userStore.companyId;
                  return (
                    <Dropdown.Item
                      className="d-flex"
                      key={company.id}
                      id={company.id}
                      onClick={
                        isCurrentCompany
                          ? null
                          : () => handleChangeCompanyButton(company.id)
                      }
                      style={{
                        pointerEvents: isCurrentCompany ? "none" : "auto",
                      }}
                    >
                      {!isCurrentCompany ? (
                        <span
                          style={{ fontSize: "20px", color: "gray" }}
                          className="material-icons-outlined mr-2"
                        >
                          radio_button_unchecked
                        </span>
                      ) : (
                        <span
                          style={{ fontSize: "20px", color: "#2D9CDB" }}
                          className="material-icons-outlined mr-2"
                        >
                          radio_button_checked
                        </span>
                      )}

                      <span
                        style={{
                          color: isCurrentCompany ? "#2D9CDB" : "black",
                          fontWeight: isCurrentCompany ? "bold" : "normal",
                        }}
                      >
                        {company.name}
                      </span>
                    </Dropdown.Item>
                  );
                })}
              </>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
}

export default UserMenu;
