import React from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import {
	SET_FINAL_CREATION_DATE,
	SET_FINAL_DELIVERY_DATE,
	SET_FINAL_TASK_BEGIN_DATE,
	SET_FINAL_TASK_END_DATE,
	SET_INITIAL_CREATION_DATE,
	SET_INITIAL_DELIVERY_DATE,
	SET_INITIAL_TASK_BEGIN_DATE,
	SET_INITIAL_TASK_END_DATE,
} from '~/redux/actions/DatailedReportFiltersActions';

function Period() {
	const filters = useSelector(state => state.detailedReportFilters);
	const dispatch = useDispatch();

	const dateFormat = 'DD/MM/YYYY';

	function onDatePickerChange(value, input) {
		value = value ? moment(value).format('YYYY-MM-DD') : null;

		switch (input) {
			case 'initial-creation-date':
				dispatch({ type: SET_INITIAL_CREATION_DATE, data: value });
				break;
			case 'final-creation-date':
				dispatch({ type: SET_FINAL_CREATION_DATE, data: value });
				break;
			case 'initial-task-begin-date':
				dispatch({ type: SET_INITIAL_TASK_BEGIN_DATE, data: value });
				break;
			case 'final-task-begin-date':
				dispatch({ type: SET_FINAL_TASK_BEGIN_DATE, data: value });
				break;
			case 'initial-delivery-date':
				dispatch({ type: SET_INITIAL_DELIVERY_DATE, data: value });
				break;
			case 'final-delivery-date':
				dispatch({ type: SET_FINAL_DELIVERY_DATE, data: value });
				break;
			case 'initial-task-end-date':
				dispatch({ type: SET_INITIAL_TASK_END_DATE, data: value });
				break;
			case 'final-task-end-date':
				dispatch({ type: SET_FINAL_TASK_END_DATE, data: value });
				break;
		}
	}

	return (
		<div>
			<div className="row mb-3">
				<div className="row col-sm-12 col-lg-6">
					<h6 className="col-sm-12">Data de criação:</h6>
					<div className="col-sm-12 col-md-6 mt-1">
						<span className="font-italic mr-2">de</span>
						<DatePicker format={dateFormat}
							value={filters.initialCreationDate ? moment(filters.initialCreationDate) : null}
							placeholder="Selecione uma data"
							onChange={e => onDatePickerChange(e, 'initial-creation-date')} />
					</div>

					<div className="col-sm-12 col-md-6 mt-1">
						<span className="font-italic mr-1">até</span>
						<DatePicker format={dateFormat}
							value={filters.finalCreationDate ? moment(filters.finalCreationDate) : null}
							placeholder="Selecione uma data"
							onChange={e => onDatePickerChange(e, 'final-creation-date')} />
					</div>
				</div>

				<div className="row col-sm-12 col-lg-6">
					<h6 className="col-sm-12">Data de início da tarefa:</h6>
					<div className="col-sm-12 col-md-6">
						<span className="font-italic mr-2 mt-1">de</span>
						<DatePicker format={dateFormat}
							value={filters.initialTaskBeginDate ? moment(filters.initialTaskBeginDate) : null}
							placeholder="Selecione uma data"
							onChange={e => onDatePickerChange(e, 'initial-task-begin-date')} />
					</div>

					<div className="col-sm-12 col-md-6">
						<span className="font-italic mr-1 mt-1">até</span>
						<DatePicker format={dateFormat}
							value={filters.finalTaskBeginDate ? moment(filters.finalTaskBeginDate) : null}
							placeholder="Selecione uma data"
							onChange={e => onDatePickerChange(e, 'final-task-begin-date')} />
					</div>
				</div>
			</div>

			<div className="row mb-3">
				<div className="row col-sm-12 col-lg-6">
					<h6 className="col-sm-12">Data de entrega:</h6>
					<div className="col-sm-12 col-md-6">
						<span className="font-italic mr-2 mt-1">de</span>
						<DatePicker format={dateFormat}
							value={filters.initialDeliveryDate ? moment(filters.initialDeliveryDate) : null}
							placeholder="Selecione uma data"
							onChange={e => onDatePickerChange(e, 'initial-delivery-date')} />
					</div>

					<div className="col-sm-12 col-md-6">
						<span className="font-italic mr-1 mt-1">até</span>
						<DatePicker format={dateFormat}
							value={filters.finalDeliveryDate ? moment(filters.finalDeliveryDate) : null}
							placeholder="Selecione uma data"
							onChange={e => onDatePickerChange(e, 'final-delivery-date')} />
					</div>
				</div>

				<div className="row col-sm-12 col-lg-6">
					<h6 className="col-sm-12">Data de conclusão:</h6>
					<div className="col-sm-12 col-md-6">
						<span className="font-italic mr-2 mt-1">de</span>
						<DatePicker format={dateFormat}
							value={filters.initialTaskEndDate ? moment(filters.initialTaskEndDate) : null}
							placeholder="Selecione uma data"
							onChange={e => onDatePickerChange(e, 'initial-task-end-date')} />
					</div>

					<div className="col-sm-12 col-md-6">
						<span className="font-italic mr-1 mt-1">até</span>
						<DatePicker format={dateFormat}
							value={filters.finalTaskEndDate ? moment(filters.finalTaskEndDate) : null}
							placeholder="Selecione uma data"
							onChange={e => onDatePickerChange(e, 'final-task-end-date')} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default Period;
