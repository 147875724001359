import React from 'react';

function Tabs(props) {
	return (
		<ul {...props}
			className={`nav nav-tabs ${props.className || ''}`}>
			{props.children}
		</ul>
	);
}

export default Tabs;
