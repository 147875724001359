import "./Filters.css";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import Inventory from "~pages/DetailedReport/components/FilterTabs/Inventory";
import Projects from "~pages/DetailedReport/components/FilterTabs/Projects";
import Service from "~pages/DetailedReport/components/FilterTabs/Service";
import Sector from "~pages/DetailedReport/components/FilterTabs/Sector";
import Period from "~pages/DetailedReport/components/FilterTabs/Period";
import User from "~pages/DetailedReport/components/FilterTabs/User";
import Status from "~pages/DetailedReport/components/FilterTabs/Status";
import Others from "./FilterTabs/Others";

import Icon from "~/components/Icon/Icon";
import Tabs from "~/components/Tabs/Tabs";
import Tab from "~/components/Tab/Tab";
import { CLEAR_FILTERS } from "~/redux/actions/DatailedReportFiltersActions";

function Filters() {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("period");

  function handleCleanAllFiltersClick() {
    dispatch({ type: CLEAR_FILTERS });
  }

  function handleTabClick(tab) {
    setActiveTab(tab);
  }

  function renderBody(tab) {
    switch (tab) {
      case "period":
        return <Period />;
      case "projects":
        return <Projects />;
      case "sector":
        return <Sector />;
      case "service":
        return <Service />;
      case "inventory":
        return <Inventory />;
      case "user":
        return <User />;
      case "status":
        return <Status />;
      case "others":
        return <Others />;
    }
  }

  return (
    <div>
      <div className="d-flex align-items-center mb-3">
        <h4 className="font-weight-bold mb-0">Filtros</h4>
        <Icon
          className="ml-3 clear-filters-icon"
          tooltip="Limpar todos os filtros"
          onClick={handleCleanAllFiltersClick}
        >
          cleaning_services
        </Icon>
      </div>

      <div className="card">
        <div className="card-header">
          <Tabs className="card-header-tabs">
            <Tab
              label="Período"
              active={activeTab === "period"}
              onClick={handleTabClick.bind(null, "period")}
            />
            <Tab
              label="Projetos"
              active={activeTab === "projects"}
              onClick={handleTabClick.bind(null, "projects")}
            />
            <Tab
              label="Setor"
              active={activeTab === "sector"}
              onClick={handleTabClick.bind(null, "sector")}
            />
            <Tab
              label="Serviço"
              active={activeTab === "service"}
              onClick={handleTabClick.bind(null, "service")}
            />
            <Tab
              label="Inventário"
              active={activeTab === "inventory"}
              onClick={handleTabClick.bind(null, "inventory")}
            />
            <Tab
              label="Usuário"
              active={activeTab === "user"}
              onClick={handleTabClick.bind(null, "user")}
            />
            <Tab
              label="Status"
              active={activeTab === "status"}
              onClick={handleTabClick.bind(null, "status")}
            />
            <Tab
              label="Outros"
              active={activeTab === "others"}
              onClick={handleTabClick.bind(null, "others")}
            />
          </Tabs>
        </div>

        <div className="card-body overflow-auto" style={{ maxHeight: "300px" }}>
          {renderBody(activeTab)}
        </div>
      </div>
    </div>
  );
}

export default Filters;
