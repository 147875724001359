import "./Kanban.css";
import { useState, useEffect, useContext } from "react";
import cloneDeep from "lodash/cloneDeep";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { BeatLoader } from "react-spinners";
import { useSelector } from "react-redux";

import TaskCard from "../TaskCard";
import Modal from "antd/lib/modal/Modal";
import DrawerTask from "../DrawerTask/DrawerTask";
import ModalCreateTask from "~/pages/CreateTask/ModalCreateTask";
import UpdateStatusModal from "~/pages/Status/UpdateStatusModal";

import ToastComponent from "../ToastComponent/ToastComponent";
import {
  TaskRoutes,
  StatusesRoutes,
  CustomCardRoutes,
  TaskFieldRoutes,
} from "../../http/routes";
import { userSelector } from "../../redux/Selectors";
import { getTask } from "../../util/util";
import { ToastContext } from "~/providers/ToastProvider";
import checkPermission from "~/helpers/checkPermission";
import CheckPermission from "~/components/CheckPermission";
import stringToSlug from "~/helpers/stringToSlug";
import {
  CheckCircleTwoTone,
  EllipsisOutlined,
  EditTwoTone,
  DeleteTwoTone,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Menu, Popover, Dropdown, Modal as ModalDelete } from "antd";

function Kanban({ modalIsOpen, handleModal, searchedTask, searchedUser }) {
  const [columns, setColumns] = useState(false);
  const [filteredColumns, setFilteredColumns] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [isTaskDrawerLoading, setIsTaskDrawerLoading] = useState(false);
  const [modalData, setModalData] = useState({});
  const userStore = useSelector(userSelector);
  const [error, setError] = useState({ hasError: false, message: "" });
  const [tasks, setTasks] = useState();
  const [statuses, setStatuses] = useState();
  const [statusClickedToEdit, setStatusClickedToEdit] = useState();
  const [reload, setReload] = useState(false);
  const [taskEditingData, setTaskEditingData] = useState(null);
  const { setToast } = useContext(ToastContext);
  const [isUpdateStatusModalVisible, setIsUpdateStatusModalVisible] =
    useState(false);
  const [customCardFields, setCustomCardFields] = useState([]);

  useEffect(() => {
    if (columns) {
      const aux = cloneDeep(columns);

      for (const [key, value] of Object.entries(aux)) {
        aux[key].items = value.items.filter((e) => {
          const { id, name, description, sector_name } = e;

          return (
            (id &&
              id
                .toLowerCase()
                .indexOf(searchedTask.replace("#", "").toLowerCase()) > -1) ||
            (name &&
              name.toLowerCase().indexOf(searchedTask.toLowerCase()) > -1) ||
            (description &&
              description.toLowerCase().indexOf(searchedTask.toLowerCase()) >
                -1) ||
            (sector_name &&
              sector_name.toLowerCase().indexOf(searchedTask.toLowerCase()) >
                -1)
          );
        });

        setFilteredColumns(aux);
      }
    }
  }, [searchedTask]);

  //TODO - Fix async states updates setModalData and setTasks
  const changeTaskValue = async (name, item, taskId, update) => {
    const auxTasks = cloneDeep(tasks);
    const task = auxTasks.find((task) => task.id === taskId);
    if (task) {
      task[name] = item;

      if (update) {
        try {
          const taskToUpdate = getTask(task);
          taskToUpdate.status_id = taskToUpdate.status.id;
          taskToUpdate.priority_id = task.priority_id;
          taskToUpdate.service_id = [taskToUpdate.service_id];
          taskToUpdate.start_date = transformDate(taskToUpdate.start_date);
          taskToUpdate.due_date = transformDate(taskToUpdate.due_date);

          await TaskRoutes.updateTask(
            taskToUpdate,
            taskId,
            userStore.subprojectId,
          );

          setModalData((prevData) => ({ ...prevData, ...task }));
          setReload(() => true);

          setTimeout(() => setReload(() => false), 1000);
          getDaily();
        } catch (err) {
          if (
            err.response.data.message ===
            "Formulários obrigatórios não preenchidos!"
          ) {
            setToast({
              type: "error",
              title: "Erro!",
              message: `${err.response.data.message}`,
              show: true,
              autohide: true,
            });
          } else {
            console.error(err);
            return false;
          }
        }
      }

      setTasks((prevData) => [{ ...prevData, ...task }]);
      return true;
    }
  };

  function transformDate(date) {
    let newDate;

    try {
      const [dateComp, timeComp] = date.split(" ");
      const [day, month, year] = dateComp.split("/");
      const [hours, min, sec] = timeComp.split(":");

      newDate = new Date(
        +year,
        month - 1,
        +day,
        +hours - 3,
        +min,
        +sec,
      ).getTime();
    } catch {
      newDate = null;
    }

    return newDate;
  }

  const editTask = async (id) => {
    const res = await TaskRoutes.getTask(userStore.subprojectId, id);
    const taskData = res.data.data;

    setTaskEditingData(taskData);

    handleModal();
  };

  const onDragEnd = async (result, columns, setColumns) => {
    function cantChangeStatusToast() {
      return setToast({
        type: "warning",
        title: "Aviso!",
        message:
          "Você não tem permissão para alterar a tarefa para este status!",
        show: true,
        autohide: true,
      });
    }

    if (
      (!checkPermission("modificar-status-exceto-concluido") &&
        !checkPermission("modificar-status-concluido")) ||
      (!checkPermission("modificar-status-exceto-concluido") &&
        checkPermission("modificar-status-concluido") &&
        stringToSlug(columns[result.destination.droppableId].name) !==
          "concluido") ||
      (checkPermission("modificar-status-exceto-concluido") &&
        !checkPermission("modificar-status-concluido") &&
        stringToSlug(columns[result.destination.droppableId].name) ===
          "concluido")
    ) {
      return cantChangeStatusToast();
    }

    const taskMoved = columns[result.source.droppableId].items.find(
      (elem) => elem.id === result.draggableId,
    );

    if (!result.destination) return;

    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);

      await TaskRoutes.updateTask(
        {
          ...taskMoved,
          status_id: result.destination.droppableId,
          service_id: [taskMoved.service_id],
          start_date: transformDate(taskMoved.start_date),
          due_date: transformDate(taskMoved.due_date),
        },
        result.draggableId,
        userStore.subprojectId,
      )
        .then((res) => {
          destItems.splice(destination.index, 1);
          destItems.splice(destination.index, 0, res.data.data);
          setColumns({
            ...columns,
            [source.droppableId]: {
              ...sourceColumn,
              items: sourceItems,
            },
            [destination.droppableId]: {
              ...destColumn,
              items: destItems,
            },
          });
        })
        .catch((err) => {
          if (
            err.response.data.message ===
            "Formulários obrigatórios não preenchidos!"
          ) {
            setToast({
              type: "error",
              title: "Erro!",
              message: `${err.response.data.message}`,
              show: true,
              autohide: true,
            });
          }
        });

      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
    getDaily();
  };

  useEffect(() => {
    if (userStore.subprojectId || reload) {
      getDaily();
    }
  }, [userStore.subprojectId, searchedUser]);

  const getCustomCardFields = async () => {
    const customCardFields = await CustomCardRoutes.getCustomCardBySubproject(
      userStore.companyId,
      userStore.subprojectId,
    );

    const customCardData = customCardFields.data.data;

    if ("form_field" in customCardData) {
      const fieldResponse = await TaskFieldRoutes.findByLabel(
        encodeURIComponent(customCardData.form_field),
      );
      customCardData["form_field_results"] = fieldResponse.data.data;
    }

    if ("blocked_field" in customCardData) {
      const fieldResponse = await TaskFieldRoutes.findByLabel(
        encodeURIComponent(customCardData.blocked_field),
      );
      customCardData["blocked_field_results"] = fieldResponse.data.data;
    }

    setCustomCardFields(customCardData);
  };

  const getDaily = async () => {
    try {
      const columnsResponse = await StatusesRoutes.getStatus(
        userStore.companyId,
        userStore.subprojectId,
      );

      getCustomCardFields();

      const columns = await columnsResponse.data.data;

      const columnsBack = await columns.reduce((acc, elem) => {
        return {
          ...acc,
          [elem.id]: {
            id: elem.id,
            default_name: elem.default_name,
            name: elem.name,
            sequence: elem.sequence,
            items: [],
          },
        };
      }, {});

      setStatuses(columns);

      const tasksResponse = await TaskRoutes.list(
        userStore.subprojectId,
        searchedUser,
      );
      const tasks = tasksResponse.data.data;

      setTasks(tasks);

      for (const key in columnsBack)
        columnsBack[key].items = await tasks.filter(
          (e) => e.status_id === columnsBack[key].id,
        );

      setColumns(columnsBack);
      setFilteredColumns(columnsBack);
    } catch (error) {
      setColumns(false);
      setError({ message: error.message, hasError: true });
    }
    setReload(false);
  };

  const handleCardClick = async (id) => {
    try {
      setIsTaskDrawerLoading(true);
      setModalShow(true);

      const res = await TaskRoutes.getTask(userStore.subprojectId, id);
      const task = res.data.data;

      setModalData({ ...task, statuses });
      setIsTaskDrawerLoading(false);
    } catch (err) {
      setToast({
        type: "error",
        title: "Erro!",
        message: "Um erro ocorreu ao abrir a tarefa!",
        show: true,
        autohide: true,
      });

      setIsTaskDrawerLoading(false);
      setModalShow(false);
    }
  };

  const handleDeleteTask = async (id) => {
    try {
      TaskRoutes.removeTask(id);

      setToast({
        type: "success",
        title: "Ok!",
        message: "Tarefa excluída com sucesso!",
        show: true,
        autohide: true,
      });

      getDaily();
    } catch (error) {
      setToast({
        type: "error",
        title: "Erro!",
        message: "Um erro ocorreu ao excluir a tarefa!",
        show: true,
        autohide: true,
      });
    }
  };

  const handleToFile = (taskId, columnId) => {
    const tasksAux = cloneDeep(tasks);
    const columnsAux = cloneDeep(columns);
    const filteredColumnsAux = cloneDeep(filteredColumns);
    const taskIndex = tasksAux.findIndex(({ id }) => id === taskId);
    const columnIndex = columnsAux[columnId].items.findIndex(
      ({ id }) => id === taskId,
    );
    const filteredColumnIndex = filteredColumnsAux[columnId].items.findIndex(
      ({ id }) => id === taskId,
    );

    tasksAux.splice(taskIndex, 1);
    columnsAux[columnId].items.splice(columnIndex, 1);
    filteredColumnsAux[columnId].items.splice(filteredColumnIndex, 1);

    setTasks(tasksAux);
    setColumns(columnsAux);
    setFilteredColumns(filteredColumnsAux);
  };

  const handleClose = () => {
    setError({ message: "", hasError: false });
  };

  function handleUpdateStatusModalCancel() {
    setStatusClickedToEdit(null);
    setIsUpdateStatusModalVisible(false);
  }

  function handleUpdateStatusModalSuccess() {
    setIsUpdateStatusModalVisible(false);
    getDaily();
  }
  function updateStatus(status) {
    setIsUpdateStatusModalVisible(true);
    setStatusClickedToEdit(status);
  }
  async function deleteStatus(status) {
    const deletedStatus = await StatusesRoutes.deleteById(
      userStore.companyId,
      status,
    );
    getDaily();

    setToast({
      type: "success",
      title: "Sucesso!",
      message: "Coluna excluida!",
      show: true,
      autohide: true,
    });
  }

  async function handleNewList() {
    let companyId = userStore.companyId;

    ////TRATAR ANTES o SEQUENCE (procurar o segundo maior valor e decrementar 1)

    let initialStatus = {
      statuses: [
        {
          name: "NOVA LISTA",
          sequence:
            statuses[statuses.length - 1].sequence + (1 - statuses.length),
          subprojectId: statuses[statuses.length - 1].subproject_id,
        },
      ],
    };

    await StatusesRoutes.save(companyId, initialStatus);
    getDaily();
    setToast({
      type: "success",
      title: "Sucesso!",
      message: "Novo status criado com sucesso!",
      show: true,
      autohide: true,
    });
  }

  const closeCreateModal = () => {
    setTaskEditingData(null);
    handleModal();
  };

  const sensor = (api) => {
    // console.log({ api });
  };

  async function changeStatusSequence(columnId, _direction) {
    let companyId = userStore.companyId;
    let subprojectId = userStore.subprojectId;

    let direction = { direction: _direction };

    try {
      await StatusesRoutes.updateSequence(
        companyId,
        subprojectId,
        columnId,
        direction,
      );
      getDaily();
    } catch (error) {
      setToast({
        type: "error",
        title: "Erro!",
        message: "Um erro ocorreu ao alterar a posição da coluna!",
        show: true,
        autohide: true,
      });
    }
  }

  const { confirm } = ModalDelete;

  const showConfirmDeleteTaskModal = (columnId) => {
    confirm({
      icon: <ExclamationCircleOutlined style={{ fontSize: "3em" }} />,
      content: `Tem certeza que deseja excluir a coluna?`,
      okText: "Excluir",
      okButtonProps: {
        style: {
          alignContent: "center",
          display: "inline-flex",
          alignItems: "center",
          alignSelf: "center",
        },
        danger: true,
        icon: <DeleteOutlined></DeleteOutlined>,
      },
      cancelText: "Cancelar",
      closable: true,
      onOk() {
        deleteStatus(columnId);
      },
    });
  };

  function renderColumns() {
    if (!columns)
      return (
        <div className="w-100 d-flex justify-content-center">
          <BeatLoader color="#000000" size={20} />
        </div>
      );

    return (
      <DragDropContext
        sensors={[sensor]}
        onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
      >
        {Object.entries(filteredColumns || columns)
          .sort(([, a], [, b]) => a.sequence - b.sequence)
          .map(([columnId, column]) => {
            const { name, items, default_name, sequence } = column;

            const orderedColumns = Object.entries(columns).sort(
              ([, a], [, b]) => a.sequence - b.sequence,
            );

            function isFirst(columnId) {
              return columnId === orderedColumns[0][0];
            }

            function isLast(columnId) {
              return (
                columnId === orderedColumns[orderedColumns.length - 1][0] ||
                columnId === orderedColumns[orderedColumns.length - 2][0]
              );
            }

            const columnName = default_name === null ? name : default_name;

            const menu = (
              <Menu>
                <Menu.ItemGroup title="Editar">
                  <Menu.Item>
                    <button
                      style={{
                        backgroundColor: "rgba(128, 128, 128, 0)",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: 500,

                        color: "#585858",
                      }}
                      onClick={() => updateStatus(columnId)}
                    >
                      {" "}
                      <EditTwoTone className="mr-2"></EditTwoTone> Título
                    </button>
                  </Menu.Item>
                  {columnName !== "CONCLUÍDO" && (
                    <Menu.Item>
                      <button
                        style={{
                          display: "flex",
                          backgroundColor: "rgba(128, 128, 128, 0)",
                          justifyContent: "space-between",
                          alignItems: "center",
                          fontWeight: 500,

                          color: "#585858",
                        }}
                        onClick={() => showConfirmDeleteTaskModal(columnId)}
                      >
                        <DeleteTwoTone
                          twoToneColor="#FF7875"
                          className="mr-2"
                        ></DeleteTwoTone>{" "}
                        Deletar
                      </button>
                    </Menu.Item>
                  )}
                </Menu.ItemGroup>
                {columnName !== "CONCLUÍDO" && (
                  <Menu.ItemGroup title="Mover Posição">
                    {!isLast(columnId) && (
                      <Menu.Item>
                        <button
                          style={{
                            display: "flex",
                            backgroundColor: "rgba(128, 128, 128, 0)",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontWeight: 500,
                            color: "#585858",
                          }}
                          onClick={() =>
                            changeStatusSequence(columnId, "right")
                          }
                        >
                          <ArrowRightOutlined
                            style={{ color: "#27AE60" }}
                            className="mr-2"
                          ></ArrowRightOutlined>{" "}
                          Direita
                        </button>
                      </Menu.Item>
                    )}
                    {!isFirst(columnId) && (
                      <Menu.Item>
                        <button
                          style={{
                            display: "flex",
                            backgroundColor: "rgba(128, 128, 128, 0)",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontWeight: 500,
                            color: "#585858",
                          }}
                          onClick={() => changeStatusSequence(columnId, "left")}
                        >
                          <ArrowLeftOutlined
                            style={{ color: "#27AE60" }}
                            className="mr-2"
                          ></ArrowLeftOutlined>{" "}
                          Esquerda
                        </button>
                      </Menu.Item>
                    )}
                  </Menu.ItemGroup>
                )}
              </Menu>
            );

            return (
              <div className="kanban-wrapper" key={sequence}>
                <div className="editStatus">
                  <h4 className="kanban-title">
                    {name}
                    {columnName === "CONCLUÍDO" && (
                      <Popover
                        content={
                          "Todas as tarefas desse status serão concluídas!"
                        }
                        title="Final de fluxo"
                      >
                        <div className="kanban-info-title">
                          <CheckCircleTwoTone twoToneColor="#52c41a" />
                        </div>
                      </Popover>
                    )}
                  </h4>

                  <CheckPermission slug="atualizar-status">
                    <Dropdown overlay={menu} trigger={["click"]}>
                      <div className="edit-column-button">
                        <EllipsisOutlined
                          style={{
                            color: "#585858",

                            fontSize: "25px",
                          }}
                        ></EllipsisOutlined>
                      </div>
                    </Dropdown>
                  </CheckPermission>
                </div>

                <div
                  className="kanban-tasks-list"
                  style={{
                    maxHeight: window.innerHeight - 335,
                  }}
                >
                  <Droppable droppableId={columnId} key={columnId}>
                    {(provided) => {
                      return (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            padding: 4,
                            width: 360,
                            minHeight: 500,
                          }}
                        >
                          {items.map((item, index) => {
                            const { id } = item;

                            return (
                              <Draggable
                                key={id}
                                draggableId={id}
                                index={index}
                              >
                                {(provided, snapshot) => {
                                  const {
                                    id,
                                    name,
                                    description,
                                    status_name,
                                    sector_name,
                                    service_name,
                                    priority_name,
                                    priority_color,
                                    responsible_photo,
                                    date_closed,
                                    due_date,
                                    start_date,
                                    comments_quantity,
                                    attachments_quantity,
                                    observers_quantity,
                                    fields_quantity,
                                    filled_fields,
                                    days_late,
                                  } = item;

                                  return (
                                    <TaskCard
                                      id={id}
                                      name={name}
                                      onFinishChangeSubproject={getDaily}
                                      description={description}
                                      statusName={status_name}
                                      sectorName={sector_name}
                                      serviceName={service_name}
                                      priorityName={priority_name}
                                      priorityColor={priority_color}
                                      responsiblePhoto={responsible_photo}
                                      dateClosed={date_closed}
                                      dueDate={due_date}
                                      startDate={start_date}
                                      commentsQuantity={comments_quantity}
                                      attachmentsQuantity={attachments_quantity}
                                      observersQuantity={observers_quantity}
                                      fieldsQuantity={fields_quantity}
                                      filledFields={filled_fields}
                                      daysLate={days_late}
                                      provided={provided}
                                      snapshot={snapshot}
                                      handleCardClick={handleCardClick.bind(
                                        null,
                                        id,
                                      )}
                                      onToFile={(taskId) =>
                                        handleToFile(taskId, columnId)
                                      }
                                      handleDeleteTask={handleDeleteTask}
                                      customFields={customCardFields}
                                    />
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      );
                    }}
                  </Droppable>
                </div>
              </div>
            );
          })}
        <div>
          <button className="kanban-new-list" onClick={() => handleNewList()}>
            + Adicionar novo status
          </button>
        </div>
      </DragDropContext>
    );
  }

  return (
    <div className="kanban-container">
      <ToastComponent
        delay="5000"
        title="Ops! Houve um erro"
        description={error.message}
        type="error"
        showToast={error.hasError}
        handleClose={handleClose}
      />
      {modalShow && (
        <DrawerTask
          show={modalShow}
          loading={isTaskDrawerLoading}
          onClose={() => setModalShow(false)}
          data={modalData}
          changeTaskValue={changeTaskValue}
          handleEditTask={editTask}
          afterSaveForm={getDaily}
          afterAddField={getDaily}
        />
      )}

      {renderColumns()}

      <Modal
        width="60%"
        className="modal-projects"
        centered
        visible={modalIsOpen}
        onCancel={closeCreateModal}
        title="Cadastro de tarefa"
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
      >
        <ModalCreateTask
          handleClose={closeCreateModal}
          refreshList={getDaily}
          taskEditingData={taskEditingData}
        />
      </Modal>
      <UpdateStatusModal
        isVisible={isUpdateStatusModalVisible}
        statusId={statusClickedToEdit}
        subprojectId={userStore.subprojectId}
        companyId={userStore.companyId}
        onCancel={handleUpdateStatusModalCancel}
        onSuccess={handleUpdateStatusModalSuccess}
      />
    </div>
  );
}

export default Kanban;
