import React, { useContext, useEffect, useState } from "react";

import Button from "~/components/Button/Button";
import CancelButton from "~/components/CancelButton/CancelButton";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import { Formik, Form } from "formik";
import { Col, Row } from "react-bootstrap";
import FormikInput from "~/components/Input/FormikInput";
import { useSelector } from "react-redux";
import {
	InventoryRoutes,
	SectorRoutes,
	ServiceRoutes,
	UserRoutes,
} from "~/http/routes";

import { userSelector } from "~/redux/Selectors";
import { useParams } from "react-router-dom";
import CollapsibleSubItems from "~/components/CollapsibleSubItems/CollapsibleSubItems";
import CollapsibleSubProject from "~/components/CollapsibleSubItems/CollapsibleSubProject";
import { ToastContext } from "~/providers/ToastProvider";

const { Title } = Typography;

const initialInventory = {
	id: "",
	name: "",
	url_google_maps: "",
	location_details: "",
};

function SectorForm({ history }) {
	const { id } = useParams();
	const [sector, setSector] = useState(initialInventory);
	const { setToast } = useContext(ToastContext);

	const { companyId } = useSelector(userSelector);

	useEffect(() => {
		if (id !== "create") {
			SectorRoutes.getSectorId(companyId, id).then(({ data }) => {
				setSector(data.data);
			});
		}
	}, [companyId, id]);

	const createSector = async (values) => {
		if (!values.inventory_id || !Array.isArray(values.inventory_id))
			values.inventory_id = [];
		const saveOrUpdate = values.id ? SectorRoutes.update : SectorRoutes.save;
		try {
			await saveOrUpdate(companyId, values);
			setToast({
				title: "Sucesso!",
				type: "success",
				message: "Setor salvo com sucesso!",
			});
			history.push("/home/sectors");
		} catch (err) {
			setToast({
				title: "Erro",
				type: "error",
				message: "Um erro inesperado ocorreu ao salvar o setor."
			});
		}
	};

	return (
		<div className="inventory">
			<div className="inventory__title">
				<ArrowLeftOutlined
					className="inventory__title--icon"
					onClick={() => history.goBack()}
				/>
				<Title level={4}>Cadastro de setor</Title>
			</div>
			<div>
				<Formik
					enableReinitialize
					onSubmit={createSector}
					initialValues={sector}
				>
					{({ isSubmitting, submitForm, values, setFieldValue }) => {
						return (
							<Form>
								<Row className="mt-4">
									<Col>
										<FormikInput name="name" placeholder="Nome do setor" />
									</Col>
								</Row>
								<Row className="mt-4">
									<Col md={12}>
										<FormikInput
											name="url_google_maps"
											placeholder="Link do maps"
										/>
									</Col>
								</Row>
								<CollapsibleSubItems
									title="Inventários"
									onSearch={() => InventoryRoutes.getInventory(companyId)}
									selections={values.inventory_id}
									handleSelections={(items) =>
										setFieldValue("inventory_id", items)
									}
								/>
								<CollapsibleSubItems
									title="Serviços"
									onSearch={() => ServiceRoutes.getServices(companyId)}
									selections={values.service_id}
									handleSelections={(items) =>
										setFieldValue("service_id", items)
									}
								/>
								{/* <CollapsibleSubItems
                  title="Usuários"
                  onSearch={UserRoutes.getUsers}
                  selections={values.user_id}
                  handleSelections={(items) => setFieldValue("user_id", items)}
                /> */}
								<CollapsibleSubItems
									title="Usuários"
									onSearch={UserRoutes.getUsers}
									selections={values.user_id}
									handleSelections={(items) => setFieldValue("user_id", items)}
								/>
								<CollapsibleSubProject
									selections={values.sub_project_id}
									handleSelections={(items) => setFieldValue("sub_project_id", items)}
								/>
								<Row className="mt-5 d-flex align-items-center flex-column">
									<Button
										className="button__save"
										size="large"
										onClick={submitForm}
										loading={isSubmitting}
									>
										Salvar
									</Button>
									<CancelButton />
								</Row>
							</Form>
						);
					}}
				</Formik>
			</div>
		</div>
	);
}

export default SectorForm;
