import React from 'react';

function Tab(props) {
	const {
		label,
		active,
		...rest
	} = props;

	return (
		<li className="nav-item"
			style={{ cursor: 'pointer' }}>
			<span {...rest}
				className={`nav-link ${active ? 'active' : ''}`}
				style={{ paddingLeft: '30px', paddingRight: '30px' }}>
				{label}
			</span>
		</li>
	);
}

export default Tab;
