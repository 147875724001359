import "./UserProfile.css";
import React, { useState } from "react";
import { isEqual, isEmpty } from "lodash";
import { Formik } from "formik";
import { Image } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { BeatLoader } from "react-spinners";

import FormikInput from "~/components/Input/FormikInput";
import Breadcrumb from "~/components/Breadcrumb/Breadcrumb";
import imagePlaceHolder from "~/assets/avatar-placeholder.png";
import { UserRoutes } from "~/http/routes";
import { userSelector } from "~/redux/Selectors";

function UserProfile() {
  const { id, userName, userPhoto, userPhone, userEmail, companyId } =
    useSelector(userSelector);

  const dispatch = useDispatch();

  const [user, setUser] = useState({
    name: userName,
    email: userEmail,
    phone: userPhone,
    photo: userPhoto,
    password: "",
  });

  const [hasErrors, setHasErrors] = useState(false);

  function renderSaveButton(isSubmitting, values) {
    const isDisabled = isSubmitting || isEqual(user, values) || hasErrors;

    function renderChildren() {
      if (isSubmitting) {
        return <BeatLoader color="#000000" loading={isSubmitting} size={10} />;
      }

      return "Salvar";
    }

    return (
      <button
        className="btn-default"
        style={{
          width: "150px",
          ...(isDisabled && { backgroundColor: "#ccc" }),
        }}
        type="submit"
        disabled={isDisabled}
      >
        {renderChildren()}
      </button>
    );
  }

  function renderCancelButton(isSubmitting, values, handleClick) {
    const isHidden = isSubmitting || isEqual(user, values) || hasErrors;

    if (!isHidden) {
      return (
        <span id="cancel-button" role="button" onClick={handleClick}>
          Cancelar
        </span>
      );
    }

    return null;
  }

  function toBase64(file, getBase64) {
    const reader = new FileReader();

    if (file && file.type.match("image.*")) {
      reader.readAsDataURL(file);
    }

    reader.onloadend = () => {
      getBase64(reader.result);
    };

    reader.onerror = (err) => {
      console.log("error:", err);
    };
  }

  async function handleFormSubmit(user, { setFieldValue }) {
    if (window.confirm("Tem certeza que deseja realizar as alterações?")) {
      const { name, email, phone, photo, password } = user;

      let photoToSave = photo.split("base64,")[1];

      try {
        await UserRoutes.updateUserProfile(companyId, id, {
          ...(name && { name }),
          ...(email && { email }),
          ...(phone && { phone }),
          ...(photo && { photo: photoToSave }),
          ...(password && { password }),
        });

        dispatch({ type: "SET_USER_NAME", data: name });
        dispatch({ type: "SET_USER_PHONE", data: phone });
        dispatch({ type: "SET_USER_EMAIL", data: email });
        dispatch({ type: "SET_USER_PHOTO", data: photo });

        setFieldValue("name", name);
        setFieldValue("email", email);
        setFieldValue("phone", phone);
        setFieldValue("photo", photo);
        setFieldValue("password", "");

        setUser({
          ...user,
          password: "",
        });
      } catch (err) {
        console.log(err);
      }
    }
  }

  function handleReset(resetForm) {
    if (window.confirm("Tem certeza que deseja cancelar?")) resetForm();
  }

  function handleFormValidation(values) {
    const { name, email, phone, password } = values;

    const errors = {};
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (name && (typeof name !== "string" || name.trim().length <= 0)) {
      errors.name = "Nome inválido!";
    }

    if (email && (typeof email !== "string" || !emailRegex.test(email))) {
      errors.email = "E-mail inválido!";
    }

    if (phone && (typeof phone !== "string" || phone.trim().length <= 0)) {
      errors.phone = "Telefone inválido!";
    }

    if (
      password &&
      (typeof password !== "string" || password.trim().length <= 0)
    ) {
      errors.password = "Senha inválida!";
    }

    if (!isEmpty(errors)) {
      setHasErrors(true);
    } else {
      setHasErrors(false);
    }

    return errors;
  }

  return (
    <div>
      <Breadcrumb title="Meu perfil" routeToBack="/home/dashboard" />

      <div className="d-flex justify-content-center">
        <div id="form-container-div">
          <Formik
            initialValues={{
              name: userName,
              email: userEmail,
              phone: userPhone,
              photo: userPhoto,
              password: "",
            }}
            onSubmit={handleFormSubmit}
            validate={handleFormValidation}
          >
            {({
              values,
              isSubmitting,
              errors,
              handleSubmit,
              resetForm,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="d-flex justify-content-center">
                  <input
                    id="imgupload"
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      toBase64(e.target.files[0], (base64) => {
                        setFieldValue("photo", base64);
                      });
                    }}
                  />

                  <label htmlFor="imgupload">
                    <Image
                      src={values.photo ? values.photo : imagePlaceHolder}
                      width="200"
                      height="200"
                      roundedCircle
                      style={{ cursor: "pointer" }}
                      onClick={null}
                    />
                  </label>
                </div>

                <div className="row mt-4">
                  <div className="col-sm-12 col-md-6 mt-4">
                    <FormikInput
                      placeholder="Nome"
                      name="name"
                      type="text"
                      error={errors.name}
                    />
                  </div>

                  <div className="col-sm-12 col-md-6 mt-4">
                    <FormikInput
                      placeholder="Email"
                      name="email"
                      text="email"
                      error={errors.email}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 col-md-6 mt-4">
                    <FormikInput
                      placeholder="Telefone"
                      name="phone"
                      type="tel"
                      mask="(99) 9 9999-9999"
                      error={errors.phone}
                    />
                  </div>

                  <div className="col-sm-12 col-md-6 mt-4">
                    <FormikInput
                      placeholder="Senha"
                      name="password"
                      type="password"
                      error={errors.password}
                    />
                  </div>
                </div>

                <div className="mt-5">
                  <div className="d-flex justify-content-center">
                    {renderSaveButton(isSubmitting, values)}
                  </div>

                  <div className="d-flex justify-content-center mt-3">
                    {renderCancelButton(
                      isSubmitting,
                      values,
                      handleReset.bind(null, resetForm),
                    )}
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default UserProfile;
