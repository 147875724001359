import React from 'react';
import { Pie } from '@ant-design/charts';

import { FadeLoader } from 'react-spinners';

function TasksPieChart(props) {
	const {
		data,
		title,
		titleFormater,
		loading,
	} = props;

	if (!data || loading)
		return (
			<div className="d-flex align-items-center justify-content-center">
				<FadeLoader color="#000000" loading={true} size={100} />
			</div>
		);

	const noData = [{ type: "NENHUMA TASK ENCONTRADA", value: 0 }];

	return (
		<Pie data={data.length > 0 ? data : noData}
			angleField={"value"}
			colorField={"type"}
			animation={false}
			color={data.length > 0 ? ["#2D9CDB", "#F2994A", "#EB5757", "#CFD8DC"] : ["rgb(255, 227, 0)"]}
			radius={1}
			innerRadius={0.6}
			width={200}
			label={{
				type: "inner",
				offset: "-50%",
				content: "{value}",
				style: {
					textAlign: "center",
					fontSize: 14,
				},
			}}
			legend={false}
			statistic={{
				title: {
					formatter: () => {
						return titleFormater || '0';
					},
					style: {
						fontSize: "35px",
						fontWeight: "bold",
					},
				},
				content: {
					formatter: () => {
						return title;
					},
					style: {
						fontWeight: "400",
						fontSize: "11px",
						marginTop: "7px",
					},
				},
			}} />
	);
}

export default TasksPieChart;
