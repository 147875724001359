import { arrowLeftIcon } from "../../assets/svg/Icons";
import React from "react";
import { Link } from "react-router-dom";

function Breadcrumb(props) {
  const { title, routeToBack } = props;

  return (
    <div className="d-flex">
      <div className="d-inline">
        <Link to={routeToBack}>{arrowLeftIcon}</Link>
      </div>
      <p className="font-weight-bold d-inline ml-3 my-0">{title}</p>
    </div>
  );
}

export default Breadcrumb;
