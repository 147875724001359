import React, { useEffect, useState } from "react";
import { Modal, Card, Typography, Checkbox } from "antd";

const { Text } = Typography;

function FormFieldsModal(props) {
  const {
    isVisible,
    fields,
    formDescription,
    onCancel,
    onSelectedFieldsChange,
  } = props;

  const [selectedFields, setSelectedFields] = useState(fields);

  const handleSelectField = (field) => {
    setSelectedFields((prevSelectedFields) => {
      const isSelected = prevSelectedFields.some(
        (selectedField) => selectedField.id === field.id,
      );

      let newSelectedFields;
      if (isSelected) {
        newSelectedFields = prevSelectedFields.filter(
          (selectedField) => selectedField.id !== field.id,
        );
      } else {
        newSelectedFields = [...prevSelectedFields, field];
      }

      onSelectedFieldsChange(newSelectedFields);
      return newSelectedFields;
    });
  };

  useEffect(() => {
    if (fields.length > 0) {
      setSelectedFields(fields);
    }
  }, [fields]);

  function renderFields() {
    return fields.map((field, index) => (
      <div key={index} className="col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-4">
        <Card
          className="shadow-sm"
          bordered={false}
          bodyStyle={{ padding: "10px" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Text ellipsis>{field.label}</Text>
            <Checkbox
              checked={selectedFields.some(
                (selectedField) => selectedField.id === field.id,
              )}
              onChange={() => handleSelectField(field)}
            />
          </div>
        </Card>
      </div>
    ));
  }

  return (
    <Modal
      visible={isVisible}
      title={`Campos do formulário: ${formDescription}`}
      footer={null}
      width="70%"
      bodyStyle={{ maxHeight: window.innerHeight - 200, overflowY: "auto" }}
      onCancel={onCancel}
    >
      <div className="row">{renderFields()}</div>
    </Modal>
  );
}

export default FormFieldsModal;
