import "./index.css";
import React from "react";
import { UserOutlined } from "@ant-design/icons";
import { Col, Row } from "react-bootstrap";
import { Avatar, Skeleton } from "antd";

import Icon from "../Icon/Icon";

function AtaParticipantCard(props) {
  const {
    name,
    role,
    sawAta,
    picture,
    loading,
    documentNumber,
    deleteParticipant,
  } = props;

  return (
    <div className="ata-participant-card-container">
      {loading ? (
        <Skeleton loading={loading} avatar title={false} />
      ) : (
        <Row>
          <Col className="ata-participant-card-avatar-col" sm={3}>
            <Avatar
              src={picture}
              icon={<UserOutlined />}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          </Col>

          <Col className="ata-participant-card-body-col" sm={5}>
            <div>
              <p className="ata-participant-card-name">{name}</p>

              <p className="ata-participant-card-role">
                {role}
                <br />
                {documentNumber && (
                  <span style={{ fontSize: 12, color: "#888" }}>
                    {documentNumber}
                  </span>
                )}
              </p>
            </div>
          </Col>
          {}

          <Col className="ata-participant-card-done-icon-col" sm={2}>
            <Icon
              style={{
                color: sawAta ? "green" : "#585858",
              }}
            >
              done_all
            </Icon>
          </Col>
          <Col className="ata-participant-card-delete-icon" sm={2}>
            <button onClick={() => deleteParticipant()}>
              <Icon>delete</Icon>
            </button>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default AtaParticipantCard;
