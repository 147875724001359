import "./UserForm.css";
import React, { useContext, useEffect, useState } from "react";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { useParams, useHistory } from "react-router-dom";
import { Typography, Alert, Divider, Skeleton, Switch } from "antd";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Checkbox } from "formik-antd";
import { Formik, Form } from "formik";
import { isEmpty, isEqual } from "lodash";

import { ProjectRoutes, permissionRoutes, UserRoutes } from "~/http/routes";
import CancelButton from "~/components/CancelButton/CancelButton";
import UnderlinedButton from "~/components/UnderlinedButton";
import Breadcrumb from "~/components/Breadcrumb/Breadcrumb";
import CheckPermission from "~/components/CheckPermission";
import FormikInput from "~/components/Input/FormikInput";
import isEmailValid from "~/helpers/isEmailValid";
import Button from "~/components/Button/Button";
import { ToastContext } from "~/providers/ToastProvider";
import { userSelector } from "~/redux/Selectors";

const { Paragraph } = Typography;

Array.prototype.unique = function () {
  var a = this.concat();
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) a.splice(j--, 1);
    }
  }

  return a;
};

function UserForm() {
  const { id } = useParams();
  const { push } = useHistory();
  const { companyId } = useSelector(userSelector);
  const { setToast } = useContext(ToastContext);

  const initialState = {
    id: id !== "create" ? id : "",
    name: "",
    username: "",
    email: "",
    phone: "",
    password: "",
    document_number: "",
    notification_whats: false,
    notification_email: false,
    user_profile_id: "",
    role: "",
    projects: [],
    permissions: [],
  };

  const [projects, setProjects] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [userProfiles, setUserProfiles] = useState([]);
  const [initialUser, setInitialUser] = useState(initialState);
  const [user, setUser] = useState(initialState);

  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [showPermissions, setShowPermissions] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [notificationEmail, setNotificationEmail] = useState(
    initialUser.notification_email,
  );
  const [notificationWhats, setNotificationWhats] = useState(
    initialUser.notification_whats,
  );
  const [isUserRegistered, setIsUserRegistered] = useState(false);
  const [emailFormScreen, setEmailFormScreen] = useState(id === "create");
  const [isUserAlreadyInThisCompany, setIsUserAlreadyInThisCompany] =
    useState(false);

  useEffect(async () => {
    setLoading(true);

    try {
      const { data } = await ProjectRoutes.getProjects(companyId);

      setProjects(data.data);
    } catch (err) {
      setLoading(false);
      errorToastr("Um erro aconteceu durante a busca dos projetos.");
      push("/home/users");
    }
  }, []);

  useEffect(async () => {
    try {
      const { data } = await permissionRoutes.list();

      setPermissions(data.data);
    } catch (err) {
      setLoading(false);
      errorToastr("Um erro aconteceu durante a busca das permissões.");
      push("/home/users");
    }
  }, []);

  useEffect(async () => {
    try {
      const { data } = await permissionRoutes.userProfiles(companyId);
      const profiles = data.data;

      setUserProfiles(profiles);
    } catch (err) {
      setLoading(false);
      errorToastr("Um erro aconteceu durante a busca dos perfis de usuário.");
      push("/home/users");
    }
  }, []);

  useEffect(async () => {
    try {
      if (id !== "create") {
        const { data } = await UserRoutes.findUser(id);
        const user = data.data;

        setIsUserRegistered(true);
        setIsUserAlreadyInThisCompany(true);

        initialUser.id = user.id;
        initialUser.name = user.name;
        initialUser.email = user.email;
        initialUser.username = user.username;
        initialUser.phone = user.phone;
        initialUser.document_number = user.document_number;
        initialUser.notification_whats = trueOrFalse(user.notification_whats);
        initialUser.notification_email = trueOrFalse(user.notification_email);
        initialUser.password = "";
        initialUser.sub_project_id = user.sub_project_id;
        initialUser.sector_id = user.sector_id;
        initialUser.service_id = user.service_id;
        initialUser.inventory_id = user.inventory_id;
        initialUser.project_id = user.project_id;

        setNotificationEmail(trueOrFalse(user.notification_email));
        setNotificationWhats(trueOrFalse(user.notification_whats));

        const company = user.companies.find((e) => e.id === companyId);

        initialUser.user_profile_id = company.user_profile_id;
        initialUser.role = company.role;

        const projectsResponse = await UserRoutes.getProjects(
          companyId,
          user.id,
        );
        let projects = projectsResponse.data.data;

        projects = projects.map((p) => ({
          id: p.id,
          subprojects: p.subprojects.map((sp) => sp.id),
        }));

        initialUser.projects = projects;

        const permissionsResponse = await UserRoutes.getPermissions(
          companyId,
          user.id,
        );
        let permissions = permissionsResponse.data.data;
        permissions = permissions.map((permission) => permission.id);

        initialUser.permissions = permissions;

        setEmailFormScreen(false);
        setInitialUser(initialUser);
        setUser(initialUser);
      }
    } catch (err) {
      setLoading(false);
      errorToastr("Um erro aconteceu.");
      push("/home/users");
    }

    setLoading(false);
  }, [companyId, id]);

  function warningToastr(message) {
    setToast({
      type: "warning",
      title: "Atenção",
      message,
      show: true,
      autohide: true,
    });
  }

  function errorToastr(message) {
    setToast({
      type: "error",
      title: "Erro",
      message,
      show: true,
      autohide: true,
    });
  }

  function successToastr(message) {
    setToast({
      type: "success",
      title: "Sucesso",
      message,
      show: true,
      autohide: true,
    });
  }

  function trueOrFalse(value) {
    return value == 1 || value == null;
  }

  async function onNextPage(email, setFieldValue) {
    setLoading(true);
    const initialUser = Object.assign({}, initialState);

    function setDefaultPermissions() {
      const profile = userProfiles[0];
      const permissions = profile.permissions.map(
        (permission) => permission.id,
      );
      setFieldValue("user_profile_id", profile.id);
      initialUser.user_profile_id = profile.id;
      setFieldValue("permissions", permissions);
      initialUser.permissions = permissions;
    }

    try {
      const { data } = await UserRoutes.findUserByEmail(email);
      const user = data.data;

      if (!isEmpty(user)) {
        setIsUserRegistered(true);
        setFieldValue("id", user.id);
        initialUser.id = user.id;
        setFieldValue("name", user.name);
        initialUser.name = user.name;
        setFieldValue("email", user.email);
        initialUser.email = user.email;
        setFieldValue("username", user.username);
        initialUser.username = user.username;
        setFieldValue("phone", user.phone);
        initialUser.phone = user.phone;
        setFieldValue("password", "");
        initialUser.password = "";
        setFieldValue("document_number", user.document_number);
        initialUser.document_number = user.document_number;

        const company = user.companies.find((e) => e.id === companyId);

        if (company) {
          setIsUserAlreadyInThisCompany(true);

          setFieldValue("user_profile_id", company.user_profile_id);
          initialUser.user_profile_id = company.user_profile_id;

          const { data } = await UserRoutes.getProjects(companyId, user.id);
          let projects = data.data;

          projects = projects.map((p) => ({
            id: p.id,
            subprojects: p.subprojects.map((sp) => sp.id),
          }));

          setFieldValue("projects", projects);
          initialUser.projects = projects;

          const permissionsResponse = await UserRoutes.getPermissions(
            companyId,
            user.id,
          );
          let permissions = permissionsResponse.data.data;
          permissions = permissions.map((permission) => permission.id);

          setFieldValue("permissions", permissions);
          initialUser.permissions = permissions;
        } else setDefaultPermissions();
      } else {
        setDefaultPermissions();
        initialUser.email = email;
      }

      setEmailFormScreen(false);
      setInitialUser(initialUser);
    } catch (err) {
      errorToastr("Um erro aconteceu.");
      push("/home/users");
    }

    setLoading(false);
  }

  function onPreviousPage(setFieldValue, resetForm) {
    setFieldValue("id", "");
    setFieldValue("name", "");
    setFieldValue("username", "");
    setFieldValue("email", "");
    setFieldValue("password", "");
    setFieldValue("user_profile_id", "");
    setFieldValue("projects", []);
    setFieldValue("permissions", []);

    resetStates();

    setEmailFormScreen(true);
    resetForm();
  }

  function resetStates() {
    setInitialUser(initialState);
    setIsUserRegistered(false);
    setIsUserAlreadyInThisCompany(false);
    setShowPermissions(false);
    setShowPassword(false);
    setHasErrors(false);
  }

  function disableInputs() {
    return isUserRegistered || isUserAlreadyInThisCompany || id !== "create";
  }

  function setPermissionsOfProfile(profileId, setFieldValue) {
    const profile = userProfiles.find((profile) => profile.id === profileId);
    const temp = profile.permissions.map((permission) => permission.id);

    setFieldValue("user_profile_id", profileId);
    setFieldValue("permissions", temp);
  }

  function handleSubprojectChange(
    projectId,
    subprojects,
    projects,
    setFieldValue,
  ) {
    const temp = projects;
    const index = temp.findIndex((e) => e.id == projectId);

    if (temp[index]?.subprojects.length === subprojects.length)
      temp.splice(index, 1);
    else {
      if (index > -1) temp[index].subprojects = subprojects;
      else
        temp.push({
          id: projectId,
          subprojects,
        });
    }

    setFieldValue("projects", temp);
  }

  function handlePermissionsChange(userPermissions, id, setFieldValue) {
    const temp = userPermissions;
    const index = temp.findIndex((e) => e == id);

    if (index > -1) temp.splice(index, 1);
    else temp.push(id);

    setFieldValue("permissions", temp);
  }

  function handleFormValidation(values) {
    const { name, username, password, phone, role, document_number } = values;

    const errors = {};

    if (typeof name !== "string" || name.trim().length <= 0) {
      errors.name = "Nome inválido!";
    }

    if (typeof username !== "string" || username.trim().length <= 0) {
      errors.username = "Nome de usuário inválido!";
    }

    if (typeof password !== "string" || password.trim().length <= 0) {
      errors.password = "Senha inválida!";
    }

    if (
      typeof phone !== "string" ||
      phone.trim().length <= 0 ||
      phone.replace(/[\(\)\_\-]/g, "").length < 13
    ) {
      errors.phone = "Número de WhatsApp inválido!";
    }

    if (typeof role !== "string") {
      errors.role = "Cargo inválido!";
    }

    if (
      typeof document_number !== "string" ||
      document_number.trim().length <= 0 ||
      document_number.replace(/[\(\)\_\-]/g, "").length < 11
    ) {
      errors.document = "Número de CPF inválido!";
    }

    if (!isEmpty(errors)) {
      setHasErrors(true);
    } else {
      setHasErrors(false);
    }

    return errors;
  }

  async function handleSubmit(values, { setSubmitting, resetForm }) {
    setSubmitting(true);

    try {
      const projects = values.projects.map((p) => p.id);
      let subprojects = [];

      for (const subproject of values.projects.map((p) => p.subprojects))
        subprojects = [...subprojects, ...subproject];

      if (!isUserAlreadyInThisCompany && !isUserRegistered) {
        const user = {
          name: values.name,
          username: values.username,
          document_number: values.document_number,
          notification_whats: values.notification_whats,
          notification_email: values.notification_email,
          email: values.email,
          password: values.password,
          phone: values.phone.replace(/[\(\)]/g, ""),
        };

        const { data } = await UserRoutes.store(user);

        const { id } = data.data;

        await UserRoutes.associateCompany(
          companyId,
          id,
          values.user_profile_id,
          values.role,
        );
        await UserRoutes.associateProject(companyId, id, projects);
        await UserRoutes.associateSubproject(companyId, id, subprojects);
        await UserRoutes.associatePermissions(
          companyId,
          id,
          values.permissions,
        );
      } else {
        const { id } = values;

        if (isUserRegistered && !isUserAlreadyInThisCompany) {
          await UserRoutes.associateCompany(
            companyId,
            id,
            values.user_profile_id,
            values.role,
          );
        }

        if (isUserRegistered && isUserAlreadyInThisCompany) {
          const userInfo = {
            name: values.name,
            username: values.username,
            document_number: values.document_number,
            notification_whats: values.notification_whats,
            notification_email: values.notification_email,
            email: values.email,
            password: values.password,
            phone: values.phone.replace(/[\(\)]/g, ""),
            sector_id: user.sector_id,
            service_id: user.service_id,
            inventory_id: user.inventory_id,
          };
          await UserRoutes.updateUser(id, userInfo);
          await UserRoutes.disassociateCompany(companyId, id);
          await UserRoutes.associateCompany(
            companyId,
            id,
            values.user_profile_id,
            values.role,
          );
        }

        await UserRoutes.associateProject(companyId, id, projects);
        await UserRoutes.associateSubproject(companyId, id, subprojects);
        await UserRoutes.associatePermissions(
          companyId,
          id,
          values.permissions,
        );
      }

      resetStates();
      resetForm();
      successToastr("Usuário salvo com sucesso.");
      push("/home/users");
    } catch (err) {
      if (err.response) {
        const { message } = err.response.data;

        if (message === "Username já cadastrado") {
          warningToastr(
            "Já existe um usuário cadastrado usando este nome de usuário!",
          );
        } else if (message === "Email já cadastrado") {
          warningToastr(
            "Já existe um usuário cadastrado usando este e-mail de usuário!",
          );
        }
      } else {
        errorToastr(
          "Um erro inesperado aconteceu enquanto o usuário era salvo.",
        );
      }
    }

    setSubmitting(false);
  }

  async function disassociateUser() {
    if (
      window.confirm(
        "Tem certeza que deseja desassociar este usuário de sua empresa?",
      )
    ) {
      await UserRoutes.disassociateCompany(companyId, id);
      push("/home/users");
    }
  }

  function renderPermissionCheckboxs(userPermissions, setFieldValue) {
    if (!showPermissions) return;

    const aux = [];
    const temp = Object.assign([], permissions);
    const quantity = Math.ceil(temp.length / 4);

    while (temp.length) aux.push(temp.splice(0, quantity));

    return aux.map((permissions) => (
      <Col sm={12} md={4} lg={3}>
        {permissions.map(({ id, name }) => (
          <div>
            <Checkbox
              className="ml-0 mr-2 mb-2"
              onChange={() =>
                handlePermissionsChange(userPermissions, id, setFieldValue)
              }
              checked={userPermissions && userPermissions.includes(id)}
            >
              {name}
            </Checkbox>
          </div>
        ))}
      </Col>
    ));
  }

  function renderEmailForm(email, setFieldValue) {
    const isValidEmail = isEmailValid(email);
    const hasError = !email.trim() || !isValidEmail;

    function renderErrorMessage() {
      let message = "Por favor, preencha o campo com um e-mail válido.";

      if (!email.trim()) message = 'O campo "E-mail" é obrigatório.';

      if (hasError)
        return (
          <Alert message={message} type="warning" showIcon={false} banner />
        );
    }

    return (
      <>
        <Row className="mt-4 d-flex justify-content-center">
          <Col sm={12} md={6}>
            <FormikInput
              name="email"
              placeholder="E-mail"
              inputPlaceholder="Exemplo: joaosilva@gmail.com"
            />

            {renderErrorMessage()}
          </Col>
        </Row>

        <Row className="mt-5 d-flex align-items-center flex-column">
          <Button
            className="button__save default-button"
            size="large"
            loading={loading}
            onClick={() => onNextPage(email, setFieldValue)}
            disabled={hasError || loading}
          >
            Próximo
          </Button>
          <CancelButton />
        </Row>
      </>
    );
  }

  function renderInfoMessage() {
    let message = "";

    if (isUserAlreadyInThisCompany && id === "create")
      message =
        "Um usuário com o e-mail informado já encontra-se vinculado à sua empresa. Você pode editá-lo.";
    else if (isUserRegistered && id === "create")
      message =
        "Um usuário com o e-mail informado já encontra-se cadastrado no sistema. Você pode vinculá-lo à sua empresa.";

    if (message)
      return (
        <Alert
          className="mt-4"
          message={message}
          type="info"
          showIcon
          closable
        />
      );
  }

  return (
    <div>
      <Row>
        <Col>
          <Breadcrumb title="Convidar usuário" routeToBack="/home/users" />
        </Col>
        <Col className="d-flex justify-content-end">
          <CheckPermission slug="desassociar-usuario">
            <Button
              className="button__save default-button disassociate-button"
              size="large"
              onClick={disassociateUser}
              loading={null}
              disabled={null}
            >
              Desassociar usuário
            </Button>
          </CheckPermission>
        </Col>
      </Row>

      <div>
        <Formik
          enableReinitialize
          initialValues={user}
          onSubmit={handleSubmit}
          validate={
            !isUserRegistered && !isUserAlreadyInThisCompany
              ? handleFormValidation
              : null
          }
        >
          {({
            isSubmitting,
            errors,
            values,
            submitForm,
            setFieldValue,
            resetForm,
          }) => {
            if (emailFormScreen)
              return renderEmailForm(values.email, setFieldValue);

            return (
              <Skeleton loading={loading}>
                {renderInfoMessage()}
                <Form>
                  <Row className="mt-4">
                    <Col sm={12} md={6} className="mb-4">
                      <FormikInput
                        className={disableInputs() && "form-input"}
                        name="name"
                        placeholder="Nome"
                        inputPlaceholder="Exemplo: João Silva"
                        disabled={disableInputs()}
                        error={errors.name}
                      />
                    </Col>
                    <Col sm={12} md={6} className="mb-4">
                      <FormikInput
                        className={disableInputs() && "form-input"}
                        name="username"
                        placeholder="Nome de usuário"
                        inputPlaceholder="Exemplo: João Silva"
                        disabled={disableInputs()}
                        error={errors.username}
                      />
                    </Col>

                    {!disableInputs() && (
                      <Col sm={12} md={6}>
                        <Row>
                          <Col sm={10} className="pr-0">
                            <FormikInput
                              name="password"
                              placeholder="Senha"
                              type={!showPassword ? "password" : "text"}
                              error={errors.password}
                            />
                          </Col>
                          <Col
                            sm={2}
                            className="d-flex justify-content-center align-items-center"
                          >
                            {!showPassword ? (
                              <EyeOutlined
                                onClick={() => setShowPassword(true)}
                                style={{ fontSize: 30 }}
                                title="Exibir senha"
                              />
                            ) : (
                              <EyeInvisibleOutlined
                                onClick={() => setShowPassword(false)}
                                style={{ fontSize: 30 }}
                                title="Esconder senha"
                              />
                            )}
                          </Col>
                        </Row>
                      </Col>
                    )}

                    <Col sm={12} md={6} className="mb-4">
                      <FormikInput
                        className={disableInputs() && "form-input"}
                        name="phone"
                        placeholder="WhatsApp"
                        inputPlaceholder="Exemplo: (44) 9 9889-8998"
                        type="tel"
                        mask="(99) 9 9999-9999"
                        disabled={disableInputs()}
                        error={errors.phone}
                      />
                    </Col>
                    <Col sm={12} md={6} className="mb-4">
                      <FormikInput
                        className={disableInputs() && "form-input"}
                        name="document_number"
                        placeholder="CPF"
                        inputPlaceholder="Exemplo: 777.777.777-77"
                        type="cpf"
                        mask="999.999.999-99"
                        disabled={disableInputs()}
                        error={errors.document}
                      />
                    </Col>

                    <Col sm={12} md={6}>
                      <FormikInput
                        name="role"
                        placeholder="Cargo/empresa"
                        inputPlaceholder="Exemplo: Pedreiro"
                        error={errors.role}
                      />
                    </Col>

                    <Col>
                      <div className="m-auto d-flex  h-100 ">
                        <Paragraph className="font-weight-bold my-auto mr-3">
                          Ativar o envio de notificações via:
                        </Paragraph>
                        <Switch
                          name="notification_email"
                          className="mr-3 my-auto "
                          //disabled={disableInputs()}
                          checked={notificationEmail}
                          checkedChildren="Email"
                          unCheckedChildren="Email"
                          value={notificationEmail}
                          onChange={() => {
                            setFieldValue(
                              "notification_email",
                              !notificationEmail,
                            );
                            setNotificationEmail(!notificationEmail);
                          }}
                        ></Switch>
                        <Switch
                          name="notification_whats"
                          className="my-auto"
                          checked={notificationWhats}
                          //disabled={disableInputs()}
                          checkedChildren="Whats"
                          unCheckedChildren="Whats"
                          value={notificationWhats}
                          onChange={() => {
                            setFieldValue(
                              "notification_whats",
                              !notificationWhats,
                            );
                            setNotificationWhats(!notificationWhats);
                          }}
                        ></Switch>
                      </div>
                    </Col>
                  </Row>

                  <div className="mt-4">
                    <Paragraph className="font-weight-bold">
                      Subprojetos que o usuário terá acesso:
                    </Paragraph>

                    <div className="projects-div">
                      {projects.map((e, i) => {
                        const options = e.subProjects.map((sp) => {
                          return { label: sp.name, value: sp.id };
                        });

                        const value =
                          values.projects.find((p) => p.id == e.id)
                            ?.subprojects || [];

                        return (
                          <div key={i}>
                            {e.name}

                            <div className="ml-5">
                              <Checkbox.Group
                                options={options}
                                value={value}
                                onChange={(subprojects) =>
                                  handleSubprojectChange(
                                    e.id,
                                    subprojects,
                                    values.projects,
                                    setFieldValue,
                                  )
                                }
                              />
                            </div>

                            <Divider />
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="mt-4">
                    <Paragraph className="font-weight-bold">
                      Defina um papel:
                    </Paragraph>

                    <div className="container">
                      <div style={{ textAlign: "center" }}>
                        {userProfiles.map(({ id, name }) => (
                          <Button
                            className={`user-profile-button d-inline-block mr-4 mb-4 ${
                              values.user_profile_id === id &&
                              "user-profile-button-selected"
                            }`}
                            onClick={() =>
                              values.user_profile_id !== id &&
                              setPermissionsOfProfile(id, setFieldValue)
                            }
                            size="large"
                          >
                            {name}
                          </Button>
                        ))}
                      </div>

                      <Row>
                        {renderPermissionCheckboxs(
                          values.permissions,
                          setFieldValue,
                        )}
                      </Row>

                      <div className="d-flex justify-content-end">
                        <UnderlinedButton
                          className="mt-4"
                          style={{ color: "blue" }}
                          onClick={() => setShowPermissions(!showPermissions)}
                        >
                          {!showPermissions
                            ? "Mostrar permissões"
                            : "Ocultar permissões"}
                        </UnderlinedButton>
                      </div>
                    </div>
                  </div>

                  <Row className="mt-5 d-flex align-items-center flex-column">
                    <Button
                      className="button__save default-button"
                      size="large"
                      onClick={submitForm}
                      loading={isSubmitting}
                      disabled={
                        isEqual(initialUser, values) ||
                        (!isUserRegistered &&
                          !isUserAlreadyInThisCompany &&
                          hasErrors) ||
                        isSubmitting
                      }
                    >
                      Salvar
                    </Button>
                    {id !== "create" ? (
                      <UnderlinedButton
                        className="mt-2"
                        onClick={() => push("/home/users")}
                      >
                        Cancelar
                      </UnderlinedButton>
                    ) : (
                      <UnderlinedButton
                        className="mt-2"
                        onClick={() => onPreviousPage(setFieldValue, resetForm)}
                      >
                        Voltar
                      </UnderlinedButton>
                    )}
                  </Row>
                </Form>
              </Skeleton>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default UserForm;
