import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { Checkbox, Divider } from 'antd';

import { SET_FILTERS_SERVICES } from '~/redux/actions/DatailedReportFiltersActions';
import { ToastContext } from '~/providers/ToastProvider';
import { ServiceRoutes } from '~/http/routes';

function Service() {
	const dispatch = useDispatch();
	const { companyId } = useSelector(state => state.userStore);
	const { services } = useSelector(state => state.detailedReportFilters);

	const { setToast } = useContext(ToastContext);
	const [companyServices, setCompanyServices] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(async () => {
		try {
			setCompanyServices((await ServiceRoutes.getServices(companyId)).data.data);
			setIsLoading(false);
		} catch (err) {
			setToast({
				type: 'error',
				title: 'Erro!',
				message: 'Um erro ocorreu ao buscar os serviços da empresa!',
				show: true,
				autohide: true,
			});
			setIsLoading(false);
		}
	}, []);

	function setServices(e) {
		dispatch({ type: SET_FILTERS_SERVICES, data: e });
	}

	function handleCheck(e) {
		const aux = services;
		const index = aux.findIndex(s => s == e);

		if (index > -1)
			aux.splice(index, 1);
		else
			aux.push(e);

		setServices(aux);
	}

	function handleCheckAll(e) {
		if (e.target.checked)
			setServices(companyServices.map(e => e.id));
		else
			setServices([]);
	}

	if (isLoading)
		return (
			<div className="d-flex justify-content-center">
				<Spinner animation="border"
					role="status" />
			</div>
		);

	return (
		<>
			<div className="row">
				<div className="col-sm-12">
					<Checkbox checked={services.length === companyServices.length}
						indeterminate={services.length !== companyServices.length}
						onChange={handleCheckAll}>
						Marcar todos
					</Checkbox>
				</div>

				<Divider />
			</div>

			<div className="row">
				{companyServices.map(({ name, id }, i) => (
					<div key={i}
						className="col-sm-12 col-md-6 col-lg-4 col-xl-3">
						<Checkbox checked={services.find(e => e == id)}
							onChange={handleCheck.bind(null, id)}>
							{name}
						</Checkbox>

						<Divider />
					</div>
				))}
			</div>
		</>
	);
}

export default Service;
