import "./Notifications.css";
import "antd/dist/antd.css";

import { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { List, Tooltip } from "antd";
import { getTask } from "~/util/util";
import moment from "moment";

import { userSelector } from "../../redux/Selectors";
import { TaskRoutes, StatusesRoutes, LogsRoutes } from "../../http/routes";
import { ToastContext } from "~/providers/ToastProvider";

import Modal from "antd/lib/modal/Modal";
import ModalCreateTask from "~/pages/CreateTask/ModalCreateTask";

import Breadcrumb from "~/components/Breadcrumb/Breadcrumb";
import DrawerTask from "../../components/DrawerTask/DrawerTask";

import ToastComponent from "../../components/ToastComponent/ToastComponent";
import { convertToBrazilTime } from "~/util/date";

const backRoute = "/home/projects/kanban";

function Notifications() {
  const [idList, setIdList] = useState([]);

  const [logList, setLogList] = useState([]);

  const [selectedTask, setSelectedTask] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [isTaskDrawerLoading, setIsTaskDrawerLoading] = useState(false);
  const [modalIsOpen, setModalState] = useState(false);
  const [reload, setReload] = useState(false);
  const [taskEditingData, setTaskEditingData] = useState(null);

  const [error, setError] = useState({ hasError: false, message: "" });

  const { setToast } = useContext(ToastContext);

  const userStore = useSelector(userSelector);

  const handleClose = () => {
    setError({ message: "", hasError: false });
  };

  useEffect(() => {
    const getList = async () => {
      const res = await LogsRoutes.getHistoricLogsByUserLogged(
        userStore.companyId,
      );
      setLogList(res.data.data);
    };
    getList();
  }, [reload]);

  let itemsList = [];

  logList.forEach((log, index) => {
    const {
      action,
      subproject_id,
      subproject_name,
      old_subproject_name,
      name,
      old_name,
      responsible_name,
      old_responsible_name,
      description,
      status_name,
      old_status_name,
      priority_name,
      old_priority_name,
      start_date,
      old_start_date,
      due_date,
      old_due_date,
      inventory_name,
      old_inventory_name,
      sector_name,
      old_sector_name,
      observer_name,
      service_name,
      old_service_name,
      comment,
      task_id,
      user,
      created_at,
      fields,
    } = log;
    switch (action) {
      case "subproject_id":
        itemsList.push({
          key: index,
          data: log,
          label: (
            <p>
              <span className="material-icons icons mr-2 ">workspaces</span>
              <b>Subprojeto</b> da Tarefa <b>{task_id}</b> alterado de{" "}
              <b>{old_subproject_name}</b> para{" "}
              <b style={{ color: "#2D9CDB" }}>{subproject_name}</b> por{" "}
              <b style={{ fontWeight: "bold", color: "#FFE300" }}>{user}</b>
              <span className="notification-date" style={{ color: "#c3c3c3" }}>
                {convertToBrazilTime(created_at)}
              </span>
              .
            </p>
          ),
        });
        break;
      case "name":
        itemsList.push({
          key: index,
          data: log,
          label: (
            <p>
              <span className="material-icons icons mr-2 ">title</span>
              <b>Título</b> da Tarefa <b>{task_id}</b> alterado de{" "}
              <b>
                "<Tooltip title={old_name}>{old_name.slice(0, 30)}</Tooltip>
                {old_name.length > 30 ? "..." : ""}"
              </b>{" "}
              para{" "}
              <b style={{ color: "#2D9CDB" }}>
                " <Tooltip title={name}>{name.slice(0, 30)}</Tooltip>
                {name.length > 30 ? "..." : ""}"
              </b>{" "}
              por <b style={{ fontWeight: "bold", color: "#FFE300" }}>{user}</b>{" "}
              <span className="notification-date" style={{ color: "#c3c3c3" }}>
                {convertToBrazilTime(created_at)}
              </span>
              .
            </p>
          ),
        });
        break;
      case "responsible_id":
        itemsList.push({
          key: index,
          data: log,
          label: (
            <p>
              <span className="material-icons icons mr-2 ">account_circle</span>{" "}
              <b>Responsável</b> da Tarefa <b>{task_id}</b> alterado de{" "}
              <b>{old_responsible_name}</b> para{" "}
              <b style={{ color: "#2D9CDB" }}>{responsible_name}</b> por{" "}
              <b style={{ fontWeight: "bold", color: "#FFE300" }}>{user}</b>
              <span className="notification-date" style={{ color: "#c3c3c3" }}>
                {convertToBrazilTime(created_at)}
              </span>
              .
            </p>
          ),
        });
        break;
      case "status_id":
        itemsList.push({
          key: index,
          data: log,
          label: (
            <p>
              <span className="material-icons icons mr-2 ">view_kanban</span>{" "}
              <b>Status</b> da Tarefa <b>{task_id}</b> alterada de{" "}
              <b>{old_status_name}</b> para{" "}
              <b style={{ color: "#2D9CDB" }}>{status_name}</b> por{" "}
              <b style={{ fontWeight: "bold", color: " #FFE300" }}>{user}</b>
              <span className="notification-date" style={{ color: "#c3c3c3" }}>
                {convertToBrazilTime(created_at)}
              </span>
              .
            </p>
          ),
        });
        break;
      case "priority_id":
        itemsList.push({
          key: index,
          data: log,
          label: (
            <p>
              <span className="material-icons icons mr-2 ">priority_high</span>{" "}
              <b>Prioridade</b> da Tarefa <b>{task_id}</b> alterada de{" "}
              <b>{old_priority_name}</b> para{" "}
              <b style={{ color: "#2D9CDB" }}>{priority_name}</b> por{" "}
              <b style={{ fontWeight: "bold", color: " #FFE300" }}>{user}</b>
              <span className="notification-date" style={{ color: "#c3c3c3" }}>
                {convertToBrazilTime(created_at)}
              </span>
              .
            </p>
          ),
        });
        break;
      case "description":
        itemsList.push({
          key: index,
          data: log,
          label: (
            <p>
              <span className="material-icons icons mr-2 ">description</span>{" "}
              <b>Descrição</b> da tarefa <b>{task_id}</b> atualizada para{" "}
              <i style={{ color: "#2D9CDB", fontWeight: "bold" }}>
                "
                <Tooltip title={description}>
                  {description.slice(0, 70)}
                </Tooltip>
                {description.length > 70 ? "..." : ""}"
              </i>{" "}
              por{" "}
              <b style={{ fontWeight: "bold", color: " #FFE300" }}>{user}</b>
              <span className="notification-date" style={{ color: "#c3c3c3" }}>
                {convertToBrazilTime(created_at)}
              </span>
              .
            </p>
          ),
        });
        break;
      case "comment":
        itemsList.push({
          key: index,
          data: log,
          label: (
            <p>
              <span className="material-icons icons mr-2 ">comment</span>{" "}
              <b>Comentário</b> adicionado na tarefa <b>{task_id}</b>
              {": "}
              <i style={{ color: "#2D9CDB", fontWeight: "bold" }}>
                " <Tooltip title={`${comment}`}>{comment.slice(0, 70)}</Tooltip>
                {comment.length > 70 ? "..." : ""}"
              </i>{" "}
              por{" "}
              <b style={{ fontWeight: "bold", color: " #FFE300" }}>{user}</b>
              <span className="notification-date" style={{ color: "#c3c3c3" }}>
                {convertToBrazilTime(created_at)}
              </span>
              .
            </p>
          ),
        });
        break;
      case "due_date":
        itemsList.push({
          key: index,
          data: log,
          label: (
            <p>
              <span className="material-icons icons mr-2 ">event</span>{" "}
              <b>Data de entrega</b> da tarefa <b>{task_id}</b> alterado{" "}
              {old_due_date && (
                <>
                  de{" "}
                  <b>
                    {moment(new Date(parseInt(old_due_date))).format(
                      "DD/MM/YY - HH:mm",
                    )}
                  </b>{" "}
                </>
              )}
              para{" "}
              <b style={{ color: "#2D9CDB" }}>
                {" "}
                {moment(new Date(parseInt(due_date))).format(
                  "DD/MM/YY - HH:mm",
                )}
              </b>{" "}
              por{" "}
              <b style={{ fontWeight: "bold", color: " #FFE300" }}>{user}</b>
              <span className="notification-date" style={{ color: "#c3c3c3" }}>
                {convertToBrazilTime(created_at)}
              </span>
              .
            </p>
          ),
        });
        break;
      case "start_date":
        itemsList.push({
          key: index,
          data: log,
          label: (
            <p>
              <span className="material-icons icons mr-2 ">today</span>{" "}
              <b>Data de início</b> da tarefa <b>{task_id}</b> alterado{" "}
              {old_start_date && (
                <>
                  de{" "}
                  <b>
                    {moment(new Date(parseInt(old_start_date))).format(
                      "DD/MM/YY - HH:mm",
                    )}
                  </b>{" "}
                </>
              )}
              para{" "}
              <b style={{ color: "#2D9CDB" }}>
                {moment(new Date(parseInt(start_date))).format(
                  "DD/MM/YY - HH:mm",
                )}
              </b>{" "}
              por{" "}
              <b style={{ fontWeight: "bold", color: " #FFE300" }}>{user}</b>
              <span className="notification-date" style={{ color: "#c3c3c3" }}>
                {convertToBrazilTime(created_at)}
              </span>
              .
            </p>
          ),
        });
        break;
      case "created_task":
        itemsList.push({
          key: index,
          data: log,
          label: (
            <p>
              <span className="material-icons icons mr-2 ">add_circle</span>{" "}
              <b>Tarefa</b> <b style={{ color: "#2D9CDB" }}>{name}</b> com ID{" "}
              <b style={{ color: "#2D9CDB" }}>{task_id}</b> <b>adicionada</b> ao
              Subprojeto <b>{subproject_name}</b> por{" "}
              <b style={{ fontWeight: "bold", color: " #FFE300" }}>{user}</b>
              <span className="notification-date" style={{ color: "#c3c3c3" }}>
                {convertToBrazilTime(created_at)}
              </span>
              .
            </p>
          ),
        });
        break;
      case "deleted_task":
        itemsList.push({
          key: index,
          data: log,
          label: (
            <p>
              <span className="material-icons icons mr-2 ">delete</span>{" "}
              <b>Tarefa</b> com ID <b style={{ color: "#2D9CDB" }}>{task_id}</b>{" "}
              <b>removida</b> do Subprojeto <b>{subproject_name}</b> por{" "}
              <b style={{ fontWeight: "bold", color: " #FFE300" }}>{user}</b>
              <span className="notification-date" style={{ color: "#c3c3c3" }}>
                {convertToBrazilTime(created_at)}
              </span>
              .
            </p>
          ),
        });
        break;

      case "created_attachment_task":
        itemsList.push({
          key: index,
          data: log,
          label: (
            <p>
              <span className="material-icons icons mr-2 ">attach_file</span>{" "}
              <b>Anexo</b> com nome <b style={{ color: "#2D9CDB" }}>{fields}</b>{" "}
              adicionado à tarefa <b>{task_id}</b>
              por{" "}
              <b style={{ fontWeight: "bold", color: " #FFE300" }}>{user}</b>
              <span className="notification-date" style={{ color: "#c3c3c3" }}>
                {convertToBrazilTime(created_at)}
              </span>
              .
            </p>
          ),
        });
        break;
      case "deleted_attachment_task":
        itemsList.push({
          key: index,
          data: log,
          label: (
            <p>
              <span className="material-icons icons mr-2 ">attachment</span>{" "}
              <b>Anexo</b> excluído da tarefa <b>{task_id}</b>
              por{" "}
              <b style={{ fontWeight: "bold", color: " #FFE300" }}>{user}</b>
              <span className="notification-date" style={{ color: "#c3c3c3" }}>
                {convertToBrazilTime(created_at)}
              </span>
              .
            </p>
          ),
        });
        break;
      case "created_task_field":
        itemsList.push({
          key: index,
          data: log,
          label: (
            <p>
              <span className="material-icons icons mr-2 ">checklist_rtl</span>{" "}
              <b>Formulário</b> com etiqueta{" "}
              <b style={{ color: "#2D9CDB" }}>{fields}</b> adicionado à tarefa{" "}
              <b>{task_id}</b>
              por{" "}
              <b style={{ fontWeight: "bold", color: " #FFE300" }}>{user}</b>
              <span className="notification-date" style={{ color: "#c3c3c3" }}>
                {convertToBrazilTime(created_at)}
              </span>
              .
            </p>
          ),
        });
        break;
      case "updated_task_field":
        itemsList.push({
          key: index,
          data: log,
          label: (
            <p>
              <span className="material-icons icons mr-2 ">edit_note</span>{" "}
              <b>Formulário</b> alterado na tarefa <b>{task_id}</b>
              por{" "}
              <b style={{ fontWeight: "bold", color: " #FFE300" }}>{user}</b>
              <span className="notification-date" style={{ color: "#c3c3c3" }}>
                {convertToBrazilTime(created_at)}
              </span>
              .
            </p>
          ),
        });
        break;
      case "inventory_id":
        itemsList.push({
          key: index,
          data: log,
          label: (
            <p>
              <span className="material-icons icons mr-2 ">inventory_2</span>{" "}
              <b>Inventário</b> da Tarefa <b>{task_id}</b> alterada de{" "}
              <b>{old_inventory_name}</b> para{" "}
              <b style={{ color: "#2D9CDB" }}>{inventory_name}</b> por{" "}
              <b style={{ fontWeight: "bold", color: " #FFE300" }}>{user}</b>
              <span className="notification-date" style={{ color: "#c3c3c3" }}>
                {convertToBrazilTime(created_at)}
              </span>
              .
            </p>
          ),
        });
        break;
      case "sector_id":
        itemsList.push({
          key: index,
          data: log,
          label: (
            <p>
              <span className="material-icons icons mr-2 ">info</span>{" "}
              <b>Setor</b> da Tarefa <b>{task_id}</b> alterada de{" "}
              <b>{old_sector_name}</b> para{" "}
              <b style={{ color: "#2D9CDB" }}>{sector_name}</b> por{" "}
              <b style={{ fontWeight: "bold", color: " #FFE300" }}>{user}</b>
              <span className="notification-date" style={{ color: "#c3c3c3" }}>
                {convertToBrazilTime(created_at)}
              </span>
              .
            </p>
          ),
        });
        break;

      case "service_id":
        itemsList.push({
          key: index,
          data: log,
          label: (
            <p>
              <span className="material-icons icons mr-2 ">info</span>{" "}
              <b>Serviço</b> da Tarefa <b>{task_id}</b> alterada de{" "}
              <b>{old_service_name}</b> para{" "}
              <b style={{ color: "#2D9CDB" }}>{service_name}</b> por{" "}
              <b style={{ fontWeight: "bold", color: " #FFE300" }}>{user}</b>
              <span className="notification-date" style={{ color: "#c3c3c3" }}>
                {convertToBrazilTime(created_at)}
              </span>
              .
            </p>
          ),
        });
        break;
      case "observer_added":
        itemsList.push({
          key: index,
          data: log,
          label: (
            <p>
              <span className="material-icons icons mr-2 ">visibility</span>{" "}
              <b>Observador</b> de nome{" "}
              <b style={{ color: "#2D9CDB" }}>{observer_name}</b> adicionado à
              Tarefa <b>{task_id}</b> por{" "}
              <b style={{ fontWeight: "bold", color: " #FFE300" }}>{user}</b>
              <span className="notification-date" style={{ color: "#c3c3c3" }}>
                {convertToBrazilTime(created_at)}
              </span>
              .
            </p>
          ),
        });
        break;
      case "observer_removed":
        itemsList.push({
          key: index,
          data: log,
          label: (
            <p>
              <span className="material-icons icons mr-2 ">visibility_off</span>{" "}
              <b>Observador</b> de nome{" "}
              <b style={{ color: "#2D9CDB" }}>{observer_name}</b> removido da
              Tarefa <b>{task_id}</b>
              por{" "}
              <b style={{ fontWeight: "bold", color: " #FFE300" }}>{user}</b>
              <span className="notification-date" style={{ color: "#c3c3c3" }}>
                {convertToBrazilTime(created_at)}
              </span>
              .
            </p>
          ),
        });
        break;

      /*      
     
      
    
      case "notification-request":
        itemsList.push({
          key: index,
           data: log,
          label: (
            <p style={{ maxWidth: "26rem" }}>
              {` Tarefa ${task_id} foi renotificada por ${user} em ${convertToBrazilTime(created_at)}.`}
            </p>
          ),
        });
        break;
      case "recolheu-assinatura":
        itemsList.push({
          key: index,
           data: log,
          label: (
            <p style={{ maxWidth: "26rem" }}>
              {` Tarefa ${task_id} foi inserida assinatura digital por ${user} em ${convertToBrazilTime(created_at)}.`}
            </p>
          ),
        });
        break; */

      default:
        break;
    }
  });

  let items = [];

  items = itemsList;

  if (itemsList.length < 1) {
    items.push({
      key: 0,
      label: (
        <p
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: "1.1rem",
            marginTop: "10px",

            color: "#c2c2c2",
          }}
        >
          <span className="material-icons icons mr-2">done_all</span> Sem
          notificações para exibir :D
        </p>
      ),
    });
  }

  async function handleRowClick({ task_id, subproject_id }) {
    try {
      setIsTaskDrawerLoading(true);
      setModalShow(true);
      const res = await TaskRoutes.getTask(subproject_id, task_id);
      const task = res.data.data;
      setSelectedTask(task);

      const company_id = userStore.companyId;

      const statuses = await StatusesRoutes.getStatus(
        company_id,
        subproject_id,
      );
      task.statuses = statuses.data.data;

      setIsTaskDrawerLoading(false);
    } catch (err) {
      setModalShow(false);
      setToast({
        type: "error",
        title: "Erro!",
        message: "Não é possível editar essa tarefa!",
        show: true,
        autohide: true,
      });
    }

    setIsTaskDrawerLoading(false);
  }

  const handleModal = () => {
    setModalState((m) => !m);
  };

  const closeCreateModal = () => {
    setReload(() => true);

    setTimeout(() => setReload(() => false), 300);
    setTaskEditingData(null);
    handleModal();
  };

  async function handleTaskEdit() {
    const res = await TaskRoutes.getTask(
      selectedTask.subproject_id,
      selectedTask.id,
    );
    const taskData = res.data.data;
    setTaskEditingData(taskData);
    setModalState(true);
  }

  async function handleTaskValueChange(name, item, taskId, update) {
    const task = selectedTask;

    if (task) {
      task[name] = item;

      if (update) {
        try {
          const taskToUpdate = getTask(task);
          taskToUpdate.status_id = taskToUpdate.status.id;

          await TaskRoutes.updateTask(
            taskToUpdate,
            taskId,
            taskToUpdate.subproject_id,
          );

          setSelectedTask((e) => ({ ...e, ...task }));
          setReload(() => true);
        } catch (err) {
          setToast({
            type: "error",
            title: "Erro!",
            message: "Um erro ocorreu alterar o status da tarefa!",
            show: true,
            autohide: true,
          });
        }
      }

      return true;
    }
  }

  const reloadListView = async () => {
    try {
      setReload(() => true);
    } catch (error) {
      setError({ message: error.message, hasError: true });
    }
  };

  return (
    <>
      <ToastComponent
        delay="5000"
        title="Ops! Houve um erro"
        description={error.message}
        type="error"
        showToast={error.hasError}
        handleClose={handleClose}
      />
      <div className="d-flex justify-content-between mb-3 ">
        <div className="d-flex ">
          <Breadcrumb routeToBack={backRoute} className={"align-middle"} />
        </div>
      </div>
      <div className="listAllLogs">
        <List
          className="listAllLogs"
          itemLayout="vertical"
          size="large"
          pagination={{
            onChange: (page) => {},
            defaultPageSize: 7,
            pageSizeOptions: [7, 20, 50, 100],
          }}
          dataSource={items}
          footer={<div></div>}
          renderItem={(item) => (
            <List.Item
              className="item-row"
              onClick={() => handleRowClick(item.data)}
              key={item.key}
            >
              {item.label}
            </List.Item>
          )}
        />
      </div>

      {modalShow && (
        <DrawerTask
          show={modalShow}
          loading={isTaskDrawerLoading}
          onClose={() => setModalShow(false)}
          data={selectedTask}
          changeTaskValue={handleTaskValueChange}
          handleEditTask={handleTaskEdit}
          //afterSaveForm={getReport}
          //afterAddField={getReport}
        />
      )}

      <Modal
        width="60%"
        className="modal-projects"
        centered
        open={modalIsOpen}
        onCancel={closeCreateModal}
        title="Editar tarefa"
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
      >
        <ModalCreateTask
          handleClose={closeCreateModal}
          refreshList={reloadListView}
          taskEditingData={taskEditingData}
        />
      </Modal>
    </>
  );
}

export default Notifications;
