import React, { useEffect, useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { Collapse } from "antd";
import {
  projectsIcon,
  registerIcon,
  usersIcon,
  logoIcon,
} from "../../assets/svg/Icons";
import "./SideNav.css";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "../../redux/Selectors";

import Icon from "~/components/Icon/Icon";
import checkPermission from "~/helpers/checkPermission";

const { Panel } = Collapse;

function SideNav() {
  const userStore = useSelector(userSelector);
  const [projectsNav, setProjectsNav] = useState();
  // const [] = useState();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const changeProject = (event) => {
      if (
        parseInt(userStore.subprojectId) !== parseInt(event.target.dataset.id)
      ) {
        dispatch({ type: "SET_SUBPROJECT_ID", data: event.target.dataset.id });
      }
      history.push("/home/projects/kanban");
    };

    const getSubmenuProject = (array) => {
      const htmlProjects = array.reduce((acc, elem) => {
        return (
          <Collapse ghost>
            {acc}
            <Panel header={elem.name} key="3">
              {elem.subProjects.reduce((acc2, elem2) => {
                return (
                  <div>
                    {acc2}
                    <button
                      className={
                        "text-black btn-projects ml-5 " +
                        (parseInt(userStore.subprojectId) === parseInt(elem2.id)
                          ? "active-project"
                          : "")
                      }
                      key={elem2.id}
                      onClick={changeProject}
                      data-id={elem2.id}
                    >
                      {elem2.name}
                    </button>
                  </div>
                );
              }, "")}
            </Panel>
          </Collapse>
        );
      }, "");

      return <>{htmlProjects}</>;
    };

    setProjectsNav(getSubmenuProject(userStore.projectInfos));
  }, [userStore.subprojectId, userStore.projectInfos, dispatch, history]);

  const itemMenu = [
    {
      title: "Diário",
      icon: (
        <Icon type="outlined" style={{ fontSize: 35 }}>
          menu_book
        </Icon>
      ),
      link: "/",
      children: [{ title: "Minhas Tarefas", link: "/home/projects/mytasks" }],
    },
    {
      title: "Cadastros",
      icon: registerIcon,
      link: "/",
      children: [
        { title: "Inventário", link: "/home/inventory" },
        { title: "Setores", link: "/home/sectors" },
        { title: "Serviços", link: "/home/services" },
        { title: "Projetos", link: "/home/projects" },
        { title: "Formulários", link: "/home/forms" },
      ],
    },

    // {
    //   title: "Meu Perfil",
    //   icon: profileIcon,
    //   link: "/",
    //   children: [],
    // },
    // {
    //   title: "Relatórios",
    //   icon: reportIcon,
    //   link: "/",
    //   children: [],
    // },
  ];

  if (checkPermission("criar-novos-usarios")) {
    itemMenu.splice(1, 0, {
      title: "Usuários",
      icon: usersIcon,
      link: "/",
      children: [
        { title: "Convidar usuário", link: "/home/users/invite/create" },
        { title: "Usuários", link: "/home/users" },
      ],
    });
  }

  if (checkPermission("acessar-relatorio-detalhado")) {
    itemMenu.push({
      title: "Relatórios",
      icon: (
        <Icon type="outlined" style={{ fontSize: 35 }}>
          description
        </Icon>
      ),
      link: "/",
      children: [
        { title: "Relatório detalhado", link: "/home/reports/detailed-report" },
        { title: "Relatório de atas", link: "/home/reports/ata-report" },
      ],
    });
  }

  const getSubmenus = (array, title) => {
    return (
      <ul className="submenu list-unstyled">
        <h5 className="mb-4">{title}</h5>
        {array.map((value) => {
          return (
            <NavLink
              exact
              to={value.link}
              className="text-black"
              key={value.title}
            >
              <li className="ml-5">{value.title}</li>
            </NavLink>
          );
        })}
      </ul>
    );
  };

  const navLinks = itemMenu.map((value) => {
    const childrens = value.children.length
      ? getSubmenus(value.children, value.title)
      : null;

    return (
      <div className="relative div-navlink has-submenu" key={value.title}>
        <span className="text-decoration-none" style={{ cursor: "pointer" }}>
          <li className="d-flex flex-column align-items-center py-3 mb-4">
            <div className="pb-3">{value.icon}</div>
            <div>{value.title}</div>
          </li>
        </span>
        {childrens}
      </div>
    );
  });

  return (
    <div
      className="bg-secondary-color"
      style={{ position: "fixed", zIndex: "3000", height: "100%" }}
    >
      <Link to="/home/dashboard" className="text-decoration-none">
        <div className="d-flex flex-column align-items-center padding-link-logo">
          <div className="pb-3">{logoIcon}</div>
          <div>
            <p className="text-uppercase text-primary-color">Pilar</p>
          </div>
        </div>
      </Link>
      <div className="mt-5">
        <ul className="list-unstyled">
          <div className="relative div-navlink has-submenu" key="Projetos">
            <span
              className="text-decoration-none"
              style={{ cursor: "pointer" }}
            >
              <li className="d-flex flex-column align-items-center py-3 mb-4">
                <div className="pb-3">{projectsIcon}</div>
                <div>Projetos</div>
              </li>
            </span>
            <ul className="submenu list-unstyled">
              <NavLink
                exact
                to="/home/projects/myprojects"
                className="text-black"
              >
                <b>
                  <li>Todos os Projetos</li>
                </b>
              </NavLink>
              <div className="separator-div" />
              {projectsNav ? projectsNav : "Carregando..."}
              {/*<NavLink*/}
              {/*  exact*/}
              {/*  to="/"*/}
              {/*  className="text-black"*/}
              {/*>*/}
              {/*  <li className="ml-5">Minhas Tarefas</li>*/}
              {/*</NavLink>*/}
            </ul>
          </div>
          {navLinks}
        </ul>
      </div>
    </div>
  );
}

export default SideNav;
