import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { Checkbox, Divider } from 'antd';

import { SET_FILTERS_USERS } from '~/redux/actions/DatailedReportFiltersActions';
import { ToastContext } from '~/providers/ToastProvider';
import { UserRoutes } from '~/http/routes';

function User() {
	const dispatch = useDispatch();
	const { companyId } = useSelector(state => state.userStore);
	const { users } = useSelector(state => state.detailedReportFilters);

	const { setToast } = useContext(ToastContext);
	const [companyUsers, setCompanyUsers] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		setIsLoading(true);
	}, []);

	useEffect(async () => {
		try {
			setCompanyUsers((await UserRoutes.getCompanyUsers(companyId)).data.data);
			setIsLoading(false);
		} catch (err) {
			setToast({
				type: 'error',
				title: 'Erro!',
				message: 'Um erro ocorreu ao buscar os usuários da empresa!',
				show: true,
				autohide: true,
			});
			setIsLoading(false);
		}
	}, []);

	function setUsers(e) {
		dispatch({ type: SET_FILTERS_USERS, data: e });
	}

	function handleCheck(e) {
		const aux = users;
		const index = aux.findIndex(u => u == e);

		if (index > -1)
			aux.splice(index, 1);
		else
			aux.push(e);

		setUsers(aux);
	}

	function handleCheckAll(e) {
		if (e.target.checked)
			setUsers(companyUsers.map(e => e.id));
		else
			setUsers([]);
	}

	if (isLoading)
		return (
			<div className="d-flex justify-content-center">
				<Spinner animation="border"
					role="status" />
			</div>
		);


	return (
		<>
			<div className="row">
				<div className="col-sm-12">
					<Checkbox checked={users.length === companyUsers.length}
						indeterminate={users.length !== companyUsers.length}
						onChange={handleCheckAll}>
						Marcar todos
					</Checkbox>
				</div>

				<Divider />
			</div>

			<div className="row">
				{companyUsers.map(({ username, id }, i) => (
					<div key={i}
						className="col-sm-12 col-md-6 col-lg-4 col-xl-3">
						<Checkbox checked={users.find(e => e == id)}
							onChange={handleCheck.bind(null, id)}>
							{username}
						</Checkbox>

						<Divider />
					</div>
				))}
			</div>
		</>
	);
}

export default User;
