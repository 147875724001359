import React, { useEffect, useMemo, useState } from "react";
import "./ProjectDropdown.css";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "../../redux/Selectors";
import { useHistory } from "react-router-dom";

const { Option, OptGroup } = Select;

function ProjectDropdown(props) {
  const { projects } = props;
  const userStore = useSelector(userSelector);
  const [selected, setSelected] = useState(userStore.subprojectName);
  const dispatch = useDispatch();
  const history = useHistory();

  const options = useMemo(
    () =>
      projects.reduce((acc, elem) => {
        return (
          <>
            {acc} +
            <OptGroup label={elem.name}>
              {elem.subProjects.reduce((acc2, elem2) => {
                return (
                  <>
                    {acc2} +
                    <Option key={elem2.id} value={elem2.id}>
                      {elem2.name}
                    </Option>
                  </>
                );
              }, <></>)}
            </OptGroup>
          </>
        );
      }, <></>),
    [projects],
  );

  useEffect(() => {
    setSelected(parseInt(userStore.subprojectId));
  }, [userStore.subprojectId]);

  const handleProjectSelected = (value) => {
    dispatch({ type: "SET_SUBPROJECT_ID", data: value });
    history.push("/home/projects/kanban");
  };

  return (
    <div className="dropdown-container d-flex flex-column align-items-center">
      <span className="project pr-3">Projeto</span>
      <Select
        value={selected || ""}
        bordered={false}
        style={{ width: "200px", textAlign: "center" }}
        onChange={handleProjectSelected}
      >
        {options}
      </Select>
      {/*<Dropdown variant="Secondary">*/}
      {/*  <Dropdown.Toggle*/}
      {/*    variant="Secondary"*/}
      {/*    id="dropdown-basic"*/}
      {/*    className="dropdown-toggle-container"*/}
      {/*  >*/}
      {/*    {projectSelected}*/}
      {/*  </Dropdown.Toggle>*/}

      {/*  <Dropdown.Menu>*/}
      {/*    {projects.map((project) => (*/}
      {/*      <Dropdown.Item key={project.name} onClick={handleProjectSelected}>*/}
      {/*        {project.name}*/}
      {/*      </Dropdown.Item>*/}
      {/*    ))}*/}
      {/*  </Dropdown.Menu>*/}
      {/*</Dropdown>*/}
    </div>
  );
}

export default ProjectDropdown;
