import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import store from "./redux/Store";
import { Provider } from "react-redux";
import storeSynchronize from "redux-localstore";
import ToastProvider from './providers/ToastProvider';

storeSynchronize(store);

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<ToastProvider>
				<App />
			</ToastProvider>
		</Provider>
	</React.StrictMode>,
	document.getElementById("root"),
);
