import React, { useEffect, useState } from "react";
import { Collapse, Table } from "antd";
import { useSelector } from "react-redux";
import { userSelector } from "~/redux/Selectors";
import { ProjectRoutes, SubprojectRoutes } from "~/http/routes";

const columns = [
  {
    key: "id",
    title: "Código",
    dataIndex: "id",
  },
  {
    key: "name",
    title: "Nome",
    dataIndex: "name",
  },
];

const CollapsibleSubProject = ({
  selections = [],
  handleSelections,
}) => {
  const [projects, setProjects] = useState([]);

  const { companyId } = useSelector(userSelector);

  useEffect(() => {
    ProjectRoutes.getProjects(companyId)
      .then(projectResponse => {
        projectResponse.data.data.forEach(project => {
          SubprojectRoutes.findAll(project.id)
            .then(subprojectResponse => {
              setProjects(data => [...data, {
                project: project,
                subproject: subprojectResponse.data.data
              }])
            })
        })
      });
  }, [companyId]);

  // const dataSource = options.map((option) => ({ ...option, key: option.id }));

  const getSubProjects = (subproject) => {
    return subproject.map((option) => ({ ...option, key: option.id }));
  }

  const handleClick = (record) => {
    if (selections.indexOf(record.id) === -1) {
      const newArray = [...selections, record.id];
      handleSelections(newArray);
    } else {
      const newArray = [...selections];
      newArray.splice(selections.indexOf(record.id), 1);
      handleSelections(newArray);
    }
  }

  const handleClickAll = (_, __, rows) => {
    const updatedArray = [...selections]
    rows.forEach(record => {
      if (selections.indexOf(record.id) === -1) {
        const newArray = [...updatedArray, record.id];
        updatedArray.push(record.id);
        handleSelections(newArray);
      } else {
        updatedArray.splice(updatedArray.indexOf(record.id), 1);
        handleSelections(updatedArray);
      }
    })

  }

  return (

    <Collapse style={{ marginTop: 15 }}>
      <Collapse.Panel header="Subprojetos">
        <Collapse>
          {
            projects.map(element => (
              <Collapse.Panel header={element.project.name} key={element.project.id}>
                <Table
                  columns={columns}
                  dataSource={getSubProjects(element.subproject)}
                  rowSelection={{
                    type: "checkbox",
                    selectedRowKeys: selections,
                    onSelect: handleClick,
                    onSelectAll: handleClickAll,
                  }}
                />
              </Collapse.Panel>
            ))
          }
        </Collapse>
      </Collapse.Panel>
    </Collapse>
  );
};

export default CollapsibleSubProject;
