import React, { useEffect, useState } from "react";
import "./SubprojectDropdown.css";
import { Select, Modal, Button } from "antd";
import { useSelector } from "react-redux";
import { userSelector } from "../../redux/Selectors";
import { round } from "lodash";

const { Option, OptGroup } = Select;

function SubprojectDropdown(props) {
  const {
    projects,
    handleSubProjectSelected,
    value,
    placeholder,
    id,
    taskId,
    isVisible,
    onClose,
    onSubmit,
  } = props;
  const userStore = useSelector(userSelector);

  const [valueSelected, setValueSelected] = useState(value);

  useEffect(() => {
    setValueSelected(userStore.subprojectId);
  }, [taskId]);

  useEffect(() => {
    if (value) {
      setValueSelected(value);
    } else {
      setValueSelected(userStore.subprojectId);
    }
  }, [value]);

  const options = projects.reduce((acc, elem) => {
    return (
      <>
        {acc} +
        <OptGroup label={elem.name}>
          {elem.subProjects.reduce((acc2, elem2) => {
            return (
              <>
                {acc2} +
                <Option key={elem2.id} value={elem2.id}>
                  {elem2.name}
                </Option>
              </>
            );
          }, <></>)}
        </OptGroup>
      </>
    );
  }, <></>);

  return (
    <Modal
      open={isVisible}
      title={`Alterar Subprojeto`}
      footer={[
        <div className="w-100 justify-content-center d-flex">
          <Button
            disabled={valueSelected == userStore.subprojectId}
            style={{
              borderRadius: "5px",
              width: "150px",
              fontSize: "1rem",
            }}
            className="btn-default"
            onClick={onSubmit}
          >
            Salvar
          </Button>
        </div>,
      ]}
      width="20%"
      bodyStyle={{ maxHeight: window.innerHeight - 200, overflowY: "auto" }}
      destroyOnClose
      onCancel={onClose}
      //afterClose
    >
      <div className="input-div rounded flex">
        <Select
          id={id}
          className="w-100 border-0 h-100 "
          value={valueSelected}
          bordered={false}
          style={{
            width: "200px",
            textAlign: "center",
            padding: "3.6px",
          }}
          onChange={handleSubProjectSelected}
        >
          {options}
        </Select>
        <span
          style={{
            backgroundColor: "white",
          }}
          className="span-input"
        >
          {placeholder}
        </span>
      </div>
    </Modal>
  );
}

export default SubprojectDropdown;
