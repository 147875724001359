import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';

import CalcCard from '~/components/CalcCard/CalcCard';
import { ToastContext } from '~/providers/ToastProvider';

function MTBFCard(props) {
	const {
		tasks,
		loading,
	} = props;

	const [MTBF, setMTBF] = useState(0);
	const { setToast } = useContext(ToastContext);

	useEffect(() => {
		getMTTR();
	}, [tasks]);

	function filterTasks(task) {
		return task.inventory_id;
	}

	function getMTTR() {
		try {
			const filteredTasks = tasks.filter(filterTasks);
			const distinctInventories = [];
			let TWT = 0;
			let A = 0;
			let E = 0;
			let TT = 0;
			let MTBF = 0;

			let today = moment();

			let minDate = today;

			for (const task of filteredTasks) {
				if (task.worked_time)
					TWT += task.worked_time;

				if (distinctInventories.indexOf(task.inventory_id) === -1)
					distinctInventories.push(task.inventory_id);

				if (moment(task.createdAt).isBefore(minDate))
					minDate = moment(task.createdAt);

			}

			A = filteredTasks.length;
			E = distinctInventories.length;
			TT = today.diff(minDate, 'hours');

			MTBF = ((TT * E) - (TWT / 3600)) / A

			setMTBF(MTBF);
		} catch (err) {
			setToast({
				type: 'error',
				title: 'Erro!',
				message: 'Um erro ocorreu ao calcular o MTBF!',
				show: true,
				autohide: true,
			});
		}
	}

	return (
		<CalcCard title="MTBF"
			quantity={!isNaN(MTBF.toFixed(2)) ? MTBF.toFixed(2) : '0.00'}
			measureUnit="horas"
			description="TEMPO MÉDIO ENTRE FALHAS"
			inform="MTBF é o tempo médio entre falhas (Mean Time Between Fails). Este índice mostra, em geral, o tempo para o aparecimento de falhas no sistema. O índice é relacionado à maquinas e equipamentos do inventário."
			loading={loading} />
	);
}

export default MTBFCard;
