import React from "react";
import "./SearchBar.css";

import { searchIcon } from "../../assets/svg/Icons";

function SearchBar(props) {
  const { placeholder, id, value, handleChange } = props;

  return (
    <div className="search-container w-100">
      <div className="d-flex align-items-center input-wrapper">
        <span className="position-absolute pl-3">{searchIcon}</span>
        <input
          className="form-control pl-5 shadow-none search-input"
          type="search"
          placeholder={placeholder}
          aria-label="Search"
          id={id}
          value={value}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}

export default SearchBar;
