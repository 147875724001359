import React, { useCallback, useEffect, useState } from "react";
import { Table } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { ServiceRoutes } from "~/http/routes";
import { useSelector } from "react-redux";
import { userSelector } from "~/redux/Selectors";

import { Input } from "antd";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined, SearchOutlined } from "@ant-design/icons";
import Button from "~/components/Button/Button";
import { Typography } from "antd";
import CheckPermission from "~/components/CheckPermission";
import checkPermission from "~/helpers/checkPermission";

const { Title } = Typography;

const ServiceList = () => {
	const history = useHistory();
	const [services, setServices] = useState([]);
	const { companyId } = useSelector(userSelector);
	const [searchFilterValue, setSearchFilterValue] = useState("");

	const handleInputChange = (event) => {
		setSearchFilterValue(event.target.value);
	};

	const filterList = useCallback(() => {
		return services.filter((value) =>
			value.name.toLowerCase().includes(searchFilterValue.toLowerCase()),
		);
	}, [searchFilterValue, services]);

	useEffect(() => {
		ServiceRoutes.getServices(companyId).then(({ data }) => {
			setServices(data.data);
		});
	}, [companyId]);

	function getColumns() {
		const columns = [
			{
				key: "name",
				title: "Nome",
				dataIndex: "name",
				width: "90%",
				onFilter: (value, record) => {
					return record["name"]
						.toString()
						.toLowerCase()
						.includes(value.toLowerCase());
				},
			},
			// {
			//   key: "active",
			//   title: "Ativo",
			//   dataIndex: "active",
			//   render: (active) => (active ? "Sim" : "Não"),
			// },
		];

		if (checkPermission('editar-servicos')) {
			columns.push({
				key: "edit",
				title: "Editar",
				align: "center",
				render: (service) => {
					return (
						<EditOutlined
							className="inventory__icons"
							onClick={() => history.push(`/home/services/${service.id}`)}
						/>
					);
				},
			});
		}

		return columns;
	}

	return (
		<div>
			<div className="inventory__title">
				<ArrowLeftOutlined
					className="inventory__title--icon"
					onClick={() => history.goBack()}
				/>
				<Title level={4}>Listagem de Serviços</Title>
			</div>
			<div className={checkPermission('cadastrar-servicos') && "inventory__filters"}
				style={{ marginTop: '20px' }}>
				<Input
					value={searchFilterValue}
					onChange={handleInputChange}
					className="inventory__filters--search"
					size="large"
					placeholder="Pesquisa por nome"
					prefix={<SearchOutlined />}
				/>
				{/* <Select
          size="large"
          className="inventory__filters--select"
          defaultValue="0"
        >
          <Option value="0">
            <CircleColor color="green">Ativo</CircleColor>
          </Option>
          <Option value="1">
            <CircleColor color="red">Inativo</CircleColor>
          </Option>
        </Select> */}
				<CheckPermission slug="cadastrar-servicos">
					<Link to="/home/services/create">
						<Button size="large" withIcon>
							Novo Cadastro
						</Button>
					</Link>
				</CheckPermission>
			</div>
			<Table
				columns={getColumns()}
				dataSource={filterList()}
			/>
		</div>
	);
};

export default ServiceList;
