import React, { useCallback, useContext, useEffect, useState } from "react";
import moment from "moment";
import { Link, useHistory, useParams } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Skeleton, Table } from "antd";

import Breadcrumb from "~/components/Breadcrumb/Breadcrumb";
import CheckPermission from "~/components/CheckPermission";
import Select from "~/components/Select/Select";
import Button from "~/components/Button/Button";
import Input from "~/components/Input/Input";
import { ToastContext } from "~/providers/ToastProvider";
import { userSelector } from "~/redux/Selectors";
import { AtaRoutes } from "~/http/routes";

const statuses = [
  { name: "Aberta", value: "OPEN" },
  //{ name: "Pendente", value: "PENDING" },
  { name: "Finalizada", value: "FINISHED" },
];

const backRoute = "/home/projects/kanban";

function AtaList() {
  const { subprojectId } = useParams();
  const { push } = useHistory();
  const { setToast } = useContext(ToastContext);

  const [atas, setAtas] = useState([]);

  const [thirdPartyCompanies, setThirdPartyCompanies] = useState([]);
  const [dates, setDates] = useState([]);

  const [titleOrCode, setTitleOrCode] = useState("");
  const [thirdPartyCompany, setThirdPartyCompany] = useState("");
  const [date, setDate] = useState("");
  const [status, setStatus] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    setLoading(true);

    let atas;
    const thirdPartyCompanies = [];
    const dates = [];

    try {
      atas = (await AtaRoutes.getAtasBySubproject(subprojectId)).data;
    } catch (err) {
      setLoading(false);
      push(backRoute);

      return setToast({
        type: "error",
        title: "Erro",
        message: "Um erro inesperado ocorreu durante a busca pelas atas!",
        show: true,
        autohide: true,
      });
    }

    for (const ata of atas) {
      if (
        ata.third_party_company &&
        !thirdPartyCompanies.find(
          (tpc) => tpc.toLowerCase() === ata.third_party_company.toLowerCase(),
        )
      )
        thirdPartyCompanies.push(ata.third_party_company);

      if (
        !dates.find(
          (date) => date === moment(ata.created_at).format("DD/MM/YYYY"),
        )
      )
        dates.push(moment(ata.created_at).format("DD/MM/YYYY"));
    }

    setAtas(atas);
    setThirdPartyCompanies(thirdPartyCompanies.sort());
    setDates(dates.sort());

    setLoading(false);
  }, []);

  const filterList = useCallback(() => {
    return atas.filter((ata) => {
      let satisfied = [];

      if (titleOrCode) {
        satisfied.push(
          ata.title.toLowerCase().includes(titleOrCode.toLowerCase()) ||
            ata.quality_code.toLowerCase().includes(titleOrCode.toLowerCase()),
        );
      }

      if (thirdPartyCompany)
        satisfied.push(
          ata.third_party_company &&
            ata.third_party_company.toLowerCase() ===
              thirdPartyCompany.toLowerCase(),
        );

      if (date)
        satisfied.push(
          ata.created_at &&
            moment(ata.created_at).format("DD/MM/YYYY") === date,
        );

      if (status)
        satisfied.push(
          ata.status && ata.status.toLowerCase() === status.toLowerCase(),
        );

      return !satisfied.includes(false) && !satisfied.includes(null);
    });
  }, [atas, titleOrCode, thirdPartyCompany, date, status]);

  const columns = [
    {
      key: "title",
      title: "Nome",
      dataIndex: "title",
      width: "30%",
    },
    {
      key: "thirdPartyCompany",
      title: "Empresa",
      dataIndex: "third_party_company",
      width: "30%",
    },
    {
      key: "date",
      title: "Data",
      dataIndex: "created_at",
      width: "10%",
      render: (created_at) => moment(created_at).format("DD/MM/YYYY"),
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      width: "10%",
      render: (status) => {
        if (status === "OPEN") return "Aberta";
        //else if (status === "PENDING") return "Pendente";

        return "Finalizada";
      },
    },
    {
      key: "visibility",
      title: "Visualizar",
      align: "center",
      width: "5%",
      render: ({ id }) => {
        return (
          <EyeOutlined
            className="inventory__icons"
            onClick={() => push(`/home/atas/${subprojectId}/${id}`)}
          />
        );
      },
    },
    {
      key: "quality_code",
      title: "Código",
      dataIndex: "quality_code",
      width: "15%",
    },
  ];

  return (
    <div>
      <Breadcrumb title="Listagem de Atas" routeToBack={backRoute} />

      <div
        className="inventory__filters"
        style={{
          backgroundColor: "transparent",
        }}
      >
        <Skeleton loading={loading} title={false} paragraph={{ rows: 2 }}>
          <Row>
            <Col sm={12} md={4}>
              <Input
                placeholder="Título ou código"
                inputPlaceholder="Pesquise por Título ou código"
                value={titleOrCode}
                onChange={(e) => setTitleOrCode(e.target.value)}
                inputIcon={{
                  name: "search",
                }}
              />
            </Col>

            <Col sm={12} md={4}>
              <Select
                placeholder="Empresa"
                inputPlaceholder="Nome da empresa"
                value={thirdPartyCompany}
                labelBackground="#fafafa"
                handleChange={(e) => setThirdPartyCompany(e.target.value)}
                inputBackground="transparent"
                options={thirdPartyCompanies.map((tpc) => ({
                  name: tpc,
                  value: tpc,
                }))}
                withNull
                style={{
                  height: 42,
                }}
              />
            </Col>

            <Col sm={12} md={2}>
              <Select
                placeholder="Data"
                value={date}
                labelBackground="#fafafa"
                handleChange={(e) => setDate(e.target.value)}
                inputBackground="transparent"
                options={dates.map((date) => ({ name: date, value: date }))}
                withNull
                style={{
                  height: 42,
                }}
              />
            </Col>

            <Col sm={12} md={2}>
              <Select
                placeholder="Status"
                value={status}
                labelBackground="#fafafa"
                handleChange={(e) => setStatus(e.target.value)}
                inputBackground="transparent"
                options={statuses}
                withNull
                style={{
                  height: 42,
                }}
              />
            </Col>
          </Row>
        </Skeleton>

        <CheckPermission slug="criar-ata">
          <Link to={`/home/atas/${subprojectId}/create`}>
            <Button size="large" withIcon>
              Novo Cadastro
            </Button>
          </Link>
        </CheckPermission>
      </div>

      <Skeleton loading={loading} paragraph={{ rows: 14 }}>
        <div>
          <Table columns={columns} dataSource={filterList()} />
        </div>
      </Skeleton>
    </div>
  );
}

export default AtaList;
